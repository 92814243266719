import { capitalize, values } from 'lodash';
import { AddressResponseStateOrProvinceEnum } from '../openapi/bff';

export const DOWNLOAD_BUYERS_HOME_GUIDE_PDF_LINK =
  'https://drive.google.com/uc?export=download&id=1cA9zvK4rIAV8aW3kzL6yXOPIB2SPvzQa';

export const WEBSITE_REGEX =
  /^((https?):\/\/)?((www.)?)[a-zA-Z0-9]+([-/_.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?\s*$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REGEX = /^[0-9]{10}/;

export const ALPHA_NUMERIC_REGEX_NO_SPACE = /^[a-zA-Z0-9]*$/;

export const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]/;

export const NUMBER_REGEX = /^[0-9]+$/;

export const WHITESPACE_REGEX = /\s/;

// 100.12, 100.1 and 100 are acceptable. 100.111 is not.
export const MONEY_AMOUNT_REGEX = /^\d+(\.\d{1,2})?$/;

// 100.12, 100.1 and 100 are acceptable. 100.111 is not. Plus negatives.
export const MONEY_AMOUNT_REGEX_ALLOW_NEGATIVES = /^-?\d+(\.\d{1,2})?$/;

export const ROUTING_NUMBER = /^[0-9]{9}$/;

export const UUID_REGEX =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const UUID_RE =
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const SYMBOLS_REGEX = /[!@#$%*()"';:,<>|\\\/=\-_^&]/;

export const parseInitials = (name: string): string => {
  return name
    .split(' ')
    .map((n) => n && n[0])
    .filter((n) => ALPHA_NUMERIC_REGEX.test(n))
    .slice(0, 2)
    .join('')
    .toUpperCase();
};

export const stringToNumber = (string: string): number => {
  return string.toUpperCase().charCodeAt(0);
};

export const capitalizeEnum = (label: string): string =>
  label
    .split('_')
    .map((str) => prettyEnum(str))
    .join(' ');

const prettyEnum = (str: string): string => {
  const lower = str.toLowerCase();
  if (lower === 'ica') return 'ICA';
  if (lower === 'mls') return 'MLS';
  if (lower === 'gci') return 'GCI';
  if (lower === 'gst') return 'GST';
  if (lower === 'hst') return 'HST';
  if (lower === 'pst') return 'PST';
  if (lower === 'qst') return 'QST';
  if (lower === 'ssn') return 'SSN';
  if (lower === 'id') return 'ID';
  if (lower === 'of') return 'of';
  if (lower === 'and') return 'and';

  return capitalize(str);
};

export const cleanPhoneNumber = (
  phoneNumberString: string | undefined
): string => {
  return ('' + phoneNumberString).replace(/\D/g, '');
};

export const getFileTypeFromUrl = (url?: string): string | null => {
  if (!url) {
    return null;
  }

  const match = url.match(/\.(\w+)(\?|$)/);
  return match ? match[1] : null;
};

export const formatPhoneNumber = (
  phoneNumberString: string | undefined
): string | undefined => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return undefined;
};

export const getFileNameFromUrl = (url: string): string | undefined =>
  url?.split('/').pop()?.split('#')[0].split('?')[0];

export const isStringPresent = (
  str: string | undefined | null
): str is string => {
  return !isStringEmpty(str);
};

export const isStringEmpty = (str: string | undefined | null): boolean => {
  return !str || str.length === 0;
};

export const isEqualAndNotEmpty = (str1: string, str2: string): boolean => {
  if (isStringEmpty(str1) || isStringEmpty(str2)) {
    return false;
  }

  return str1 === str2;
};

export const displayStringOrNA = (str?: string): string => str || 'N/A';

export const displayStringOrNotSelected = (str?: string): string =>
  str || 'Not Selected';

export const splitNameIntoFirstAndLast = (
  name: string
): { firstName: string; lastName: string } => {
  if (!name) {
    return { firstName: '', lastName: '' };
  }

  const nameArray = name.split(' ').filter((letter) => letter);
  const isLengthOdd = nameArray.length % 2 !== 0;
  let indexToSplit = Math.floor(nameArray.length / 2);

  if (isLengthOdd) {
    indexToSplit += 1;
  }

  const firstName = nameArray.slice(0, indexToSplit).join(' ');
  const lastName = nameArray.slice(indexToSplit).join(' ');

  return { firstName, lastName };
};

export const isEmptyEditor = (text: string): boolean => {
  return (
    typeof text === 'undefined' ||
    text.replace(/<(.|\n)*?>/g, '').trim().length === 0
  );
};

export interface ISelectOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export const STATES: AddressResponseStateOrProvinceEnum[] = [
  AddressResponseStateOrProvinceEnum.Alabama,
  AddressResponseStateOrProvinceEnum.Alaska,
  AddressResponseStateOrProvinceEnum.Arizona,
  AddressResponseStateOrProvinceEnum.Arkansas,
  AddressResponseStateOrProvinceEnum.California,
  AddressResponseStateOrProvinceEnum.Colorado,
  AddressResponseStateOrProvinceEnum.Connecticut,
  AddressResponseStateOrProvinceEnum.Delaware,
  AddressResponseStateOrProvinceEnum.DistrictOfColumbia,
  AddressResponseStateOrProvinceEnum.Florida,
  AddressResponseStateOrProvinceEnum.Georgia,
  AddressResponseStateOrProvinceEnum.Hawaii,
  AddressResponseStateOrProvinceEnum.Idaho,
  AddressResponseStateOrProvinceEnum.Illinois,
  AddressResponseStateOrProvinceEnum.Indiana,
  AddressResponseStateOrProvinceEnum.Iowa,
  AddressResponseStateOrProvinceEnum.Kansas,
  AddressResponseStateOrProvinceEnum.Kentucky,
  AddressResponseStateOrProvinceEnum.Louisiana,
  AddressResponseStateOrProvinceEnum.Maine,
  AddressResponseStateOrProvinceEnum.Maryland,
  AddressResponseStateOrProvinceEnum.Massachusetts,
  AddressResponseStateOrProvinceEnum.Michigan,
  AddressResponseStateOrProvinceEnum.Minnesota,
  AddressResponseStateOrProvinceEnum.Mississippi,
  AddressResponseStateOrProvinceEnum.Missouri,
  AddressResponseStateOrProvinceEnum.Montana,
  AddressResponseStateOrProvinceEnum.Nebraska,
  AddressResponseStateOrProvinceEnum.Nevada,
  AddressResponseStateOrProvinceEnum.NewHampshire,
  AddressResponseStateOrProvinceEnum.NewJersey,
  AddressResponseStateOrProvinceEnum.NewMexico,
  AddressResponseStateOrProvinceEnum.NewYork,
  AddressResponseStateOrProvinceEnum.NorthCarolina,
  AddressResponseStateOrProvinceEnum.NorthDakota,
  AddressResponseStateOrProvinceEnum.Ohio,
  AddressResponseStateOrProvinceEnum.Oklahoma,
  AddressResponseStateOrProvinceEnum.Oregon,
  AddressResponseStateOrProvinceEnum.Pennsylvania,
  AddressResponseStateOrProvinceEnum.PuertoRico,
  AddressResponseStateOrProvinceEnum.RhodeIsland,
  AddressResponseStateOrProvinceEnum.SouthCarolina,
  AddressResponseStateOrProvinceEnum.SouthDakota,
  AddressResponseStateOrProvinceEnum.Tennessee,
  AddressResponseStateOrProvinceEnum.Texas,
  AddressResponseStateOrProvinceEnum.Utah,
  AddressResponseStateOrProvinceEnum.Vermont,
  AddressResponseStateOrProvinceEnum.Virginia,
  AddressResponseStateOrProvinceEnum.Washington,
  AddressResponseStateOrProvinceEnum.WestVirginia,
  AddressResponseStateOrProvinceEnum.Wisconsin,
  AddressResponseStateOrProvinceEnum.Wyoming,
];

export const countryMapState = {
  ['STATES']: values(STATES)
    .sort((a, z) => a.localeCompare(z))
    .map<ISelectOption>((state) => ({
      value: state,
      label: capitalizeEnum(state),
    })),
};

export const convertToEnum = (str: string): string => {
  return str
    .split(' ')
    .map((s) => s.toUpperCase())
    .join('_');
};
