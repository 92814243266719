/* tslint:disable */
/* eslint-disable */
/**
 * Yenta API
 * Yenta Main Application
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiveAgentsResponse
 */
export interface ActiveAgentsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ActiveAgentsResponse
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AddDomesticTeamMemberRequest
 */
export interface AddDomesticTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddDomesticTeamMemberRequest
     */
    'agentId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddDomesticTeamMemberRequest
     */
    'roles'?: Array<AddDomesticTeamMemberRequestRolesEnum>;
}

export const AddDomesticTeamMemberRequestRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type AddDomesticTeamMemberRequestRolesEnum = typeof AddDomesticTeamMemberRequestRolesEnum[keyof typeof AddDomesticTeamMemberRequestRolesEnum];

/**
 * 
 * @export
 * @interface AddFlexTeamLeader
 */
export interface AddFlexTeamLeader extends AddFlexTeamMember {
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeamLeader
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof AddFlexTeamLeader
     */
    'feeSplits'?: Array<FeeSplit>;
}


/**
 * 
 * @export
 * @interface AddFlexTeamLeaderAllOf
 */
export interface AddFlexTeamLeaderAllOf {
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeamLeaderAllOf
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof AddFlexTeamLeaderAllOf
     */
    'feeSplits'?: Array<FeeSplit>;
}
/**
 * 
 * @export
 * @interface AddFlexTeamLeaderMonomorphic
 */
export interface AddFlexTeamLeaderMonomorphic {
    /**
     * 
     * @type {string}
     * @memberof AddFlexTeamLeaderMonomorphic
     */
    'agentId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddFlexTeamLeaderMonomorphic
     */
    'roles'?: Array<AddFlexTeamLeaderMonomorphicRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeamLeaderMonomorphic
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof AddFlexTeamLeaderMonomorphic
     */
    'feeSplits'?: Array<FeeSplit>;
}

export const AddFlexTeamLeaderMonomorphicRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type AddFlexTeamLeaderMonomorphicRolesEnum = typeof AddFlexTeamLeaderMonomorphicRolesEnum[keyof typeof AddFlexTeamLeaderMonomorphicRolesEnum];

/**
 * 
 * @export
 * @interface AddFlexTeamMember
 */
export interface AddFlexTeamMember {
    /**
     * 
     * @type {string}
     * @memberof AddFlexTeamMember
     */
    'agentId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddFlexTeamMember
     */
    'roles'?: Array<AddFlexTeamMemberRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof AddFlexTeamMember
     */
    'type'?: AddFlexTeamMemberTypeEnum;
}

export const AddFlexTeamMemberRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type AddFlexTeamMemberRolesEnum = typeof AddFlexTeamMemberRolesEnum[keyof typeof AddFlexTeamMemberRolesEnum];
export const AddFlexTeamMemberTypeEnum = {
    Teammate: 'TEAMMATE',
    Leader: 'LEADER'
} as const;

export type AddFlexTeamMemberTypeEnum = typeof AddFlexTeamMemberTypeEnum[keyof typeof AddFlexTeamMemberTypeEnum];

/**
 * 
 * @export
 * @interface AddFlexTeammate
 */
export interface AddFlexTeammate extends AddFlexTeamMember {
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeammate
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<AddMemberLeaderSplit>}
     * @memberof AddFlexTeammate
     */
    'leaderSplits'?: Array<AddMemberLeaderSplit>;
    /**
     * 
     * @type {Array<AddMemberFeeSplit>}
     * @memberof AddFlexTeammate
     */
    'feeSplits'?: Array<AddMemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof AddFlexTeammate
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof AddFlexTeammate
     */
    'paysLeaderSplit': boolean;
}


/**
 * 
 * @export
 * @interface AddFlexTeammateAllOf
 */
export interface AddFlexTeammateAllOf {
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeammateAllOf
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<AddMemberLeaderSplit>}
     * @memberof AddFlexTeammateAllOf
     */
    'leaderSplits'?: Array<AddMemberLeaderSplit>;
    /**
     * 
     * @type {Array<AddMemberFeeSplit>}
     * @memberof AddFlexTeammateAllOf
     */
    'feeSplits'?: Array<AddMemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof AddFlexTeammateAllOf
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof AddFlexTeammateAllOf
     */
    'paysLeaderSplit'?: boolean;
}
/**
 * 
 * @export
 * @interface AddFlexTeammateMonomorphic
 */
export interface AddFlexTeammateMonomorphic {
    /**
     * 
     * @type {string}
     * @memberof AddFlexTeammateMonomorphic
     */
    'agentId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddFlexTeammateMonomorphic
     */
    'roles'?: Array<AddFlexTeammateMonomorphicRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AddFlexTeammateMonomorphic
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<AddMemberLeaderSplit>}
     * @memberof AddFlexTeammateMonomorphic
     */
    'leaderSplits'?: Array<AddMemberLeaderSplit>;
    /**
     * 
     * @type {Array<AddMemberFeeSplit>}
     * @memberof AddFlexTeammateMonomorphic
     */
    'feeSplits'?: Array<AddMemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof AddFlexTeammateMonomorphic
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof AddFlexTeammateMonomorphic
     */
    'paysLeaderSplit': boolean;
}

export const AddFlexTeammateMonomorphicRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type AddFlexTeammateMonomorphicRolesEnum = typeof AddFlexTeammateMonomorphicRolesEnum[keyof typeof AddFlexTeammateMonomorphicRolesEnum];

/**
 * 
 * @export
 * @interface AddGroupMemberRequest
 */
export interface AddGroupMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddGroupMemberRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddGroupMemberRequest
     */
    'role': AddGroupMemberRequestRoleEnum;
}

export const AddGroupMemberRequestRoleEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type AddGroupMemberRequestRoleEnum = typeof AddGroupMemberRequestRoleEnum[keyof typeof AddGroupMemberRequestRoleEnum];

/**
 * 
 * @export
 * @interface AddMemberFeeSplit
 */
export interface AddMemberFeeSplit {
    /**
     * 
     * @type {string}
     * @memberof AddMemberFeeSplit
     */
    'leaderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddMemberFeeSplit
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof AddMemberFeeSplit
     */
    'feeSplits': Array<FeeSplit>;
}
/**
 * 
 * @export
 * @interface AddMemberLeaderSplit
 */
export interface AddMemberLeaderSplit {
    /**
     * 
     * @type {string}
     * @memberof AddMemberLeaderSplit
     */
    'leaderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddMemberLeaderSplit
     */
    'agentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddMemberLeaderSplit
     */
    'leaderSplit': number;
}
/**
 * 
 * @export
 * @interface AddNormalTeamMemberRequest
 */
export interface AddNormalTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddNormalTeamMemberRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddNormalTeamMemberRequest
     */
    'commissionSplit'?: number;
}
/**
 * 
 * @export
 * @interface AddPlatinumTeamMemberRequest
 */
export interface AddPlatinumTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPlatinumTeamMemberRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddPlatinumTeamMemberRequest
     */
    'commissionSplit'?: number;
}
/**
 * 
 * @export
 * @interface AddTeamMemberRequest
 */
export interface AddTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof AddTeamMemberRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddTeamMemberRequest
     */
    'commissionSplit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddTeamMemberRequest
     */
    'roles'?: Array<AddTeamMemberRequestRolesEnum>;
}

export const AddTeamMemberRequestRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type AddTeamMemberRequestRolesEnum = typeof AddTeamMemberRequestRolesEnum[keyof typeof AddTeamMemberRequestRolesEnum];

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': AddressCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'stateOrProvince': AddressStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zipOrPostalCode': string;
}

export const AddressCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressCountryEnum = typeof AddressCountryEnum[keyof typeof AddressCountryEnum];
export const AddressStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressStateOrProvinceEnum = typeof AddressStateOrProvinceEnum[keyof typeof AddressStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AddressRequest
 */
export interface AddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'streetAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'stateOrProvince': AddressRequestStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'country': AddressRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    'zipOrPostalCode': string;
}

export const AddressRequestStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressRequestStateOrProvinceEnum = typeof AddressRequestStateOrProvinceEnum[keyof typeof AddressRequestStateOrProvinceEnum];
export const AddressRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressRequestCountryEnum = typeof AddressRequestCountryEnum[keyof typeof AddressRequestCountryEnum];

/**
 * 
 * @export
 * @interface AddressResponse
 */
export interface AddressResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'country'?: AddressResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress1'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'streetAddress2'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'stateOrProvince'?: AddressResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'zipOrPostalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressResponse
     */
    'oneLine'?: string;
}

export const AddressResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AddressResponseCountryEnum = typeof AddressResponseCountryEnum[keyof typeof AddressResponseCountryEnum];
export const AddressResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AddressResponseStateOrProvinceEnum = typeof AddressResponseStateOrProvinceEnum[keyof typeof AddressResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AdministrativeArea
 */
export interface AdministrativeArea {
    /**
     * 
     * @type {number}
     * @memberof AdministrativeArea
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeArea
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeArea
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeArea
     */
    'countryIso': string;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof AdministrativeArea
     */
    'licenseTransferFee'?: MonetaryAmount;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrativeArea
     */
    'agentCanTransferLicense': boolean;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeArea
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeArea
     */
    'country'?: AdministrativeAreaCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeArea
     */
    'stateOrProvince'?: AdministrativeAreaStateOrProvinceEnum;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof AdministrativeArea
     */
    'signupFee'?: MonetaryAmount;
}

export const AdministrativeAreaCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AdministrativeAreaCountryEnum = typeof AdministrativeAreaCountryEnum[keyof typeof AdministrativeAreaCountryEnum];
export const AdministrativeAreaStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AdministrativeAreaStateOrProvinceEnum = typeof AdministrativeAreaStateOrProvinceEnum[keyof typeof AdministrativeAreaStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AdministrativeAreaRequest
 */
export interface AdministrativeAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaRequest
     */
    'country': AdministrativeAreaRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaRequest
     */
    'stateOrProvince': AdministrativeAreaRequestStateOrProvinceEnum;
}

export const AdministrativeAreaRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AdministrativeAreaRequestCountryEnum = typeof AdministrativeAreaRequestCountryEnum[keyof typeof AdministrativeAreaRequestCountryEnum];
export const AdministrativeAreaRequestStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AdministrativeAreaRequestStateOrProvinceEnum = typeof AdministrativeAreaRequestStateOrProvinceEnum[keyof typeof AdministrativeAreaRequestStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AdministrativeAreaResponse
 */
export interface AdministrativeAreaResponse {
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AdministrativeAreaResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'country'?: AdministrativeAreaResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AdministrativeAreaResponse
     */
    'stateOrProvince'?: AdministrativeAreaResponseStateOrProvinceEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'signupFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof AdministrativeAreaResponse
     */
    'licenseTransferFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof AdministrativeAreaResponse
     */
    'agentCanTransferLicense'?: boolean;
}

export const AdministrativeAreaResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AdministrativeAreaResponseCountryEnum = typeof AdministrativeAreaResponseCountryEnum[keyof typeof AdministrativeAreaResponseCountryEnum];
export const AdministrativeAreaResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AdministrativeAreaResponseStateOrProvinceEnum = typeof AdministrativeAreaResponseStateOrProvinceEnum[keyof typeof AdministrativeAreaResponseStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface AgentBasicInfoResponse
 */
export interface AgentBasicInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'realUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'defaultCurrency'?: AgentBasicInfoResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentBasicInfoResponse
     */
    'status'?: AgentBasicInfoResponseStatusEnum;
}

export const AgentBasicInfoResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentBasicInfoResponseDefaultCurrencyEnum = typeof AgentBasicInfoResponseDefaultCurrencyEnum[keyof typeof AgentBasicInfoResponseDefaultCurrencyEnum];
export const AgentBasicInfoResponseStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentBasicInfoResponseStatusEnum = typeof AgentBasicInfoResponseStatusEnum[keyof typeof AgentBasicInfoResponseStatusEnum];

/**
 * 
 * @export
 * @interface AgentBasicInfosResponse
 */
export interface AgentBasicInfosResponse {
    /**
     * 
     * @type {Array<AgentBasicInfoResponse>}
     * @memberof AgentBasicInfosResponse
     */
    'agentBasicInfos'?: Array<AgentBasicInfoResponse>;
}
/**
 * 
 * @export
 * @interface AgentBySlugResponse
 */
export interface AgentBySlugResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {Array<PublicLicenseResponse>}
     * @memberof AgentBySlugResponse
     */
    'licenses'?: Array<PublicLicenseResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentBySlugResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<PublicLocationResponse>}
     * @memberof AgentBySlugResponse
     */
    'locations'?: Array<PublicLocationResponse>;
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof AgentBySlugResponse
     */
    'divisions'?: Array<DivisionResponse>;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof AgentBySlugResponse
     */
    'planMembership'?: PlanMembershipResponse;
}
/**
 * 
 * @export
 * @interface AgentCommentDetails
 */
export interface AgentCommentDetails {
    /**
     * 
     * @type {string}
     * @memberof AgentCommentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentCommentDetails
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentCommentDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentCommentDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentCommentDetails
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface AgentDivisionRequest
 */
export interface AgentDivisionRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentDivisionRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface AgentDivisionRequestWrapper
 */
export interface AgentDivisionRequestWrapper {
    /**
     * 
     * @type {Array<AgentDivisionRequest>}
     * @memberof AgentDivisionRequestWrapper
     */
    'divisions': Array<AgentDivisionRequest>;
}
/**
 * 
 * @export
 * @interface AgentDocumentResponse
 */
export interface AgentDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentDocumentResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentDocumentResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'uploadedById'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentDocumentResponse
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentResponse
     */
    'path'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentDocumentResponse
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentDocumentUpdateRequest
 */
export interface AgentDocumentUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDocumentUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentDocumentUpdateRequest
     */
    'tags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentDocumentsResponse
 */
export interface AgentDocumentsResponse {
    /**
     * 
     * @type {Array<AgentDocumentResponse>}
     * @memberof AgentDocumentsResponse
     */
    'documents'?: Array<AgentDocumentResponse>;
}
/**
 * 
 * @export
 * @interface AgentDriverLicenseImageResponse
 */
export interface AgentDriverLicenseImageResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentDriverLicenseImageResponse
     */
    'driverLicenseImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface AgentFlexTeamMembershipResponse
 */
export interface AgentFlexTeamMembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamType'?: AgentFlexTeamMembershipResponseTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teamName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'enforceLeaderSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'paymentDetailsVisibility'?: AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'cdaApprover'?: AgentFlexTeamMembershipResponseCdaApproverEnum;
    /**
     * 
     * @type {Array<FlexTeammateDto>}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'teammates'?: Array<FlexTeammateDto>;
    /**
     * 
     * @type {Array<FlexTeamMemberDto>}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'leaders'?: Array<FlexTeamMemberDto>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'memberIdByAgentIds'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AgentFlexTeamMembershipResponse
     */
    'permittedTransactionEditors'?: AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum;
}

export const AgentFlexTeamMembershipResponseTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type AgentFlexTeamMembershipResponseTeamTypeEnum = typeof AgentFlexTeamMembershipResponseTeamTypeEnum[keyof typeof AgentFlexTeamMembershipResponseTeamTypeEnum];
export const AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum = typeof AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum[keyof typeof AgentFlexTeamMembershipResponsePaymentDetailsVisibilityEnum];
export const AgentFlexTeamMembershipResponseCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type AgentFlexTeamMembershipResponseCdaApproverEnum = typeof AgentFlexTeamMembershipResponseCdaApproverEnum[keyof typeof AgentFlexTeamMembershipResponseCdaApproverEnum];
export const AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum = typeof AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum[keyof typeof AgentFlexTeamMembershipResponsePermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface AgentIdByShareworksIdResponse
 */
export interface AgentIdByShareworksIdResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentIdByShareworksIdResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentIdByShareworksIdResponse
     */
    'shareworksId'?: number;
}
/**
 * 
 * @export
 * @interface AgentIdsByShareworksIdsResponse
 */
export interface AgentIdsByShareworksIdsResponse {
    /**
     * 
     * @type {Array<AgentIdByShareworksIdResponse>}
     * @memberof AgentIdsByShareworksIdsResponse
     */
    'agentIdsByShareworksIds'?: Array<AgentIdByShareworksIdResponse>;
}
/**
 * 
 * @export
 * @interface AgentIdsResponse
 */
export interface AgentIdsResponse {
    /**
     * 
     * @type {number}
     * @memberof AgentIdsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentIdsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentIdsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentIdsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentIdsResponse
     */
    'agentIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentInfo
 */
export interface AgentInfo {
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'intercomId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'personalEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'personalPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentInfo
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentInfo
     */
    'status'?: AgentInfoStatusEnum;
}

export const AgentInfoStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentInfoStatusEnum = typeof AgentInfoStatusEnum[keyof typeof AgentInfoStatusEnum];

/**
 * 
 * @export
 * @interface AgentInfosRequest
 */
export interface AgentInfosRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentInfosRequest
     */
    'agentIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentInfosResponse
 */
export interface AgentInfosResponse {
    /**
     * 
     * @type {Array<AgentInfo>}
     * @memberof AgentInfosResponse
     */
    'agentInfos'?: Array<AgentInfo>;
}
/**
 * 
 * @export
 * @interface AgentLeadResponse
 */
export interface AgentLeadResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentLeadResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentLeadResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'agentLeadType'?: AgentLeadResponseAgentLeadTypeEnum;
    /**
     * 
     * @type {AgentResponse}
     * @memberof AgentLeadResponse
     */
    'agentResponse'?: AgentResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof AgentLeadResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLeadResponse
     */
    'listingId'?: string;
}

export const AgentLeadResponseAgentLeadTypeEnum = {
    MarketAnalysis: 'MARKET_ANALYSIS',
    Property: 'PROPERTY',
    General: 'GENERAL'
} as const;

export type AgentLeadResponseAgentLeadTypeEnum = typeof AgentLeadResponseAgentLeadTypeEnum[keyof typeof AgentLeadResponseAgentLeadTypeEnum];

/**
 * 
 * @export
 * @interface AgentLicenseImageApprovalStatusRequest
 */
export interface AgentLicenseImageApprovalStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentLicenseImageApprovalStatusRequest
     */
    'status'?: AgentLicenseImageApprovalStatusRequestStatusEnum;
}

export const AgentLicenseImageApprovalStatusRequestStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
} as const;

export type AgentLicenseImageApprovalStatusRequestStatusEnum = typeof AgentLicenseImageApprovalStatusRequestStatusEnum[keyof typeof AgentLicenseImageApprovalStatusRequestStatusEnum];

/**
 * 
 * @export
 * @interface AgentLicenseImagesResponse
 */
export interface AgentLicenseImagesResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentLicenseImagesResponse
     */
    'licenseImageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentLicenseImagesResponse
     */
    'signatureImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface AgentListResponse
 */
export interface AgentListResponse {
    /**
     * 
     * @type {Array<AgentResponse>}
     * @memberof AgentListResponse
     */
    'agents'?: Array<AgentResponse>;
}
/**
 * 
 * @export
 * @interface AgentNationalIdsResponse
 */
export interface AgentNationalIdsResponse {
    /**
     * 
     * @type {Array<NationalIdentificationValue>}
     * @memberof AgentNationalIdsResponse
     */
    'identifications': Array<NationalIdentificationValue>;
}
/**
 * 
 * @export
 * @interface AgentPreviewResponse
 */
export interface AgentPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'type'?: AgentPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'accountCountry'?: AgentPreviewResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'defaultCurrency'?: AgentPreviewResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPreviewResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPreviewResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof AgentPreviewResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
}

export const AgentPreviewResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type AgentPreviewResponseTypeEnum = typeof AgentPreviewResponseTypeEnum[keyof typeof AgentPreviewResponseTypeEnum];
export const AgentPreviewResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AgentPreviewResponseAccountCountryEnum = typeof AgentPreviewResponseAccountCountryEnum[keyof typeof AgentPreviewResponseAccountCountryEnum];
export const AgentPreviewResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentPreviewResponseDefaultCurrencyEnum = typeof AgentPreviewResponseDefaultCurrencyEnum[keyof typeof AgentPreviewResponseDefaultCurrencyEnum];

/**
 * 
 * @export
 * @interface AgentProfileScoreResponse
 */
export interface AgentProfileScoreResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentProfileScoreResponse
     */
    'percentage': string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasUploadedPhoto': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasUpdatedZipcodes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasAddedWebsites': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasCreatedAgentWebsite': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasAddedSocialMediaAccounts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentProfileScoreResponse
     */
    'hasWrittenBio': boolean;
}
/**
 * 
 * @export
 * @interface AgentPublicInfo
 */
export interface AgentPublicInfo {
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicInfo
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'agentLicenseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'agentLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfo
     */
    'status'?: AgentPublicInfoStatusEnum;
}

export const AgentPublicInfoStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentPublicInfoStatusEnum = typeof AgentPublicInfoStatusEnum[keyof typeof AgentPublicInfoStatusEnum];

/**
 * 
 * @export
 * @interface AgentPublicInfoPaginatedResponse
 */
export interface AgentPublicInfoPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof AgentPublicInfoPaginatedResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentPublicInfoPaginatedResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicInfoPaginatedResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentPublicInfoPaginatedResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentPublicInfo>}
     * @memberof AgentPublicInfoPaginatedResponse
     */
    'agentPublicInfos'?: Array<AgentPublicInfo>;
}
/**
 * 
 * @export
 * @interface AgentPublicInfoWithAdminAreas
 */
export interface AgentPublicInfoWithAdminAreas {
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'slug'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'agentLicenseUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'agentLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'status'?: AgentPublicInfoWithAdminAreasStatusEnum;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentPublicInfoWithAdminAreas
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
}

export const AgentPublicInfoWithAdminAreasStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentPublicInfoWithAdminAreasStatusEnum = typeof AgentPublicInfoWithAdminAreasStatusEnum[keyof typeof AgentPublicInfoWithAdminAreasStatusEnum];

/**
 * 
 * @export
 * @interface AgentPublicInfosResponse
 */
export interface AgentPublicInfosResponse {
    /**
     * 
     * @type {Array<AgentPublicInfo>}
     * @memberof AgentPublicInfosResponse
     */
    'agentInfos'?: Array<AgentPublicInfo>;
}
/**
 * 
 * @export
 * @interface AgentPublicResponse
 */
export interface AgentPublicResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentPublicResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentPublicResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'type'?: AgentPublicResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'accountCountry'?: AgentPublicResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'defaultCurrency'?: AgentPublicResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentPublicResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentPublicResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof AgentPublicResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
    /**
     * 
     * @type {string}
     * @memberof AgentPublicResponse
     */
    'agentStatus'?: AgentPublicResponseAgentStatusEnum;
}

export const AgentPublicResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type AgentPublicResponseTypeEnum = typeof AgentPublicResponseTypeEnum[keyof typeof AgentPublicResponseTypeEnum];
export const AgentPublicResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AgentPublicResponseAccountCountryEnum = typeof AgentPublicResponseAccountCountryEnum[keyof typeof AgentPublicResponseAccountCountryEnum];
export const AgentPublicResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentPublicResponseDefaultCurrencyEnum = typeof AgentPublicResponseDefaultCurrencyEnum[keyof typeof AgentPublicResponseDefaultCurrencyEnum];
export const AgentPublicResponseAgentStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentPublicResponseAgentStatusEnum = typeof AgentPublicResponseAgentStatusEnum[keyof typeof AgentPublicResponseAgentStatusEnum];

/**
 * 
 * @export
 * @interface AgentReferralCentralDetailsDto
 */
export interface AgentReferralCentralDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'realUsername'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'defaultCurrency'?: AgentReferralCentralDetailsDtoDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'status'?: AgentReferralCentralDetailsDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentReferralCentralDetailsDto
     */
    'bio'?: string;
    /**
     * 
     * @type {Array<ServiceAreaResponse>}
     * @memberof AgentReferralCentralDetailsDto
     */
    'serviceAreas'?: Array<ServiceAreaResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentReferralCentralDetailsDto
     */
    'competencies'?: Array<AgentReferralCentralDetailsDtoCompetenciesEnum>;
    /**
     * 
     * @type {AddressResponse}
     * @memberof AgentReferralCentralDetailsDto
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AgentReferralCentralDetailsDto
     */
    'optedIntoTransactionsAndListingData'?: boolean;
}

export const AgentReferralCentralDetailsDtoDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentReferralCentralDetailsDtoDefaultCurrencyEnum = typeof AgentReferralCentralDetailsDtoDefaultCurrencyEnum[keyof typeof AgentReferralCentralDetailsDtoDefaultCurrencyEnum];
export const AgentReferralCentralDetailsDtoStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentReferralCentralDetailsDtoStatusEnum = typeof AgentReferralCentralDetailsDtoStatusEnum[keyof typeof AgentReferralCentralDetailsDtoStatusEnum];
export const AgentReferralCentralDetailsDtoCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type AgentReferralCentralDetailsDtoCompetenciesEnum = typeof AgentReferralCentralDetailsDtoCompetenciesEnum[keyof typeof AgentReferralCentralDetailsDtoCompetenciesEnum];

/**
 * 
 * @export
 * @interface AgentResponse
 */
export interface AgentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'type'?: AgentResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'accountCountry'?: AgentResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'defaultCurrency'?: AgentResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof AgentResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof AgentResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'agentStatus'?: AgentResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     * @deprecated
     */
    'sponsorId'?: string;
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof AgentResponse
     */
    'sponsorAgent'?: AgentPreviewResponse;
    /**
     * 
     * @type {Array<SponsorResponse>}
     * @memberof AgentResponse
     */
    'sponsors'?: Array<SponsorResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'personalPhoneNumber'?: string;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof AgentResponse
     */
    'addresses'?: Array<AddressResponse>;
    /**
     * 
     * @type {AddressResponse}
     * @memberof AgentResponse
     */
    'primaryAddress'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'internalPhoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'tipaltiConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesTransactionEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesTransactionPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesPaymentEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesPaymentPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'notifyLeadsInSMS'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'receivesLeadsOnlyFromZipcodes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'anniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'lastAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'nextAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'deferredCapEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'capDeferred'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentResponse
     */
    'competencies'?: Array<AgentResponseCompetenciesEnum>;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof AgentResponse
     */
    'licenses'?: Array<LicenseResponse>;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof AgentResponse
     */
    'planMembership'?: PlanMembershipResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'temporaryPlanExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'nextAgentPlansConfig'?: AgentResponseNextAgentPlansConfigEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'sizeOfNetwork'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'hasSignedICA'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'shareworksId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'chimeStatus'?: AgentResponseChimeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'chimeSubscribedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'chimeUnsubscribedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     * @deprecated
     */
    'preferredNetsuiteEntity'?: AgentResponsePreferredNetsuiteEntityEnum;
    /**
     * 
     * @type {BusinessEntityValue}
     * @memberof AgentResponse
     */
    'paidViaBusinessEntity'?: BusinessEntityValue;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'shouldSkipTipaltiOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsTaxIdOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsWillBeneficiaryOnboarding'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'hasAgentWebsite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsMentorship'?: boolean;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof AgentResponse
     */
    'applications'?: Array<ApplicationResponse>;
    /**
     * 
     * @type {Array<OnboardingResponse>}
     * @memberof AgentResponse
     */
    'onboardings'?: Array<OnboardingResponse>;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof AgentResponse
     */
    'boards'?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof AgentResponse
     */
    'mls'?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {Array<OfficePreviewResponse>}
     * @memberof AgentResponse
     */
    'offices'?: Array<OfficePreviewResponse>;
    /**
     * 
     * @type {Array<AgentTeamMembershipResponse>}
     * @memberof AgentResponse
     */
    'teamMemberships'?: Array<AgentTeamMembershipResponse>;
    /**
     * 
     * @type {Array<AgentFlexTeamMembershipResponse>}
     * @memberof AgentResponse
     */
    'flexTeamMemberships'?: Array<AgentFlexTeamMembershipResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminationReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminationReasonDetails'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'terminatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'terminatedAsOf'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'notEligibleForRehire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'needsWebsiteOnboarding'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'realv1UserId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'restricted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'revshareBan'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'nonReportable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'awardsBanned'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'gracePeriodEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'sponsorProducingGracePeriodEnd'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'driverLicenseImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'onboardingChecklistId'?: string;
    /**
     * 
     * @type {Array<Division>}
     * @memberof AgentResponse
     */
    'divisions'?: Array<Division>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'learnworldsUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'realUsername'?: string;
    /**
     * 
     * @type {OneRealImpactFundConfigDto}
     * @memberof AgentResponse
     */
    'oneRealImpactFundConfig'?: OneRealImpactFundConfigDto;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'prefersSingleCheck'?: boolean;
    /**
     * 
     * @type {UserAvailabilityResponse}
     * @memberof AgentResponse
     */
    'availability'?: UserAvailabilityResponse;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'optedIntoSms'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentResponse
     */
    'voiceVectorDisclaimerAcceptedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentResponse
     */
    'optedIntoTransactionsAndListingData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'bio'?: string;
    /**
     * 
     * @type {Array<ServiceAreaResponse>}
     * @memberof AgentResponse
     */
    'serviceAreas'?: Array<ServiceAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof AgentResponse
     */
    'dropboxId'?: string;
}

export const AgentResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type AgentResponseTypeEnum = typeof AgentResponseTypeEnum[keyof typeof AgentResponseTypeEnum];
export const AgentResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type AgentResponseAccountCountryEnum = typeof AgentResponseAccountCountryEnum[keyof typeof AgentResponseAccountCountryEnum];
export const AgentResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type AgentResponseDefaultCurrencyEnum = typeof AgentResponseDefaultCurrencyEnum[keyof typeof AgentResponseDefaultCurrencyEnum];
export const AgentResponseAgentStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type AgentResponseAgentStatusEnum = typeof AgentResponseAgentStatusEnum[keyof typeof AgentResponseAgentStatusEnum];
export const AgentResponseCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type AgentResponseCompetenciesEnum = typeof AgentResponseCompetenciesEnum[keyof typeof AgentResponseCompetenciesEnum];
export const AgentResponseNextAgentPlansConfigEnum = {
    UsResidentialV1: 'US_RESIDENTIAL_V1',
    UsResidentialV2: 'US_RESIDENTIAL_V2',
    UsCommercialV1: 'US_COMMERCIAL_V1',
    CaResidentialV1: 'CA_RESIDENTIAL_V1',
    CaResidentialScottBensonV1: 'CA_RESIDENTIAL_SCOTT_BENSON_V1',
    UsBroker4K: 'US_BROKER_4K',
    UsBroker6K: 'US_BROKER_6K',
    UsBroker12K: 'US_BROKER_12K',
    CaBroker4K: 'CA_BROKER_4K',
    CaBroker6K: 'CA_BROKER_6K',
    CaBroker12K: 'CA_BROKER_12K'
} as const;

export type AgentResponseNextAgentPlansConfigEnum = typeof AgentResponseNextAgentPlansConfigEnum[keyof typeof AgentResponseNextAgentPlansConfigEnum];
export const AgentResponseChimeStatusEnum = {
    SubscribeRequested: 'SUBSCRIBE_REQUESTED',
    UnsubscribeRequested: 'UNSUBSCRIBE_REQUESTED'
} as const;

export type AgentResponseChimeStatusEnum = typeof AgentResponseChimeStatusEnum[keyof typeof AgentResponseChimeStatusEnum];
export const AgentResponsePreferredNetsuiteEntityEnum = {
    Llc: 'REAL_BROKER_LLC',
    Ab: 'REAL_BROKER_AB',
    Bc: 'REAL_BROKER_BC',
    Lfro: 'REAL_BROKER_LFRO',
    Ontario: 'REAL_BROKER_ONTARIO',
    Manitoba: 'REAL_BROKER_MANITOBA'
} as const;

export type AgentResponsePreferredNetsuiteEntityEnum = typeof AgentResponsePreferredNetsuiteEntityEnum[keyof typeof AgentResponsePreferredNetsuiteEntityEnum];

/**
 * 
 * @export
 * @interface AgentTeamMembershipResponse
 */
export interface AgentTeamMembershipResponse {
    /**
     * 
     * @type {TeamPreviewResponse}
     * @memberof AgentTeamMembershipResponse
     */
    'team'?: TeamPreviewResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentTeamMembershipResponse
     */
    'roles'?: Array<AgentTeamMembershipResponseRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AgentTeamMembershipResponse
     */
    'memberCommissionSplit'?: number;
}

export const AgentTeamMembershipResponseRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type AgentTeamMembershipResponseRolesEnum = typeof AgentTeamMembershipResponseRolesEnum[keyof typeof AgentTeamMembershipResponseRolesEnum];

/**
 * 
 * @export
 * @interface AgentTestimonial
 */
export interface AgentTestimonial {
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonial
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonial
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface AgentTestimonialResponse
 */
export interface AgentTestimonialResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonialResponse
     */
    'clientName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentTestimonialResponse
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface AgentUpdateBoardsRequest
 */
export interface AgentUpdateBoardsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateBoardsRequest
     */
    'boardIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AgentUpdateBusinessEmailRequest
 */
export interface AgentUpdateBusinessEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateBusinessEmailRequest
     */
    'newBusinessEmailRequest': string;
}
/**
 * 
 * @export
 * @interface AgentUpdateJoinDateAndAnniversaryDateRequest
 */
export interface AgentUpdateJoinDateAndAnniversaryDateRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateJoinDateAndAnniversaryDateRequest
     */
    'newJoinDate': string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateJoinDateAndAnniversaryDateRequest
     */
    'newAnniversaryDate': string;
}
/**
 * 
 * @export
 * @interface AgentUpdateJoinRealEmailRequest
 */
export interface AgentUpdateJoinRealEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateJoinRealEmailRequest
     */
    'newJoinRealEmail': string;
}
/**
 * 
 * @export
 * @interface AgentUpdateLicenseBoardMlsRequest
 */
export interface AgentUpdateLicenseBoardMlsRequest {
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    'licenses'?: Array<LicenseRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    'mlsIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateLicenseBoardMlsRequest
     */
    'boardIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AgentUpdateLicenseVerifiedRequest
 */
export interface AgentUpdateLicenseVerifiedRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateLicenseVerifiedRequest
     */
    'licenseVerified': boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateMlsesRequest
 */
export interface AgentUpdateMlsesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateMlsesRequest
     */
    'mlsIds': Array<string>;
}
/**
 * 
 * @export
 * @interface AgentUpdateNeedOnBoardingRequest
 */
export interface AgentUpdateNeedOnBoardingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateNeedOnBoardingRequest
     */
    'needOnBoarding': boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateNeedsTaxOnboardingRequest
 */
export interface AgentUpdateNeedsTaxOnboardingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateNeedsTaxOnboardingRequest
     */
    'needsTaxOnboarding': boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateNeedsWebsiteOnboardingRequest
 */
export interface AgentUpdateNeedsWebsiteOnboardingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateNeedsWebsiteOnboardingRequest
     */
    'needsWebsiteOnboarding': boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdateNeedsWillBeneficiaryOnboardingRequest
 */
export interface AgentUpdateNeedsWillBeneficiaryOnboardingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateNeedsWillBeneficiaryOnboardingRequest
     */
    'needsWillBeneficiaryOnboarding': boolean;
}
/**
 * 
 * @export
 * @interface AgentUpdatePlansRequest
 */
export interface AgentUpdatePlansRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpdatePlansRequest
     */
    'plansConfig': AgentUpdatePlansRequestPlansConfigEnum;
    /**
     * 
     * @type {FutureAgentPlan}
     * @memberof AgentUpdatePlansRequest
     */
    'futureAgentPlan'?: FutureAgentPlan;
}

export const AgentUpdatePlansRequestPlansConfigEnum = {
    UsResidentialV1: 'US_RESIDENTIAL_V1',
    UsResidentialV2: 'US_RESIDENTIAL_V2',
    UsCommercialV1: 'US_COMMERCIAL_V1',
    CaResidentialV1: 'CA_RESIDENTIAL_V1',
    CaResidentialScottBensonV1: 'CA_RESIDENTIAL_SCOTT_BENSON_V1',
    UsBroker4K: 'US_BROKER_4K',
    UsBroker6K: 'US_BROKER_6K',
    UsBroker12K: 'US_BROKER_12K',
    CaBroker4K: 'CA_BROKER_4K',
    CaBroker6K: 'CA_BROKER_6K',
    CaBroker12K: 'CA_BROKER_12K'
} as const;

export type AgentUpdatePlansRequestPlansConfigEnum = typeof AgentUpdatePlansRequestPlansConfigEnum[keyof typeof AgentUpdatePlansRequestPlansConfigEnum];

/**
 * 
 * @export
 * @interface AgentUpdateRequest
 */
export interface AgentUpdateRequest {
    /**
     * 
     * @type {AddressRequest}
     * @memberof AgentUpdateRequest
     */
    'addressRequest'?: AddressRequest;
    /**
     * 
     * @type {Array<AddressRequest>}
     * @memberof AgentUpdateRequest
     */
    'addressRequests'?: Array<AddressRequest>;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'about'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateRequest
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgentUpdateRequest
     */
    'competencies'?: Array<AgentUpdateRequestCompetenciesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'receivesLeadsOnlyFromZipcodes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'notifyLeadsInSMS'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'receivesTransactionEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'receivesTransactionPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'receivesPaymentEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'receivesPaymentPushNotifications'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpdateRequest
     */
    'optInToEquityPlan'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'personalEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'personalPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentUpdateRequest
     */
    'slug'?: string;
}

export const AgentUpdateRequestCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type AgentUpdateRequestCompetenciesEnum = typeof AgentUpdateRequestCompetenciesEnum[keyof typeof AgentUpdateRequestCompetenciesEnum];

/**
 * 
 * @export
 * @interface AgentUpsertReferralCentralOnboardingDataRequest
 */
export interface AgentUpsertReferralCentralOnboardingDataRequest {
    /**
     * 
     * @type {string}
     * @memberof AgentUpsertReferralCentralOnboardingDataRequest
     */
    'bio'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentUpsertReferralCentralOnboardingDataRequest
     */
    'optedIntoTransactionsAndListingData'?: boolean;
    /**
     * 
     * @type {Array<ServiceAreaRequest>}
     * @memberof AgentUpsertReferralCentralOnboardingDataRequest
     */
    'serviceAreas'?: Array<ServiceAreaRequest>;
}
/**
 * 
 * @export
 * @interface AgentWebsiteResponse
 */
export interface AgentWebsiteResponse {
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentWebsiteResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentWebsiteResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {AgentResponse}
     * @memberof AgentWebsiteResponse
     */
    'agentResponse'?: AgentResponse;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'photoSet'?: AgentWebsiteResponsePhotoSetEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'themeColor'?: AgentWebsiteResponseThemeColorEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'websiteTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'mainTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'mainSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'bottomTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'bottomSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature1Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature1Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature2Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature2Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature3Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentWebsiteResponse
     */
    'feature3Content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentWebsiteResponse
     */
    'showReferralLink'?: boolean;
    /**
     * 
     * @type {Array<AgentTestimonialResponse>}
     * @memberof AgentWebsiteResponse
     */
    'testimonials'?: Array<AgentTestimonialResponse>;
    /**
     * 
     * @type {PropertyFilterResponse}
     * @memberof AgentWebsiteResponse
     */
    'propertyFilter'?: PropertyFilterResponse;
}

export const AgentWebsiteResponsePhotoSetEnum = {
    Cabin: 'CABIN',
    Luxury: 'LUXURY',
    Urban: 'URBAN',
    Country: 'COUNTRY',
    PhotoSet5: 'PHOTO_SET_5',
    PhotoSet6: 'PHOTO_SET_6',
    PhotoSet7: 'PHOTO_SET_7',
    PhotoSet8: 'PHOTO_SET_8',
    PhotoSet9: 'PHOTO_SET_9',
    PhotoSet10: 'PHOTO_SET_10'
} as const;

export type AgentWebsiteResponsePhotoSetEnum = typeof AgentWebsiteResponsePhotoSetEnum[keyof typeof AgentWebsiteResponsePhotoSetEnum];
export const AgentWebsiteResponseThemeColorEnum = {
    Green: 'GREEN',
    Black: 'BLACK',
    Blue: 'BLUE',
    Red: 'RED',
    Yellow: 'YELLOW',
    Magenta: 'MAGENTA'
} as const;

export type AgentWebsiteResponseThemeColorEnum = typeof AgentWebsiteResponseThemeColorEnum[keyof typeof AgentWebsiteResponseThemeColorEnum];

/**
 * 
 * @export
 * @interface AgentsReferralCentralDetailsResponse
 */
export interface AgentsReferralCentralDetailsResponse {
    /**
     * 
     * @type {number}
     * @memberof AgentsReferralCentralDetailsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgentsReferralCentralDetailsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgentsReferralCentralDetailsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgentsReferralCentralDetailsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentReferralCentralDetailsDto>}
     * @memberof AgentsReferralCentralDetailsResponse
     */
    'agentReferralCentralDetailsDtos'?: Array<AgentReferralCentralDetailsDto>;
}
/**
 * 
 * @export
 * @interface AgreementAmendmentResponse
 */
export interface AgreementAmendmentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'agreementAmendmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'agreementDefinitionAmendmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementAmendmentResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementAmendmentResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementAmendmentResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementAmendmentResponse
     */
    'position'?: number;
}
/**
 * 
 * @export
 * @interface AgreementCreationRequest
 */
export interface AgreementCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationRequest
     */
    'agreementDefinitionId': string;
}
/**
 * 
 * @export
 * @interface AgreementCreationResponse
 */
export interface AgreementCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'agreementType'?: AgreementCreationResponseAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementCreationResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementCreationResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementCreationResponse
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementCreationResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementCreationResponse
     */
    'signed'?: boolean;
}

export const AgreementCreationResponseAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementCreationResponseAgreementTypeEnum = typeof AgreementCreationResponseAgreementTypeEnum[keyof typeof AgreementCreationResponseAgreementTypeEnum];

/**
 * 
 * @export
 * @interface AgreementDefinitionAmendmentResponse
 */
export interface AgreementDefinitionAmendmentResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'agreementDefinitionAmendmentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'agreementDefinitionAmendmentVersion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionAmendmentResponse
     */
    'state'?: AgreementDefinitionAmendmentResponseStateEnum;
}

export const AgreementDefinitionAmendmentResponseStateEnum = {
    Active: 'ACTIVE',
    Created: 'CREATED',
    Archived: 'ARCHIVED'
} as const;

export type AgreementDefinitionAmendmentResponseStateEnum = typeof AgreementDefinitionAmendmentResponseStateEnum[keyof typeof AgreementDefinitionAmendmentResponseStateEnum];

/**
 * 
 * @export
 * @interface AgreementDefinitionCreationRequest
 */
export interface AgreementDefinitionCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationRequest
     */
    'commissionPlanId': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationRequest
     */
    'agreementType': AgreementDefinitionCreationRequestAgreementTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementDefinitionCreationRequest
     */
    'stateOrProvinces'?: Array<AgreementDefinitionCreationRequestStateOrProvincesEnum>;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionCreationRequest
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementDefinitionCreationRequest
     */
    'forceSign'?: boolean;
}

export const AgreementDefinitionCreationRequestAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementDefinitionCreationRequestAgreementTypeEnum = typeof AgreementDefinitionCreationRequestAgreementTypeEnum[keyof typeof AgreementDefinitionCreationRequestAgreementTypeEnum];
export const AgreementDefinitionCreationRequestStateOrProvincesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AgreementDefinitionCreationRequestStateOrProvincesEnum = typeof AgreementDefinitionCreationRequestStateOrProvincesEnum[keyof typeof AgreementDefinitionCreationRequestStateOrProvincesEnum];

/**
 * 
 * @export
 * @interface AgreementDefinitionCreationResponse
 */
export interface AgreementDefinitionCreationResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionCreationResponse
     */
    'state'?: AgreementDefinitionCreationResponseStateEnum;
}

export const AgreementDefinitionCreationResponseStateEnum = {
    Active: 'ACTIVE',
    Created: 'CREATED',
    Archived: 'ARCHIVED'
} as const;

export type AgreementDefinitionCreationResponseStateEnum = typeof AgreementDefinitionCreationResponseStateEnum[keyof typeof AgreementDefinitionCreationResponseStateEnum];

/**
 * 
 * @export
 * @interface AgreementDefinitionPageableResponse
 */
export interface AgreementDefinitionPageableResponse {
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementDefinitionPageableResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionPageableResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgreementDefinitionResponse>}
     * @memberof AgreementDefinitionPageableResponse
     */
    'agreementDefinitionResponses'?: Array<AgreementDefinitionResponse>;
}
/**
 * 
 * @export
 * @interface AgreementDefinitionPreview
 */
export interface AgreementDefinitionPreview {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionPreview
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionPreview
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionPreview
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionPreview
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionPreview
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionPreview
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {DocumentImages}
     * @memberof AgreementDefinitionPreview
     */
    'documentImages'?: DocumentImages;
}
/**
 * 
 * @export
 * @interface AgreementDefinitionPreviewResponse
 */
export interface AgreementDefinitionPreviewResponse {
    /**
     * 
     * @type {Array<AgreementDefinitionPreview>}
     * @memberof AgreementDefinitionPreviewResponse
     */
    'agreementDefinitionPreviews'?: Array<AgreementDefinitionPreview>;
}
/**
 * 
 * @export
 * @interface AgreementDefinitionResponse
 */
export interface AgreementDefinitionResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionResponse
     */
    'agreementVersion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'state'?: AgreementDefinitionResponseStateEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AgreementDefinitionResponse
     */
    'stateOrProvinces'?: Array<AgreementDefinitionResponseStateOrProvincesEnum>;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionResponse
     */
    'definitionAmendmentCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionResponse
     */
    'agreementType'?: AgreementDefinitionResponseAgreementTypeEnum;
}

export const AgreementDefinitionResponseStateEnum = {
    Active: 'ACTIVE',
    Created: 'CREATED',
    Archived: 'ARCHIVED'
} as const;

export type AgreementDefinitionResponseStateEnum = typeof AgreementDefinitionResponseStateEnum[keyof typeof AgreementDefinitionResponseStateEnum];
export const AgreementDefinitionResponseStateOrProvincesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type AgreementDefinitionResponseStateOrProvincesEnum = typeof AgreementDefinitionResponseStateOrProvincesEnum[keyof typeof AgreementDefinitionResponseStateOrProvincesEnum];
export const AgreementDefinitionResponseAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementDefinitionResponseAgreementTypeEnum = typeof AgreementDefinitionResponseAgreementTypeEnum[keyof typeof AgreementDefinitionResponseAgreementTypeEnum];

/**
 * 
 * @export
 * @interface AgreementDefinitionWithAmendmentsResponse
 */
export interface AgreementDefinitionWithAmendmentsResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'documentTemplateId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'agreementVersion'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'state'?: AgreementDefinitionWithAmendmentsResponseStateEnum;
    /**
     * 
     * @type {Array<AgreementDefinitionAmendmentResponse>}
     * @memberof AgreementDefinitionWithAmendmentsResponse
     */
    'amendmentDefinitions'?: Array<AgreementDefinitionAmendmentResponse>;
}

export const AgreementDefinitionWithAmendmentsResponseStateEnum = {
    Active: 'ACTIVE',
    Created: 'CREATED',
    Archived: 'ARCHIVED'
} as const;

export type AgreementDefinitionWithAmendmentsResponseStateEnum = typeof AgreementDefinitionWithAmendmentsResponseStateEnum[keyof typeof AgreementDefinitionWithAmendmentsResponseStateEnum];

/**
 * 
 * @export
 * @interface AgreementFileUrlResponse
 */
export interface AgreementFileUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementFileUrlResponse
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementFileUrlResponse
     */
    'attachmentUrl'?: string;
}
/**
 * 
 * @export
 * @interface AgreementPreviewResponse
 */
export interface AgreementPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'type'?: AgreementPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementPreviewResponse
     */
    'signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AgreementPreviewResponse
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<Signee>}
     * @memberof AgreementPreviewResponse
     */
    'signees'?: Array<Signee>;
}

export const AgreementPreviewResponseTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementPreviewResponseTypeEnum = typeof AgreementPreviewResponseTypeEnum[keyof typeof AgreementPreviewResponseTypeEnum];

/**
 * 
 * @export
 * @interface AgreementResponse
 */
export interface AgreementResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'agreementType'?: AgreementResponseAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementResponse
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof AgreementResponse
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {Array<AgreementAmendmentResponse>}
     * @memberof AgreementResponse
     */
    'amendments'?: Array<AgreementAmendmentResponse>;
}

export const AgreementResponseAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementResponseAgreementTypeEnum = typeof AgreementResponseAgreementTypeEnum[keyof typeof AgreementResponseAgreementTypeEnum];

/**
 * 
 * @export
 * @interface AgreementSigningRequest
 */
export interface AgreementSigningRequest {
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningRequest
     */
    'agreementId': string;
}
/**
 * 
 * @export
 * @interface AgreementSigningResponse
 */
export interface AgreementSigningResponse {
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'agreementType'?: AgreementSigningResponseAgreementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof AgreementSigningResponse
     */
    'signedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof AgreementSigningResponse
     */
    'agreementDefinitionId'?: string;
}

export const AgreementSigningResponseAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type AgreementSigningResponseAgreementTypeEnum = typeof AgreementSigningResponseAgreementTypeEnum[keyof typeof AgreementSigningResponseAgreementTypeEnum];

/**
 * 
 * @export
 * @interface AllCommissionPlansBasicInfoResponse
 */
export interface AllCommissionPlansBasicInfoResponse {
    /**
     * 
     * @type {Array<CommissionPlanBasicResponse>}
     * @memberof AllCommissionPlansBasicInfoResponse
     */
    'commissionPlans'?: Array<CommissionPlanBasicResponse>;
}
/**
 * 
 * @export
 * @interface AmountDiscountResponse
 */
export interface AmountDiscountResponse {
    /**
     * 
     * @type {number}
     * @memberof AmountDiscountResponse
     */
    'originalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmountDiscountResponse
     */
    'discountedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmountDiscountResponse
     */
    'couponCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmountDiscountResponse
     */
    'calculatedDiscount'?: number;
}
/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ApiResponse
     */
    'status'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationDriverLicenseImageResponse
 */
export interface ApplicationDriverLicenseImageResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationDriverLicenseImageResponse
     */
    'driverLicenseImageUrl'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationRequest
 */
export interface ApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'birthDate': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     * @deprecated
     */
    'boardOrMls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     * @deprecated
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     * @deprecated
     */
    'mls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'currentBrokerage': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationRequest
     */
    'hasPendingTransactionsWithCurrentBrokerage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'estimatedSales': string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationRequest
     */
    'commercialAgent': boolean;
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof ApplicationRequest
     * @deprecated
     */
    'doesBusinessIn'?: Array<LicenseRequest>;
    /**
     * 
     * @type {Array<LicenseBoardMLSRequest>}
     * @memberof ApplicationRequest
     */
    'doesBusinessInExtended'?: Array<LicenseBoardMLSRequest>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'sponsoredByCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'teamLeaderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'teamRole'?: ApplicationRequestTeamRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'applicationType'?: ApplicationRequestApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'preferredTitleVendor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequest
     */
    'icaSignatureType'?: ApplicationRequestIcaSignatureTypeEnum;
}

export const ApplicationRequestTeamRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type ApplicationRequestTeamRoleEnum = typeof ApplicationRequestTeamRoleEnum[keyof typeof ApplicationRequestTeamRoleEnum];
export const ApplicationRequestApplicationTypeEnum = {
    Regular: 'REGULAR',
    LetterOfIntent: 'LETTER_OF_INTENT',
    Ica: 'ICA'
} as const;

export type ApplicationRequestApplicationTypeEnum = typeof ApplicationRequestApplicationTypeEnum[keyof typeof ApplicationRequestApplicationTypeEnum];
export const ApplicationRequestIcaSignatureTypeEnum = {
    FormFill: 'FORM_FILL',
    Agreement: 'AGREEMENT'
} as const;

export type ApplicationRequestIcaSignatureTypeEnum = typeof ApplicationRequestIcaSignatureTypeEnum[keyof typeof ApplicationRequestIcaSignatureTypeEnum];

/**
 * 
 * @export
 * @interface ApplicationResponse
 */
export interface ApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'status'?: ApplicationResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'nextStatus'?: ApplicationResponseNextStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'applicationType'?: ApplicationResponseApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'country'?: ApplicationResponseCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'boardOrMls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'mls'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'currentBrokerage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'hasPendingTransactionsWithCurrentBrokerage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'estimatedSales'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'commercialAgent'?: boolean;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'doesBusinessIn'?: Array<LicenseResponse>;
    /**
     * 
     * @type {Array<LicenseBoardMLSResponse>}
     * @memberof ApplicationResponse
     */
    'doesBusinessInExtended'?: Array<LicenseBoardMLSResponse>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaType'?: ApplicationResponseIcaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaSignature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaAnniversaryDateRequested'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     * @deprecated
     */
    'icaReferringAgentName'?: string;
    /**
     * 
     * @type {Array<SponsoringAgentDto>}
     * @memberof ApplicationResponse
     */
    'icaSponsoringAgents'?: Array<SponsoringAgentDto>;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'icaSignedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamLeaderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'teamRole'?: ApplicationResponseTeamRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'preferredTitleVendor'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'feesPaidAt'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof ApplicationResponse
     */
    'paidFeesAmount'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'feesStripeChargeId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'approvedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'licenseTransferredAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'rejectedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'rejectedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEmergencyContactRelationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'icaInitials'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiSignature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'loiResidentIn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'loiNumDaysUntilJoining'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'loiSignedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'optInToStockPlan'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationResponse
     */
    'feesWaivedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'feesWaivedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'driverLicenseImagePath'?: string;
    /**
     * 
     * @type {ICAAgreement}
     * @memberof ApplicationResponse
     */
    'icaAgreement'?: ICAAgreement;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponse
     */
    'couponCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'preferredTitleVendorFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponse
     */
    'individualAgentFlag'?: boolean;
    /**
     * 
     * @type {TeamInvitationDto}
     * @memberof ApplicationResponse
     */
    'teamInvitation'?: TeamInvitationDto;
    /**
     * 
     * @type {GenericTeamInvitationResponse}
     * @memberof ApplicationResponse
     */
    'genericTeamInvitation'?: GenericTeamInvitationResponse;
}

export const ApplicationResponseStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type ApplicationResponseStatusEnum = typeof ApplicationResponseStatusEnum[keyof typeof ApplicationResponseStatusEnum];
export const ApplicationResponseNextStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type ApplicationResponseNextStatusEnum = typeof ApplicationResponseNextStatusEnum[keyof typeof ApplicationResponseNextStatusEnum];
export const ApplicationResponseApplicationTypeEnum = {
    Regular: 'REGULAR',
    LetterOfIntent: 'LETTER_OF_INTENT',
    Ica: 'ICA'
} as const;

export type ApplicationResponseApplicationTypeEnum = typeof ApplicationResponseApplicationTypeEnum[keyof typeof ApplicationResponseApplicationTypeEnum];
export const ApplicationResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type ApplicationResponseCountryEnum = typeof ApplicationResponseCountryEnum[keyof typeof ApplicationResponseCountryEnum];
export const ApplicationResponseIcaTypeEnum = {
    ResidentialStandardV1: 'RESIDENTIAL_STANDARD_V1',
    CommercialStandardV1: 'COMMERCIAL_STANDARD_V1'
} as const;

export type ApplicationResponseIcaTypeEnum = typeof ApplicationResponseIcaTypeEnum[keyof typeof ApplicationResponseIcaTypeEnum];
export const ApplicationResponseTeamRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type ApplicationResponseTeamRoleEnum = typeof ApplicationResponseTeamRoleEnum[keyof typeof ApplicationResponseTeamRoleEnum];

/**
 * 
 * @export
 * @interface ApplicationSigningRequest
 */
export interface ApplicationSigningRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSigningRequest
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSigningRequest
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSigningRequest
     */
    'agreementId': string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSigningRequest
     */
    'signeeToken': string;
}
/**
 * 
 * @export
 * @interface ApplicationsPaginatedResponse
 */
export interface ApplicationsPaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationsPaginatedResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationsPaginatedResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationsPaginatedResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationsPaginatedResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof ApplicationsPaginatedResponse
     */
    'applications'?: Array<ApplicationResponse>;
}
/**
 * 
 * @export
 * @interface ApplyToRealRequest
 */
export interface ApplyToRealRequest {
    /**
     * 
     * @type {string}
     * @memberof ApplyToRealRequest
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplyToRealRequest
     */
    'sponsoredByCode'?: string;
}
/**
 * 
 * @export
 * @interface ApproveApplicationRequest
 */
export interface ApproveApplicationRequest {
    /**
     * 
     * @type {FutureAgentPlan}
     * @memberof ApproveApplicationRequest
     */
    'futureAgentPlan'?: FutureAgentPlan;
    /**
     * 
     * @type {string}
     * @memberof ApproveApplicationRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApproveApplicationRequest
     * @deprecated
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {Array<SponsorInfo>}
     * @memberof ApproveApplicationRequest
     */
    'sponsors'?: Array<SponsorInfo>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    'mlsIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    'boardIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApproveApplicationRequest
     */
    'officeIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApproveApplicationRequest
     */
    'agentPlansConfig'?: ApproveApplicationRequestAgentPlansConfigEnum;
    /**
     * 
     * @type {string}
     * @memberof ApproveApplicationRequest
     * @deprecated
     */
    'planMembershipConfigName'?: ApproveApplicationRequestPlanMembershipConfigNameEnum;
    /**
     * 
     * @type {number}
     * @memberof ApproveApplicationRequest
     */
    'gracePeriodEnd'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApproveApplicationRequest
     */
    'sponsorProducingGracePeriodEnd'?: number;
}

export const ApproveApplicationRequestAgentPlansConfigEnum = {
    UsResidentialV1: 'US_RESIDENTIAL_V1',
    UsResidentialV2: 'US_RESIDENTIAL_V2',
    UsCommercialV1: 'US_COMMERCIAL_V1',
    CaResidentialV1: 'CA_RESIDENTIAL_V1',
    CaResidentialScottBensonV1: 'CA_RESIDENTIAL_SCOTT_BENSON_V1',
    UsBroker4K: 'US_BROKER_4K',
    UsBroker6K: 'US_BROKER_6K',
    UsBroker12K: 'US_BROKER_12K',
    CaBroker4K: 'CA_BROKER_4K',
    CaBroker6K: 'CA_BROKER_6K',
    CaBroker12K: 'CA_BROKER_12K'
} as const;

export type ApproveApplicationRequestAgentPlansConfigEnum = typeof ApproveApplicationRequestAgentPlansConfigEnum[keyof typeof ApproveApplicationRequestAgentPlansConfigEnum];
export const ApproveApplicationRequestPlanMembershipConfigNameEnum = {
    UsResidentialV1: 'US_RESIDENTIAL_V1',
    UsResidentialV2: 'US_RESIDENTIAL_V2',
    UsCommercialV1: 'US_COMMERCIAL_V1',
    CaResidentialV1: 'CA_RESIDENTIAL_V1',
    CaResidentialScottBensonV1: 'CA_RESIDENTIAL_SCOTT_BENSON_V1'
} as const;

export type ApproveApplicationRequestPlanMembershipConfigNameEnum = typeof ApproveApplicationRequestPlanMembershipConfigNameEnum[keyof typeof ApproveApplicationRequestPlanMembershipConfigNameEnum];

/**
 * 
 * @export
 * @interface AttractionInfo
 */
export interface AttractionInfo {
    /**
     * 
     * @type {number}
     * @memberof AttractionInfo
     */
    'personalAttractions': number;
    /**
     * 
     * @type {number}
     * @memberof AttractionInfo
     */
    'indirectAttractions': number;
}
/**
 * 
 * @export
 * @interface AvailabilityResponse
 */
export interface AvailabilityResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AvailabilityResponse
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface AwardParameters
 */
export interface AwardParameters {
    /**
     * 
     * @type {number}
     * @memberof AwardParameters
     */
    'capAwardAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AwardParameters
     */
    'attractingAwardAmount': number;
    /**
     * 
     * @type {number}
     * @memberof AwardParameters
     */
    'cultureAwardAmount': number;
}
/**
 * 
 * @export
 * @interface AwardsConfigCapLevelValue
 */
export interface AwardsConfigCapLevelValue {
    /**
     * 
     * @type {string}
     * @memberof AwardsConfigCapLevelValue
     */
    'awardType'?: AwardsConfigCapLevelValueAwardTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AwardsConfigCapLevelValue
     */
    'capLevel'?: number;
    /**
     * 
     * @type {number}
     * @memberof AwardsConfigCapLevelValue
     */
    'awardAmount'?: number;
}

export const AwardsConfigCapLevelValueAwardTypeEnum = {
    Capping: 'CAPPING',
    Attracting: 'ATTRACTING'
} as const;

export type AwardsConfigCapLevelValueAwardTypeEnum = typeof AwardsConfigCapLevelValueAwardTypeEnum[keyof typeof AwardsConfigCapLevelValueAwardTypeEnum];

/**
 * 
 * @export
 * @interface AwardsConfigRequest
 */
export interface AwardsConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof AwardsConfigRequest
     */
    'effectiveDate': string;
    /**
     * 
     * @type {number}
     * @memberof AwardsConfigRequest
     */
    'minAttractingGCI': number;
    /**
     * 
     * @type {Array<AwardsConfigCapLevelValue>}
     * @memberof AwardsConfigRequest
     */
    'capLevels': Array<AwardsConfigCapLevelValue>;
}
/**
 * 
 * @export
 * @interface AwardsConfigResponse
 */
export interface AwardsConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof AwardsConfigResponse
     */
    'effectiveDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AwardsConfigResponse
     */
    'minAttractingGci'?: number;
    /**
     * 
     * @type {Array<AwardsConfigCapLevelValue>}
     * @memberof AwardsConfigResponse
     */
    'capLevels'?: Array<AwardsConfigCapLevelValue>;
}
/**
 * 
 * @export
 * @interface BankAccountResponse
 */
export interface BankAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankAccountResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'bankName'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BankAccountResponse
     */
    'bankAddress'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'bankRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'wireRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'ftniSettlementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'msdxBatch'?: BankAccountResponseMsdxBatchEnum;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'msdxGlNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountResponse
     */
    'bankPartnerAccountId'?: string;
}

export const BankAccountResponseMsdxBatchEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type BankAccountResponseMsdxBatchEnum = typeof BankAccountResponseMsdxBatchEnum[keyof typeof BankAccountResponseMsdxBatchEnum];

/**
 * 
 * @export
 * @interface BannerResponse
 */
export interface BannerResponse {
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'bannerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'type'?: BannerResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'severity'?: BannerResponseSeverityEnum;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'retention'?: BannerResponseRetentionEnum;
    /**
     * 
     * @type {string}
     * @memberof BannerResponse
     */
    'status'?: BannerResponseStatusEnum;
}

export const BannerResponseTypeEnum = {
    LicenseReviewedAndApproved: 'LICENSE_REVIEWED_AND_APPROVED'
} as const;

export type BannerResponseTypeEnum = typeof BannerResponseTypeEnum[keyof typeof BannerResponseTypeEnum];
export const BannerResponseSeverityEnum = {
    Info: 'INFO',
    Warning: 'WARNING'
} as const;

export type BannerResponseSeverityEnum = typeof BannerResponseSeverityEnum[keyof typeof BannerResponseSeverityEnum];
export const BannerResponseRetentionEnum = {
    OneTime: 'ONE_TIME',
    Permanent: 'PERMANENT'
} as const;

export type BannerResponseRetentionEnum = typeof BannerResponseRetentionEnum[keyof typeof BannerResponseRetentionEnum];
export const BannerResponseStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type BannerResponseStatusEnum = typeof BannerResponseStatusEnum[keyof typeof BannerResponseStatusEnum];

/**
 * 
 * @export
 * @interface BaseMessage
 */
export interface BaseMessage {
    /**
     * 
     * @type {string}
     * @memberof BaseMessage
     */
    'eventId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseMessage
     */
    'createdAt'?: number;
}
/**
 * 
 * @export
 * @interface BasicAgreementByTypeRequest
 */
export interface BasicAgreementByTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementByTypeRequest
     */
    'userId': string;
    /**
     * 
     * @type {SignatureDisplayContextDto}
     * @memberof BasicAgreementByTypeRequest
     */
    'displayContext'?: SignatureDisplayContextDto;
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementByTypeRequest
     */
    'agreementType'?: BasicAgreementByTypeRequestAgreementTypeEnum;
}

export const BasicAgreementByTypeRequestAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type BasicAgreementByTypeRequestAgreementTypeEnum = typeof BasicAgreementByTypeRequestAgreementTypeEnum[keyof typeof BasicAgreementByTypeRequestAgreementTypeEnum];

/**
 * 
 * @export
 * @interface BasicAgreementDefinitionCreationCommand
 */
export interface BasicAgreementDefinitionCreationCommand {
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementDefinitionCreationCommand
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementDefinitionCreationCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementDefinitionCreationCommand
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BasicAgreementDefinitionCreationCommand
     */
    'agreementType'?: BasicAgreementDefinitionCreationCommandAgreementTypeEnum;
}

export const BasicAgreementDefinitionCreationCommandAgreementTypeEnum = {
    WillableRevshare: 'WILLABLE_REVSHARE',
    Loi: 'LOI',
    Ica: 'ICA',
    BuyerBroker: 'BUYER_BROKER',
    UnlicensedAssistantAddendum: 'UNLICENSED_ASSISTANT_ADDENDUM',
    LicensedAssistantAddendum: 'LICENSED_ASSISTANT_ADDENDUM',
    AssistantAccessAgreement: 'ASSISTANT_ACCESS_AGREEMENT',
    AssistantPolicyAndProcedures: 'ASSISTANT_POLICY_AND_PROCEDURES',
    AgentReferral: 'AGENT_REFERRAL',
    WalletDebit: 'WALLET_DEBIT',
    WalletCredit: 'WALLET_CREDIT',
    WalletLending: 'WALLET_LENDING'
} as const;

export type BasicAgreementDefinitionCreationCommandAgreementTypeEnum = typeof BasicAgreementDefinitionCreationCommandAgreementTypeEnum[keyof typeof BasicAgreementDefinitionCreationCommandAgreementTypeEnum];

/**
 * 
 * @export
 * @interface BatchCreatePaymentMethods
 */
export interface BatchCreatePaymentMethods {
    /**
     * 
     * @type {Array<CreatePaymentMethod>}
     * @memberof BatchCreatePaymentMethods
     */
    'paymentMethods'?: Array<CreatePaymentMethod>;
}
/**
 * 
 * @export
 * @interface BatchPaymentMethodsResponse
 */
export interface BatchPaymentMethodsResponse {
    /**
     * 
     * @type {string}
     * @memberof BatchPaymentMethodsResponse
     */
    'paymentSettingsId'?: string;
    /**
     * 
     * @type {Array<PaymentMethodResponse>}
     * @memberof BatchPaymentMethodsResponse
     */
    'paymentMethods'?: Array<PaymentMethodResponse>;
}
/**
 * 
 * @export
 * @interface BatchTeamGetRequest
 */
export interface BatchTeamGetRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchTeamGetRequest
     */
    'teamIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BatchTeamGetResponse
 */
export interface BatchTeamGetResponse {
    /**
     * 
     * @type {Array<TeamResponse>}
     * @memberof BatchTeamGetResponse
     */
    'teams'?: Array<TeamResponse>;
}
/**
 * 
 * @export
 * @interface BoardDropboxErrorResponse
 */
export interface BoardDropboxErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardDropboxErrorResponse
     */
    'boardId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardDropboxErrorResponse
     */
    'errorMsg'?: string;
}
/**
 * 
 * @export
 * @interface BoardJoinedRequest
 */
export interface BoardJoinedRequest {
    /**
     * 
     * @type {string}
     * @memberof BoardJoinedRequest
     */
    'applicationId': string;
}
/**
 * 
 * @export
 * @interface BoardPreviewResponse
 */
export interface BoardPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof BoardPreviewResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BoardPreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'billingFrequency'?: BoardPreviewResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardPreviewResponse
     */
    'fee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'billingType'?: BoardPreviewResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'paymentType'?: BoardPreviewResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'unreportedLicenseRisk'?: BoardPreviewResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardPreviewResponse
     */
    'brokerAffiliation'?: BoardPreviewResponseBrokerAffiliationEnum;
}

export const BoardPreviewResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type BoardPreviewResponseBillingFrequencyEnum = typeof BoardPreviewResponseBillingFrequencyEnum[keyof typeof BoardPreviewResponseBillingFrequencyEnum];
export const BoardPreviewResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type BoardPreviewResponseBillingTypeEnum = typeof BoardPreviewResponseBillingTypeEnum[keyof typeof BoardPreviewResponseBillingTypeEnum];
export const BoardPreviewResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type BoardPreviewResponsePaymentTypeEnum = typeof BoardPreviewResponsePaymentTypeEnum[keyof typeof BoardPreviewResponsePaymentTypeEnum];
export const BoardPreviewResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type BoardPreviewResponseUnreportedLicenseRiskEnum = typeof BoardPreviewResponseUnreportedLicenseRiskEnum[keyof typeof BoardPreviewResponseUnreportedLicenseRiskEnum];
export const BoardPreviewResponseBrokerAffiliationEnum = {
    Primary: 'PRIMARY',
    Secondary: 'SECONDARY'
} as const;

export type BoardPreviewResponseBrokerAffiliationEnum = typeof BoardPreviewResponseBrokerAffiliationEnum[keyof typeof BoardPreviewResponseBrokerAffiliationEnum];

/**
 * 
 * @export
 * @interface BoardRequest
 */
export interface BoardRequest {
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'logo'?: string;
    /**
     * 
     * @type {AdministrativeAreaRequest}
     * @memberof BoardRequest
     */
    'administrativeArea'?: AdministrativeAreaRequest;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'code'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardRequest
     */
    'registrationFee': MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'status'?: BoardRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'officeCode'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof BoardRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'billingFrequency'?: BoardRequestBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardRequest
     */
    'fee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'billingType'?: BoardRequestBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'paymentType'?: BoardRequestPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'unreportedLicenseRisk'?: BoardRequestUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardRequest
     */
    'brokerAffiliation'?: BoardRequestBrokerAffiliationEnum;
}

export const BoardRequestStatusEnum = {
    NotMembers: 'NOT_MEMBERS',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    WillingToJoin: 'WILLING_TO_JOIN'
} as const;

export type BoardRequestStatusEnum = typeof BoardRequestStatusEnum[keyof typeof BoardRequestStatusEnum];
export const BoardRequestBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type BoardRequestBillingFrequencyEnum = typeof BoardRequestBillingFrequencyEnum[keyof typeof BoardRequestBillingFrequencyEnum];
export const BoardRequestBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type BoardRequestBillingTypeEnum = typeof BoardRequestBillingTypeEnum[keyof typeof BoardRequestBillingTypeEnum];
export const BoardRequestPaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type BoardRequestPaymentTypeEnum = typeof BoardRequestPaymentTypeEnum[keyof typeof BoardRequestPaymentTypeEnum];
export const BoardRequestUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type BoardRequestUnreportedLicenseRiskEnum = typeof BoardRequestUnreportedLicenseRiskEnum[keyof typeof BoardRequestUnreportedLicenseRiskEnum];
export const BoardRequestBrokerAffiliationEnum = {
    Primary: 'PRIMARY',
    Secondary: 'SECONDARY'
} as const;

export type BoardRequestBrokerAffiliationEnum = typeof BoardRequestBrokerAffiliationEnum[keyof typeof BoardRequestBrokerAffiliationEnum];

/**
 * 
 * @export
 * @interface BoardResponse
 */
export interface BoardResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BoardResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof BoardResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof BoardResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'billingFrequency'?: BoardResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardResponse
     */
    'fee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'billingType'?: BoardResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'paymentType'?: BoardResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'unreportedLicenseRisk'?: BoardResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'brokerAffiliation'?: BoardResponseBrokerAffiliationEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof BoardResponse
     */
    'registrationFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardResponse
     */
    'status'?: BoardResponseStatusEnum;
}

export const BoardResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type BoardResponseBillingFrequencyEnum = typeof BoardResponseBillingFrequencyEnum[keyof typeof BoardResponseBillingFrequencyEnum];
export const BoardResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type BoardResponseBillingTypeEnum = typeof BoardResponseBillingTypeEnum[keyof typeof BoardResponseBillingTypeEnum];
export const BoardResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type BoardResponsePaymentTypeEnum = typeof BoardResponsePaymentTypeEnum[keyof typeof BoardResponsePaymentTypeEnum];
export const BoardResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type BoardResponseUnreportedLicenseRiskEnum = typeof BoardResponseUnreportedLicenseRiskEnum[keyof typeof BoardResponseUnreportedLicenseRiskEnum];
export const BoardResponseBrokerAffiliationEnum = {
    Primary: 'PRIMARY',
    Secondary: 'SECONDARY'
} as const;

export type BoardResponseBrokerAffiliationEnum = typeof BoardResponseBrokerAffiliationEnum[keyof typeof BoardResponseBrokerAffiliationEnum];
export const BoardResponseStatusEnum = {
    NotMembers: 'NOT_MEMBERS',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    WillingToJoin: 'WILLING_TO_JOIN'
} as const;

export type BoardResponseStatusEnum = typeof BoardResponseStatusEnum[keyof typeof BoardResponseStatusEnum];

/**
 * 
 * @export
 * @interface BoardSecretsResponse
 */
export interface BoardSecretsResponse {
    /**
     * 
     * @type {string}
     * @memberof BoardSecretsResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoardSecretsResponse
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface BoundExperimentGroupResponse
 */
export interface BoundExperimentGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof BoundExperimentGroupResponse
     */
    'index'?: number;
    /**
     * 
     * @type {string}
     * @memberof BoundExperimentGroupResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoundExperimentGroupResponse
     */
    'payload'?: string;
}
/**
 * 
 * @export
 * @interface BoundExperimentResponse
 */
export interface BoundExperimentResponse {
    /**
     * 
     * @type {string}
     * @memberof BoundExperimentResponse
     */
    'name'?: string;
    /**
     * 
     * @type {BoundExperimentGroupResponse}
     * @memberof BoundExperimentResponse
     */
    'group'?: BoundExperimentGroupResponse;
}
/**
 * 
 * @export
 * @interface BoundExperimentsResponse
 */
export interface BoundExperimentsResponse {
    /**
     * 
     * @type {Array<BoundExperimentResponse>}
     * @memberof BoundExperimentsResponse
     */
    'experiments'?: Array<BoundExperimentResponse>;
}
/**
 * 
 * @export
 * @interface BusinessEntityValue
 */
export interface BusinessEntityValue {
    /**
     * 
     * @type {string}
     * @memberof BusinessEntityValue
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentificationValue>}
     * @memberof BusinessEntityValue
     */
    'nationalIds'?: Array<NationalIdentificationValue>;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementCreationRequest
 */
export interface BuyerBrokerAgreementCreationRequest {
    /**
     * 
     * @type {Array<BuyerInfo>}
     * @memberof BuyerBrokerAgreementCreationRequest
     */
    'buyerInfos': Array<BuyerInfo>;
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementCreationRequest
     */
    'agreementDefinitionId': string;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof BuyerBrokerAgreementCreationRequest
     */
    'documentFieldValues'?: Array<DocumentFieldValueDto>;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementCreationResponse
 */
export interface BuyerBrokerAgreementCreationResponse {
    /**
     * 
     * @type {Array<BuyerBrokerAgreementResponse>}
     * @memberof BuyerBrokerAgreementCreationResponse
     */
    'buyerBrokerAgreementResponses'?: Array<BuyerBrokerAgreementResponse>;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementEmailResendRequest
 */
export interface BuyerBrokerAgreementEmailResendRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerBrokerAgreementEmailResendRequest
     */
    'buyerBrokerAgreementIds': Array<string>;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementMembersInfo
 */
export interface BuyerBrokerAgreementMembersInfo {
    /**
     * 
     * @type {AgentBasicInfoResponse}
     * @memberof BuyerBrokerAgreementMembersInfo
     */
    'agentInfo'?: AgentBasicInfoResponse;
    /**
     * 
     * @type {BuyerInfo}
     * @memberof BuyerBrokerAgreementMembersInfo
     */
    'buyerInfo'?: BuyerInfo;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementPageableResponse
 */
export interface BuyerBrokerAgreementPageableResponse {
    /**
     * 
     * @type {number}
     * @memberof BuyerBrokerAgreementPageableResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuyerBrokerAgreementPageableResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerBrokerAgreementPageableResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BuyerBrokerAgreementPageableResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<BuyerBrokerAgreementResponse>}
     * @memberof BuyerBrokerAgreementPageableResponse
     */
    'buyerBrokerAgreementResponses'?: Array<BuyerBrokerAgreementResponse>;
}
/**
 * 
 * @export
 * @interface BuyerBrokerAgreementResponse
 */
export interface BuyerBrokerAgreementResponse {
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementResponse
     */
    'name'?: string;
    /**
     * 
     * @type {BuyerInfo}
     * @memberof BuyerBrokerAgreementResponse
     */
    'buyerInfo'?: BuyerInfo;
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementResponse
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerBrokerAgreementResponse
     */
    'signatureDocumentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof BuyerBrokerAgreementResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof BuyerBrokerAgreementResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerBrokerAgreementResponse
     */
    'signed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerBrokerAgreementResponse
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerBrokerAgreementResponse
     */
    'buyerOptedRealServices'?: Array<BuyerBrokerAgreementResponseBuyerOptedRealServicesEnum>;
}

export const BuyerBrokerAgreementResponseBuyerOptedRealServicesEnum = {
    Title: 'TITLE',
    Mortgage: 'MORTGAGE'
} as const;

export type BuyerBrokerAgreementResponseBuyerOptedRealServicesEnum = typeof BuyerBrokerAgreementResponseBuyerOptedRealServicesEnum[keyof typeof BuyerBrokerAgreementResponseBuyerOptedRealServicesEnum];

/**
 * 
 * @export
 * @interface BuyerInfo
 */
export interface BuyerInfo {
    /**
     * 
     * @type {string}
     * @memberof BuyerInfo
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof BuyerInfo
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof BuyerInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerInfo
     */
    'phoneNumber': string;
}
/**
 * 
 * @export
 * @interface BuyerOptedServicesRequest
 */
export interface BuyerOptedServicesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerOptedServicesRequest
     */
    'realServices': Array<BuyerOptedServicesRequestRealServicesEnum>;
}

export const BuyerOptedServicesRequestRealServicesEnum = {
    Title: 'TITLE',
    Mortgage: 'MORTGAGE'
} as const;

export type BuyerOptedServicesRequestRealServicesEnum = typeof BuyerOptedServicesRequestRealServicesEnum[keyof typeof BuyerOptedServicesRequestRealServicesEnum];

/**
 * 
 * @export
 * @interface BuyerOptedServicesResponse
 */
export interface BuyerOptedServicesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerOptedServicesResponse
     */
    'servicesOpted'?: Array<BuyerOptedServicesResponseServicesOptedEnum>;
}

export const BuyerOptedServicesResponseServicesOptedEnum = {
    Title: 'TITLE',
    Mortgage: 'MORTGAGE'
} as const;

export type BuyerOptedServicesResponseServicesOptedEnum = typeof BuyerOptedServicesResponseServicesOptedEnum[keyof typeof BuyerOptedServicesResponseServicesOptedEnum];

/**
 * 
 * @export
 * @interface BuyerResponse
 */
export interface BuyerResponse {
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'buyerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerResponse
     */
    'optedRealServices'?: Array<BuyerResponseOptedRealServicesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerResponse
     */
    'isRegistered'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerResponse
     */
    'isBuyer'?: boolean;
}

export const BuyerResponseOptedRealServicesEnum = {
    Title: 'TITLE',
    Mortgage: 'MORTGAGE'
} as const;

export type BuyerResponseOptedRealServicesEnum = typeof BuyerResponseOptedRealServicesEnum[keyof typeof BuyerResponseOptedRealServicesEnum];

/**
 * 
 * @export
 * @interface BuyerServicesResponse
 */
export interface BuyerServicesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerServicesResponse
     */
    'servicesOffered'?: Array<BuyerServicesResponseServicesOfferedEnum>;
}

export const BuyerServicesResponseServicesOfferedEnum = {
    Title: 'TITLE',
    Mortgage: 'MORTGAGE'
} as const;

export type BuyerServicesResponseServicesOfferedEnum = typeof BuyerServicesResponseServicesOfferedEnum[keyof typeof BuyerServicesResponseServicesOfferedEnum];

/**
 * 
 * @export
 * @interface BuyerSignupRequest
 */
export interface BuyerSignupRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyerSignupRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof BuyerSignupRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CalculateRequest
 */
export interface CalculateRequest {
    /**
     * 
     * @type {string}
     * @memberof CalculateRequest
     */
    'country': CalculateRequestCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CalculateRequest
     */
    'transactionsPerYear': number;
    /**
     * 
     * @type {number}
     * @memberof CalculateRequest
     */
    'salesVolumePerYear': number;
    /**
     * 
     * @type {number}
     * @memberof CalculateRequest
     */
    'commissionPct'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculateRequest
     */
    'fixedCommission'?: number;
    /**
     * 
     * @type {CompareBrokerage}
     * @memberof CalculateRequest
     */
    'compareBrokerage'?: CompareBrokerage;
    /**
     * 
     * @type {AttractionInfo}
     * @memberof CalculateRequest
     */
    'attractionInfo': AttractionInfo;
    /**
     * 
     * @type {EquityInfo}
     * @memberof CalculateRequest
     */
    'equityInfo': EquityInfo;
    /**
     * 
     * @type {string}
     * @memberof CalculateRequest
     */
    'teamCapLevel'?: CalculateRequestTeamCapLevelEnum;
}

export const CalculateRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CalculateRequestCountryEnum = typeof CalculateRequestCountryEnum[keyof typeof CalculateRequestCountryEnum];
export const CalculateRequestTeamCapLevelEnum = {
    NormalTeam: 'NORMAL_TEAM',
    PlatinumTeam: 'PLATINUM_TEAM',
    GroupTeam: 'GROUP_TEAM',
    DomesticTeam: 'DOMESTIC_TEAM'
} as const;

export type CalculateRequestTeamCapLevelEnum = typeof CalculateRequestTeamCapLevelEnum[keyof typeof CalculateRequestTeamCapLevelEnum];

/**
 * 
 * @export
 * @interface CalculateResponse
 */
export interface CalculateResponse {
    /**
     * 
     * @type {number}
     * @memberof CalculateResponse
     */
    'total'?: number;
    /**
     * 
     * @type {Array<Revenue>}
     * @memberof CalculateResponse
     */
    'totalBreakdown'?: Array<Revenue>;
    /**
     * 
     * @type {Array<Revenue>}
     * @memberof CalculateResponse
     */
    'compareBrokerageBreakdown'?: Array<Revenue>;
    /**
     * 
     * @type {Array<MonthlyEarnings>}
     * @memberof CalculateResponse
     */
    'projectedMonthlyEarnings'?: Array<MonthlyEarnings>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalculateResponse
     */
    'missingProperties'?: Array<string>;
    /**
     * 
     * @type {Array<InvalidProperty>}
     * @memberof CalculateResponse
     */
    'invalidProperties'?: Array<InvalidProperty>;
}
/**
 * 
 * @export
 * @interface CalculatorParameters
 */
export interface CalculatorParameters {
    /**
     * 
     * @type {Array<CountryParameters>}
     * @memberof CalculatorParameters
     */
    'countryParameters'?: Array<CountryParameters>;
    /**
     * 
     * @type {AwardParameters}
     * @memberof CalculatorParameters
     */
    'awardParameters'?: AwardParameters;
    /**
     * 
     * @type {StockParameters}
     * @memberof CalculatorParameters
     */
    'stockParameters'?: StockParameters;
}
/**
 * 
 * @export
 * @interface CalendarCountOverviewResponse
 */
export interface CalendarCountOverviewResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarCountOverviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'pastCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'upcomingCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'rsvpYesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'rsvpNoCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarCountOverviewResponse
     */
    'rsvpMaybeCount'?: number;
}
/**
 * 
 * @export
 * @interface CalendarCreateRequest
 */
export interface CalendarCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendarCreateRequest
     */
    'calendarName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarCreateRequest
     */
    'calendarUrl'?: string;
}
/**
 * 
 * @export
 * @interface CalendarEventInviteePaginatedResponse
 */
export interface CalendarEventInviteePaginatedResponse {
    /**
     * 
     * @type {number}
     * @memberof CalendarEventInviteePaginatedResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventInviteePaginatedResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventInviteePaginatedResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventInviteePaginatedResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<CalendarEventInviteeResponse>}
     * @memberof CalendarEventInviteePaginatedResponse
     */
    'invitees'?: Array<CalendarEventInviteeResponse>;
}
/**
 * 
 * @export
 * @interface CalendarEventInviteeResponse
 */
export interface CalendarEventInviteeResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'calendarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'calendarEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventInviteeResponse
     */
    'rsvpStatus'?: CalendarEventInviteeResponseRsvpStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventInviteeResponse
     */
    'reminderEmailSentAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventInviteeResponse
     */
    'reminderPushNotificationSentAt'?: number;
}

export const CalendarEventInviteeResponseRsvpStatusEnum = {
    Yes: 'YES',
    No: 'NO',
    Maybe: 'MAYBE'
} as const;

export type CalendarEventInviteeResponseRsvpStatusEnum = typeof CalendarEventInviteeResponseRsvpStatusEnum[keyof typeof CalendarEventInviteeResponseRsvpStatusEnum];

/**
 * 
 * @export
 * @interface CalendarEventResponse
 */
export interface CalendarEventResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'calendarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'calendarName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     * @deprecated
     */
    'calendarEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventResponse
     */
    'startAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarEventResponse
     */
    'endAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'status'?: CalendarEventResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'rsvpStatus'?: CalendarEventResponseRsvpStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventResponse
     */
    'recurrenceRule'?: string;
}

export const CalendarEventResponseStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE'
} as const;

export type CalendarEventResponseStatusEnum = typeof CalendarEventResponseStatusEnum[keyof typeof CalendarEventResponseStatusEnum];
export const CalendarEventResponseRsvpStatusEnum = {
    Yes: 'YES',
    No: 'NO',
    Maybe: 'MAYBE'
} as const;

export type CalendarEventResponseRsvpStatusEnum = typeof CalendarEventResponseRsvpStatusEnum[keyof typeof CalendarEventResponseRsvpStatusEnum];

/**
 * 
 * @export
 * @interface CalendarEventUpdateRequest
 */
export interface CalendarEventUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateRequest
     */
    'host'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateRequest
     */
    'status'?: CalendarEventUpdateRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventUpdateRequest
     */
    'location'?: string;
}

export const CalendarEventUpdateRequestStatusEnum = {
    Online: 'ONLINE',
    Offline: 'OFFLINE'
} as const;

export type CalendarEventUpdateRequestStatusEnum = typeof CalendarEventUpdateRequestStatusEnum[keyof typeof CalendarEventUpdateRequestStatusEnum];

/**
 * 
 * @export
 * @interface CalendarInfoResponse
 */
export interface CalendarInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarInfoResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarInfoResponse
     * @deprecated
     */
    'calendarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarInfoResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarInfoResponse
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarInfoResponse
     */
    'syncedAt'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarInfoResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarInfoResponse
     */
    'officeIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CalendarInfosResponse
 */
export interface CalendarInfosResponse {
    /**
     * 
     * @type {Array<CalendarInfoResponse>}
     * @memberof CalendarInfosResponse
     */
    'calendars'?: Array<CalendarInfoResponse>;
}
/**
 * 
 * @export
 * @interface CalendarInviteesCountResponse
 */
export interface CalendarInviteesCountResponse {
    /**
     * 
     * @type {number}
     * @memberof CalendarInviteesCountResponse
     */
    'rsvpYesCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarInviteesCountResponse
     */
    'rsvpNoCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CalendarInviteesCountResponse
     */
    'rsvpMaybeCount'?: number;
}
/**
 * 
 * @export
 * @interface CalendarResponse
 */
export interface CalendarResponse {
    /**
     * 
     * @type {string}
     * @memberof CalendarResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarResponse
     * @deprecated
     */
    'calendarId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarResponse
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof CalendarResponse
     */
    'syncedAt'?: number;
    /**
     * 
     * @type {Array<CalendarEventResponse>}
     * @memberof CalendarResponse
     */
    'calendarEvents'?: Array<CalendarEventResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarResponse
     */
    'officeIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CalendarUpdateRequest
 */
export interface CalendarUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendarUpdateRequest
     */
    'calendarName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarUpdateRequest
     */
    'calendarUrl'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarUpdateRequest
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarUpdateRequest
     */
    'officeIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CanadaRoutingNumber
 */
export interface CanadaRoutingNumber {
    /**
     * 
     * @type {string}
     * @memberof CanadaRoutingNumber
     */
    'institutionNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CanadaRoutingNumber
     */
    'branchNumber': string;
}
/**
 * 
 * @export
 * @interface CapDefermentStatus
 */
export interface CapDefermentStatus {
    /**
     * 
     * @type {string}
     * @memberof CapDefermentStatus
     */
    'agentId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CapDefermentStatus
     */
    'capDeferred'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CapDefermentStatus
     */
    'asOf'?: string;
    /**
     * 
     * @type {string}
     * @memberof CapDefermentStatus
     */
    'endsOn'?: string;
}
/**
 * 
 * @export
 * @interface ChecklistDefinitionDto
 */
export interface ChecklistDefinitionDto {
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'checklistDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'type': ChecklistDefinitionDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'targetType': ChecklistDefinitionDtoTargetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'dealType': ChecklistDefinitionDtoDealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChecklistDefinitionDto
     */
    'representee': ChecklistDefinitionDtoRepresenteeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChecklistDefinitionDto
     */
    'propertyTypes'?: Array<ChecklistDefinitionDtoPropertyTypesEnum>;
}

export const ChecklistDefinitionDtoTypeEnum = {
    Transaction: 'TRANSACTION',
    Journey: 'JOURNEY',
    Checklist: 'CHECKLIST'
} as const;

export type ChecklistDefinitionDtoTypeEnum = typeof ChecklistDefinitionDtoTypeEnum[keyof typeof ChecklistDefinitionDtoTypeEnum];
export const ChecklistDefinitionDtoTargetTypeEnum = {
    Transaction: 'TRANSACTION',
    Referral: 'REFERRAL',
    InternalReferral: 'INTERNAL_REFERRAL',
    Listing: 'LISTING',
    ChecklistItem: 'CHECKLIST_ITEM',
    Dropbox: 'DROPBOX',
    Real: 'REAL',
    Office: 'OFFICE',
    Loan: 'LOAN',
    Agent: 'AGENT',
    AgentDebt: 'AGENT_DEBT',
    Borrower: 'BORROWER',
    MortgageLead: 'MORTGAGE_LEAD',
    Application: 'APPLICATION',
    Checklist: 'CHECKLIST',
    Email: 'EMAIL',
    Comment: 'COMMENT',
    Team: 'TEAM',
    OutgoingPayment: 'OUTGOING_PAYMENT',
    Wallet: 'WALLET',
    Voice: 'VOICE',
    Mls: 'MLS',
    Board: 'BOARD',
    User: 'USER',
    Conversation: 'CONVERSATION',
    ReferralCentral: 'REFERRAL_CENTRAL',
    SignatureDocument: 'SIGNATURE_DOCUMENT',
    SignaturePacket: 'SIGNATURE_PACKET',
    UserAgreement: 'USER_AGREEMENT'
} as const;

export type ChecklistDefinitionDtoTargetTypeEnum = typeof ChecklistDefinitionDtoTargetTypeEnum[keyof typeof ChecklistDefinitionDtoTargetTypeEnum];
export const ChecklistDefinitionDtoDealTypeEnum = {
    Sale: 'SALE',
    Lease: 'LEASE'
} as const;

export type ChecklistDefinitionDtoDealTypeEnum = typeof ChecklistDefinitionDtoDealTypeEnum[keyof typeof ChecklistDefinitionDtoDealTypeEnum];
export const ChecklistDefinitionDtoRepresenteeEnum = {
    Buyer: 'BUYER',
    Seller: 'SELLER',
    Dual: 'DUAL'
} as const;

export type ChecklistDefinitionDtoRepresenteeEnum = typeof ChecklistDefinitionDtoRepresenteeEnum[keyof typeof ChecklistDefinitionDtoRepresenteeEnum];
export const ChecklistDefinitionDtoPropertyTypesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    LandLot: 'LAND_LOT',
    Condo: 'CONDO',
    MobileHome: 'MOBILE_HOME',
    NewConstruction: 'NEW_CONSTRUCTION'
} as const;

export type ChecklistDefinitionDtoPropertyTypesEnum = typeof ChecklistDefinitionDtoPropertyTypesEnum[keyof typeof ChecklistDefinitionDtoPropertyTypesEnum];

/**
 * 
 * @export
 * @interface CommissionPlanBasicResponse
 */
export interface CommissionPlanBasicResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanBasicResponse
     */
    'commissionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanBasicResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanBasicResponse
     */
    'country'?: CommissionPlanBasicResponseCountryEnum;
}

export const CommissionPlanBasicResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CommissionPlanBasicResponseCountryEnum = typeof CommissionPlanBasicResponseCountryEnum[keyof typeof CommissionPlanBasicResponseCountryEnum];

/**
 * 
 * @export
 * @interface CommissionPlanParameters
 */
export interface CommissionPlanParameters {
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     * @deprecated
     */
    'brokerageFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'monthlyBrokerageFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'perTransactionBrokerageFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'agentSplit': number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'cap': number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'normalTeamCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'platinumTeamCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'groupTeamCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'domesticTeamCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'postCapTransactionFee': number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'beopFee': number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanParameters
     */
    'realSplit'?: number;
}
/**
 * 
 * @export
 * @interface CommissionPlanResponse
 */
export interface CommissionPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommissionPlanResponse
     */
    'country'?: CommissionPlanResponseCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'annualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'normalTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'normalTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'platinumTeamLeadAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'platinumTeamMemberAnnualCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'domesticTeamCap'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'groupTeamCap'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'preCapRealCommissionSplit'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'postCapSalesTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'postCapLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'postCapTransactionFeePercentage'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'minCommissionForPostCapTransactionFeePercentage'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'signUpFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'personalDealFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CommissionPlanResponse
     */
    'allowedPersonalDeals'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'annualBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'perTransactionBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'minCommissionForBrokerageFee'?: MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CommissionPlanResponse
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CommissionPlanResponse
     */
    'beopFee'?: MoneyValue;
    /**
     * 
     * @type {Array<MoneyValue>}
     * @memberof CommissionPlanResponse
     */
    'allAnnualCaps'?: Array<MoneyValue>;
}

export const CommissionPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CommissionPlanResponseCountryEnum = typeof CommissionPlanResponseCountryEnum[keyof typeof CommissionPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'ein'?: string;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof CompanyResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof CompanyResponse
     */
    'address'?: AddressResponse;
}
/**
 * 
 * @export
 * @interface CompareBrokerage
 */
export interface CompareBrokerage {
    /**
     * 
     * @type {CommissionPlanParameters}
     * @memberof CompareBrokerage
     */
    'commissionPlan': CommissionPlanParameters;
    /**
     * 
     * @type {AwardParameters}
     * @memberof CompareBrokerage
     */
    'awards': AwardParameters;
    /**
     * 
     * @type {number}
     * @memberof CompareBrokerage
     */
    'eliteAwardAmount': number;
    /**
     * 
     * @type {number}
     * @memberof CompareBrokerage
     */
    'monthlyRevshareEarnings'?: number;
}
/**
 * 
 * @export
 * @interface ConvertUserToAgentRequest
 */
export interface ConvertUserToAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof ConvertUserToAgentRequest
     */
    'accountCountry': ConvertUserToAgentRequestAccountCountryEnum;
}

export const ConvertUserToAgentRequestAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type ConvertUserToAgentRequestAccountCountryEnum = typeof ConvertUserToAgentRequestAccountCountryEnum[keyof typeof ConvertUserToAgentRequestAccountCountryEnum];

/**
 * 
 * @export
 * @interface CountryParameters
 */
export interface CountryParameters {
    /**
     * 
     * @type {string}
     * @memberof CountryParameters
     */
    'country'?: CountryParametersCountryEnum;
    /**
     * 
     * @type {CommissionPlanParameters}
     * @memberof CountryParameters
     */
    'commissionPlan'?: CommissionPlanParameters;
    /**
     * 
     * @type {ElitePlanParameters}
     * @memberof CountryParameters
     */
    'elitePlan'?: ElitePlanParameters;
    /**
     * 
     * @type {EquityPlanParameters}
     * @memberof CountryParameters
     */
    'equityPurchasePlan'?: EquityPlanParameters;
    /**
     * 
     * @type {RevshareParameters}
     * @memberof CountryParameters
     */
    'revshareParameters'?: RevshareParameters;
}

export const CountryParametersCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CountryParametersCountryEnum = typeof CountryParametersCountryEnum[keyof typeof CountryParametersCountryEnum];

/**
 * 
 * @export
 * @interface CreateAgentLeadRequest
 */
export interface CreateAgentLeadRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'agentLeadType'?: CreateAgentLeadRequestAgentLeadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'name'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateAgentLeadRequest
     */
    'addressRequest'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentLeadRequest
     */
    'listingId'?: string;
}

export const CreateAgentLeadRequestAgentLeadTypeEnum = {
    MarketAnalysis: 'MARKET_ANALYSIS',
    Property: 'PROPERTY',
    General: 'GENERAL'
} as const;

export type CreateAgentLeadRequestAgentLeadTypeEnum = typeof CreateAgentLeadRequestAgentLeadTypeEnum[keyof typeof CreateAgentLeadRequestAgentLeadTypeEnum];

/**
 * 
 * @export
 * @interface CreateAgentReferralAgreementCommand
 */
export interface CreateAgentReferralAgreementCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'agreementDefinitionId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'referralAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'receivingAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'referralManagingBrokerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'receivingManagingBrokerId': string;
    /**
     * 
     * @type {Array<DocumentFieldValueDto>}
     * @memberof CreateAgentReferralAgreementCommand
     */
    'documentFieldValues': Array<DocumentFieldValueDto>;
}
/**
 * 
 * @export
 * @interface CreateAgentReferralAgreementDefinition
 */
export interface CreateAgentReferralAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementDefinition
     */
    'commissionPlanId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAgentReferralAgreementDefinition
     */
    'requiredAt': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAgentReferralAgreementDefinition
     */
    'statesOrProvinces': Array<CreateAgentReferralAgreementDefinitionStatesOrProvincesEnum>;
}

export const CreateAgentReferralAgreementDefinitionStatesOrProvincesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type CreateAgentReferralAgreementDefinitionStatesOrProvincesEnum = typeof CreateAgentReferralAgreementDefinitionStatesOrProvincesEnum[keyof typeof CreateAgentReferralAgreementDefinitionStatesOrProvincesEnum];

/**
 * 
 * @export
 * @interface CreateAgentReferralAgreementDefinitionAllOf
 */
export interface CreateAgentReferralAgreementDefinitionAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateAgentReferralAgreementDefinitionAllOf
     */
    'commissionPlanId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateAgentReferralAgreementDefinitionAllOf
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAgentReferralAgreementDefinitionAllOf
     */
    'statesOrProvinces'?: Array<CreateAgentReferralAgreementDefinitionAllOfStatesOrProvincesEnum>;
}

export const CreateAgentReferralAgreementDefinitionAllOfStatesOrProvincesEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type CreateAgentReferralAgreementDefinitionAllOfStatesOrProvincesEnum = typeof CreateAgentReferralAgreementDefinitionAllOfStatesOrProvincesEnum[keyof typeof CreateAgentReferralAgreementDefinitionAllOfStatesOrProvincesEnum];

/**
 * 
 * @export
 * @interface CreateApplicationRequest
 */
export interface CreateApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateApplicationRequest
     */
    'applicationType'?: CreateApplicationRequestApplicationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicationRequest
     */
    'teamInvitationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateApplicationRequest
     */
    'genericTeamInvitationId'?: string;
}

export const CreateApplicationRequestApplicationTypeEnum = {
    Regular: 'REGULAR',
    LetterOfIntent: 'LETTER_OF_INTENT',
    Ica: 'ICA'
} as const;

export type CreateApplicationRequestApplicationTypeEnum = typeof CreateApplicationRequestApplicationTypeEnum[keyof typeof CreateApplicationRequestApplicationTypeEnum];

/**
 * 
 * @export
 * @interface CreateAssistantAccessAgreement
 */
export interface CreateAssistantAccessAgreement extends BasicAgreementByTypeRequest {
}


/**
 * 
 * @export
 * @interface CreateAssistantAccessAgreementDefinition
 */
export interface CreateAssistantAccessAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateAssistantPolicyAndProceduresAgreement
 */
export interface CreateAssistantPolicyAndProceduresAgreement extends BasicAgreementByTypeRequest {
}


/**
 * 
 * @export
 * @interface CreateAssistantPolicyAndProceduresAgreementDefinition
 */
export interface CreateAssistantPolicyAndProceduresAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateBankAccountRequest
 */
export interface CreateBankAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'bankName': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateBankAccountRequest
     */
    'addressRequest': AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'bankRoutingNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'wireRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'ftniSettlementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'msdxBatch': CreateBankAccountRequestMsdxBatchEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'msdxGlNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBankAccountRequest
     */
    'bankPartnerAccountId'?: string;
}

export const CreateBankAccountRequestMsdxBatchEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type CreateBankAccountRequestMsdxBatchEnum = typeof CreateBankAccountRequestMsdxBatchEnum[keyof typeof CreateBankAccountRequestMsdxBatchEnum];

/**
 * 
 * @export
 * @interface CreateCommissionPlanRequest
 */
export interface CreateCommissionPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCommissionPlanRequest
     */
    'country': CreateCommissionPlanRequestCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'annualCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'normalTeamLeadAnnualCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'normalTeamMemberAnnualCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'platinumTeamLeadAnnualCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'platinumTeamMemberAnnualCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'domesticTeamCap': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'groupTeamCap': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CreateCommissionPlanRequest
     */
    'preCapRealCommissionSplit': number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'postCapSalesTransactionFee': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'postCapLeaseTransactionFee': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'signUpFee': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'personalDealFee': MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof CreateCommissionPlanRequest
     */
    'allowedPersonalDeals': number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'annualBrokerageFee': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'perTransactionBrokerageFee': MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'minCommissionForBrokerageFee': MoneyValue;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCommissionPlanRequest
     */
    'commercial'?: boolean;
    /**
     * 
     * @type {MoneyValue}
     * @memberof CreateCommissionPlanRequest
     */
    'beopFee'?: MoneyValue;
}

export const CreateCommissionPlanRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateCommissionPlanRequestCountryEnum = typeof CreateCommissionPlanRequestCountryEnum[keyof typeof CreateCommissionPlanRequestCountryEnum];

/**
 * 
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'ein': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'administrativeAreaId': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateCompanyRequest
     */
    'address': AddressRequest;
}
/**
 * 
 * @export
 * @interface CreateDefaultExperimentRequest
 */
export interface CreateDefaultExperimentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDefaultExperimentRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateDomesticTeamRequest
 */
export interface CreateDomesticTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDomesticTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDomesticTeamRequest
     */
    'leaderAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDomesticTeamRequest
     */
    'commissionDocumentApprover'?: CreateDomesticTeamRequestCommissionDocumentApproverEnum;
}

export const CreateDomesticTeamRequestCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreateDomesticTeamRequestCommissionDocumentApproverEnum = typeof CreateDomesticTeamRequestCommissionDocumentApproverEnum[keyof typeof CreateDomesticTeamRequestCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface CreateEliteEquityAwardPlanRequest
 */
export interface CreateEliteEquityAwardPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'country': CreateEliteEquityAwardPlanRequestCountryEnum;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'postCapTransactionFeesTotal': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'minGCI': MonetaryAmount;
    /**
     * 
     * @type {number}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'minTransactionsClosed': number;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'minTransactionPrice': MonetaryAmount;
    /**
     * 
     * @type {number}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'minParticipationLevel': number;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'awardedAmount': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'postEliteSalesTransactionFee': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEliteEquityAwardPlanRequest
     */
    'postEliteLeaseTransactionFee': MonetaryAmount;
}

export const CreateEliteEquityAwardPlanRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateEliteEquityAwardPlanRequestCountryEnum = typeof CreateEliteEquityAwardPlanRequestCountryEnum[keyof typeof CreateEliteEquityAwardPlanRequestCountryEnum];

/**
 * 
 * @export
 * @interface CreateEquityAwardPlanRequest
 */
export interface CreateEquityAwardPlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEquityAwardPlanRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityAwardPlanRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityAwardPlanRequest
     */
    'country': CreateEquityAwardPlanRequestCountryEnum;
    /**
     * 
     * @type {Array<CreateEquityAwardTierRequest>}
     * @memberof CreateEquityAwardPlanRequest
     */
    'tiers': Array<CreateEquityAwardTierRequest>;
}

export const CreateEquityAwardPlanRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateEquityAwardPlanRequestCountryEnum = typeof CreateEquityAwardPlanRequestCountryEnum[keyof typeof CreateEquityAwardPlanRequestCountryEnum];

/**
 * 
 * @export
 * @interface CreateEquityAwardTierRequest
 */
export interface CreateEquityAwardTierRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    'minNumberOfAgents': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    'maxNumberOfAgents': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    'numberOfSharesForCapping': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityAwardTierRequest
     */
    'numberOfSharesForAttracting': number;
}
/**
 * 
 * @export
 * @interface CreateEquityPurchasePlanRequest
 */
export interface CreateEquityPurchasePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'country': CreateEquityPurchasePlanRequestCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'preCapNetCommissionPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'postCapNetCommissionPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'preCapBonusSharePercent': number;
    /**
     * 
     * @type {number}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'postCapBonusSharePercent': number;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'preCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'normalTeamMemberPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'normalTeamLeaderPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'platinumTeamMemberPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'platinumTeamLeaderPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'groupTeamPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'domesticTeamPreCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'postCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'normalTeamMemberPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'normalTeamLeaderPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'platinumTeamMemberPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'platinumTeamLeaderPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'groupTeamPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'domesticTeamPostCapMax': MonetaryAmount;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateEquityPurchasePlanRequest
     */
    'minPurchase': MonetaryAmount;
}

export const CreateEquityPurchasePlanRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateEquityPurchasePlanRequestCountryEnum = typeof CreateEquityPurchasePlanRequestCountryEnum[keyof typeof CreateEquityPurchasePlanRequestCountryEnum];

/**
 * 
 * @export
 * @interface CreateExperimentGroupRequest
 */
export interface CreateExperimentGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateExperimentGroupRequest
     */
    'groupIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentGroupRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentGroupRequest
     */
    'payload'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExperimentGroupRequest
     */
    'overrideIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateExperimentGroupRequest
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface CreateExperimentRequest
 */
export interface CreateExperimentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    'state': CreateExperimentRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    'salt': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExperimentRequest
     */
    'filterScript'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateExperimentRequest
     */
    'rolloutStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExperimentRequest
     */
    'rolloutEnd'?: number;
    /**
     * 
     * @type {Array<CreateExperimentGroupRequest>}
     * @memberof CreateExperimentRequest
     */
    'groups'?: Array<CreateExperimentGroupRequest>;
}

export const CreateExperimentRequestStateEnum = {
    PreRollout: 'PRE_ROLLOUT',
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type CreateExperimentRequestStateEnum = typeof CreateExperimentRequestStateEnum[keyof typeof CreateExperimentRequestStateEnum];

/**
 * 
 * @export
 * @interface CreateExternalTransactionCoordinatorConnectionRequest
 */
export interface CreateExternalTransactionCoordinatorConnectionRequest {
    /**
     * 
     * @type {EmailAddress}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'emailAddress': EmailAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'accessType': CreateExternalTransactionCoordinatorConnectionRequestAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalTransactionCoordinatorConnectionRequest
     */
    'accessDate'?: string;
}

export const CreateExternalTransactionCoordinatorConnectionRequestAccessTypeEnum = {
    AllListingsAndTransactions: 'ALL_LISTINGS_AND_TRANSACTIONS',
    CreatedAfterSpecificDate: 'CREATED_AFTER_SPECIFIC_DATE',
    CreatedByOrAssignedTo: 'CREATED_BY_OR_ASSIGNED_TO'
} as const;

export type CreateExternalTransactionCoordinatorConnectionRequestAccessTypeEnum = typeof CreateExternalTransactionCoordinatorConnectionRequestAccessTypeEnum[keyof typeof CreateExternalTransactionCoordinatorConnectionRequestAccessTypeEnum];

/**
 * 
 * @export
 * @interface CreateFlexTeam
 */
export interface CreateFlexTeam {
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeam
     */
    'flexTeamConfigId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeam
     */
    'requestedTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeam
     */
    'name': string;
    /**
     * 
     * @type {Array<AddFlexTeammate>}
     * @memberof CreateFlexTeam
     */
    'initialTeammates'?: Array<AddFlexTeammate>;
    /**
     * 
     * @type {Array<AddFlexTeamLeader>}
     * @memberof CreateFlexTeam
     */
    'initialLeaders'?: Array<AddFlexTeamLeader>;
    /**
     * 
     * @type {TeamCap}
     * @memberof CreateFlexTeam
     */
    'teamCap'?: TeamCap;
}
/**
 * 
 * @export
 * @interface CreateFlexTeamAndConfig
 */
export interface CreateFlexTeamAndConfig {
    /**
     * 
     * @type {CreateFlexTeamConfig}
     * @memberof CreateFlexTeamAndConfig
     */
    'config': CreateFlexTeamConfig;
    /**
     * 
     * @type {CreateFlexTeam}
     * @memberof CreateFlexTeamAndConfig
     */
    'team': CreateFlexTeam;
}
/**
 * 
 * @export
 * @interface CreateFlexTeamConfig
 */
export interface CreateFlexTeamConfig {
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'commissionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'teamType'?: CreateFlexTeamConfigTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFlexTeamConfig
     */
    'minTeammates': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFlexTeamConfig
     */
    'maxTeammates': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFlexTeamConfig
     */
    'minLeaders': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFlexTeamConfig
     */
    'maxLeaders': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'paymentDetailsVisibility': CreateFlexTeamConfigPaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {CreateLeaderSplitConfig}
     * @memberof CreateFlexTeamConfig
     */
    'leaderSplitConfig': CreateLeaderSplitConfig;
    /**
     * 
     * @type {CreateRealCapConfig}
     * @memberof CreateFlexTeamConfig
     */
    'realCapConfig': CreateRealCapConfig;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'cdaApprover': CreateFlexTeamConfigCdaApproverEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFlexTeamConfig
     */
    'paidAtClosing': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateFlexTeamConfig
     */
    'leaderOverridableProperties'?: Array<CreateFlexTeamConfigLeaderOverridablePropertiesEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfig
     */
    'permittedTransactionEditors'?: CreateFlexTeamConfigPermittedTransactionEditorsEnum;
}

export const CreateFlexTeamConfigTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type CreateFlexTeamConfigTeamTypeEnum = typeof CreateFlexTeamConfigTeamTypeEnum[keyof typeof CreateFlexTeamConfigTeamTypeEnum];
export const CreateFlexTeamConfigPaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type CreateFlexTeamConfigPaymentDetailsVisibilityEnum = typeof CreateFlexTeamConfigPaymentDetailsVisibilityEnum[keyof typeof CreateFlexTeamConfigPaymentDetailsVisibilityEnum];
export const CreateFlexTeamConfigCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreateFlexTeamConfigCdaApproverEnum = typeof CreateFlexTeamConfigCdaApproverEnum[keyof typeof CreateFlexTeamConfigCdaApproverEnum];
export const CreateFlexTeamConfigLeaderOverridablePropertiesEnum = {
    LeaderSplitEnforcement: 'LEADER_SPLIT_ENFORCEMENT',
    PaymentDetailsVisibility: 'PAYMENT_DETAILS_VISIBILITY',
    PaidAtClosing: 'PAID_AT_CLOSING',
    CdaApprovalRoles: 'CDA_APPROVAL_ROLES',
    PermittedTransactionEditorRoles: 'PERMITTED_TRANSACTION_EDITOR_ROLES'
} as const;

export type CreateFlexTeamConfigLeaderOverridablePropertiesEnum = typeof CreateFlexTeamConfigLeaderOverridablePropertiesEnum[keyof typeof CreateFlexTeamConfigLeaderOverridablePropertiesEnum];
export const CreateFlexTeamConfigPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreateFlexTeamConfigPermittedTransactionEditorsEnum = typeof CreateFlexTeamConfigPermittedTransactionEditorsEnum[keyof typeof CreateFlexTeamConfigPermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface CreateFlexTeamConfigForLegacyTeam
 */
export interface CreateFlexTeamConfigForLegacyTeam {
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfigForLegacyTeam
     */
    'teamType': CreateFlexTeamConfigForLegacyTeamTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamConfigForLegacyTeam
     */
    'country': CreateFlexTeamConfigForLegacyTeamCountryEnum;
}

export const CreateFlexTeamConfigForLegacyTeamTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type CreateFlexTeamConfigForLegacyTeamTeamTypeEnum = typeof CreateFlexTeamConfigForLegacyTeamTeamTypeEnum[keyof typeof CreateFlexTeamConfigForLegacyTeamTeamTypeEnum];
export const CreateFlexTeamConfigForLegacyTeamCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateFlexTeamConfigForLegacyTeamCountryEnum = typeof CreateFlexTeamConfigForLegacyTeamCountryEnum[keyof typeof CreateFlexTeamConfigForLegacyTeamCountryEnum];

/**
 * 
 * @export
 * @interface CreateFlexTeamMonomorphic
 */
export interface CreateFlexTeamMonomorphic {
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamMonomorphic
     */
    'flexTeamConfigId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamMonomorphic
     */
    'requestedTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFlexTeamMonomorphic
     */
    'name': string;
    /**
     * 
     * @type {Array<AddFlexTeammateMonomorphic>}
     * @memberof CreateFlexTeamMonomorphic
     */
    'initialTeammates'?: Array<AddFlexTeammateMonomorphic>;
    /**
     * 
     * @type {Array<AddFlexTeamLeaderMonomorphic>}
     * @memberof CreateFlexTeamMonomorphic
     */
    'initialLeaders'?: Array<AddFlexTeamLeaderMonomorphic>;
    /**
     * 
     * @type {TeamCap}
     * @memberof CreateFlexTeamMonomorphic
     */
    'teamCap'?: TeamCap;
}
/**
 * 
 * @export
 * @interface CreateGenericTeamInvitationRequestDto
 */
export interface CreateGenericTeamInvitationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGenericTeamInvitationRequestDto
     */
    'teamId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateGenericTeamInvitationRequestDto
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGenericTeamInvitationRequestDto
     */
    'waiveFees'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateGroupRequest
 */
export interface CreateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupRequest
     */
    'groupName': string;
}
/**
 * 
 * @export
 * @interface CreateICAAgreementDefinition
 */
export interface CreateICAAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
    /**
     * 
     * @type {number}
     * @memberof CreateICAAgreementDefinition
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateICAAgreementDefinition
     */
    'commissionPlanId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateICAAgreementDefinition
     */
    'forceSign': boolean;
}


/**
 * 
 * @export
 * @interface CreateICAAgreementDefinitionAllOf
 */
export interface CreateICAAgreementDefinitionAllOf {
    /**
     * 
     * @type {number}
     * @memberof CreateICAAgreementDefinitionAllOf
     */
    'requiredAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateICAAgreementDefinitionAllOf
     */
    'commissionPlanId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateICAAgreementDefinitionAllOf
     */
    'forceSign'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateInternalTransactionCoordinatorConnectionRequest
 */
export interface CreateInternalTransactionCoordinatorConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInternalTransactionCoordinatorConnectionRequest
     */
    'transactionCoordinatorId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalTransactionCoordinatorConnectionRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalTransactionCoordinatorConnectionRequest
     */
    'accessType': CreateInternalTransactionCoordinatorConnectionRequestAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateInternalTransactionCoordinatorConnectionRequest
     */
    'accessDate'?: string;
}

export const CreateInternalTransactionCoordinatorConnectionRequestAccessTypeEnum = {
    AllListingsAndTransactions: 'ALL_LISTINGS_AND_TRANSACTIONS',
    CreatedAfterSpecificDate: 'CREATED_AFTER_SPECIFIC_DATE',
    CreatedByOrAssignedTo: 'CREATED_BY_OR_ASSIGNED_TO'
} as const;

export type CreateInternalTransactionCoordinatorConnectionRequestAccessTypeEnum = typeof CreateInternalTransactionCoordinatorConnectionRequestAccessTypeEnum[keyof typeof CreateInternalTransactionCoordinatorConnectionRequestAccessTypeEnum];

/**
 * 
 * @export
 * @interface CreateLeaderSplitConfig
 */
export interface CreateLeaderSplitConfig {
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaderSplitConfig
     */
    'enforceSplits': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaderSplitConfig
     */
    'minSplitPercent': number;
}
/**
 * 
 * @export
 * @interface CreateLicensedAssistantAddendumAgreement
 */
export interface CreateLicensedAssistantAddendumAgreement extends BasicAgreementByTypeRequest {
}


/**
 * 
 * @export
 * @interface CreateLicensedAssistantAddendumAgreementDefinition
 */
export interface CreateLicensedAssistantAddendumAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateNormalTeamMemberRequest
 */
export interface CreateNormalTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamMemberRequest
     */
    'memberAgentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateNormalTeamMemberRequest
     */
    'memberCommissionSplit'?: number;
}
/**
 * 
 * @export
 * @interface CreateNormalTeamRequest
 */
export interface CreateNormalTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamRequest
     */
    'leaderAgentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateNormalTeamRequest
     */
    'memberCommissionSplit': number;
    /**
     * 
     * @type {number}
     * @memberof CreateNormalTeamRequest
     */
    'maxMemberCommissionSplit'?: number;
    /**
     * 
     * @type {Array<CreateNormalTeamMemberRequest>}
     * @memberof CreateNormalTeamRequest
     */
    'members': Array<CreateNormalTeamMemberRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateNormalTeamRequest
     */
    'commissionDocumentApprover'?: CreateNormalTeamRequestCommissionDocumentApproverEnum;
}

export const CreateNormalTeamRequestCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreateNormalTeamRequestCommissionDocumentApproverEnum = typeof CreateNormalTeamRequestCommissionDocumentApproverEnum[keyof typeof CreateNormalTeamRequestCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface CreateNoteRequest
 */
export interface CreateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'allowableRole': CreateNoteRequestAllowableRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateNoteRequest
     */
    'followupDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'entityType': CreateNoteRequestEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateNoteRequest
     */
    'entityId': string;
}

export const CreateNoteRequestAllowableRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type CreateNoteRequestAllowableRoleEnum = typeof CreateNoteRequestAllowableRoleEnum[keyof typeof CreateNoteRequestAllowableRoleEnum];
export const CreateNoteRequestEntityTypeEnum = {
    Application: 'APPLICATION',
    Transaction: 'TRANSACTION',
    Agent: 'AGENT',
    Mls: 'MLS',
    Board: 'BOARD'
} as const;

export type CreateNoteRequestEntityTypeEnum = typeof CreateNoteRequestEntityTypeEnum[keyof typeof CreateNoteRequestEntityTypeEnum];

/**
 * 
 * @export
 * @interface CreateOfficeRequest
 */
export interface CreateOfficeRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateOfficeRequest
     */
    'address': AddressRequest;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateOfficeRequest
     */
    'stateOfficeAddress'?: AddressRequest;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateOfficeRequest
     */
    'commissionDocumentAddress'?: AddressRequest;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateOfficeRequest
     */
    'branchOfficeAddress'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'faxNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'brokerageLicenseNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'transactionType': CreateOfficeRequestTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'administrativeAreaId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'designatedBrokerId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'managingBrokerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'paysOtherAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'leasePaysOtherAgent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'cdaDocumentTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'invoiceDocumentTitle': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'acceptsEscrow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'usesTipalti'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'paymentSystem'?: CreateOfficeRequestPaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'paymentBatchName'?: CreateOfficeRequestPaymentBatchNameEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'paymentInstructions': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfficeRequest
     */
    'mlsIds'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfficeRequest
     */
    'boardIds'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'bankAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'trustAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'operatingAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'walletCommissionAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'walletTrustAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'walletOperatingAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'companyId': string;
    /**
     * 
     * @type {Array<TaxValue>}
     * @memberof CreateOfficeRequest
     */
    'taxes'?: Array<TaxValue>;
    /**
     * 
     * @type {string}
     * @memberof CreateOfficeRequest
     */
    'coordinatorFeeType'?: CreateOfficeRequestCoordinatorFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'paysCommissionFromTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'allowsLease'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'realWithholdsTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'taxPaidFromCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     */
    'allowsLeaseTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'additionalCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'rebate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'transactionCoordinatorFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'reimbursementFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfficeRequest
     * @deprecated
     */
    'mlsFee'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOfficeRequest
     */
    'additionalFeesAndRebates'?: Array<CreateOfficeRequestAdditionalFeesAndRebatesEnum>;
}

export const CreateOfficeRequestTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type CreateOfficeRequestTransactionTypeEnum = typeof CreateOfficeRequestTransactionTypeEnum[keyof typeof CreateOfficeRequestTransactionTypeEnum];
export const CreateOfficeRequestPaymentSystemEnum = {
    Tipalti: 'TIPALTI',
    MicrosoftDynamics: 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal: 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet: 'WALLET'
} as const;

export type CreateOfficeRequestPaymentSystemEnum = typeof CreateOfficeRequestPaymentSystemEnum[keyof typeof CreateOfficeRequestPaymentSystemEnum];
export const CreateOfficeRequestPaymentBatchNameEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type CreateOfficeRequestPaymentBatchNameEnum = typeof CreateOfficeRequestPaymentBatchNameEnum[keyof typeof CreateOfficeRequestPaymentBatchNameEnum];
export const CreateOfficeRequestCoordinatorFeeTypeEnum = {
    NetCommission: 'NET_COMMISSION',
    GrossCommission: 'GROSS_COMMISSION'
} as const;

export type CreateOfficeRequestCoordinatorFeeTypeEnum = typeof CreateOfficeRequestCoordinatorFeeTypeEnum[keyof typeof CreateOfficeRequestCoordinatorFeeTypeEnum];
export const CreateOfficeRequestAdditionalFeesAndRebatesEnum = {
    AdditionalCommission: 'ADDITIONAL_COMMISSION',
    Rebate: 'REBATE',
    TransactionCoordinatorFee: 'TRANSACTION_COORDINATOR_FEE',
    ReimbursementFee: 'REIMBURSEMENT_FEE',
    MlsFee: 'MLS_FEE',
    OneRealImpactFund: 'ONE_REAL_IMPACT_FUND'
} as const;

export type CreateOfficeRequestAdditionalFeesAndRebatesEnum = typeof CreateOfficeRequestAdditionalFeesAndRebatesEnum[keyof typeof CreateOfficeRequestAdditionalFeesAndRebatesEnum];

/**
 * 
 * @export
 * @interface CreateOrUpdateAgentWebsiteRequest
 */
export interface CreateOrUpdateAgentWebsiteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'agentIdOrSlug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'photoSet'?: CreateOrUpdateAgentWebsiteRequestPhotoSetEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'themeColor'?: CreateOrUpdateAgentWebsiteRequestThemeColorEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'websiteTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'mainTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'mainSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'bottomTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'bottomSubtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature1Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature1Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature2Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature2Content'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature3Title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'feature3Content'?: string;
    /**
     * 
     * @type {Array<AgentTestimonial>}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'testimonials'?: Array<AgentTestimonial>;
    /**
     * 
     * @type {PropertyFilter}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'propertyFilter'?: PropertyFilter;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'showReferralLink'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'competencies'?: Array<CreateOrUpdateAgentWebsiteRequestCompetenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateAgentWebsiteRequest
     */
    'youtubeURL'?: string;
}

export const CreateOrUpdateAgentWebsiteRequestPhotoSetEnum = {
    Cabin: 'CABIN',
    Luxury: 'LUXURY',
    Urban: 'URBAN',
    Country: 'COUNTRY',
    PhotoSet5: 'PHOTO_SET_5',
    PhotoSet6: 'PHOTO_SET_6',
    PhotoSet7: 'PHOTO_SET_7',
    PhotoSet8: 'PHOTO_SET_8',
    PhotoSet9: 'PHOTO_SET_9',
    PhotoSet10: 'PHOTO_SET_10'
} as const;

export type CreateOrUpdateAgentWebsiteRequestPhotoSetEnum = typeof CreateOrUpdateAgentWebsiteRequestPhotoSetEnum[keyof typeof CreateOrUpdateAgentWebsiteRequestPhotoSetEnum];
export const CreateOrUpdateAgentWebsiteRequestThemeColorEnum = {
    Green: 'GREEN',
    Black: 'BLACK',
    Blue: 'BLUE',
    Red: 'RED',
    Yellow: 'YELLOW',
    Magenta: 'MAGENTA'
} as const;

export type CreateOrUpdateAgentWebsiteRequestThemeColorEnum = typeof CreateOrUpdateAgentWebsiteRequestThemeColorEnum[keyof typeof CreateOrUpdateAgentWebsiteRequestThemeColorEnum];
export const CreateOrUpdateAgentWebsiteRequestCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type CreateOrUpdateAgentWebsiteRequestCompetenciesEnum = typeof CreateOrUpdateAgentWebsiteRequestCompetenciesEnum[keyof typeof CreateOrUpdateAgentWebsiteRequestCompetenciesEnum];

/**
 * 
 * @export
 * @interface CreatePaymentMethod
 */
export interface CreatePaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'usRoutingNumber': string;
    /**
     * 
     * @type {CanadaRoutingNumber}
     * @memberof CreatePaymentMethod
     */
    'canadaRoutingNumber'?: CanadaRoutingNumber;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'type': CreatePaymentMethodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethod
     */
    'purpose': CreatePaymentMethodPurposeEnum;
}

export const CreatePaymentMethodTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type CreatePaymentMethodTypeEnum = typeof CreatePaymentMethodTypeEnum[keyof typeof CreatePaymentMethodTypeEnum];
export const CreatePaymentMethodPurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type CreatePaymentMethodPurposeEnum = typeof CreatePaymentMethodPurposeEnum[keyof typeof CreatePaymentMethodPurposeEnum];

/**
 * 
 * @export
 * @interface CreatePaymentMethodResponse
 */
export interface CreatePaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'paymentSettingsId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'usRoutingNumber': string;
    /**
     * 
     * @type {CanadaRoutingNumber}
     * @memberof CreatePaymentMethodResponse
     */
    'canadaRoutingNumber'?: CanadaRoutingNumber;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'type'?: CreatePaymentMethodResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     */
    'purpose'?: CreatePaymentMethodResponsePurposeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentMethodResponse
     * @deprecated
     */
    'msDynamicsSyncedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     * @deprecated
     */
    'msDynamicsCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePaymentMethodResponse
     * @deprecated
     */
    'msDynamicsId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePaymentMethodResponse
     */
    'wallet'?: boolean;
    /**
     * 
     * @type {Array<MsdxBankAccountDto>}
     * @memberof CreatePaymentMethodResponse
     */
    'msdxBankAccounts'?: Array<MsdxBankAccountDto>;
    /**
     * 
     * @type {number}
     * @memberof CreatePaymentMethodResponse
     */
    'updatedAt'?: number;
}

export const CreatePaymentMethodResponseTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type CreatePaymentMethodResponseTypeEnum = typeof CreatePaymentMethodResponseTypeEnum[keyof typeof CreatePaymentMethodResponseTypeEnum];
export const CreatePaymentMethodResponsePurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type CreatePaymentMethodResponsePurposeEnum = typeof CreatePaymentMethodResponsePurposeEnum[keyof typeof CreatePaymentMethodResponsePurposeEnum];

/**
 * 
 * @export
 * @interface CreatePlatinumTeamRequest
 */
export interface CreatePlatinumTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePlatinumTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePlatinumTeamRequest
     */
    'leaderAgentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePlatinumTeamRequest
     */
    'memberCommissionSplit': number;
    /**
     * 
     * @type {number}
     * @memberof CreatePlatinumTeamRequest
     */
    'maxMemberCommissionSplit'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreatePlatinumTeamRequest
     */
    'commissionDocumentApprover'?: CreatePlatinumTeamRequestCommissionDocumentApproverEnum;
}

export const CreatePlatinumTeamRequestCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreatePlatinumTeamRequestCommissionDocumentApproverEnum = typeof CreatePlatinumTeamRequestCommissionDocumentApproverEnum[keyof typeof CreatePlatinumTeamRequestCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface CreateRealCapConfig
 */
export interface CreateRealCapConfig {
    /**
     * 
     * @type {number}
     * @memberof CreateRealCapConfig
     */
    'leaderCap': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateRealCapConfig
     */
    'excludedMemberCaps'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateRevenueSharePlanRequest
 */
export interface CreateRevenueSharePlanRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    'country': CreateRevenueSharePlanRequestCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueSharePlanRequest
     */
    'producingSpanInMonths'?: number;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateRevenueSharePlanRequest
     */
    'commissionsForProducingStatus'?: MonetaryAmount;
    /**
     * 
     * @type {Array<CreateRevenueShareTierRequest>}
     * @memberof CreateRevenueSharePlanRequest
     */
    'tiers': Array<CreateRevenueShareTierRequest>;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateRevenueSharePlanRequest
     */
    'annualFee'?: MonetaryAmount;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueSharePlanRequest
     */
    'processingFeePercent'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRevenueSharePlanRequest
     */
    'willableRevshareAgreementTemplateId': string;
}

export const CreateRevenueSharePlanRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type CreateRevenueSharePlanRequestCountryEnum = typeof CreateRevenueSharePlanRequestCountryEnum[keyof typeof CreateRevenueSharePlanRequestCountryEnum];

/**
 * 
 * @export
 * @interface CreateRevenueShareTierRequest
 */
export interface CreateRevenueShareTierRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    'tier': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    'commissionPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CreateRevenueShareTierRequest
     */
    'numberOfTierOneAgentsToUnlockThisTier': number;
    /**
     * 
     * @type {MonetaryAmount}
     * @memberof CreateRevenueShareTierRequest
     */
    'maxAnnualAmountPerAgent': MonetaryAmount;
}
/**
 * 
 * @export
 * @interface CreateTaxForm
 */
export interface CreateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxForm
     */
    'type'?: CreateTaxFormTypeEnum;
}

export const CreateTaxFormTypeEnum = {
    W9: 'W9',
    W8Eci: 'W8ECI',
    W8Ben: 'W8BEN',
    Canada: 'CANADA',
    Legacy: 'LEGACY'
} as const;

export type CreateTaxFormTypeEnum = typeof CreateTaxFormTypeEnum[keyof typeof CreateTaxFormTypeEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormCanada
 */
export interface CreateTaxFormCanada extends CreateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanada
     */
    'taxpayerName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanada
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormCanada
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormCanada
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanada
     */
    'typedSignature': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormCanada
     */
    'signedAt': number;
}


/**
 * 
 * @export
 * @interface CreateTaxFormCanadaAllOf
 */
export interface CreateTaxFormCanadaAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'taxpayerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormCanadaAllOf
     */
    'signedAt'?: number;
}
/**
 * 
 * @export
 * @interface CreateTaxFormW8BEN
 */
export interface CreateTaxFormW8BEN extends CreateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BEN
     */
    'name': string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW8BEN
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BEN
     */
    'foreignTaxId': string;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8BEN
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8BEN
     */
    'mailingAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BEN
     */
    'countryOfIncorporation': CreateTaxFormW8BENCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BEN
     */
    'typedSignature': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW8BEN
     */
    'signedAt': number;
}

export const CreateTaxFormW8BENCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type CreateTaxFormW8BENCountryOfIncorporationEnum = typeof CreateTaxFormW8BENCountryOfIncorporationEnum[keyof typeof CreateTaxFormW8BENCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormW8BENAllOf
 */
export interface CreateTaxFormW8BENAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'mailingAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'countryOfIncorporation'?: CreateTaxFormW8BENAllOfCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW8BENAllOf
     */
    'signedAt'?: number;
}

export const CreateTaxFormW8BENAllOfCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type CreateTaxFormW8BENAllOfCountryOfIncorporationEnum = typeof CreateTaxFormW8BENAllOfCountryOfIncorporationEnum[keyof typeof CreateTaxFormW8BENAllOfCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormW8ECI
 */
export interface CreateTaxFormW8ECI extends CreateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'taxEntityType': CreateTaxFormW8ECITaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW8ECI
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'foreignTaxId': string;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8ECI
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8ECI
     */
    'businessAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'countryOfIncorporation': CreateTaxFormW8ECICountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECI
     */
    'typedSignature': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW8ECI
     */
    'signedAt': number;
}

export const CreateTaxFormW8ECITaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type CreateTaxFormW8ECITaxEntityTypeEnum = typeof CreateTaxFormW8ECITaxEntityTypeEnum[keyof typeof CreateTaxFormW8ECITaxEntityTypeEnum];
export const CreateTaxFormW8ECICountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type CreateTaxFormW8ECICountryOfIncorporationEnum = typeof CreateTaxFormW8ECICountryOfIncorporationEnum[keyof typeof CreateTaxFormW8ECICountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormW8ECIAllOf
 */
export interface CreateTaxFormW8ECIAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'taxEntityType'?: CreateTaxFormW8ECIAllOfTaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'businessAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'countryOfIncorporation'?: CreateTaxFormW8ECIAllOfCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW8ECIAllOf
     */
    'signedAt'?: number;
}

export const CreateTaxFormW8ECIAllOfTaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type CreateTaxFormW8ECIAllOfTaxEntityTypeEnum = typeof CreateTaxFormW8ECIAllOfTaxEntityTypeEnum[keyof typeof CreateTaxFormW8ECIAllOfTaxEntityTypeEnum];
export const CreateTaxFormW8ECIAllOfCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type CreateTaxFormW8ECIAllOfCountryOfIncorporationEnum = typeof CreateTaxFormW8ECIAllOfCountryOfIncorporationEnum[keyof typeof CreateTaxFormW8ECIAllOfCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormW9
 */
export interface CreateTaxFormW9 extends CreateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9
     */
    'taxClassification': CreateTaxFormW9TaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW9
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW9
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9
     */
    'electronicDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9
     */
    'typedSignature': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW9
     */
    'signedAt': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9
     */
    'businessEntity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9
     */
    'individual'?: boolean;
}

export const CreateTaxFormW9TaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type CreateTaxFormW9TaxClassificationEnum = typeof CreateTaxFormW9TaxClassificationEnum[keyof typeof CreateTaxFormW9TaxClassificationEnum];

/**
 * 
 * @export
 * @interface CreateTaxFormW9AllOf
 */
export interface CreateTaxFormW9AllOf {
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9AllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9AllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9AllOf
     */
    'taxClassification'?: CreateTaxFormW9AllOfTaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof CreateTaxFormW9AllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof CreateTaxFormW9AllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9AllOf
     */
    'electronicDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateTaxFormW9AllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateTaxFormW9AllOf
     */
    'signedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9AllOf
     */
    'businessEntity'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaxFormW9AllOf
     */
    'individual'?: boolean;
}

export const CreateTaxFormW9AllOfTaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type CreateTaxFormW9AllOfTaxClassificationEnum = typeof CreateTaxFormW9AllOfTaxClassificationEnum[keyof typeof CreateTaxFormW9AllOfTaxClassificationEnum];

/**
 * 
 * @export
 * @interface CreateTeamInvitationRequest
 */
export interface CreateTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamInvitationRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamInvitationRequest
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTeamInvitationRequest
     */
    'waiveFees'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateTeamMemberRequest
 */
export interface CreateTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamMemberRequest
     */
    'memberAgentId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamMemberRequest
     */
    'memberCommissionSplit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateTeamMemberRequest
     */
    'roles'?: Array<CreateTeamMemberRequestRolesEnum>;
}

export const CreateTeamMemberRequestRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type CreateTeamMemberRequestRolesEnum = typeof CreateTeamMemberRequestRolesEnum[keyof typeof CreateTeamMemberRequestRolesEnum];

/**
 * 
 * @export
 * @interface CreateTeamRequest
 */
export interface CreateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'type'?: CreateTeamRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamRequest
     */
    'memberCommissionSplit'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamRequest
     */
    'maxMemberCommissionSplit'?: number;
    /**
     * 
     * @type {Array<CreateTeamMemberRequest>}
     * @memberof CreateTeamRequest
     */
    'members'?: Array<CreateTeamMemberRequest>;
    /**
     * 
     * @type {string}
     * @memberof CreateTeamRequest
     */
    'commissionDocumentApprover'?: CreateTeamRequestCommissionDocumentApproverEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateTeamRequest
     */
    'maxMemberCommissionSplitOrDefault'?: number;
}

export const CreateTeamRequestTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Group: 'GROUP',
    Domestic: 'DOMESTIC',
    Pro: 'PRO'
} as const;

export type CreateTeamRequestTypeEnum = typeof CreateTeamRequestTypeEnum[keyof typeof CreateTeamRequestTypeEnum];
export const CreateTeamRequestCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type CreateTeamRequestCommissionDocumentApproverEnum = typeof CreateTeamRequestCommissionDocumentApproverEnum[keyof typeof CreateTeamRequestCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface CreateUnlicensedAssistantAddendumAgreement
 */
export interface CreateUnlicensedAssistantAddendumAgreement extends BasicAgreementByTypeRequest {
}


/**
 * 
 * @export
 * @interface CreateUnlicensedAssistantAddendumAgreementDefinition
 */
export interface CreateUnlicensedAssistantAddendumAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateWalletCreditAgreementDefinition
 */
export interface CreateWalletCreditAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateWalletDebitAgreementDefinition
 */
export interface CreateWalletDebitAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateWalletLendingAgreementDefinition
 */
export interface CreateWalletLendingAgreementDefinition extends BasicAgreementDefinitionCreationCommand {
}


/**
 * 
 * @export
 * @interface CreateWillBeneficiaryRequest
 */
export interface CreateWillBeneficiaryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     */
    'lastName': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof CreateWillBeneficiaryRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateWillBeneficiaryRequest
     * @deprecated
     */
    'typedSignature'?: string;
}
/**
 * 
 * @export
 * @interface DateRange
 */
export interface DateRange {
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof DateRange
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface DayHourRange
 */
export interface DayHourRange {
    /**
     * 
     * @type {string}
     * @memberof DayHourRange
     */
    'dayOfWeek': DayHourRangeDayOfWeekEnum;
    /**
     * 
     * @type {HourRange}
     * @memberof DayHourRange
     */
    'hourRange': HourRange;
}

export const DayHourRangeDayOfWeekEnum = {
    Monday: 'MONDAY',
    Tuesday: 'TUESDAY',
    Wednesday: 'WEDNESDAY',
    Thursday: 'THURSDAY',
    Friday: 'FRIDAY',
    Saturday: 'SATURDAY',
    Sunday: 'SUNDAY'
} as const;

export type DayHourRangeDayOfWeekEnum = typeof DayHourRangeDayOfWeekEnum[keyof typeof DayHourRangeDayOfWeekEnum];

/**
 * 
 * @export
 * @interface DesignCenterJwtResponse
 */
export interface DesignCenterJwtResponse {
    /**
     * 
     * @type {string}
     * @memberof DesignCenterJwtResponse
     */
    'jwt'?: string;
}
/**
 * 
 * @export
 * @interface DirectoryEntryPermittedRolesResponse
 */
export interface DirectoryEntryPermittedRolesResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryPermittedRolesResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectoryEntryPermittedRolesResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectoryEntryPermittedRolesResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryPermittedRolesResponse
     */
    'type'?: DirectoryEntryPermittedRolesResponseTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectoryEntryPermittedRolesResponse
     */
    'roles'?: Array<DirectoryEntryPermittedRolesResponseRolesEnum>;
}

export const DirectoryEntryPermittedRolesResponseTypeEnum = {
    Vendor: 'VENDOR',
    Person: 'PERSON'
} as const;

export type DirectoryEntryPermittedRolesResponseTypeEnum = typeof DirectoryEntryPermittedRolesResponseTypeEnum[keyof typeof DirectoryEntryPermittedRolesResponseTypeEnum];
export const DirectoryEntryPermittedRolesResponseRolesEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryEntryPermittedRolesResponseRolesEnum = typeof DirectoryEntryPermittedRolesResponseRolesEnum[keyof typeof DirectoryEntryPermittedRolesResponseRolesEnum];

/**
 * 
 * @export
 * @interface DirectoryEntryResponse
 */
export interface DirectoryEntryResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectoryEntryResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectoryEntryResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'type'?: DirectoryEntryResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'nameLogical'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'linkedName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'linkedId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DirectoryEntryResponse
     */
    'hasW9'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof DirectoryEntryResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'status'?: DirectoryEntryResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryEntryResponse
     */
    'role'?: DirectoryEntryResponseRoleEnum;
    /**
     * 
     * @type {Array<NationalBusinessIdentification>}
     * @memberof DirectoryEntryResponse
     */
    'nationalBusinessIdentifications'?: Array<NationalBusinessIdentification>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectoryEntryResponse
     */
    'administrativeAreaIds'?: Array<string>;
}

export const DirectoryEntryResponseTypeEnum = {
    Vendor: 'VENDOR',
    Person: 'PERSON'
} as const;

export type DirectoryEntryResponseTypeEnum = typeof DirectoryEntryResponseTypeEnum[keyof typeof DirectoryEntryResponseTypeEnum];
export const DirectoryEntryResponseStatusEnum = {
    Unverified: 'UNVERIFIED',
    Verified: 'VERIFIED',
    Archived: 'ARCHIVED'
} as const;

export type DirectoryEntryResponseStatusEnum = typeof DirectoryEntryResponseStatusEnum[keyof typeof DirectoryEntryResponseStatusEnum];
export const DirectoryEntryResponseRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryEntryResponseRoleEnum = typeof DirectoryEntryResponseRoleEnum[keyof typeof DirectoryEntryResponseRoleEnum];

/**
 * 
 * @export
 * @interface DirectoryPermissionsRequest
 */
export interface DirectoryPermissionsRequest {
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPermissionsRequest
     */
    'agentPermissions'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPermissionsRequest
     */
    'teamPermissions'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPermissionsRequest
     */
    'flexTeamPermissions'?: Array<PermissionDto>;
}
/**
 * 
 * @export
 * @interface DirectoryPersonCreateRequest
 */
export interface DirectoryPersonCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonCreateRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonCreateRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonCreateRequest
     */
    'role': DirectoryPersonCreateRequestRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonCreateRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonCreateRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof DirectoryPersonCreateRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {DirectoryPermissionsRequest}
     * @memberof DirectoryPersonCreateRequest
     */
    'permissions'?: DirectoryPermissionsRequest;
}

export const DirectoryPersonCreateRequestRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryPersonCreateRequestRoleEnum = typeof DirectoryPersonCreateRequestRoleEnum[keyof typeof DirectoryPersonCreateRequestRoleEnum];

/**
 * 
 * @export
 * @interface DirectoryPersonLinkRequest
 */
export interface DirectoryPersonLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonLinkRequest
     */
    'vendorId': string;
}
/**
 * 
 * @export
 * @interface DirectoryPersonResponse
 */
export interface DirectoryPersonResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectoryPersonResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectoryPersonResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {DirectoryVendorPartialResponse}
     * @memberof DirectoryPersonResponse
     */
    'linkedVendor'?: DirectoryVendorPartialResponse;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'role'?: DirectoryPersonResponseRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof DirectoryPersonResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'status'?: DirectoryPersonResponseStatusEnum;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPersonResponse
     */
    'agentPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPersonResponse
     */
    'teamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryPersonResponse
     */
    'flexTeamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'currentUserPermission'?: DirectoryPersonResponseCurrentUserPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonResponse
     */
    'createdBy'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DirectoryPersonResponse
     */
    'public'?: boolean;
}

export const DirectoryPersonResponseRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryPersonResponseRoleEnum = typeof DirectoryPersonResponseRoleEnum[keyof typeof DirectoryPersonResponseRoleEnum];
export const DirectoryPersonResponseStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type DirectoryPersonResponseStatusEnum = typeof DirectoryPersonResponseStatusEnum[keyof typeof DirectoryPersonResponseStatusEnum];
export const DirectoryPersonResponseCurrentUserPermissionEnum = {
    None: 'NONE',
    ReadOnly: 'READ_ONLY',
    ReadWrite: 'READ_WRITE'
} as const;

export type DirectoryPersonResponseCurrentUserPermissionEnum = typeof DirectoryPersonResponseCurrentUserPermissionEnum[keyof typeof DirectoryPersonResponseCurrentUserPermissionEnum];

/**
 * 
 * @export
 * @interface DirectoryPersonUpdateRequest
 */
export interface DirectoryPersonUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonUpdateRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonUpdateRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonUpdateRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonUpdateRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof DirectoryPersonUpdateRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof DirectoryPersonUpdateRequest
     */
    'role'?: DirectoryPersonUpdateRequestRoleEnum;
    /**
     * 
     * @type {DirectoryPermissionsRequest}
     * @memberof DirectoryPersonUpdateRequest
     */
    'permissions'?: DirectoryPermissionsRequest;
}

export const DirectoryPersonUpdateRequestRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryPersonUpdateRequestRoleEnum = typeof DirectoryPersonUpdateRequestRoleEnum[keyof typeof DirectoryPersonUpdateRequestRoleEnum];

/**
 * 
 * @export
 * @interface DirectoryVendorCreateRequest
 */
export interface DirectoryVendorCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorCreateRequest
     */
    'role': DirectoryVendorCreateRequestRoleEnum;
    /**
     * 
     * @type {Array<NationalBusinessIdentification>}
     * @memberof DirectoryVendorCreateRequest
     */
    'nationalBusinessIdentifications'?: Array<NationalBusinessIdentification>;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorCreateRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorCreateRequest
     */
    'secondaryEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorCreateRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof DirectoryVendorCreateRequest
     */
    'address': AddressRequest;
    /**
     * 
     * @type {Array<DirectoryPersonCreateRequest>}
     * @memberof DirectoryVendorCreateRequest
     */
    'personCreateRequests'?: Array<DirectoryPersonCreateRequest>;
    /**
     * 
     * @type {DirectoryPermissionsRequest}
     * @memberof DirectoryVendorCreateRequest
     */
    'permissions'?: DirectoryPermissionsRequest;
}

export const DirectoryVendorCreateRequestRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryVendorCreateRequestRoleEnum = typeof DirectoryVendorCreateRequestRoleEnum[keyof typeof DirectoryVendorCreateRequestRoleEnum];

/**
 * 
 * @export
 * @interface DirectoryVendorPartialResponse
 */
export interface DirectoryVendorPartialResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectoryVendorPartialResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectoryVendorPartialResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'role'?: DirectoryVendorPartialResponseRoleEnum;
    /**
     * 
     * @type {Array<NationalBusinessIdentification>}
     * @memberof DirectoryVendorPartialResponse
     */
    'nationalBusinessIdentifications'?: Array<NationalBusinessIdentification>;
    /**
     * 
     * @type {boolean}
     * @memberof DirectoryVendorPartialResponse
     */
    'hasW9'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'secondaryEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof DirectoryVendorPartialResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'status'?: DirectoryVendorPartialResponseStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectoryVendorPartialResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorPartialResponse
     */
    'agentPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorPartialResponse
     */
    'teamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorPartialResponse
     */
    'flexTeamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'currentUserPermission'?: DirectoryVendorPartialResponseCurrentUserPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorPartialResponse
     */
    'createdBy'?: string;
}

export const DirectoryVendorPartialResponseRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryVendorPartialResponseRoleEnum = typeof DirectoryVendorPartialResponseRoleEnum[keyof typeof DirectoryVendorPartialResponseRoleEnum];
export const DirectoryVendorPartialResponseStatusEnum = {
    Unverified: 'UNVERIFIED',
    Verified: 'VERIFIED',
    Archived: 'ARCHIVED'
} as const;

export type DirectoryVendorPartialResponseStatusEnum = typeof DirectoryVendorPartialResponseStatusEnum[keyof typeof DirectoryVendorPartialResponseStatusEnum];
export const DirectoryVendorPartialResponseCurrentUserPermissionEnum = {
    None: 'NONE',
    ReadOnly: 'READ_ONLY',
    ReadWrite: 'READ_WRITE'
} as const;

export type DirectoryVendorPartialResponseCurrentUserPermissionEnum = typeof DirectoryVendorPartialResponseCurrentUserPermissionEnum[keyof typeof DirectoryVendorPartialResponseCurrentUserPermissionEnum];

/**
 * 
 * @export
 * @interface DirectoryVendorResponse
 */
export interface DirectoryVendorResponse {
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DirectoryVendorResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof DirectoryVendorResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'role'?: DirectoryVendorResponseRoleEnum;
    /**
     * 
     * @type {Array<NationalBusinessIdentification>}
     * @memberof DirectoryVendorResponse
     */
    'nationalBusinessIdentifications'?: Array<NationalBusinessIdentification>;
    /**
     * 
     * @type {boolean}
     * @memberof DirectoryVendorResponse
     */
    'hasW9'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'secondaryEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof DirectoryVendorResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'status'?: DirectoryVendorResponseStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DirectoryVendorResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorResponse
     */
    'agentPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorResponse
     */
    'teamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {Array<PermissionDto>}
     * @memberof DirectoryVendorResponse
     */
    'flexTeamPermissionSet'?: Array<PermissionDto>;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'currentUserPermission'?: DirectoryVendorResponseCurrentUserPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorResponse
     */
    'createdBy'?: string;
    /**
     * 
     * @type {Array<DirectoryPersonResponse>}
     * @memberof DirectoryVendorResponse
     */
    'linkedPersons'?: Array<DirectoryPersonResponse>;
}

export const DirectoryVendorResponseRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryVendorResponseRoleEnum = typeof DirectoryVendorResponseRoleEnum[keyof typeof DirectoryVendorResponseRoleEnum];
export const DirectoryVendorResponseStatusEnum = {
    Unverified: 'UNVERIFIED',
    Verified: 'VERIFIED',
    Archived: 'ARCHIVED'
} as const;

export type DirectoryVendorResponseStatusEnum = typeof DirectoryVendorResponseStatusEnum[keyof typeof DirectoryVendorResponseStatusEnum];
export const DirectoryVendorResponseCurrentUserPermissionEnum = {
    None: 'NONE',
    ReadOnly: 'READ_ONLY',
    ReadWrite: 'READ_WRITE'
} as const;

export type DirectoryVendorResponseCurrentUserPermissionEnum = typeof DirectoryVendorResponseCurrentUserPermissionEnum[keyof typeof DirectoryVendorResponseCurrentUserPermissionEnum];

/**
 * 
 * @export
 * @interface DirectoryVendorUpdateRequest
 */
export interface DirectoryVendorUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalBusinessIdentification>}
     * @memberof DirectoryVendorUpdateRequest
     */
    'nationalBusinessIdentifications'?: Array<NationalBusinessIdentification>;
    /**
     * 
     * @type {AddressRequest}
     * @memberof DirectoryVendorUpdateRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorUpdateRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorUpdateRequest
     */
    'secondaryEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorUpdateRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DirectoryVendorUpdateRequest
     */
    'role'?: DirectoryVendorUpdateRequestRoleEnum;
    /**
     * 
     * @type {DirectoryPermissionsRequest}
     * @memberof DirectoryVendorUpdateRequest
     */
    'permissions'?: DirectoryPermissionsRequest;
}

export const DirectoryVendorUpdateRequestRoleEnum = {
    Client: 'CLIENT',
    Landlord: 'LANDLORD',
    Tenant: 'TENANT',
    TitleEscrow: 'TITLE_ESCROW',
    Lender: 'LENDER',
    Lawyer: 'LAWYER',
    Trustee: 'TRUSTEE',
    OtherAgent: 'OTHER_AGENT',
    Vendor: 'VENDOR',
    Referral: 'REFERRAL',
    Other: 'OTHER'
} as const;

export type DirectoryVendorUpdateRequestRoleEnum = typeof DirectoryVendorUpdateRequestRoleEnum[keyof typeof DirectoryVendorUpdateRequestRoleEnum];

/**
 * 
 * @export
 * @interface Distance
 */
export interface Distance {
    /**
     * 
     * @type {number}
     * @memberof Distance
     */
    'length': number;
    /**
     * 
     * @type {string}
     * @memberof Distance
     */
    'unit': DistanceUnitEnum;
}

export const DistanceUnitEnum = {
    Miles: 'MILES',
    Kilometers: 'KILOMETERS'
} as const;

export type DistanceUnitEnum = typeof DistanceUnitEnum[keyof typeof DistanceUnitEnum];

/**
 * 
 * @export
 * @interface Division
 */
export interface Division {
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Division
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface DivisionRequest
 */
export interface DivisionRequest {
    /**
     * 
     * @type {string}
     * @memberof DivisionRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DivisionResponse
 */
export interface DivisionResponse {
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DivisionResponse
     */
    'logoUrl'?: string;
}
/**
 * 
 * @export
 * @interface DivisionResponseWrapper
 */
export interface DivisionResponseWrapper {
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof DivisionResponseWrapper
     */
    'divisions'?: Array<DivisionResponse>;
}
/**
 * 
 * @export
 * @interface DocumentFieldValueDto
 */
export interface DocumentFieldValueDto {
    /**
     * 
     * @type {number}
     * @memberof DocumentFieldValueDto
     */
    'index': number;
    /**
     * 
     * @type {string}
     * @memberof DocumentFieldValueDto
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface DocumentImagePage
 */
export interface DocumentImagePage {
    /**
     * 
     * @type {number}
     * @memberof DocumentImagePage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {any}
     * @memberof DocumentImagePage
     */
    'viewport'?: any;
    /**
     * 
     * @type {Array<any>}
     * @memberof DocumentImagePage
     */
    'annotations'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof DocumentImagePage
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface DocumentImages
 */
export interface DocumentImages {
    /**
     * 
     * @type {Array<DocumentImagePage>}
     * @memberof DocumentImages
     */
    'pages'?: Array<DocumentImagePage>;
    /**
     * 
     * @type {number}
     * @memberof DocumentImages
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface DownLineAgentResponse
 */
export interface DownLineAgentResponse {
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownLineAgentResponse
     */
    'status'?: DownLineAgentResponseStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DownLineAgentResponse
     */
    'sizeOfNetwork'?: number;
}

export const DownLineAgentResponseStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type DownLineAgentResponseStatusEnum = typeof DownLineAgentResponseStatusEnum[keyof typeof DownLineAgentResponseStatusEnum];

/**
 * 
 * @export
 * @interface DownLineAgentsResponse
 */
export interface DownLineAgentsResponse {
    /**
     * 
     * @type {number}
     * @memberof DownLineAgentsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DownLineAgentsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DownLineAgentsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DownLineAgentsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<DownLineAgentResponse>}
     * @memberof DownLineAgentsResponse
     */
    'downLineAgents'?: Array<DownLineAgentResponse>;
}
/**
 * 
 * @export
 * @interface EditBankAccountRequest
 */
export interface EditBankAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'bankName'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof EditBankAccountRequest
     */
    'addressRequest'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'bankRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'wireRoutingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'accountName'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'ftniSettlementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'msdxBatch'?: EditBankAccountRequestMsdxBatchEnum;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'msdxGlNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditBankAccountRequest
     */
    'bankPartnerAccountId'?: string;
}

export const EditBankAccountRequestMsdxBatchEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type EditBankAccountRequestMsdxBatchEnum = typeof EditBankAccountRequestMsdxBatchEnum[keyof typeof EditBankAccountRequestMsdxBatchEnum];

/**
 * 
 * @export
 * @interface EditCompanyRequest
 */
export interface EditCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCompanyRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyRequest
     */
    'ein'?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCompanyRequest
     */
    'administrativeAreaId'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof EditCompanyRequest
     */
    'address'?: AddressRequest;
}
/**
 * 
 * @export
 * @interface EliteEquityAwardPlanResponse
 */
export interface EliteEquityAwardPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EliteEquityAwardPlanResponse
     */
    'country'?: EliteEquityAwardPlanResponseCountryEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postCapTransactionFeesTotal'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minGCI'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minTransactionsClosed'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minTransactionPrice'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof EliteEquityAwardPlanResponse
     */
    'minParticipationLevel'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'award'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postEliteLeaseTransactionFee'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EliteEquityAwardPlanResponse
     */
    'postEliteSalesTransactionFee'?: MoneyValue;
}

export const EliteEquityAwardPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EliteEquityAwardPlanResponseCountryEnum = typeof EliteEquityAwardPlanResponseCountryEnum[keyof typeof EliteEquityAwardPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface ElitePlanParameters
 */
export interface ElitePlanParameters {
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'requiredQualifyingTransaction'?: number;
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'requiredPostCapFees'?: number;
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'requiredGCI'?: number;
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'postCapTransactionFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'minQualifyingPropertyPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof ElitePlanParameters
     */
    'eliteAwardAmount': number;
}
/**
 * 
 * @export
 * @interface EmailAddress
 */
export interface EmailAddress {
    /**
     * 
     * @type {string}
     * @memberof EmailAddress
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface EntityStatusTimelineItemResponseConnectionRequestStatus
 */
export interface EntityStatusTimelineItemResponseConnectionRequestStatus {
    /**
     * 
     * @type {string}
     * @memberof EntityStatusTimelineItemResponseConnectionRequestStatus
     */
    'status'?: EntityStatusTimelineItemResponseConnectionRequestStatusStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EntityStatusTimelineItemResponseConnectionRequestStatus
     */
    'completedAt'?: number;
}

export const EntityStatusTimelineItemResponseConnectionRequestStatusStatusEnum = {
    InviteStarted: 'INVITE_STARTED',
    Emailed: 'EMAILED',
    LinkClicked: 'LINK_CLICKED',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    AccountApproved: 'ACCOUNT_APPROVED',
    SignAssistantAmendment: 'SIGN_ASSISTANT_AMENDMENT',
    Active: 'ACTIVE',
    Rejected: 'REJECTED',
    EmailResent: 'EMAIL_RESENT',
    Expired: 'EXPIRED'
} as const;

export type EntityStatusTimelineItemResponseConnectionRequestStatusStatusEnum = typeof EntityStatusTimelineItemResponseConnectionRequestStatusStatusEnum[keyof typeof EntityStatusTimelineItemResponseConnectionRequestStatusStatusEnum];

/**
 * 
 * @export
 * @interface EquityAwardPlanResponse
 */
export interface EquityAwardPlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardPlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityAwardPlanResponse
     */
    'country'?: EquityAwardPlanResponseCountryEnum;
    /**
     * 
     * @type {Array<EquityAwardTierResponse>}
     * @memberof EquityAwardPlanResponse
     */
    'tiers'?: Array<EquityAwardTierResponse>;
}

export const EquityAwardPlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EquityAwardPlanResponseCountryEnum = typeof EquityAwardPlanResponseCountryEnum[keyof typeof EquityAwardPlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface EquityAwardTierResponse
 */
export interface EquityAwardTierResponse {
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'minNumberOfAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'maxNumberOfAgents'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'numberOfSharesForCapping'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityAwardTierResponse
     */
    'numberOfSharesForAttracting'?: number;
}
/**
 * 
 * @export
 * @interface EquityInfo
 */
export interface EquityInfo {
    /**
     * 
     * @type {boolean}
     * @memberof EquityInfo
     */
    'enrollInStockPurchasePlan': boolean;
    /**
     * 
     * @type {string}
     * @memberof EquityInfo
     * @deprecated
     */
    'projectedPerformance': EquityInfoProjectedPerformanceEnum;
    /**
     * 
     * @type {number}
     * @memberof EquityInfo
     */
    'projectedPerformanceCustom'?: number;
}

export const EquityInfoProjectedPerformanceEnum = {
    Low: 'LOW',
    Average: 'AVERAGE',
    High: 'HIGH'
} as const;

export type EquityInfoProjectedPerformanceEnum = typeof EquityInfoProjectedPerformanceEnum[keyof typeof EquityInfoProjectedPerformanceEnum];

/**
 * 
 * @export
 * @interface EquityPlanParameters
 */
export interface EquityPlanParameters {
    /**
     * 
     * @type {number}
     * @memberof EquityPlanParameters
     */
    'postCapBonusPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPlanParameters
     */
    'postCapMaxAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPlanParameters
     */
    'postCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPlanParameters
     */
    'preCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPlanParameters
     */
    'preCapBonusPercent'?: number;
}
/**
 * 
 * @export
 * @interface EquityPurchasePlanResponse
 */
export interface EquityPurchasePlanResponse {
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquityPurchasePlanResponse
     */
    'country'?: EquityPurchasePlanResponseCountryEnum;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapNetCommissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapBonusSharePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapBonusSharePercent'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'preCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamMemberPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamLeaderPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'groupTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'domesticTeamPreCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'postCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'normalTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamMemberPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'platinumTeamLeaderPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'groupTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'domesticTeamPostCapMax'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof EquityPurchasePlanResponse
     */
    'minPurchase'?: MoneyValue;
}

export const EquityPurchasePlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type EquityPurchasePlanResponseCountryEnum = typeof EquityPurchasePlanResponseCountryEnum[keyof typeof EquityPurchasePlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface ExperimentGroupResponse
 */
export interface ExperimentGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof ExperimentGroupResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExperimentGroupResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperimentGroupResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperimentGroupResponse
     */
    'groupIndex'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExperimentGroupResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentGroupResponse
     */
    'payload'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExperimentGroupResponse
     */
    'overrideIds'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ExperimentGroupResponse
     */
    'weight'?: number;
}
/**
 * 
 * @export
 * @interface ExperimentResponse
 */
export interface ExperimentResponse {
    /**
     * 
     * @type {string}
     * @memberof ExperimentResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExperimentResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperimentResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExperimentResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentResponse
     */
    'state': ExperimentResponseStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ExperimentResponse
     */
    'salt': string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentResponse
     */
    'filterScript'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExperimentResponse
     */
    'rolloutStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExperimentResponse
     */
    'rolloutEnd'?: number;
    /**
     * 
     * @type {Array<ExperimentGroupResponse>}
     * @memberof ExperimentResponse
     */
    'groups'?: Array<ExperimentGroupResponse>;
}

export const ExperimentResponseStateEnum = {
    PreRollout: 'PRE_ROLLOUT',
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type ExperimentResponseStateEnum = typeof ExperimentResponseStateEnum[keyof typeof ExperimentResponseStateEnum];

/**
 * 
 * @export
 * @interface ExternalConnectionRequestBasicInfoResponse
 */
export interface ExternalConnectionRequestBasicInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'connectionIdentityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalConnectionRequestBasicInfoResponse
     */
    'expired'?: boolean;
}
/**
 * 
 * @export
 * @interface FailedMessage
 */
export interface FailedMessage {
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'clientType': FailedMessageClientTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'status': FailedMessageStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FailedMessage
     */
    'reprocessingAttempts'?: number;
    /**
     * 
     * @type {KafkaMessageLocation}
     * @memberof FailedMessage
     */
    'kafkaMessageLocation': KafkaMessageLocation;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'eventId': string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'key'?: string;
    /**
     * 
     * @type {WrapperBaseMessage}
     * @memberof FailedMessage
     */
    'payload'?: WrapperBaseMessage;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'unprocessed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FailedMessage
     */
    'processed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FailedMessage
     */
    'shortenedId'?: string;
}

export const FailedMessageClientTypeEnum = {
    Producer: 'PRODUCER',
    Consumer: 'CONSUMER'
} as const;

export type FailedMessageClientTypeEnum = typeof FailedMessageClientTypeEnum[keyof typeof FailedMessageClientTypeEnum];
export const FailedMessageStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type FailedMessageStatusEnum = typeof FailedMessageStatusEnum[keyof typeof FailedMessageStatusEnum];

/**
 * 
 * @export
 * @interface FailedMessageRetryRequest
 */
export interface FailedMessageRetryRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageRetryRequest
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface FailedMessageUpdateStatusRequest
 */
export interface FailedMessageUpdateStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessageUpdateStatusRequest
     */
    'status'?: FailedMessageUpdateStatusRequestStatusEnum;
}

export const FailedMessageUpdateStatusRequestStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type FailedMessageUpdateStatusRequestStatusEnum = typeof FailedMessageUpdateStatusRequestStatusEnum[keyof typeof FailedMessageUpdateStatusRequestStatusEnum];

/**
 * 
 * @export
 * @interface FailedMessagesCriteriaRequest
 */
export interface FailedMessagesCriteriaRequest {
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof FailedMessagesCriteriaRequest
     */
    'createdAtTo'?: string;
}
/**
 * 
 * @export
 * @interface FeePaymentRequest
 */
export interface FeePaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof FeePaymentRequest
     */
    'applicationId': string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof FeePaymentRequest
     */
    'fees': MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof FeePaymentRequest
     */
    'paymentMethod': string;
    /**
     * 
     * @type {string}
     * @memberof FeePaymentRequest
     */
    'promoCode'?: string;
}
/**
 * 
 * @export
 * @interface FeeSplit
 */
export interface FeeSplit {
    /**
     * 
     * @type {string}
     * @memberof FeeSplit
     */
    'feeType': FeeSplitFeeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FeeSplit
     */
    'percent': number;
}

export const FeeSplitFeeTypeEnum = {
    BrokerageFee: 'BROKERAGE_FEE',
    PersonalDealFee: 'PERSONAL_DEAL_FEE',
    RealSplit: 'REAL_SPLIT',
    TransactionFee: 'TRANSACTION_FEE',
    TransactionCoordinatorFee: 'TRANSACTION_COORDINATOR_FEE',
    MlsFee: 'MLS_FEE',
    BeopFee: 'BEOP_FEE',
    BoardFee: 'BOARD_FEE'
} as const;

export type FeeSplitFeeTypeEnum = typeof FeeSplitFeeTypeEnum[keyof typeof FeeSplitFeeTypeEnum];

/**
 * 
 * @export
 * @interface FixEmailRequest
 */
export interface FixEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof FixEmailRequest
     */
    'fromEmail': string;
    /**
     * 
     * @type {string}
     * @memberof FixEmailRequest
     */
    'toEmail': string;
}
/**
 * 
 * @export
 * @interface FlexTeamConfigDto
 */
export interface FlexTeamConfigDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'commissionPlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'country'?: FlexTeamConfigDtoCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'teamType'?: FlexTeamConfigDtoTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamConfigDto
     */
    'minTeammates'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamConfigDto
     */
    'maxTeammates'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamConfigDto
     */
    'minLeaders'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamConfigDto
     */
    'maxLeaders'?: number;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'paymentDetailsVisibility'?: FlexTeamConfigDtoPaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FlexTeamConfigDto
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {LeaderSplitConfigDto}
     * @memberof FlexTeamConfigDto
     */
    'leaderSplitConfig'?: LeaderSplitConfigDto;
    /**
     * 
     * @type {RealCapConfigDto}
     * @memberof FlexTeamConfigDto
     */
    'realCapConfig'?: RealCapConfigDto;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'cdaApprover'?: FlexTeamConfigDtoCdaApproverEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeamConfigDto
     */
    'leaderOverridableProperties'?: Array<FlexTeamConfigDtoLeaderOverridablePropertiesEnum>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FlexTeamConfigDto
     */
    'allowedMemberCaps'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'status'?: FlexTeamConfigDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamConfigDto
     */
    'permittedTransactionEditors'?: FlexTeamConfigDtoPermittedTransactionEditorsEnum;
}

export const FlexTeamConfigDtoCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type FlexTeamConfigDtoCountryEnum = typeof FlexTeamConfigDtoCountryEnum[keyof typeof FlexTeamConfigDtoCountryEnum];
export const FlexTeamConfigDtoTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type FlexTeamConfigDtoTeamTypeEnum = typeof FlexTeamConfigDtoTeamTypeEnum[keyof typeof FlexTeamConfigDtoTeamTypeEnum];
export const FlexTeamConfigDtoPaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type FlexTeamConfigDtoPaymentDetailsVisibilityEnum = typeof FlexTeamConfigDtoPaymentDetailsVisibilityEnum[keyof typeof FlexTeamConfigDtoPaymentDetailsVisibilityEnum];
export const FlexTeamConfigDtoCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamConfigDtoCdaApproverEnum = typeof FlexTeamConfigDtoCdaApproverEnum[keyof typeof FlexTeamConfigDtoCdaApproverEnum];
export const FlexTeamConfigDtoLeaderOverridablePropertiesEnum = {
    LeaderSplitEnforcement: 'LEADER_SPLIT_ENFORCEMENT',
    PaymentDetailsVisibility: 'PAYMENT_DETAILS_VISIBILITY',
    PaidAtClosing: 'PAID_AT_CLOSING',
    CdaApprovalRoles: 'CDA_APPROVAL_ROLES',
    PermittedTransactionEditorRoles: 'PERMITTED_TRANSACTION_EDITOR_ROLES'
} as const;

export type FlexTeamConfigDtoLeaderOverridablePropertiesEnum = typeof FlexTeamConfigDtoLeaderOverridablePropertiesEnum[keyof typeof FlexTeamConfigDtoLeaderOverridablePropertiesEnum];
export const FlexTeamConfigDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type FlexTeamConfigDtoStatusEnum = typeof FlexTeamConfigDtoStatusEnum[keyof typeof FlexTeamConfigDtoStatusEnum];
export const FlexTeamConfigDtoPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamConfigDtoPermittedTransactionEditorsEnum = typeof FlexTeamConfigDtoPermittedTransactionEditorsEnum[keyof typeof FlexTeamConfigDtoPermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface FlexTeamDto
 */
export interface FlexTeamDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {FlexTeamConfigDto}
     * @memberof FlexTeamDto
     */
    'config'?: FlexTeamConfigDto;
    /**
     * 
     * @type {Array<FlexTeamLeaderDto>}
     * @memberof FlexTeamDto
     */
    'leaders'?: Array<FlexTeamLeaderDto>;
    /**
     * 
     * @type {Array<FlexTeammateDto>}
     * @memberof FlexTeamDto
     */
    'teammates'?: Array<FlexTeammateDto>;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'status'?: FlexTeamDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FlexTeamDto
     */
    'enforceLeaderSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'paymentDetailsVisibility'?: FlexTeamDtoPaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof FlexTeamDto
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'cdaApprover'?: FlexTeamDtoCdaApproverEnum;
    /**
     * 
     * @type {TeamCap}
     * @memberof FlexTeamDto
     */
    'teamCap'?: TeamCap;
    /**
     * 
     * @type {Array<TeamInvitationSimplifiedDto>}
     * @memberof FlexTeamDto
     */
    'teamInvitations'?: Array<TeamInvitationSimplifiedDto>;
    /**
     * 
     * @type {Array<GenericTeamInvitationApplicationResponse>}
     * @memberof FlexTeamDto
     */
    'pendingGenericTeamApplications'?: Array<GenericTeamInvitationApplicationResponse>;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'permittedTransactionEditors'?: FlexTeamDtoPermittedTransactionEditorsEnum;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'computedCdaApprover'?: FlexTeamDtoComputedCdaApproverEnum;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamDto
     */
    'computedPermittedTransactionEditors'?: FlexTeamDtoComputedPermittedTransactionEditorsEnum;
}

export const FlexTeamDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type FlexTeamDtoStatusEnum = typeof FlexTeamDtoStatusEnum[keyof typeof FlexTeamDtoStatusEnum];
export const FlexTeamDtoPaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type FlexTeamDtoPaymentDetailsVisibilityEnum = typeof FlexTeamDtoPaymentDetailsVisibilityEnum[keyof typeof FlexTeamDtoPaymentDetailsVisibilityEnum];
export const FlexTeamDtoCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamDtoCdaApproverEnum = typeof FlexTeamDtoCdaApproverEnum[keyof typeof FlexTeamDtoCdaApproverEnum];
export const FlexTeamDtoPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamDtoPermittedTransactionEditorsEnum = typeof FlexTeamDtoPermittedTransactionEditorsEnum[keyof typeof FlexTeamDtoPermittedTransactionEditorsEnum];
export const FlexTeamDtoComputedCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamDtoComputedCdaApproverEnum = typeof FlexTeamDtoComputedCdaApproverEnum[keyof typeof FlexTeamDtoComputedCdaApproverEnum];
export const FlexTeamDtoComputedPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type FlexTeamDtoComputedPermittedTransactionEditorsEnum = typeof FlexTeamDtoComputedPermittedTransactionEditorsEnum[keyof typeof FlexTeamDtoComputedPermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface FlexTeamLeaderDto
 */
export interface FlexTeamLeaderDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeamLeaderDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamLeaderDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeamLeaderDto
     */
    'roles'?: Array<FlexTeamLeaderDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamLeaderDto
     */
    'realCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamLeaderDto
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof FlexTeamLeaderDto
     */
    'feeSplits'?: Array<FeeSplit>;
}

export const FlexTeamLeaderDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type FlexTeamLeaderDtoRolesEnum = typeof FlexTeamLeaderDtoRolesEnum[keyof typeof FlexTeamLeaderDtoRolesEnum];

/**
 * 
 * @export
 * @interface FlexTeamMemberDto
 */
export interface FlexTeamMemberDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeamMemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeamMemberDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeamMemberDto
     */
    'roles'?: Array<FlexTeamMemberDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof FlexTeamMemberDto
     */
    'realCap'?: number;
}

export const FlexTeamMemberDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type FlexTeamMemberDtoRolesEnum = typeof FlexTeamMemberDtoRolesEnum[keyof typeof FlexTeamMemberDtoRolesEnum];

/**
 * 
 * @export
 * @interface FlexTeammateDto
 */
export interface FlexTeammateDto {
    /**
     * 
     * @type {string}
     * @memberof FlexTeammateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FlexTeammateDto
     */
    'agentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FlexTeammateDto
     */
    'roles'?: Array<FlexTeammateDtoRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof FlexTeammateDto
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof FlexTeammateDto
     */
    'leaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof FlexTeammateDto
     */
    'computedLeaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof FlexTeammateDto
     */
    'feeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof FlexTeammateDto
     */
    'computedFeeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof FlexTeammateDto
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof FlexTeammateDto
     */
    'paysLeaderSplit'?: boolean;
}

export const FlexTeammateDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type FlexTeammateDtoRolesEnum = typeof FlexTeammateDtoRolesEnum[keyof typeof FlexTeammateDtoRolesEnum];

/**
 * 
 * @export
 * @interface FrontLineAgentInfo
 */
export interface FrontLineAgentInfo {
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontLineAgentInfo
     */
    'status'?: FrontLineAgentInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FrontLineAgentInfo
     */
    'sizeOfNetwork'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontLineAgentInfo
     */
    'sponsorSplit'?: number;
}

export const FrontLineAgentInfoStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type FrontLineAgentInfoStatusEnum = typeof FrontLineAgentInfoStatusEnum[keyof typeof FrontLineAgentInfoStatusEnum];

/**
 * 
 * @export
 * @interface FrontLineAgentInfoResponse
 */
export interface FrontLineAgentInfoResponse {
    /**
     * 
     * @type {Array<FrontLineAgentInfo>}
     * @memberof FrontLineAgentInfoResponse
     */
    'frontLineAgentInfos'?: Array<FrontLineAgentInfo>;
}
/**
 * 
 * @export
 * @interface FutureAgentPlan
 */
export interface FutureAgentPlan {
    /**
     * 
     * @type {string}
     * @memberof FutureAgentPlan
     */
    'agentPlansConfig': FutureAgentPlanAgentPlansConfigEnum;
    /**
     * 
     * @type {string}
     * @memberof FutureAgentPlan
     */
    'agentPlansConfigStartDate': string;
}

export const FutureAgentPlanAgentPlansConfigEnum = {
    UsResidentialV1: 'US_RESIDENTIAL_V1',
    UsResidentialV2: 'US_RESIDENTIAL_V2',
    UsCommercialV1: 'US_COMMERCIAL_V1',
    CaResidentialV1: 'CA_RESIDENTIAL_V1',
    CaResidentialScottBensonV1: 'CA_RESIDENTIAL_SCOTT_BENSON_V1',
    UsBroker4K: 'US_BROKER_4K',
    UsBroker6K: 'US_BROKER_6K',
    UsBroker12K: 'US_BROKER_12K',
    CaBroker4K: 'CA_BROKER_4K',
    CaBroker6K: 'CA_BROKER_6K',
    CaBroker12K: 'CA_BROKER_12K'
} as const;

export type FutureAgentPlanAgentPlansConfigEnum = typeof FutureAgentPlanAgentPlansConfigEnum[keyof typeof FutureAgentPlanAgentPlansConfigEnum];

/**
 * 
 * @export
 * @interface GenericSearchResponseAdministrativeAreaResponse
 */
export interface GenericSearchResponseAdministrativeAreaResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAdministrativeAreaResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAdministrativeAreaResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAdministrativeAreaResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAdministrativeAreaResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof GenericSearchResponseAdministrativeAreaResponse
     */
    'results'?: Array<AdministrativeAreaResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgentInfo
 */
export interface GenericSearchResponseAgentInfo {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentInfo
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentInfo
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgentInfo
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentInfo
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentInfo>}
     * @memberof GenericSearchResponseAgentInfo
     */
    'results'?: Array<AgentInfo>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgentPublicInfoWithAdminAreas
 */
export interface GenericSearchResponseAgentPublicInfoWithAdminAreas {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicInfoWithAdminAreas
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicInfoWithAdminAreas
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgentPublicInfoWithAdminAreas
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicInfoWithAdminAreas
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentPublicInfoWithAdminAreas>}
     * @memberof GenericSearchResponseAgentPublicInfoWithAdminAreas
     */
    'results'?: Array<AgentPublicInfoWithAdminAreas>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgentPublicResponse
 */
export interface GenericSearchResponseAgentPublicResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgentPublicResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentPublicResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentPublicResponse>}
     * @memberof GenericSearchResponseAgentPublicResponse
     */
    'results'?: Array<AgentPublicResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgentResponse
 */
export interface GenericSearchResponseAgentResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgentResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgentResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgentResponse>}
     * @memberof GenericSearchResponseAgentResponse
     */
    'results'?: Array<AgentResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgreementDefinitionResponse
 */
export interface GenericSearchResponseAgreementDefinitionResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementDefinitionResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementDefinitionResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgreementDefinitionResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementDefinitionResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgreementDefinitionResponse>}
     * @memberof GenericSearchResponseAgreementDefinitionResponse
     */
    'results'?: Array<AgreementDefinitionResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseAgreementPreviewResponse
 */
export interface GenericSearchResponseAgreementPreviewResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementPreviewResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementPreviewResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseAgreementPreviewResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseAgreementPreviewResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<AgreementPreviewResponse>}
     * @memberof GenericSearchResponseAgreementPreviewResponse
     */
    'results'?: Array<AgreementPreviewResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseBankAccountResponse
 */
export interface GenericSearchResponseBankAccountResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBankAccountResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBankAccountResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseBankAccountResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBankAccountResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<BankAccountResponse>}
     * @memberof GenericSearchResponseBankAccountResponse
     */
    'results'?: Array<BankAccountResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseBoardResponse
 */
export interface GenericSearchResponseBoardResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBoardResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBoardResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseBoardResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseBoardResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<BoardResponse>}
     * @memberof GenericSearchResponseBoardResponse
     */
    'results'?: Array<BoardResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseCompanyResponse
 */
export interface GenericSearchResponseCompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseCompanyResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseCompanyResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseCompanyResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseCompanyResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<CompanyResponse>}
     * @memberof GenericSearchResponseCompanyResponse
     */
    'results'?: Array<CompanyResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseFailedMessage
 */
export interface GenericSearchResponseFailedMessage {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFailedMessage
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFailedMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenericSearchResponseFailedMessage
     */
    'sortDirection'?: GenericSearchResponseFailedMessageSortDirectionEnum;
    /**
     * 
     * @type {Array<FailedMessage>}
     * @memberof GenericSearchResponseFailedMessage
     */
    'results'?: Array<FailedMessage>;
}

export const GenericSearchResponseFailedMessageSortDirectionEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type GenericSearchResponseFailedMessageSortDirectionEnum = typeof GenericSearchResponseFailedMessageSortDirectionEnum[keyof typeof GenericSearchResponseFailedMessageSortDirectionEnum];

/**
 * 
 * @export
 * @interface GenericSearchResponseFlexTeamConfigDto
 */
export interface GenericSearchResponseFlexTeamConfigDto {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFlexTeamConfigDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFlexTeamConfigDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseFlexTeamConfigDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseFlexTeamConfigDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<FlexTeamConfigDto>}
     * @memberof GenericSearchResponseFlexTeamConfigDto
     */
    'results'?: Array<FlexTeamConfigDto>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseMlsPublicResponse
 */
export interface GenericSearchResponseMlsPublicResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsPublicResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsPublicResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseMlsPublicResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsPublicResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<MlsPublicResponse>}
     * @memberof GenericSearchResponseMlsPublicResponse
     */
    'results'?: Array<MlsPublicResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseMlsResponse
 */
export interface GenericSearchResponseMlsResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseMlsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseMlsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<MlsResponse>}
     * @memberof GenericSearchResponseMlsResponse
     */
    'results'?: Array<MlsResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseOfficeSearchResponse
 */
export interface GenericSearchResponseOfficeSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseOfficeSearchResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseOfficeSearchResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseOfficeSearchResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseOfficeSearchResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<OfficeSearchResponse>}
     * @memberof GenericSearchResponseOfficeSearchResponse
     */
    'results'?: Array<OfficeSearchResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponsePaymentSettingsResponse
 */
export interface GenericSearchResponsePaymentSettingsResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponsePaymentSettingsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponsePaymentSettingsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponsePaymentSettingsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponsePaymentSettingsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<PaymentSettingsResponse>}
     * @memberof GenericSearchResponsePaymentSettingsResponse
     */
    'results'?: Array<PaymentSettingsResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseSearchFlexTeamDto
 */
export interface GenericSearchResponseSearchFlexTeamDto {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseSearchFlexTeamDto
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseSearchFlexTeamDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseSearchFlexTeamDto
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseSearchFlexTeamDto
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<SearchFlexTeamDto>}
     * @memberof GenericSearchResponseSearchFlexTeamDto
     */
    'results'?: Array<SearchFlexTeamDto>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseTeamResponse
 */
export interface GenericSearchResponseTeamResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTeamResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTeamResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseTeamResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTeamResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<TeamResponse>}
     * @memberof GenericSearchResponseTeamResponse
     */
    'results'?: Array<TeamResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseTransactionCoordinatorConnectionResponse
 */
export interface GenericSearchResponseTransactionCoordinatorConnectionResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorConnectionResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorConnectionResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseTransactionCoordinatorConnectionResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorConnectionResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<TransactionCoordinatorConnectionResponse>}
     * @memberof GenericSearchResponseTransactionCoordinatorConnectionResponse
     */
    'results'?: Array<TransactionCoordinatorConnectionResponse>;
}
/**
 * 
 * @export
 * @interface GenericSearchResponseTransactionCoordinatorSearchResponse
 */
export interface GenericSearchResponseTransactionCoordinatorSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorSearchResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorSearchResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericSearchResponseTransactionCoordinatorSearchResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericSearchResponseTransactionCoordinatorSearchResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<TransactionCoordinatorSearchResponse>}
     * @memberof GenericSearchResponseTransactionCoordinatorSearchResponse
     */
    'results'?: Array<TransactionCoordinatorSearchResponse>;
}
/**
 * 
 * @export
 * @interface GenericTeamInvitationApplicationResponse
 */
export interface GenericTeamInvitationApplicationResponse {
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'emailAddress': string;
    /**
     * 
     * @type {number}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'capLevel': number;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'status': GenericTeamInvitationApplicationResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationApplicationResponse
     */
    'agentId': string;
}

export const GenericTeamInvitationApplicationResponseStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type GenericTeamInvitationApplicationResponseStatusEnum = typeof GenericTeamInvitationApplicationResponseStatusEnum[keyof typeof GenericTeamInvitationApplicationResponseStatusEnum];

/**
 * 
 * @export
 * @interface GenericTeamInvitationResponse
 */
export interface GenericTeamInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'invitationCreatedByAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'teamId': string;
    /**
     * 
     * @type {number}
     * @memberof GenericTeamInvitationResponse
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericTeamInvitationResponse
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GenericTeamInvitationResponse
     */
    'expirationTime': number;
    /**
     * 
     * @type {string}
     * @memberof GenericTeamInvitationResponse
     */
    'couponCode'?: string;
}
/**
 * 
 * @export
 * @interface GetAgentIdsByShareworksIdsRequest
 */
export interface GetAgentIdsByShareworksIdsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof GetAgentIdsByShareworksIdsRequest
     */
    'shareworksIds': Array<number>;
}
/**
 * 
 * @export
 * @interface GetCountriesResponse
 */
export interface GetCountriesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCountriesResponse
     */
    'countries'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetDownLineAgentsRequest
 */
export interface GetDownLineAgentsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDownLineAgentsRequest
     */
    'agentIds': Array<string>;
}
/**
 * 
 * @export
 * @interface GroupMemberResponse
 */
export interface GroupMemberResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupMemberResponse
     */
    'groupId'?: string;
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof GroupMemberResponse
     */
    'user'?: UserPreviewResponse;
    /**
     * 
     * @type {AgentBasicInfoResponse}
     * @memberof GroupMemberResponse
     */
    'userInfo'?: AgentBasicInfoResponse;
    /**
     * 
     * @type {string}
     * @memberof GroupMemberResponse
     */
    'role'?: GroupMemberResponseRoleEnum;
}

export const GroupMemberResponseRoleEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type GroupMemberResponseRoleEnum = typeof GroupMemberResponseRoleEnum[keyof typeof GroupMemberResponseRoleEnum];

/**
 * 
 * @export
 * @interface GroupResponse
 */
export interface GroupResponse {
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof GroupResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'officeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'officeName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResponse
     */
    'groupName'?: string;
    /**
     * 
     * @type {Array<GroupMemberResponse>}
     * @memberof GroupResponse
     */
    'groupMembers'?: Array<GroupMemberResponse>;
}
/**
 * 
 * @export
 * @interface GroupResponseLite
 */
export interface GroupResponseLite {
    /**
     * 
     * @type {string}
     * @memberof GroupResponseLite
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GroupResponseLite
     */
    'groupName'?: string;
}
/**
 * 
 * @export
 * @interface HourRange
 */
export interface HourRange {
    /**
     * 
     * @type {string}
     * @memberof HourRange
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof HourRange
     */
    'endTime': string;
}
/**
 * 
 * @export
 * @interface ICAAgreement
 */
export interface ICAAgreement {
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'agreementId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'signeeToken'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICAAgreement
     */
    'signedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof ICAAgreement
     */
    'agreementDefinitionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ICAAgreement
     */
    'agreementVersion'?: number;
}
/**
 * 
 * @export
 * @interface ICASignRequest
 */
export interface ICASignRequest {
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'customAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     * @deprecated
     */
    'referringAgentName'?: string;
    /**
     * 
     * @type {Array<SponsoringAgentDto>}
     * @memberof ICASignRequest
     */
    'sponsoringAgents'?: Array<SponsoringAgentDto>;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'emergencyContactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'emergencyContactPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'emergencyContactEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'emergencyContactRelationship'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'entityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICASignRequest
     */
    'initials'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ICASignRequest
     */
    'optInStockPlan'?: boolean;
}
/**
 * 
 * @export
 * @interface IdResponse
 */
export interface IdResponse {
    /**
     * 
     * @type {string}
     * @memberof IdResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface InvalidProperty
 */
export interface InvalidProperty {
    /**
     * 
     * @type {string}
     * @memberof InvalidProperty
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidProperty
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface JobExecutionResponse
 */
export interface JobExecutionResponse {
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    'jobExecutionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    'jobExecutionStatus'?: JobExecutionResponseJobExecutionStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    'startedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof JobExecutionResponse
     */
    'completedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof JobExecutionResponse
     */
    'errorLog'?: string;
}

export const JobExecutionResponseJobExecutionStatusEnum = {
    Created: 'CREATED',
    Running: 'RUNNING',
    Success: 'SUCCESS',
    Fail: 'FAIL'
} as const;

export type JobExecutionResponseJobExecutionStatusEnum = typeof JobExecutionResponseJobExecutionStatusEnum[keyof typeof JobExecutionResponseJobExecutionStatusEnum];

/**
 * 
 * @export
 * @interface JobExecutionsResponse
 */
export interface JobExecutionsResponse {
    /**
     * 
     * @type {Array<JobExecutionResponse>}
     * @memberof JobExecutionsResponse
     */
    'jobExecutionResponses'?: Array<JobExecutionResponse>;
}
/**
 * 
 * @export
 * @interface KafkaMessageLocation
 */
export interface KafkaMessageLocation {
    /**
     * 
     * @type {string}
     * @memberof KafkaMessageLocation
     */
    'topic': string;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'partition'?: number;
    /**
     * 
     * @type {number}
     * @memberof KafkaMessageLocation
     */
    'offset'?: number;
}
/**
 * 
 * @export
 * @interface KeymakerIdsRequest
 */
export interface KeymakerIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof KeymakerIdsRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface LeaderSplitConfigDto
 */
export interface LeaderSplitConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof LeaderSplitConfigDto
     */
    'enforceSplits'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeaderSplitConfigDto
     */
    'minSplitPercent'?: number;
}
/**
 * 
 * @export
 * @interface LearnworldsSsoRequest
 */
export interface LearnworldsSsoRequest {
    /**
     * 
     * @type {string}
     * @memberof LearnworldsSsoRequest
     */
    'redirectUrl': string;
}
/**
 * 
 * @export
 * @interface LearnworldsSsoResponse
 */
export interface LearnworldsSsoResponse {
    /**
     * 
     * @type {string}
     * @memberof LearnworldsSsoResponse
     */
    'redirectUrl'?: string;
}
/**
 * 
 * @export
 * @interface LetterOfIntentSignRequest
 */
export interface LetterOfIntentSignRequest {
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    'residentIn': string;
    /**
     * 
     * @type {number}
     * @memberof LetterOfIntentSignRequest
     */
    'numDays': number;
    /**
     * 
     * @type {string}
     * @memberof LetterOfIntentSignRequest
     */
    'signature': string;
}
/**
 * 
 * @export
 * @interface LicenseBoardMLSRequest
 */
export interface LicenseBoardMLSRequest {
    /**
     * 
     * @type {LicenseRequest}
     * @memberof LicenseBoardMLSRequest
     */
    'licenseRequest'?: LicenseRequest;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSRequest
     */
    'boards'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSRequest
     */
    'mlses'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LicenseBoardMLSResponse
 */
export interface LicenseBoardMLSResponse {
    /**
     * 
     * @type {LicenseResponse}
     * @memberof LicenseBoardMLSResponse
     */
    'licenseResponse'?: LicenseResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSResponse
     */
    'boards'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LicenseBoardMLSResponse
     */
    'mlses'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LicenseRequest
 */
export interface LicenseRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    'licenseType'?: LicenseRequestLicenseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    'number': string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRequest
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    'expirationDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseRequest
     */
    'knownComplaints': boolean;
    /**
     * 
     * @type {AdministrativeAreaRequest}
     * @memberof LicenseRequest
     */
    'administrativeAreaRequest': AdministrativeAreaRequest;
    /**
     * 
     * @type {string}
     * @memberof LicenseRequest
     */
    'licenseImagePath'?: string;
}

export const LicenseRequestLicenseTypeEnum = {
    Broker: 'BROKER',
    Agent: 'AGENT',
    LoanOfficer: 'LOAN_OFFICER'
} as const;

export type LicenseRequestLicenseTypeEnum = typeof LicenseRequestLicenseTypeEnum[keyof typeof LicenseRequestLicenseTypeEnum];

/**
 * 
 * @export
 * @interface LicenseResponse
 */
export interface LicenseResponse {
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseResponse
     */
    'knownComplaints'?: boolean;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof LicenseResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'licenseImagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     */
    'licenseImageApprovalStatus'?: LicenseResponseLicenseImageApprovalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LicenseResponse
     * @deprecated
     */
    'signatureImagePath'?: string;
}

export const LicenseResponseLicenseImageApprovalStatusEnum = {
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
} as const;

export type LicenseResponseLicenseImageApprovalStatusEnum = typeof LicenseResponseLicenseImageApprovalStatusEnum[keyof typeof LicenseResponseLicenseImageApprovalStatusEnum];

/**
 * 
 * @export
 * @interface LicenseTransferRequest
 */
export interface LicenseTransferRequest {
    /**
     * 
     * @type {string}
     * @memberof LicenseTransferRequest
     */
    'applicationId': string;
    /**
     * 
     * @type {string}
     * @memberof LicenseTransferRequest
     */
    'licenseTransferredAt'?: string;
}
/**
 * 
 * @export
 * @interface LocalTaxResponse
 */
export interface LocalTaxResponse {
    /**
     * 
     * @type {string}
     * @memberof LocalTaxResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocalTaxResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTaxResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocalTaxResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalTaxResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalTaxResponse
     */
    'zipCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocalTaxResponse
     */
    'code'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocalTaxResponse
     */
    'ratePercent'?: number;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'longitude': number;
}
/**
 * 
 * @export
 * @interface MakeMemberTeamLeader
 */
export interface MakeMemberTeamLeader {
    /**
     * 
     * @type {number}
     * @memberof MakeMemberTeamLeader
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof MakeMemberTeamLeader
     */
    'feeSplits'?: Array<FeeSplit>;
    /**
     * 
     * @type {string}
     * @memberof MakeMemberTeamLeader
     */
    'newMemberType'?: MakeMemberTeamLeaderNewMemberTypeEnum;
}

export const MakeMemberTeamLeaderNewMemberTypeEnum = {
    Teammate: 'TEAMMATE',
    Leader: 'LEADER'
} as const;

export type MakeMemberTeamLeaderNewMemberTypeEnum = typeof MakeMemberTeamLeaderNewMemberTypeEnum[keyof typeof MakeMemberTeamLeaderNewMemberTypeEnum];

/**
 * 
 * @export
 * @interface MakeMemberTeammate
 */
export interface MakeMemberTeammate {
    /**
     * 
     * @type {number}
     * @memberof MakeMemberTeammate
     */
    'realCap': number;
    /**
     * 
     * @type {Array<AddMemberLeaderSplit>}
     * @memberof MakeMemberTeammate
     */
    'leaderSplits'?: Array<AddMemberLeaderSplit>;
    /**
     * 
     * @type {Array<AddMemberFeeSplit>}
     * @memberof MakeMemberTeammate
     */
    'feeSplits'?: Array<AddMemberFeeSplit>;
    /**
     * 
     * @type {string}
     * @memberof MakeMemberTeammate
     */
    'newMemberType'?: MakeMemberTeammateNewMemberTypeEnum;
}

export const MakeMemberTeammateNewMemberTypeEnum = {
    Teammate: 'TEAMMATE',
    Leader: 'LEADER'
} as const;

export type MakeMemberTeammateNewMemberTypeEnum = typeof MakeMemberTeammateNewMemberTypeEnum[keyof typeof MakeMemberTeammateNewMemberTypeEnum];

/**
 * 
 * @export
 * @interface MarkUserAsBrokerRequest
 */
export interface MarkUserAsBrokerRequest {
    /**
     * 
     * @type {string}
     * @memberof MarkUserAsBrokerRequest
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface MaskedNationalIdentification
 */
export interface MaskedNationalIdentification {
    /**
     * 
     * @type {string}
     * @memberof MaskedNationalIdentification
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedNationalIdentification
     */
    'type'?: MaskedNationalIdentificationTypeEnum;
}

export const MaskedNationalIdentificationTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    BAndOId: 'B_AND_O_ID',
    GrtId: 'GRT_ID',
    GetId: 'GET_ID',
    LaCbtId: 'LA_CBT_ID',
    CbjId: 'CBJ_ID',
    Itin: 'ITIN',
    Bn: 'BN',
    GstId: 'GST_ID',
    PstId: 'PST_ID',
    HstId: 'HST_ID',
    QstId: 'QST_ID',
    Sin: 'SIN',
    Fnin: 'FNIN'
} as const;

export type MaskedNationalIdentificationTypeEnum = typeof MaskedNationalIdentificationTypeEnum[keyof typeof MaskedNationalIdentificationTypeEnum];

/**
 * 
 * @export
 * @interface MaskedPaymentMethodResponse
 */
export interface MaskedPaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'maskedAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'usRoutingNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'institutionNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'branchNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'type'?: MaskedPaymentMethodResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MaskedPaymentMethodResponse
     */
    'purpose'?: MaskedPaymentMethodResponsePurposeEnum;
}

export const MaskedPaymentMethodResponseTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type MaskedPaymentMethodResponseTypeEnum = typeof MaskedPaymentMethodResponseTypeEnum[keyof typeof MaskedPaymentMethodResponseTypeEnum];
export const MaskedPaymentMethodResponsePurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type MaskedPaymentMethodResponsePurposeEnum = typeof MaskedPaymentMethodResponsePurposeEnum[keyof typeof MaskedPaymentMethodResponsePurposeEnum];

/**
 * 
 * @export
 * @interface MaskedTaxFormLiteResponse
 */
export interface MaskedTaxFormLiteResponse {
    /**
     * 
     * @type {string}
     * @memberof MaskedTaxFormLiteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedTaxFormLiteResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedTaxFormLiteResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaskedTaxFormLiteResponse
     */
    'type'?: MaskedTaxFormLiteResponseTypeEnum;
    /**
     * 
     * @type {Array<MaskedNationalIdentification>}
     * @memberof MaskedTaxFormLiteResponse
     */
    'nationalIds'?: Array<MaskedNationalIdentification>;
}

export const MaskedTaxFormLiteResponseTypeEnum = {
    W9: 'W9',
    W8Eci: 'W8ECI',
    W8Ben: 'W8BEN',
    Canada: 'CANADA',
    Legacy: 'LEGACY'
} as const;

export type MaskedTaxFormLiteResponseTypeEnum = typeof MaskedTaxFormLiteResponseTypeEnum[keyof typeof MaskedTaxFormLiteResponseTypeEnum];

/**
 * 
 * @export
 * @interface MemberFeeSplit
 */
export interface MemberFeeSplit {
    /**
     * 
     * @type {string}
     * @memberof MemberFeeSplit
     */
    'leaderId': string;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof MemberFeeSplit
     */
    'feeSplits': Array<FeeSplit>;
}
/**
 * 
 * @export
 * @interface MemberLeaderSplit
 */
export interface MemberLeaderSplit {
    /**
     * 
     * @type {string}
     * @memberof MemberLeaderSplit
     */
    'leaderId': string;
    /**
     * 
     * @type {number}
     * @memberof MemberLeaderSplit
     */
    'leaderSplit': number;
}
/**
 * 
 * @export
 * @interface MemberTeamCap
 */
export interface MemberTeamCap {
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'capAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'preCapFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberTeamCap
     */
    'postCapFee'?: number;
}
/**
 * 
 * @export
 * @interface MetabaseDashboardUrlResponse
 */
export interface MetabaseDashboardUrlResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof MetabaseDashboardUrlResponse
     */
    'urls'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MigrateBoardRequest
 */
export interface MigrateBoardRequest {
    /**
     * 
     * @type {string}
     * @memberof MigrateBoardRequest
     */
    'fromId': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateBoardRequest
     */
    'toId': string;
}
/**
 * 
 * @export
 * @interface MigrateMlsRequest
 */
export interface MigrateMlsRequest {
    /**
     * 
     * @type {string}
     * @memberof MigrateMlsRequest
     */
    'fromId': string;
    /**
     * 
     * @type {string}
     * @memberof MigrateMlsRequest
     */
    'toId': string;
}
/**
 * 
 * @export
 * @interface MlsCreatePropertyTypeRequest
 */
export interface MlsCreatePropertyTypeRequest {
    /**
     * 
     * @type {string}
     * @memberof MlsCreatePropertyTypeRequest
     */
    'mlsId'?: string;
    /**
     * 
     * @type {Array<MlsPropertyTypeMapping>}
     * @memberof MlsCreatePropertyTypeRequest
     */
    'mlsPropertyTypeMappings'?: Array<MlsPropertyTypeMapping>;
}
/**
 * 
 * @export
 * @interface MlsDropboxErrorResponse
 */
export interface MlsDropboxErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsDropboxErrorResponse
     */
    'mlsId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsDropboxErrorResponse
     */
    'errorMsg'?: string;
}
/**
 * 
 * @export
 * @interface MlsGetPropertyTypeResponse
 */
export interface MlsGetPropertyTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsGetPropertyTypeResponse
     */
    'mlsId'?: string;
    /**
     * 
     * @type {Array<MlsPropertyTypeMapping>}
     * @memberof MlsGetPropertyTypeResponse
     */
    'mlsPropertyTypeMappings'?: Array<MlsPropertyTypeMapping>;
}
/**
 * 
 * @export
 * @interface MlsPreviewResponse
 */
export interface MlsPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof MlsPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof MlsPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof MlsPreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'billingFrequency'?: MlsPreviewResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsPreviewResponse
     */
    'recurringFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'billingType'?: MlsPreviewResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'paymentType'?: MlsPreviewResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'brokerOrMemberId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'unreportedLicenseRisk'?: MlsPreviewResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPreviewResponse
     */
    'idxFeedStatus'?: MlsPreviewResponseIdxFeedStatusEnum;
}

export const MlsPreviewResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type MlsPreviewResponseBillingFrequencyEnum = typeof MlsPreviewResponseBillingFrequencyEnum[keyof typeof MlsPreviewResponseBillingFrequencyEnum];
export const MlsPreviewResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type MlsPreviewResponseBillingTypeEnum = typeof MlsPreviewResponseBillingTypeEnum[keyof typeof MlsPreviewResponseBillingTypeEnum];
export const MlsPreviewResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type MlsPreviewResponsePaymentTypeEnum = typeof MlsPreviewResponsePaymentTypeEnum[keyof typeof MlsPreviewResponsePaymentTypeEnum];
export const MlsPreviewResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type MlsPreviewResponseUnreportedLicenseRiskEnum = typeof MlsPreviewResponseUnreportedLicenseRiskEnum[keyof typeof MlsPreviewResponseUnreportedLicenseRiskEnum];
export const MlsPreviewResponseIdxFeedStatusEnum = {
    AccessAvailable: 'ACCESS_AVAILABLE',
    NotCurrentlyAvailable: 'NOT_CURRENTLY_AVAILABLE',
    PendingAccess: 'PENDING_ACCESS'
} as const;

export type MlsPreviewResponseIdxFeedStatusEnum = typeof MlsPreviewResponseIdxFeedStatusEnum[keyof typeof MlsPreviewResponseIdxFeedStatusEnum];

/**
 * 
 * @export
 * @interface MlsPropertyTypeMapping
 */
export interface MlsPropertyTypeMapping {
    /**
     * 
     * @type {string}
     * @memberof MlsPropertyTypeMapping
     */
    'mlsListingType'?: MlsPropertyTypeMappingMlsListingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsPropertyTypeMapping
     */
    'mlsPropertyType'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsPropertyTypeMapping
     */
    'realPropertyType'?: MlsPropertyTypeMappingRealPropertyTypeEnum;
}

export const MlsPropertyTypeMappingMlsListingTypeEnum = {
    Commercial: 'Commercial',
    Farm: 'Farm',
    Land: 'Land',
    Multifamily: 'Multifamily',
    Rental: 'Rental',
    Residential: 'Residential'
} as const;

export type MlsPropertyTypeMappingMlsListingTypeEnum = typeof MlsPropertyTypeMappingMlsListingTypeEnum[keyof typeof MlsPropertyTypeMappingMlsListingTypeEnum];
export const MlsPropertyTypeMappingRealPropertyTypeEnum = {
    House: 'HOUSE',
    Townhouse: 'TOWNHOUSE',
    Condo: 'CONDO',
    Multifamily: 'MULTIFAMILY',
    Land: 'LAND'
} as const;

export type MlsPropertyTypeMappingRealPropertyTypeEnum = typeof MlsPropertyTypeMappingRealPropertyTypeEnum[keyof typeof MlsPropertyTypeMappingRealPropertyTypeEnum];

/**
 * 
 * @export
 * @interface MlsPublicResponse
 */
export interface MlsPublicResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsPublicResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsPublicResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof MlsPublicResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsPublicResponse
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface MlsRequest
 */
export interface MlsRequest {
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaRequest>}
     * @memberof MlsRequest
     */
    'administrativeAreas'?: Array<AdministrativeAreaRequest>;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'code'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsRequest
     */
    'registrationFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'status'?: MlsRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'officeCode'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof MlsRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'billingFrequency'?: MlsRequestBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsRequest
     */
    'recurringFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'billingType'?: MlsRequestBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'paymentType'?: MlsRequestPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'brokerOrMemberId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'unreportedLicenseRisk'?: MlsRequestUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsRequest
     */
    'idxFeedStatus'?: MlsRequestIdxFeedStatusEnum;
}

export const MlsRequestStatusEnum = {
    NotMembers: 'NOT_MEMBERS',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    WillingToJoin: 'WILLING_TO_JOIN'
} as const;

export type MlsRequestStatusEnum = typeof MlsRequestStatusEnum[keyof typeof MlsRequestStatusEnum];
export const MlsRequestBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type MlsRequestBillingFrequencyEnum = typeof MlsRequestBillingFrequencyEnum[keyof typeof MlsRequestBillingFrequencyEnum];
export const MlsRequestBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type MlsRequestBillingTypeEnum = typeof MlsRequestBillingTypeEnum[keyof typeof MlsRequestBillingTypeEnum];
export const MlsRequestPaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type MlsRequestPaymentTypeEnum = typeof MlsRequestPaymentTypeEnum[keyof typeof MlsRequestPaymentTypeEnum];
export const MlsRequestUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type MlsRequestUnreportedLicenseRiskEnum = typeof MlsRequestUnreportedLicenseRiskEnum[keyof typeof MlsRequestUnreportedLicenseRiskEnum];
export const MlsRequestIdxFeedStatusEnum = {
    AccessAvailable: 'ACCESS_AVAILABLE',
    NotCurrentlyAvailable: 'NOT_CURRENTLY_AVAILABLE',
    PendingAccess: 'PENDING_ACCESS'
} as const;

export type MlsRequestIdxFeedStatusEnum = typeof MlsRequestIdxFeedStatusEnum[keyof typeof MlsRequestIdxFeedStatusEnum];

/**
 * 
 * @export
 * @interface MlsResponse
 */
export interface MlsResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MlsResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof MlsResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof MlsResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'officeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof MlsResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'billingFrequency'?: MlsResponseBillingFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'nextDueDate'?: string;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsResponse
     */
    'recurringFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'billingType'?: MlsResponseBillingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'paymentType'?: MlsResponsePaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'brokerOrMemberId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'unreportedLicenseRisk'?: MlsResponseUnreportedLicenseRiskEnum;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'idxFeedStatus'?: MlsResponseIdxFeedStatusEnum;
    /**
     * 
     * @type {MoneyValue}
     * @memberof MlsResponse
     */
    'registrationFee'?: MoneyValue;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsResponse
     */
    'status'?: MlsResponseStatusEnum;
}

export const MlsResponseBillingFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    Yearly: 'YEARLY',
    BiYearly: 'BI_YEARLY'
} as const;

export type MlsResponseBillingFrequencyEnum = typeof MlsResponseBillingFrequencyEnum[keyof typeof MlsResponseBillingFrequencyEnum];
export const MlsResponseBillingTypeEnum = {
    DirectBillToAgent: 'DIRECT_BILL_TO_AGENT',
    BilledThroughBroker: 'BILLED_THROUGH_BROKER'
} as const;

export type MlsResponseBillingTypeEnum = typeof MlsResponseBillingTypeEnum[keyof typeof MlsResponseBillingTypeEnum];
export const MlsResponsePaymentTypeEnum = {
    CreditCard: 'CREDIT_CARD',
    Check: 'CHECK'
} as const;

export type MlsResponsePaymentTypeEnum = typeof MlsResponsePaymentTypeEnum[keyof typeof MlsResponsePaymentTypeEnum];
export const MlsResponseUnreportedLicenseRiskEnum = {
    OfficeSuspension: 'OFFICE_SUSPENSION',
    BrokerInvoiceIncrease: 'BROKER_INVOICE_INCREASE',
    BothSuspensionIncrease: 'BOTH_SUSPENSION_INCREASE',
    AgentSuspensionOnly: 'AGENT_SUSPENSION_ONLY',
    NoRisk: 'NO_RISK'
} as const;

export type MlsResponseUnreportedLicenseRiskEnum = typeof MlsResponseUnreportedLicenseRiskEnum[keyof typeof MlsResponseUnreportedLicenseRiskEnum];
export const MlsResponseIdxFeedStatusEnum = {
    AccessAvailable: 'ACCESS_AVAILABLE',
    NotCurrentlyAvailable: 'NOT_CURRENTLY_AVAILABLE',
    PendingAccess: 'PENDING_ACCESS'
} as const;

export type MlsResponseIdxFeedStatusEnum = typeof MlsResponseIdxFeedStatusEnum[keyof typeof MlsResponseIdxFeedStatusEnum];
export const MlsResponseStatusEnum = {
    NotMembers: 'NOT_MEMBERS',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    WillingToJoin: 'WILLING_TO_JOIN'
} as const;

export type MlsResponseStatusEnum = typeof MlsResponseStatusEnum[keyof typeof MlsResponseStatusEnum];

/**
 * 
 * @export
 * @interface MlsSecretsResponse
 */
export interface MlsSecretsResponse {
    /**
     * 
     * @type {string}
     * @memberof MlsSecretsResponse
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof MlsSecretsResponse
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface MonetaryAmount
 */
export interface MonetaryAmount {
    /**
     * 
     * @type {number}
     * @memberof MonetaryAmount
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MonetaryAmount
     */
    'currency'?: string;
}
/**
 * 
 * @export
 * @interface MoneyValue
 */
export interface MoneyValue {
    /**
     * 
     * @type {number}
     * @memberof MoneyValue
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MoneyValue
     */
    'currency'?: MoneyValueCurrencyEnum;
}

export const MoneyValueCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type MoneyValueCurrencyEnum = typeof MoneyValueCurrencyEnum[keyof typeof MoneyValueCurrencyEnum];

/**
 * 
 * @export
 * @interface MonthlyEarnings
 */
export interface MonthlyEarnings {
    /**
     * 
     * @type {Array<Revenue>}
     * @memberof MonthlyEarnings
     */
    'revenues'?: Array<Revenue>;
}
/**
 * 
 * @export
 * @interface MsdxBankAccountDto
 */
export interface MsdxBankAccountDto {
    /**
     * 
     * @type {string}
     * @memberof MsdxBankAccountDto
     */
    'subsidiary'?: MsdxBankAccountDtoSubsidiaryEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdxBankAccountDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdxBankAccountDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdxBankAccountDto
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof MsdxBankAccountDto
     */
    'syncedAt'?: number;
}

export const MsdxBankAccountDtoSubsidiaryEnum = {
    Llc: 'REAL_BROKER_LLC',
    Ab: 'REAL_BROKER_AB',
    Bc: 'REAL_BROKER_BC',
    Ontario: 'REAL_BROKER_ONTARIO',
    Manitoba: 'REAL_BROKER_MANITOBA',
    Lfro: 'REAL_BROKER_LFRO'
} as const;

export type MsdxBankAccountDtoSubsidiaryEnum = typeof MsdxBankAccountDtoSubsidiaryEnum[keyof typeof MsdxBankAccountDtoSubsidiaryEnum];

/**
 * 
 * @export
 * @interface MsdxVendorDto
 */
export interface MsdxVendorDto {
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'subsidiary'?: MsdxVendorDtoSubsidiaryEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'no'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdxVendorDto
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof MsdxVendorDto
     */
    'syncedAt'?: number;
}

export const MsdxVendorDtoSubsidiaryEnum = {
    Llc: 'REAL_BROKER_LLC',
    Ab: 'REAL_BROKER_AB',
    Bc: 'REAL_BROKER_BC',
    Ontario: 'REAL_BROKER_ONTARIO',
    Manitoba: 'REAL_BROKER_MANITOBA',
    Lfro: 'REAL_BROKER_LFRO'
} as const;

export type MsdxVendorDtoSubsidiaryEnum = typeof MsdxVendorDtoSubsidiaryEnum[keyof typeof MsdxVendorDtoSubsidiaryEnum];

/**
 * 
 * @export
 * @interface NationalBusinessIdentification
 */
export interface NationalBusinessIdentification {
    /**
     * 
     * @type {string}
     * @memberof NationalBusinessIdentification
     */
    'type': NationalBusinessIdentificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NationalBusinessIdentification
     */
    'nationalId': string;
}

export const NationalBusinessIdentificationTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    BAndOId: 'B_AND_O_ID',
    GrtId: 'GRT_ID',
    GetId: 'GET_ID',
    LaCbtId: 'LA_CBT_ID',
    CbjId: 'CBJ_ID',
    Itin: 'ITIN',
    Bn: 'BN',
    GstId: 'GST_ID',
    PstId: 'PST_ID',
    HstId: 'HST_ID',
    QstId: 'QST_ID',
    Sin: 'SIN',
    Fnin: 'FNIN'
} as const;

export type NationalBusinessIdentificationTypeEnum = typeof NationalBusinessIdentificationTypeEnum[keyof typeof NationalBusinessIdentificationTypeEnum];

/**
 * 
 * @export
 * @interface NationalIdentification
 */
export interface NationalIdentification {
    /**
     * 
     * @type {string}
     * @memberof NationalIdentification
     */
    'type'?: NationalIdentificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NationalIdentification
     */
    'id'?: string;
}

export const NationalIdentificationTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    BAndOId: 'B_AND_O_ID',
    GrtId: 'GRT_ID',
    GetId: 'GET_ID',
    LaCbtId: 'LA_CBT_ID',
    CbjId: 'CBJ_ID',
    Itin: 'ITIN',
    Bn: 'BN',
    GstId: 'GST_ID',
    PstId: 'PST_ID',
    HstId: 'HST_ID',
    QstId: 'QST_ID',
    Sin: 'SIN',
    Fnin: 'FNIN'
} as const;

export type NationalIdentificationTypeEnum = typeof NationalIdentificationTypeEnum[keyof typeof NationalIdentificationTypeEnum];

/**
 * 
 * @export
 * @interface NationalIdentificationValue
 */
export interface NationalIdentificationValue {
    /**
     * 
     * @type {string}
     * @memberof NationalIdentificationValue
     */
    'type': NationalIdentificationValueTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NationalIdentificationValue
     */
    'id': string;
}

export const NationalIdentificationValueTypeEnum = {
    Ssn: 'SSN',
    Ein: 'EIN',
    BAndOId: 'B_AND_O_ID',
    GrtId: 'GRT_ID',
    GetId: 'GET_ID',
    LaCbtId: 'LA_CBT_ID',
    CbjId: 'CBJ_ID',
    Itin: 'ITIN',
    Bn: 'BN',
    GstId: 'GST_ID',
    PstId: 'PST_ID',
    HstId: 'HST_ID',
    QstId: 'QST_ID',
    Sin: 'SIN',
    Fnin: 'FNIN'
} as const;

export type NationalIdentificationValueTypeEnum = typeof NationalIdentificationValueTypeEnum[keyof typeof NationalIdentificationValueTypeEnum];

/**
 * 
 * @export
 * @interface NetworkSizeByTierResponse
 */
export interface NetworkSizeByTierResponse {
    /**
     * 
     * @type {number}
     * @memberof NetworkSizeByTierResponse
     */
    'totalNetworkSize'?: number;
    /**
     * 
     * @type {Array<TierNetworkSize>}
     * @memberof NetworkSizeByTierResponse
     */
    'tierNetworkSizes'?: Array<TierNetworkSize>;
}
/**
 * 
 * @export
 * @interface NoteBatchEntityRequest
 */
export interface NoteBatchEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof NoteBatchEntityRequest
     */
    'entityType': NoteBatchEntityRequestEntityTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoteBatchEntityRequest
     */
    'entityIds': Array<string>;
}

export const NoteBatchEntityRequestEntityTypeEnum = {
    Application: 'APPLICATION',
    Transaction: 'TRANSACTION',
    Agent: 'AGENT',
    Mls: 'MLS',
    Board: 'BOARD'
} as const;

export type NoteBatchEntityRequestEntityTypeEnum = typeof NoteBatchEntityRequestEntityTypeEnum[keyof typeof NoteBatchEntityRequestEntityTypeEnum];

/**
 * 
 * @export
 * @interface NoteResponse
 */
export interface NoteResponse {
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof NoteResponse
     */
    'user'?: UserPreviewResponse;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'allowableRole'?: NoteResponseAllowableRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof NoteResponse
     */
    'followupDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'entityType'?: NoteResponseEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteResponse
     */
    'noteStatus'?: NoteResponseNoteStatusEnum;
}

export const NoteResponseAllowableRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type NoteResponseAllowableRoleEnum = typeof NoteResponseAllowableRoleEnum[keyof typeof NoteResponseAllowableRoleEnum];
export const NoteResponseEntityTypeEnum = {
    Application: 'APPLICATION',
    Transaction: 'TRANSACTION',
    Agent: 'AGENT',
    Mls: 'MLS',
    Board: 'BOARD'
} as const;

export type NoteResponseEntityTypeEnum = typeof NoteResponseEntityTypeEnum[keyof typeof NoteResponseEntityTypeEnum];
export const NoteResponseNoteStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type NoteResponseNoteStatusEnum = typeof NoteResponseNoteStatusEnum[keyof typeof NoteResponseNoteStatusEnum];

/**
 * 
 * @export
 * @interface NotesResponse
 */
export interface NotesResponse {
    /**
     * 
     * @type {Array<NoteResponse>}
     * @memberof NotesResponse
     */
    'notes'?: Array<NoteResponse>;
}
/**
 * 
 * @export
 * @interface OfficeAgentPreviewResponse
 */
export interface OfficeAgentPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeAgentPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeAgentPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'licenseVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'needOnBoarding'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'type'?: OfficeAgentPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'usesSkySlope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'serviceAreaZipcodes'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'slug'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'accountCountry'?: OfficeAgentPreviewResponseAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'defaultCurrency'?: OfficeAgentPreviewResponseDefaultCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeAgentPreviewResponse
     */
    'paymentDetailsInvalid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentPreviewResponse
     */
    'priorUserId'?: string;
    /**
     * 
     * @type {Array<AdministrativeAreaResponse>}
     * @memberof OfficeAgentPreviewResponse
     */
    'administrativeAreas'?: Array<AdministrativeAreaResponse>;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof OfficeAgentPreviewResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof OfficeAgentPreviewResponse
     */
    'licenses'?: Array<LicenseResponse>;
}

export const OfficeAgentPreviewResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type OfficeAgentPreviewResponseTypeEnum = typeof OfficeAgentPreviewResponseTypeEnum[keyof typeof OfficeAgentPreviewResponseTypeEnum];
export const OfficeAgentPreviewResponseAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type OfficeAgentPreviewResponseAccountCountryEnum = typeof OfficeAgentPreviewResponseAccountCountryEnum[keyof typeof OfficeAgentPreviewResponseAccountCountryEnum];
export const OfficeAgentPreviewResponseDefaultCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type OfficeAgentPreviewResponseDefaultCurrencyEnum = typeof OfficeAgentPreviewResponseDefaultCurrencyEnum[keyof typeof OfficeAgentPreviewResponseDefaultCurrencyEnum];

/**
 * 
 * @export
 * @interface OfficeAgentSearchResponse
 */
export interface OfficeAgentSearchResponse {
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof OfficeAgentSearchResponse
     */
    'divisions'?: Array<DivisionResponse>;
    /**
     * 
     * @type {string}
     * @memberof OfficeAgentSearchResponse
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface OfficeDropboxErrorResponse
 */
export interface OfficeDropboxErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeDropboxErrorResponse
     */
    'officeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeDropboxErrorResponse
     */
    'errorMsg'?: string;
}
/**
 * 
 * @export
 * @interface OfficeGroupResponse
 */
export interface OfficeGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeGroupResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeGroupResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeGroupResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {Array<GroupResponse>}
     * @memberof OfficeGroupResponse
     */
    'groups'?: Array<GroupResponse>;
}
/**
 * 
 * @export
 * @interface OfficePreviewResponse
 */
export interface OfficePreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficePreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficePreviewResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'faxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'brokerageLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'transactionType'?: OfficePreviewResponseTransactionTypeEnum;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof OfficePreviewResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    'designatedBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficePreviewResponse
     */
    'managingBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof OfficePreviewResponse
     */
    'company'?: CompanyResponse;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'paysOtherAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'leasePaysOtherAgent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'skySlopeGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'cdaDocumentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'invoiceDocumentTitle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'acceptsEscrow'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'coordinatorFeeType'?: OfficePreviewResponseCoordinatorFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'defaultOfficeInAdminArea'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'paysCommissionFromTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'allowsLease'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'defaultOfficeDropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'paymentSystem'?: OfficePreviewResponsePaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'paymentBatchName'?: OfficePreviewResponsePaymentBatchNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'realWithholdsTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'taxPaidFromCommission'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficePreviewResponse
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfficePreviewResponse
     */
    'voiceCallPriorityOrder'?: Array<OfficePreviewResponseVoiceCallPriorityOrderEnum>;
    /**
     * 
     * @type {Array<VoiceCallHoldTime>}
     * @memberof OfficePreviewResponse
     */
    'voiceCallHoldTimeSecs'?: Array<VoiceCallHoldTime>;
    /**
     * 
     * @type {boolean}
     * @memberof OfficePreviewResponse
     */
    'usesTipalti'?: boolean;
}

export const OfficePreviewResponseTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type OfficePreviewResponseTransactionTypeEnum = typeof OfficePreviewResponseTransactionTypeEnum[keyof typeof OfficePreviewResponseTransactionTypeEnum];
export const OfficePreviewResponseCoordinatorFeeTypeEnum = {
    NetCommission: 'NET_COMMISSION',
    GrossCommission: 'GROSS_COMMISSION'
} as const;

export type OfficePreviewResponseCoordinatorFeeTypeEnum = typeof OfficePreviewResponseCoordinatorFeeTypeEnum[keyof typeof OfficePreviewResponseCoordinatorFeeTypeEnum];
export const OfficePreviewResponsePaymentSystemEnum = {
    Tipalti: 'TIPALTI',
    MicrosoftDynamics: 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal: 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet: 'WALLET'
} as const;

export type OfficePreviewResponsePaymentSystemEnum = typeof OfficePreviewResponsePaymentSystemEnum[keyof typeof OfficePreviewResponsePaymentSystemEnum];
export const OfficePreviewResponsePaymentBatchNameEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type OfficePreviewResponsePaymentBatchNameEnum = typeof OfficePreviewResponsePaymentBatchNameEnum[keyof typeof OfficePreviewResponsePaymentBatchNameEnum];
export const OfficePreviewResponseVoiceCallPriorityOrderEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type OfficePreviewResponseVoiceCallPriorityOrderEnum = typeof OfficePreviewResponseVoiceCallPriorityOrderEnum[keyof typeof OfficePreviewResponseVoiceCallPriorityOrderEnum];

/**
 * 
 * @export
 * @interface OfficeResponse
 */
export interface OfficeResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'faxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'brokerageLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'transactionType'?: OfficeResponseTransactionTypeEnum;
    /**
     * 
     * @type {AdministrativeAreaResponse}
     * @memberof OfficeResponse
     */
    'administrativeArea'?: AdministrativeAreaResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficeResponse
     */
    'designatedBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {OfficeAgentPreviewResponse}
     * @memberof OfficeResponse
     */
    'managingBroker'?: OfficeAgentPreviewResponse;
    /**
     * 
     * @type {CompanyResponse}
     * @memberof OfficeResponse
     */
    'company'?: CompanyResponse;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'paysOtherAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'leasePaysOtherAgent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'skySlopeGuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'cdaDocumentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'invoiceDocumentTitle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'acceptsEscrow'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'coordinatorFeeType'?: OfficeResponseCoordinatorFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'defaultOfficeInAdminArea'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'paysCommissionFromTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'allowsLease'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'dropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'defaultOfficeDropboxId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'paymentSystem'?: OfficeResponsePaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'paymentBatchName'?: OfficeResponsePaymentBatchNameEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'realWithholdsTax'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'taxPaidFromCommission'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfficeResponse
     */
    'voiceCallPriorityOrder'?: Array<OfficeResponseVoiceCallPriorityOrderEnum>;
    /**
     * 
     * @type {Array<VoiceCallHoldTime>}
     * @memberof OfficeResponse
     */
    'voiceCallHoldTimeSecs'?: Array<VoiceCallHoldTime>;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeResponse
     */
    'stateOfficeAddress'?: AddressResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeResponse
     */
    'commissionDocumentAddress'?: AddressResponse;
    /**
     * 
     * @type {AddressResponse}
     * @memberof OfficeResponse
     */
    'branchOfficeAddress'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficeResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof OfficeResponse
     */
    'mls'?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof OfficeResponse
     */
    'boards'?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'bankAccount'?: BankAccountResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'trustAccount'?: BankAccountResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'operatingAccount'?: BankAccountResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'walletCommissionAccount'?: BankAccountResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'walletTrustAccount'?: BankAccountResponse;
    /**
     * 
     * @type {BankAccountResponse}
     * @memberof OfficeResponse
     */
    'walletOperatingAccount'?: BankAccountResponse;
    /**
     * 
     * @type {Array<TaxValue>}
     * @memberof OfficeResponse
     */
    'taxes'?: Array<TaxValue>;
    /**
     * 
     * @type {Array<ChecklistDefinitionDto>}
     * @memberof OfficeResponse
     */
    'checklistDefinitions'?: Array<ChecklistDefinitionDto>;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultTxChecklist'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultJourney'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultListingChecklist'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultListingJourney'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultReferralChecklist'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {ChecklistDefinitionDto}
     * @memberof OfficeResponse
     */
    'defaultReferralJourney'?: ChecklistDefinitionDto;
    /**
     * 
     * @type {number}
     * @memberof OfficeResponse
     */
    'metabaseDashboardId'?: number;
    /**
     * 
     * @type {Array<GroupResponseLite>}
     * @memberof OfficeResponse
     */
    'groups'?: Array<GroupResponseLite>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfficeResponse
     */
    'additionalFeesAndRebates'?: Array<OfficeResponseAdditionalFeesAndRebatesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     * @deprecated
     */
    'additionalCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     * @deprecated
     */
    'rebate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     * @deprecated
     */
    'transactionCoordinatorFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     * @deprecated
     */
    'reimbursementFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     * @deprecated
     */
    'mlsFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeResponse
     */
    'usesTipalti'?: boolean;
}

export const OfficeResponseTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type OfficeResponseTransactionTypeEnum = typeof OfficeResponseTransactionTypeEnum[keyof typeof OfficeResponseTransactionTypeEnum];
export const OfficeResponseCoordinatorFeeTypeEnum = {
    NetCommission: 'NET_COMMISSION',
    GrossCommission: 'GROSS_COMMISSION'
} as const;

export type OfficeResponseCoordinatorFeeTypeEnum = typeof OfficeResponseCoordinatorFeeTypeEnum[keyof typeof OfficeResponseCoordinatorFeeTypeEnum];
export const OfficeResponsePaymentSystemEnum = {
    Tipalti: 'TIPALTI',
    MicrosoftDynamics: 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal: 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet: 'WALLET'
} as const;

export type OfficeResponsePaymentSystemEnum = typeof OfficeResponsePaymentSystemEnum[keyof typeof OfficeResponsePaymentSystemEnum];
export const OfficeResponsePaymentBatchNameEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type OfficeResponsePaymentBatchNameEnum = typeof OfficeResponsePaymentBatchNameEnum[keyof typeof OfficeResponsePaymentBatchNameEnum];
export const OfficeResponseVoiceCallPriorityOrderEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type OfficeResponseVoiceCallPriorityOrderEnum = typeof OfficeResponseVoiceCallPriorityOrderEnum[keyof typeof OfficeResponseVoiceCallPriorityOrderEnum];
export const OfficeResponseAdditionalFeesAndRebatesEnum = {
    AdditionalCommission: 'ADDITIONAL_COMMISSION',
    Rebate: 'REBATE',
    TransactionCoordinatorFee: 'TRANSACTION_COORDINATOR_FEE',
    ReimbursementFee: 'REIMBURSEMENT_FEE',
    MlsFee: 'MLS_FEE',
    OneRealImpactFund: 'ONE_REAL_IMPACT_FUND'
} as const;

export type OfficeResponseAdditionalFeesAndRebatesEnum = typeof OfficeResponseAdditionalFeesAndRebatesEnum[keyof typeof OfficeResponseAdditionalFeesAndRebatesEnum];

/**
 * 
 * @export
 * @interface OfficeSearchResponse
 */
export interface OfficeSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof OfficeSearchResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof OfficeSearchResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfficeSearchResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfficeSearchResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfficeSearchResponse
     */
    'transactionType'?: OfficeSearchResponseTransactionTypeEnum;
    /**
     * 
     * @type {Address}
     * @memberof OfficeSearchResponse
     */
    'address'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof OfficeSearchResponse
     */
    'stateOfficeAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof OfficeSearchResponse
     */
    'commissionDocumentAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof OfficeSearchResponse
     */
    'branchOfficeAddress'?: Address;
    /**
     * 
     * @type {AdministrativeArea}
     * @memberof OfficeSearchResponse
     */
    'administrativeArea'?: AdministrativeArea;
    /**
     * 
     * @type {string}
     * @memberof OfficeSearchResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {OfficeAgentSearchResponse}
     * @memberof OfficeSearchResponse
     */
    'designatedBroker'?: OfficeAgentSearchResponse;
    /**
     * 
     * @type {OfficeAgentSearchResponse}
     * @memberof OfficeSearchResponse
     */
    'managingBroker'?: OfficeAgentSearchResponse;
    /**
     * 
     * @type {string}
     * @memberof OfficeSearchResponse
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfficeSearchResponse
     */
    'active'?: boolean;
}

export const OfficeSearchResponseTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type OfficeSearchResponseTransactionTypeEnum = typeof OfficeSearchResponseTransactionTypeEnum[keyof typeof OfficeSearchResponseTransactionTypeEnum];

/**
 * 
 * @export
 * @interface OfficialPlanResponse
 */
export interface OfficialPlanResponse {
    /**
     * 
     * @type {CommissionPlanResponse}
     * @memberof OfficialPlanResponse
     */
    'commissionPlan'?: CommissionPlanResponse;
    /**
     * 
     * @type {EliteEquityAwardPlanResponse}
     * @memberof OfficialPlanResponse
     */
    'eliteEquityAwardPlan'?: EliteEquityAwardPlanResponse;
    /**
     * 
     * @type {AwardsConfigResponse}
     * @memberof OfficialPlanResponse
     */
    'awardsConfig'?: AwardsConfigResponse;
    /**
     * 
     * @type {EquityPurchasePlanResponse}
     * @memberof OfficialPlanResponse
     */
    'equityPurchasePlan'?: EquityPurchasePlanResponse;
    /**
     * 
     * @type {RevenueSharePlanResponse}
     * @memberof OfficialPlanResponse
     */
    'revSharePlan'?: RevenueSharePlanResponse;
}
/**
 * 
 * @export
 * @interface OnboardUserRequest
 */
export interface OnboardUserRequest {
    /**
     * 
     * @type {string}
     * @memberof OnboardUserRequest
     */
    'role': OnboardUserRequestRoleEnum;
}

export const OnboardUserRequestRoleEnum = {
    TransactionCoordinator: 'TRANSACTION_COORDINATOR'
} as const;

export type OnboardUserRequestRoleEnum = typeof OnboardUserRequestRoleEnum[keyof typeof OnboardUserRequestRoleEnum];

/**
 * 
 * @export
 * @interface OnboardingResponse
 */
export interface OnboardingResponse {
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponse
     */
    'onboardingId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponse
     */
    'role'?: OnboardingResponseRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponse
     */
    'sherlockApplicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingResponse
     */
    'status'?: OnboardingResponseStatusEnum;
}

export const OnboardingResponseRoleEnum = {
    TransactionCoordinator: 'TRANSACTION_COORDINATOR'
} as const;

export type OnboardingResponseRoleEnum = typeof OnboardingResponseRoleEnum[keyof typeof OnboardingResponseRoleEnum];
export const OnboardingResponseStatusEnum = {
    ApplicationStarted: 'APPLICATION_STARTED',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    Approved: 'APPROVED',
    AutoApproved: 'AUTO_APPROVED',
    Rejected: 'REJECTED'
} as const;

export type OnboardingResponseStatusEnum = typeof OnboardingResponseStatusEnum[keyof typeof OnboardingResponseStatusEnum];

/**
 * 
 * @export
 * @interface OneRealImpactFundConfigDto
 */
export interface OneRealImpactFundConfigDto {
    /**
     * 
     * @type {number}
     * @memberof OneRealImpactFundConfigDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof OneRealImpactFundConfigDto
     */
    'percent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OneRealImpactFundConfigDto
     */
    'percentEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface PagedBoardResponse
 */
export interface PagedBoardResponse {
    /**
     * 
     * @type {number}
     * @memberof PagedBoardResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedBoardResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedBoardResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedBoardResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<BoardResponse>}
     * @memberof PagedBoardResponse
     */
    'boardResponseList'?: Array<BoardResponse>;
}
/**
 * 
 * @export
 * @interface PagedDirectoryEntryResponse
 */
export interface PagedDirectoryEntryResponse {
    /**
     * 
     * @type {number}
     * @memberof PagedDirectoryEntryResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedDirectoryEntryResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedDirectoryEntryResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedDirectoryEntryResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<DirectoryEntryResponse>}
     * @memberof PagedDirectoryEntryResponse
     */
    'directoryEntryResponseList'?: Array<DirectoryEntryResponse>;
}
/**
 * 
 * @export
 * @interface PagedPersonResponse
 */
export interface PagedPersonResponse {
    /**
     * 
     * @type {number}
     * @memberof PagedPersonResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedPersonResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedPersonResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedPersonResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<DirectoryPersonResponse>}
     * @memberof PagedPersonResponse
     */
    'directoryPersonResponseList'?: Array<DirectoryPersonResponse>;
}
/**
 * 
 * @export
 * @interface PagedVendorResponse
 */
export interface PagedVendorResponse {
    /**
     * 
     * @type {number}
     * @memberof PagedVendorResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PagedVendorResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PagedVendorResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagedVendorResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<DirectoryVendorResponse>}
     * @memberof PagedVendorResponse
     */
    'directoryVendorResponseList'?: Array<DirectoryVendorResponse>;
}
/**
 * 
 * @export
 * @interface PaginatedExperimentsResponse
 */
export interface PaginatedExperimentsResponse {
    /**
     * 
     * @type {number}
     * @memberof PaginatedExperimentsResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedExperimentsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaginatedExperimentsResponse
     */
    'hasNext'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PaginatedExperimentsResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<ExperimentResponse>}
     * @memberof PaginatedExperimentsResponse
     */
    'experiments'?: Array<ExperimentResponse>;
}
/**
 * 
 * @export
 * @interface PatchAgreementAmendmentDefinitionRequest
 */
export interface PatchAgreementAmendmentDefinitionRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchAgreementAmendmentDefinitionRequest
     */
    'state': PatchAgreementAmendmentDefinitionRequestStateEnum;
}

export const PatchAgreementAmendmentDefinitionRequestStateEnum = {
    Active: 'ACTIVE',
    Created: 'CREATED',
    Archived: 'ARCHIVED'
} as const;

export type PatchAgreementAmendmentDefinitionRequestStateEnum = typeof PatchAgreementAmendmentDefinitionRequestStateEnum[keyof typeof PatchAgreementAmendmentDefinitionRequestStateEnum];

/**
 * 
 * @export
 * @interface PatchAgreementDefinitionAmendmentOrderRequest
 */
export interface PatchAgreementDefinitionAmendmentOrderRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchAgreementDefinitionAmendmentOrderRequest
     */
    'orderedAmendmentDefinitionIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PatchTeamInvitationRequest
 */
export interface PatchTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTeamInvitationRequest
     */
    'invitationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTeamInvitationRequest
     */
    'status'?: PatchTeamInvitationRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchTeamInvitationRequest
     */
    'teamInvitationEmailStatus'?: PatchTeamInvitationRequestTeamInvitationEmailStatusEnum;
}

export const PatchTeamInvitationRequestStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type PatchTeamInvitationRequestStatusEnum = typeof PatchTeamInvitationRequestStatusEnum[keyof typeof PatchTeamInvitationRequestStatusEnum];
export const PatchTeamInvitationRequestTeamInvitationEmailStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED'
} as const;

export type PatchTeamInvitationRequestTeamInvitationEmailStatusEnum = typeof PatchTeamInvitationRequestTeamInvitationEmailStatusEnum[keyof typeof PatchTeamInvitationRequestTeamInvitationEmailStatusEnum];

/**
 * 
 * @export
 * @interface PayableInfoResponse
 */
export interface PayableInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PayableInfoResponse
     */
    'payable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayableInfoResponse
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof PayableInfoResponse
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
}
/**
 * 
 * @export
 * @interface PayeeDetailsResponse
 */
export interface PayeeDetailsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PayeeDetailsResponse
     */
    'registered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    'paymentMethod'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    'signUpLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PayeeDetailsResponse
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface PaymentDetails
 */
export interface PaymentDetails {
    /**
     * 
     * @type {Array<PaymentMethodResponse>}
     * @memberof PaymentDetails
     */
    'paymentMethods'?: Array<PaymentMethodResponse>;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     */
    'paymentSystemId'?: string;
    /**
     * 
     * @type {TipaltiInfoDto}
     * @memberof PaymentDetails
     */
    'tipaltiInfo'?: TipaltiInfoDto;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDetails
     */
    'invalid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentDetails
     */
    'payable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetails
     * @deprecated
     */
    'msDynamicsVendorNo'?: string;
    /**
     * 
     * @type {Array<MsdxVendorDto>}
     * @memberof PaymentDetails
     */
    'msdxVendors'?: Array<MsdxVendorDto>;
}
/**
 * 
 * @export
 * @interface PaymentMethodResponse
 */
export interface PaymentMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'nickname'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'bankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'usRoutingNumber': string;
    /**
     * 
     * @type {CanadaRoutingNumber}
     * @memberof PaymentMethodResponse
     */
    'canadaRoutingNumber'?: CanadaRoutingNumber;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'type'?: PaymentMethodResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     */
    'purpose'?: PaymentMethodResponsePurposeEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     * @deprecated
     */
    'msDynamicsSyncedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     * @deprecated
     */
    'msDynamicsCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodResponse
     * @deprecated
     */
    'msDynamicsId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodResponse
     */
    'wallet'?: boolean;
    /**
     * 
     * @type {Array<MsdxBankAccountDto>}
     * @memberof PaymentMethodResponse
     */
    'msdxBankAccounts'?: Array<MsdxBankAccountDto>;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodResponse
     */
    'updatedAt'?: number;
}

export const PaymentMethodResponseTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type PaymentMethodResponseTypeEnum = typeof PaymentMethodResponseTypeEnum[keyof typeof PaymentMethodResponseTypeEnum];
export const PaymentMethodResponsePurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type PaymentMethodResponsePurposeEnum = typeof PaymentMethodResponsePurposeEnum[keyof typeof PaymentMethodResponsePurposeEnum];

/**
 * 
 * @export
 * @interface PaymentMethodsResponse
 */
export interface PaymentMethodsResponse {
    /**
     * 
     * @type {Array<PaymentMethodResponse>}
     * @memberof PaymentMethodsResponse
     */
    'paymentMethods'?: Array<PaymentMethodResponse>;
}
/**
 * 
 * @export
 * @interface PaymentSettingsResponse
 */
export interface PaymentSettingsResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentSettingsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentSettingsResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentSettingsResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSettingsResponse
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {MaskedTaxFormLiteResponse}
     * @memberof PaymentSettingsResponse
     */
    'taxForm'?: MaskedTaxFormLiteResponse;
    /**
     * 
     * @type {Array<MaskedPaymentMethodResponse>}
     * @memberof PaymentSettingsResponse
     */
    'paymentMethods'?: Array<MaskedPaymentMethodResponse>;
}
/**
 * 
 * @export
 * @interface PermissionDto
 */
export interface PermissionDto {
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'subjectId': string;
    /**
     * 
     * @type {string}
     * @memberof PermissionDto
     */
    'permissionLevel': PermissionDtoPermissionLevelEnum;
}

export const PermissionDtoPermissionLevelEnum = {
    None: 'NONE',
    ReadOnly: 'READ_ONLY',
    ReadWrite: 'READ_WRITE'
} as const;

export type PermissionDtoPermissionLevelEnum = typeof PermissionDtoPermissionLevelEnum[keyof typeof PermissionDtoPermissionLevelEnum];

/**
 * 
 * @export
 * @interface PersonalAccountResponse
 */
export interface PersonalAccountResponse {
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof PersonalAccountResponse
     */
    'personalAccount'?: UserPreviewResponse;
}
/**
 * 
 * @export
 * @interface PlanMembershipResponse
 */
export interface PlanMembershipResponse {
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {CommissionPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'commissionPlan'?: CommissionPlanResponse;
    /**
     * 
     * @type {EquityPurchasePlanResponse}
     * @memberof PlanMembershipResponse
     */
    'equityPurchasePlan'?: EquityPurchasePlanResponse;
    /**
     * 
     * @type {EquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'equityAwardPlan'?: EquityAwardPlanResponse;
    /**
     * 
     * @type {EliteEquityAwardPlanResponse}
     * @memberof PlanMembershipResponse
     */
    'eliteEquityAwardPlan'?: EliteEquityAwardPlanResponse;
    /**
     * 
     * @type {RevenueSharePlanResponse}
     * @memberof PlanMembershipResponse
     */
    'revenueSharePlan'?: RevenueSharePlanResponse;
    /**
     * 
     * @type {string}
     * @memberof PlanMembershipResponse
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof PlanMembershipResponse
     */
    'optInToEquityPlan'?: number;
}
/**
 * 
 * @export
 * @interface PostAgreementAmendmentRequest
 */
export interface PostAgreementAmendmentRequest {
    /**
     * 
     * @type {string}
     * @memberof PostAgreementAmendmentRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PostAgreementAmendmentRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAgreementAmendmentRequest
     */
    'documentTemplateId': string;
    /**
     * 
     * @type {number}
     * @memberof PostAgreementAmendmentRequest
     */
    'version'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostAgreementAmendmentRequest
     */
    'requiredAt'?: number;
}
/**
 * 
 * @export
 * @interface PromotionCodeCreateRequest
 */
export interface PromotionCodeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeCreateRequest
     */
    'promoCode': string;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeCreateRequest
     */
    'currency': PromotionCodeCreateRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeCreateRequest
     */
    'duration': PromotionCodeCreateRequestDurationEnum;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeCreateRequest
     */
    'typeOff': PromotionCodeCreateRequestTypeOffEnum;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeCreateRequest
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeCreateRequest
     */
    'maxRedemptions'?: number;
}

export const PromotionCodeCreateRequestCurrencyEnum = {
    Usd: 'USD',
    Cad: 'CAD'
} as const;

export type PromotionCodeCreateRequestCurrencyEnum = typeof PromotionCodeCreateRequestCurrencyEnum[keyof typeof PromotionCodeCreateRequestCurrencyEnum];
export const PromotionCodeCreateRequestDurationEnum = {
    Forever: 'FOREVER',
    Once: 'ONCE',
    Repeating: 'REPEATING'
} as const;

export type PromotionCodeCreateRequestDurationEnum = typeof PromotionCodeCreateRequestDurationEnum[keyof typeof PromotionCodeCreateRequestDurationEnum];
export const PromotionCodeCreateRequestTypeOffEnum = {
    AmountOff: 'AMOUNT_OFF',
    PercentOff: 'PERCENT_OFF'
} as const;

export type PromotionCodeCreateRequestTypeOffEnum = typeof PromotionCodeCreateRequestTypeOffEnum[keyof typeof PromotionCodeCreateRequestTypeOffEnum];

/**
 * 
 * @export
 * @interface PromotionCodeResponse
 */
export interface PromotionCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeResponse
     */
    'promoCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeResponse
     */
    'amountOff'?: number;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeResponse
     */
    'percentOff'?: number;
    /**
     * 
     * @type {string}
     * @memberof PromotionCodeResponse
     */
    'currency'?: string;
    /**
     * 
     * @type {number}
     * @memberof PromotionCodeResponse
     */
    'maxRedemptions'?: number;
}
/**
 * 
 * @export
 * @interface PropertyFilter
 */
export interface PropertyFilter {
    /**
     * 
     * @type {string}
     * @memberof PropertyFilter
     */
    'categoryType'?: PropertyFilterCategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilter
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilter
     */
    'googlePlaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilter
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilter
     */
    'longitude'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyFilter
     */
    'homeJunctionPropertyTypes'?: Array<PropertyFilterHomeJunctionPropertyTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilter
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilter
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilter
     */
    'bedrooms'?: PropertyFilterBedroomsEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilter
     */
    'bathrooms'?: PropertyFilterBathroomsEnum;
}

export const PropertyFilterCategoryTypeEnum = {
    Buy: 'BUY',
    Rent: 'RENT',
    Sold: 'SOLD',
    Mls: 'MLS'
} as const;

export type PropertyFilterCategoryTypeEnum = typeof PropertyFilterCategoryTypeEnum[keyof typeof PropertyFilterCategoryTypeEnum];
export const PropertyFilterHomeJunctionPropertyTypesEnum = {
    Condominium: 'CONDOMINIUM',
    House: 'HOUSE',
    Multifamily: 'MULTIFAMILY',
    Townhouse: 'TOWNHOUSE',
    Land: 'LAND'
} as const;

export type PropertyFilterHomeJunctionPropertyTypesEnum = typeof PropertyFilterHomeJunctionPropertyTypesEnum[keyof typeof PropertyFilterHomeJunctionPropertyTypesEnum];
export const PropertyFilterBedroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterBedroomsEnum = typeof PropertyFilterBedroomsEnum[keyof typeof PropertyFilterBedroomsEnum];
export const PropertyFilterBathroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterBathroomsEnum = typeof PropertyFilterBathroomsEnum[keyof typeof PropertyFilterBathroomsEnum];

/**
 * 
 * @export
 * @interface PropertyFilterResponse
 */
export interface PropertyFilterResponse {
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'categoryType'?: PropertyFilterResponseCategoryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'mlsNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'googlePlaceId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'longitude'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyFilterResponse
     */
    'homeJunctionPropertyTypes'?: Array<PropertyFilterResponseHomeJunctionPropertyTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyFilterResponse
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'bedrooms'?: PropertyFilterResponseBedroomsEnum;
    /**
     * 
     * @type {string}
     * @memberof PropertyFilterResponse
     */
    'bathrooms'?: PropertyFilterResponseBathroomsEnum;
}

export const PropertyFilterResponseCategoryTypeEnum = {
    Buy: 'BUY',
    Rent: 'RENT',
    Sold: 'SOLD',
    Mls: 'MLS'
} as const;

export type PropertyFilterResponseCategoryTypeEnum = typeof PropertyFilterResponseCategoryTypeEnum[keyof typeof PropertyFilterResponseCategoryTypeEnum];
export const PropertyFilterResponseHomeJunctionPropertyTypesEnum = {
    Condominium: 'CONDOMINIUM',
    House: 'HOUSE',
    Multifamily: 'MULTIFAMILY',
    Townhouse: 'TOWNHOUSE',
    Land: 'LAND'
} as const;

export type PropertyFilterResponseHomeJunctionPropertyTypesEnum = typeof PropertyFilterResponseHomeJunctionPropertyTypesEnum[keyof typeof PropertyFilterResponseHomeJunctionPropertyTypesEnum];
export const PropertyFilterResponseBedroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterResponseBedroomsEnum = typeof PropertyFilterResponseBedroomsEnum[keyof typeof PropertyFilterResponseBedroomsEnum];
export const PropertyFilterResponseBathroomsEnum = {
    Any: 'ANY',
    Studio: 'STUDIO',
    OneOrMore: 'ONE_OR_MORE',
    TwoOrMore: 'TWO_OR_MORE',
    ThreeOrMore: 'THREE_OR_MORE',
    FourOrMore: 'FOUR_OR_MORE',
    FiveOrMore: 'FIVE_OR_MORE'
} as const;

export type PropertyFilterResponseBathroomsEnum = typeof PropertyFilterResponseBathroomsEnum[keyof typeof PropertyFilterResponseBathroomsEnum];

/**
 * 
 * @export
 * @interface PublicAdministrativeAreaDto
 */
export interface PublicAdministrativeAreaDto {
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'countryIso'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicAdministrativeAreaDto
     */
    'stateOrProvince'?: PublicAdministrativeAreaDtoStateOrProvinceEnum;
}

export const PublicAdministrativeAreaDtoStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type PublicAdministrativeAreaDtoStateOrProvinceEnum = typeof PublicAdministrativeAreaDtoStateOrProvinceEnum[keyof typeof PublicAdministrativeAreaDtoStateOrProvinceEnum];

/**
 * 
 * @export
 * @interface PublicLicenseResponse
 */
export interface PublicLicenseResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'type'?: PublicLicenseResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicLicenseResponse
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'expirationDate'?: string;
    /**
     * 
     * @type {PublicAdministrativeAreaDto}
     * @memberof PublicLicenseResponse
     */
    'administrativeArea'?: PublicAdministrativeAreaDto;
    /**
     * 
     * @type {string}
     * @memberof PublicLicenseResponse
     */
    'licenseImagePath'?: string;
}

export const PublicLicenseResponseTypeEnum = {
    Broker: 'BROKER',
    Agent: 'AGENT',
    LoanOfficer: 'LOAN_OFFICER'
} as const;

export type PublicLicenseResponseTypeEnum = typeof PublicLicenseResponseTypeEnum[keyof typeof PublicLicenseResponseTypeEnum];

/**
 * 
 * @export
 * @interface PublicLocationResponse
 */
export interface PublicLocationResponse {
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'stateOrProvince'?: PublicLocationResponseStateOrProvinceEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicLocationResponse
     */
    'country'?: PublicLocationResponseCountryEnum;
}

export const PublicLocationResponseStateOrProvinceEnum = {
    Alabama: 'ALABAMA',
    Alaska: 'ALASKA',
    Arizona: 'ARIZONA',
    Arkansas: 'ARKANSAS',
    California: 'CALIFORNIA',
    Colorado: 'COLORADO',
    Connecticut: 'CONNECTICUT',
    Delaware: 'DELAWARE',
    DistrictOfColumbia: 'DISTRICT_OF_COLUMBIA',
    Florida: 'FLORIDA',
    Georgia: 'GEORGIA',
    Hawaii: 'HAWAII',
    Idaho: 'IDAHO',
    Illinois: 'ILLINOIS',
    Indiana: 'INDIANA',
    Iowa: 'IOWA',
    Kansas: 'KANSAS',
    Kentucky: 'KENTUCKY',
    Louisiana: 'LOUISIANA',
    Maine: 'MAINE',
    Maryland: 'MARYLAND',
    Massachusetts: 'MASSACHUSETTS',
    Michigan: 'MICHIGAN',
    Minnesota: 'MINNESOTA',
    Mississippi: 'MISSISSIPPI',
    Missouri: 'MISSOURI',
    Montana: 'MONTANA',
    Nebraska: 'NEBRASKA',
    Nevada: 'NEVADA',
    NewHampshire: 'NEW_HAMPSHIRE',
    NewJersey: 'NEW_JERSEY',
    NewMexico: 'NEW_MEXICO',
    NewYork: 'NEW_YORK',
    NorthCarolina: 'NORTH_CAROLINA',
    NorthDakota: 'NORTH_DAKOTA',
    Ohio: 'OHIO',
    Oklahoma: 'OKLAHOMA',
    Oregon: 'OREGON',
    Pennsylvania: 'PENNSYLVANIA',
    RhodeIsland: 'RHODE_ISLAND',
    SouthCarolina: 'SOUTH_CAROLINA',
    SouthDakota: 'SOUTH_DAKOTA',
    Tennessee: 'TENNESSEE',
    Texas: 'TEXAS',
    Utah: 'UTAH',
    Vermont: 'VERMONT',
    Virginia: 'VIRGINIA',
    Washington: 'WASHINGTON',
    WestVirginia: 'WEST_VIRGINIA',
    Wisconsin: 'WISCONSIN',
    Wyoming: 'WYOMING',
    PuertoRico: 'PUERTO_RICO',
    Alberta: 'ALBERTA',
    BritishColumbia: 'BRITISH_COLUMBIA',
    Manitoba: 'MANITOBA',
    NewBrunswick: 'NEW_BRUNSWICK',
    NewfoundlandAndLabrador: 'NEWFOUNDLAND_AND_LABRADOR',
    NovaScotia: 'NOVA_SCOTIA',
    Ontario: 'ONTARIO',
    PrinceEdwardIsland: 'PRINCE_EDWARD_ISLAND',
    Quebec: 'QUEBEC',
    Saskatchewan: 'SASKATCHEWAN'
} as const;

export type PublicLocationResponseStateOrProvinceEnum = typeof PublicLocationResponseStateOrProvinceEnum[keyof typeof PublicLocationResponseStateOrProvinceEnum];
export const PublicLocationResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type PublicLocationResponseCountryEnum = typeof PublicLocationResponseCountryEnum[keyof typeof PublicLocationResponseCountryEnum];

/**
 * 
 * @export
 * @interface RealCapConfigDto
 */
export interface RealCapConfigDto {
    /**
     * 
     * @type {number}
     * @memberof RealCapConfigDto
     */
    'leaderCap'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RealCapConfigDto
     */
    'excludedMemberCaps'?: Array<number>;
}
/**
 * 
 * @export
 * @interface RedeemGenericTeamInvitationRequest
 */
export interface RedeemGenericTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemGenericTeamInvitationRequest
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof RedeemGenericTeamInvitationRequest
     */
    'applicationId': string;
}
/**
 * 
 * @export
 * @interface RedeemTeamInvitationRequest
 */
export interface RedeemTeamInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemTeamInvitationRequest
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof RedeemTeamInvitationRequest
     */
    'applicationId': string;
}
/**
 * 
 * @export
 * @interface RegistrationResponse
 */
export interface RegistrationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof RegistrationResponse
     */
    'pushNotificationRegistrationIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RejectApplicationRequest
 */
export interface RejectApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof RejectApplicationRequest
     */
    'agentId': string;
}
/**
 * 
 * @export
 * @interface RelativeAnniversariesResponse
 */
export interface RelativeAnniversariesResponse {
    /**
     * 
     * @type {string}
     * @memberof RelativeAnniversariesResponse
     */
    'lastAnniversary'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelativeAnniversariesResponse
     */
    'nextAnniversary'?: string;
}
/**
 * 
 * @export
 * @interface RemoveGroupMemberRequest
 */
export interface RemoveGroupMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveGroupMemberRequest
     */
    'userId'?: string;
}
/**
 * 
 * @export
 * @interface RemoveTransactionCoordinatorConnectionRequest
 */
export interface RemoveTransactionCoordinatorConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveTransactionCoordinatorConnectionRequest
     */
    'removalType': RemoveTransactionCoordinatorConnectionRequestRemovalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RemoveTransactionCoordinatorConnectionRequest
     */
    'removalDate'?: string;
}

export const RemoveTransactionCoordinatorConnectionRequestRemovalTypeEnum = {
    AllListingsAndTransactions: 'ALL_LISTINGS_AND_TRANSACTIONS',
    CreatedAfterSpecificDate: 'CREATED_AFTER_SPECIFIC_DATE'
} as const;

export type RemoveTransactionCoordinatorConnectionRequestRemovalTypeEnum = typeof RemoveTransactionCoordinatorConnectionRequestRemovalTypeEnum[keyof typeof RemoveTransactionCoordinatorConnectionRequestRemovalTypeEnum];

/**
 * 
 * @export
 * @interface ResendAgreementEmails
 */
export interface ResendAgreementEmails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ResendAgreementEmails
     */
    'agreementIds': Array<string>;
}
/**
 * 
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'usernameOrEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    'clientPlatform': ResetPasswordRequestClientPlatformEnum;
}

export const ResetPasswordRequestClientPlatformEnum = {
    Brokerage: 'BROKERAGE',
    Consumer: 'CONSUMER'
} as const;

export type ResetPasswordRequestClientPlatformEnum = typeof ResetPasswordRequestClientPlatformEnum[keyof typeof ResetPasswordRequestClientPlatformEnum];

/**
 * 
 * @export
 * @interface Revenue
 */
export interface Revenue {
    /**
     * 
     * @type {string}
     * @memberof Revenue
     */
    'source'?: RevenueSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof Revenue
     */
    'amount'?: number;
}

export const RevenueSourceEnum = {
    Commission: 'COMMISSION',
    SppPreCapPurchase: 'SPP_PRE_CAP_PURCHASE',
    SppPostCapPurchase: 'SPP_POST_CAP_PURCHASE',
    SppPreCapBonus: 'SPP_PRE_CAP_BONUS',
    SppPostCapBonus: 'SPP_POST_CAP_BONUS',
    EliteAward: 'ELITE_AWARD',
    AttractingAward: 'ATTRACTING_AWARD',
    Revshare: 'REVSHARE',
    CappingAward: 'CAPPING_AWARD',
    CultureAward: 'CULTURE_AWARD'
} as const;

export type RevenueSourceEnum = typeof RevenueSourceEnum[keyof typeof RevenueSourceEnum];

/**
 * 
 * @export
 * @interface RevenueSharePlanResponse
 */
export interface RevenueSharePlanResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'country'?: RevenueSharePlanResponseCountryEnum;
    /**
     * 
     * @type {Array<RevenueShareTierResponse>}
     * @memberof RevenueSharePlanResponse
     */
    'tiers'?: Array<RevenueShareTierResponse>;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'producingSpanInMonths'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlanResponse
     */
    'commissionsForProducingStatus'?: MoneyValue;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueSharePlanResponse
     */
    'annualFee'?: MoneyValue;
    /**
     * 
     * @type {number}
     * @memberof RevenueSharePlanResponse
     */
    'processingFeePercent'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevenueSharePlanResponse
     */
    'willableRevshareAgreementTemplateId'?: string;
}

export const RevenueSharePlanResponseCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type RevenueSharePlanResponseCountryEnum = typeof RevenueSharePlanResponseCountryEnum[keyof typeof RevenueSharePlanResponseCountryEnum];

/**
 * 
 * @export
 * @interface RevenueShareTierResponse
 */
export interface RevenueShareTierResponse {
    /**
     * 
     * @type {string}
     * @memberof RevenueShareTierResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'tier'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'commissionPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevenueShareTierResponse
     */
    'numberOfTierOneAgentsToUnlockThisTier'?: number;
    /**
     * 
     * @type {MoneyValue}
     * @memberof RevenueShareTierResponse
     */
    'maxAnnualAmountPerAgent'?: MoneyValue;
}
/**
 * 
 * @export
 * @interface RevshareParameters
 */
export interface RevshareParameters {
    /**
     * 
     * @type {Array<RevshareTierParameters>}
     * @memberof RevshareParameters
     */
    'tiers'?: Array<RevshareTierParameters>;
}
/**
 * 
 * @export
 * @interface RevshareTierParameters
 */
export interface RevshareTierParameters {
    /**
     * 
     * @type {number}
     * @memberof RevshareTierParameters
     */
    'gciPercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevshareTierParameters
     */
    'maxAmountPerAgent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RevshareTierParameters
     */
    'numberOfTier1AgentsRequiredToUnlock'?: number;
}
/**
 * 
 * @export
 * @interface SearchFlexTeamConfigDto
 */
export interface SearchFlexTeamConfigDto {
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamConfigDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamConfigDto
     */
    'teamType'?: SearchFlexTeamConfigDtoTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamConfigDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamConfigDto
     */
    'status'?: SearchFlexTeamConfigDtoStatusEnum;
}

export const SearchFlexTeamConfigDtoTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type SearchFlexTeamConfigDtoTeamTypeEnum = typeof SearchFlexTeamConfigDtoTeamTypeEnum[keyof typeof SearchFlexTeamConfigDtoTeamTypeEnum];
export const SearchFlexTeamConfigDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type SearchFlexTeamConfigDtoStatusEnum = typeof SearchFlexTeamConfigDtoStatusEnum[keyof typeof SearchFlexTeamConfigDtoStatusEnum];

/**
 * 
 * @export
 * @interface SearchFlexTeamDto
 */
export interface SearchFlexTeamDto {
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamDto
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchFlexTeamDto
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchFlexTeamDto
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {SearchFlexTeamConfigDto}
     * @memberof SearchFlexTeamDto
     */
    'config'?: SearchFlexTeamConfigDto;
    /**
     * 
     * @type {Array<SearchFlexTeamMemberDto>}
     * @memberof SearchFlexTeamDto
     */
    'leaders'?: Array<SearchFlexTeamMemberDto>;
    /**
     * 
     * @type {Array<SearchFlexTeamMemberDto>}
     * @memberof SearchFlexTeamDto
     */
    'teammates'?: Array<SearchFlexTeamMemberDto>;
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamDto
     */
    'status'?: SearchFlexTeamDtoStatusEnum;
}

export const SearchFlexTeamDtoStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type SearchFlexTeamDtoStatusEnum = typeof SearchFlexTeamDtoStatusEnum[keyof typeof SearchFlexTeamDtoStatusEnum];

/**
 * 
 * @export
 * @interface SearchFlexTeamMemberDto
 */
export interface SearchFlexTeamMemberDto {
    /**
     * 
     * @type {string}
     * @memberof SearchFlexTeamMemberDto
     */
    'id'?: string;
    /**
     * 
     * @type {SearchUserDto}
     * @memberof SearchFlexTeamMemberDto
     */
    'agent'?: SearchUserDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFlexTeamMemberDto
     */
    'roles'?: Array<SearchFlexTeamMemberDtoRolesEnum>;
}

export const SearchFlexTeamMemberDtoRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type SearchFlexTeamMemberDtoRolesEnum = typeof SearchFlexTeamMemberDtoRolesEnum[keyof typeof SearchFlexTeamMemberDtoRolesEnum];

/**
 * 
 * @export
 * @interface SearchUserDto
 */
export interface SearchUserDto {
    /**
     * 
     * @type {string}
     * @memberof SearchUserDto
     */
    'type'?: SearchUserDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchUserDto
     */
    'middleName'?: string;
}

export const SearchUserDtoTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type SearchUserDtoTypeEnum = typeof SearchUserDtoTypeEnum[keyof typeof SearchUserDtoTypeEnum];

/**
 * 
 * @export
 * @interface ServiceAreaRequest
 */
export interface ServiceAreaRequest {
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaRequest
     */
    'agentId': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaRequest
     */
    'address': string;
    /**
     * 
     * @type {Location}
     * @memberof ServiceAreaRequest
     */
    'location': Location;
    /**
     * 
     * @type {Distance}
     * @memberof ServiceAreaRequest
     */
    'radius': Distance;
}
/**
 * 
 * @export
 * @interface ServiceAreaResponse
 */
export interface ServiceAreaResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAreaResponse
     */
    'address'?: string;
    /**
     * 
     * @type {Location}
     * @memberof ServiceAreaResponse
     */
    'location'?: Location;
    /**
     * 
     * @type {Distance}
     * @memberof ServiceAreaResponse
     */
    'radius'?: Distance;
}
/**
 * 
 * @export
 * @interface SetNationalIdentficationsRequest
 */
export interface SetNationalIdentficationsRequest {
    /**
     * 
     * @type {Array<NationalIdentificationValue>}
     * @memberof SetNationalIdentficationsRequest
     */
    'identifications': Array<NationalIdentificationValue>;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'country'?: SignUpRequestCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'phoneNumber'?: string;
}

export const SignUpRequestCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type SignUpRequestCountryEnum = typeof SignUpRequestCountryEnum[keyof typeof SignUpRequestCountryEnum];

/**
 * 
 * @export
 * @interface SignUpUser
 */
export interface SignUpUser {
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'authUserType': SignUpUserAuthUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUser
     */
    'phoneNumber'?: string;
}

export const SignUpUserAuthUserTypeEnum = {
    Agent: 'AGENT',
    Applicant: 'APPLICANT',
    Borrower: 'BORROWER',
    Buyer: 'BUYER',
    LoanOfficer: 'LOAN_OFFICER',
    LoanOfficerAssistant: 'LOAN_OFFICER_ASSISTANT',
    LoanProcessor: 'LOAN_PROCESSOR',
    RealOriginateLoanOfficer: 'REAL_ORIGINATE_LOAN_OFFICER',
    TransactionCoordinator: 'TRANSACTION_COORDINATOR'
} as const;

export type SignUpUserAuthUserTypeEnum = typeof SignUpUserAuthUserTypeEnum[keyof typeof SignUpUserAuthUserTypeEnum];

/**
 * 
 * @export
 * @interface SignatureDisplayContextDto
 */
export interface SignatureDisplayContextDto {
    /**
     * 
     * @type {string}
     * @memberof SignatureDisplayContextDto
     */
    'timeZone'?: string;
}
/**
 * 
 * @export
 * @interface Signee
 */
export interface Signee {
    /**
     * 
     * @type {string}
     * @memberof Signee
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Signee
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Signee
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Signee
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Signee
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface SponsorAgentInfo
 */
export interface SponsorAgentInfo {
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsorAgentInfo
     */
    'status'?: SponsorAgentInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SponsorAgentInfo
     */
    'sizeOfNetwork'?: number;
    /**
     * 
     * @type {number}
     * @memberof SponsorAgentInfo
     */
    'revShareSplit'?: number;
    /**
     * 
     * @type {Array<SponsorAgentInfo>}
     * @memberof SponsorAgentInfo
     */
    'sponsors'?: Array<SponsorAgentInfo>;
    /**
     * 
     * @type {number}
     * @memberof SponsorAgentInfo
     */
    'level'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SponsorAgentInfo
     */
    'real'?: boolean;
}

export const SponsorAgentInfoStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type SponsorAgentInfoStatusEnum = typeof SponsorAgentInfoStatusEnum[keyof typeof SponsorAgentInfoStatusEnum];

/**
 * 
 * @export
 * @interface SponsorInfo
 */
export interface SponsorInfo {
    /**
     * 
     * @type {string}
     * @memberof SponsorInfo
     */
    'sponsorCode': string;
    /**
     * 
     * @type {number}
     * @memberof SponsorInfo
     */
    'revShareSplit'?: number;
}
/**
 * 
 * @export
 * @interface SponsorResponse
 */
export interface SponsorResponse {
    /**
     * 
     * @type {AgentPreviewResponse}
     * @memberof SponsorResponse
     */
    'sponsor'?: AgentPreviewResponse;
    /**
     * 
     * @type {number}
     * @memberof SponsorResponse
     */
    'revShareSplit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SponsorResponse
     */
    'real'?: boolean;
}
/**
 * 
 * @export
 * @interface SponsorTreeResponse
 */
export interface SponsorTreeResponse {
    /**
     * 
     * @type {Array<SponsorAgentInfo>}
     * @memberof SponsorTreeResponse
     */
    'sponsors'?: Array<SponsorAgentInfo>;
    /**
     * 
     * @type {Array<SponsorAgentInfo>}
     * @memberof SponsorTreeResponse
     */
    'flattenedSponsors'?: Array<SponsorAgentInfo>;
}
/**
 * 
 * @export
 * @interface SponsoringAgentDto
 */
export interface SponsoringAgentDto {
    /**
     * 
     * @type {string}
     * @memberof SponsoringAgentDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SponsoringAgentDto
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface StartJobExecutionRequest
 */
export interface StartJobExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof StartJobExecutionRequest
     */
    'jobExecutionName': string;
    /**
     * 
     * @type {string}
     * @memberof StartJobExecutionRequest
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface StockParameters
 */
export interface StockParameters {
    /**
     * 
     * @type {number}
     * @memberof StockParameters
     */
    'lastFetched'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockParameters
     */
    'currentPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockParameters
     */
    'currentChangePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof StockParameters
     */
    'currentChangePrice'?: number;
    /**
     * 
     * @type {Array<StockValue>}
     * @memberof StockParameters
     */
    'historicalValues'?: Array<StockValue>;
    /**
     * 
     * @type {Array<StockProjection>}
     * @memberof StockParameters
     */
    'projections'?: Array<StockProjection>;
}
/**
 * 
 * @export
 * @interface StockProjection
 */
export interface StockProjection {
    /**
     * 
     * @type {string}
     * @memberof StockProjection
     */
    'performance'?: StockProjectionPerformanceEnum;
    /**
     * 
     * @type {Array<StockValue>}
     * @memberof StockProjection
     */
    'stockValues'?: Array<StockValue>;
}

export const StockProjectionPerformanceEnum = {
    Low: 'LOW',
    Average: 'AVERAGE',
    High: 'HIGH'
} as const;

export type StockProjectionPerformanceEnum = typeof StockProjectionPerformanceEnum[keyof typeof StockProjectionPerformanceEnum];

/**
 * 
 * @export
 * @interface StockValue
 */
export interface StockValue {
    /**
     * 
     * @type {string}
     * @memberof StockValue
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof StockValue
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface TaxFormAcknowledgementResponse
 */
export interface TaxFormAcknowledgementResponse {
    /**
     * 
     * @type {boolean}
     * @memberof TaxFormAcknowledgementResponse
     */
    'pending'?: boolean;
}
/**
 * 
 * @export
 * @interface TaxFormCanadaResponse
 */
export interface TaxFormCanadaResponse extends TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormCanadaResponse
     */
    'taxpayerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormCanadaResponse
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormCanadaResponse
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormCanadaResponse
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof TaxFormCanadaResponse
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormCanadaResponse
     */
    'signedAt'?: number;
}


/**
 * 
 * @export
 * @interface TaxFormLegacyResponse
 */
export interface TaxFormLegacyResponse extends TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormLegacyResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormLegacyResponse
     */
    'nationalIds'?: Array<NationalIdentification>;
}


/**
 * 
 * @export
 * @interface TaxFormLegacyResponseAllOf
 */
export interface TaxFormLegacyResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof TaxFormLegacyResponseAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormLegacyResponseAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
}
/**
 * 
 * @export
 * @interface TaxFormLiteResponse
 */
export interface TaxFormLiteResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormLiteResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormLiteResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormLiteResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormLiteResponse
     */
    'type'?: TaxFormLiteResponseTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormLiteResponse
     */
    'nationalIds'?: Array<NationalIdentification>;
}

export const TaxFormLiteResponseTypeEnum = {
    W9: 'W9',
    W8Eci: 'W8ECI',
    W8Ben: 'W8BEN',
    Canada: 'CANADA',
    Legacy: 'LEGACY'
} as const;

export type TaxFormLiteResponseTypeEnum = typeof TaxFormLiteResponseTypeEnum[keyof typeof TaxFormLiteResponseTypeEnum];

/**
 * 
 * @export
 * @interface TaxFormResponse
 */
export interface TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormResponse
     */
    'type'?: TaxFormResponseTypeEnum;
}

export const TaxFormResponseTypeEnum = {
    W9: 'W9',
    W8Eci: 'W8ECI',
    W8Ben: 'W8BEN',
    Canada: 'CANADA',
    Legacy: 'LEGACY'
} as const;

export type TaxFormResponseTypeEnum = typeof TaxFormResponseTypeEnum[keyof typeof TaxFormResponseTypeEnum];

/**
 * 
 * @export
 * @interface TaxFormW8BENResponse
 */
export interface TaxFormW8BENResponse extends TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8BENResponse
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormW8BENResponse
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8BENResponse
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8BENResponse
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8BENResponse
     */
    'mailingAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8BENResponse
     */
    'countryOfIncorporation'?: TaxFormW8BENResponseCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8BENResponse
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormW8BENResponse
     */
    'signedAt'?: number;
}

export const TaxFormW8BENResponseCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type TaxFormW8BENResponseCountryOfIncorporationEnum = typeof TaxFormW8BENResponseCountryOfIncorporationEnum[keyof typeof TaxFormW8BENResponseCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface TaxFormW8ECIResponse
 */
export interface TaxFormW8ECIResponse extends TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'taxEntityType'?: TaxFormW8ECIResponseTaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormW8ECIResponse
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8ECIResponse
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8ECIResponse
     */
    'businessAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'countryOfIncorporation'?: TaxFormW8ECIResponseCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponse
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormW8ECIResponse
     */
    'signedAt'?: number;
}

export const TaxFormW8ECIResponseTaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type TaxFormW8ECIResponseTaxEntityTypeEnum = typeof TaxFormW8ECIResponseTaxEntityTypeEnum[keyof typeof TaxFormW8ECIResponseTaxEntityTypeEnum];
export const TaxFormW8ECIResponseCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type TaxFormW8ECIResponseCountryOfIncorporationEnum = typeof TaxFormW8ECIResponseCountryOfIncorporationEnum[keyof typeof TaxFormW8ECIResponseCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface TaxFormW8ECIResponseAllOf
 */
export interface TaxFormW8ECIResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'taxEntityType'?: TaxFormW8ECIResponseAllOfTaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'businessAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'countryOfIncorporation'?: TaxFormW8ECIResponseAllOfCountryOfIncorporationEnum;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormW8ECIResponseAllOf
     */
    'signedAt'?: number;
}

export const TaxFormW8ECIResponseAllOfTaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type TaxFormW8ECIResponseAllOfTaxEntityTypeEnum = typeof TaxFormW8ECIResponseAllOfTaxEntityTypeEnum[keyof typeof TaxFormW8ECIResponseAllOfTaxEntityTypeEnum];
export const TaxFormW8ECIResponseAllOfCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type TaxFormW8ECIResponseAllOfCountryOfIncorporationEnum = typeof TaxFormW8ECIResponseAllOfCountryOfIncorporationEnum[keyof typeof TaxFormW8ECIResponseAllOfCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface TaxFormW9Response
 */
export interface TaxFormW9Response extends TaxFormResponse {
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9Response
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9Response
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9Response
     */
    'taxClassification'?: TaxFormW9ResponseTaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormW9Response
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW9Response
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof TaxFormW9Response
     */
    'electronicDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9Response
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormW9Response
     */
    'signedAt'?: number;
}

export const TaxFormW9ResponseTaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type TaxFormW9ResponseTaxClassificationEnum = typeof TaxFormW9ResponseTaxClassificationEnum[keyof typeof TaxFormW9ResponseTaxClassificationEnum];

/**
 * 
 * @export
 * @interface TaxFormW9ResponseAllOf
 */
export interface TaxFormW9ResponseAllOf {
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9ResponseAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9ResponseAllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9ResponseAllOf
     */
    'taxClassification'?: TaxFormW9ResponseAllOfTaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof TaxFormW9ResponseAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof TaxFormW9ResponseAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof TaxFormW9ResponseAllOf
     */
    'electronicDelivery'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaxFormW9ResponseAllOf
     */
    'typedSignature'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxFormW9ResponseAllOf
     */
    'signedAt'?: number;
}

export const TaxFormW9ResponseAllOfTaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type TaxFormW9ResponseAllOfTaxClassificationEnum = typeof TaxFormW9ResponseAllOfTaxClassificationEnum[keyof typeof TaxFormW9ResponseAllOfTaxClassificationEnum];

/**
 * 
 * @export
 * @interface TaxFormsLiteResponse
 */
export interface TaxFormsLiteResponse {
    /**
     * 
     * @type {Array<TaxFormLiteResponse>}
     * @memberof TaxFormsLiteResponse
     */
    'taxForms'?: Array<TaxFormLiteResponse>;
}
/**
 * 
 * @export
 * @interface TaxFormsResponse
 */
export interface TaxFormsResponse {
    /**
     * 
     * @type {Array<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response>}
     * @memberof TaxFormsResponse
     */
    'taxForms'?: Array<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response>;
}
/**
 * 
 * @export
 * @interface TaxValue
 */
export interface TaxValue {
    /**
     * 
     * @type {string}
     * @memberof TaxValue
     */
    'type': TaxValueTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TaxValue
     */
    'rate'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaxValue
     */
    'taxId': string;
}

export const TaxValueTypeEnum = {
    Gst: 'GST',
    Pst: 'PST',
    Hst: 'HST',
    Qst: 'QST',
    BAndO: 'B_AND_O',
    Grt: 'GRT',
    Get: 'GET',
    LaCbt: 'LA_CBT',
    Cbj: 'CBJ',
    SdSst: 'SD_SST',
    SdState: 'SD_STATE',
    SdLocal: 'SD_LOCAL',
    HiGet: 'HI_GET',
    HiState: 'HI_STATE',
    HiLocal: 'HI_LOCAL'
} as const;

export type TaxValueTypeEnum = typeof TaxValueTypeEnum[keyof typeof TaxValueTypeEnum];

/**
 * 
 * @export
 * @interface TeamCap
 */
export interface TeamCap {
    /**
     * 
     * @type {number}
     * @memberof TeamCap
     */
    'capAmount': number;
    /**
     * 
     * @type {number}
     * @memberof TeamCap
     */
    'preCapFee': number;
    /**
     * 
     * @type {number}
     * @memberof TeamCap
     */
    'postCapFee': number;
}
/**
 * 
 * @export
 * @interface TeamInvitationDto
 */
export interface TeamInvitationDto {
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'invitationId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationDto
     */
    'capLevel'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationDto
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'invitationCreatedByAgentId': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'status': TeamInvitationDtoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'couponCode'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationDto
     */
    'emailSentAt': number;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'applicationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'applicationStatus'?: TeamInvitationDtoApplicationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'emailStatus': TeamInvitationDtoEmailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationDto
     */
    'firstAndLastName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationDto
     */
    'pending'?: boolean;
}

export const TeamInvitationDtoStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamInvitationDtoStatusEnum = typeof TeamInvitationDtoStatusEnum[keyof typeof TeamInvitationDtoStatusEnum];
export const TeamInvitationDtoApplicationStatusEnum = {
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamInvitationDtoApplicationStatusEnum = typeof TeamInvitationDtoApplicationStatusEnum[keyof typeof TeamInvitationDtoApplicationStatusEnum];
export const TeamInvitationDtoEmailStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED'
} as const;

export type TeamInvitationDtoEmailStatusEnum = typeof TeamInvitationDtoEmailStatusEnum[keyof typeof TeamInvitationDtoEmailStatusEnum];

/**
 * 
 * @export
 * @interface TeamInvitationSimplifiedDto
 */
export interface TeamInvitationSimplifiedDto {
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationSimplifiedDto
     */
    'waiveFees'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationSimplifiedDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationSimplifiedDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationSimplifiedDto
     */
    'emailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationSimplifiedDto
     */
    'invitationId': string;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationSimplifiedDto
     */
    'invitationSentAt': number;
    /**
     * 
     * @type {number}
     * @memberof TeamInvitationSimplifiedDto
     */
    'capLevel': number;
    /**
     * 
     * @type {boolean}
     * @memberof TeamInvitationSimplifiedDto
     */
    'invitationPending'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamInvitationSimplifiedDto
     */
    'invitationStatus': TeamInvitationSimplifiedDtoInvitationStatusEnum;
}

export const TeamInvitationSimplifiedDtoInvitationStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamInvitationSimplifiedDtoInvitationStatusEnum = typeof TeamInvitationSimplifiedDtoInvitationStatusEnum[keyof typeof TeamInvitationSimplifiedDtoInvitationStatusEnum];

/**
 * 
 * @export
 * @interface TeamLeadersAndAdminsResponse
 */
export interface TeamLeadersAndAdminsResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamLeadersAndAdminsResponse
     */
    'teamId'?: string;
    /**
     * 
     * @type {Array<AgentResponse>}
     * @memberof TeamLeadersAndAdminsResponse
     */
    'leaders'?: Array<AgentResponse>;
    /**
     * 
     * @type {Array<AgentResponse>}
     * @memberof TeamLeadersAndAdminsResponse
     */
    'admins'?: Array<AgentResponse>;
}
/**
 * 
 * @export
 * @interface TeamLeadersResponse
 */
export interface TeamLeadersResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamLeadersResponse
     */
    'teamId'?: string;
    /**
     * 
     * @type {Array<AgentResponse>}
     * @memberof TeamLeadersResponse
     */
    'leaders'?: Array<AgentResponse>;
}
/**
 * 
 * @export
 * @interface TeamMemberResponse
 */
export interface TeamMemberResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamMemberResponse
     */
    'id'?: string;
    /**
     * 
     * @type {UserPreviewResponse}
     * @memberof TeamMemberResponse
     */
    'agent'?: UserPreviewResponse;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamMemberResponse
     */
    'flexRoles'?: Array<TeamMemberResponseFlexRolesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamMemberResponse
     */
    'roles'?: Array<TeamMemberResponseRolesEnum>;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberResponse
     */
    'memberCommissionSplit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberResponse
     */
    'realCap'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamMemberResponse
     */
    'leaderSplit'?: number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof TeamMemberResponse
     */
    'leaderFeeSplits'?: Array<FeeSplit>;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof TeamMemberResponse
     */
    'teammateLeaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof TeamMemberResponse
     */
    'teammateFeeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {string}
     * @memberof TeamMemberResponse
     */
    'invitationStatus'?: TeamMemberResponseInvitationStatusEnum;
}

export const TeamMemberResponseFlexRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type TeamMemberResponseFlexRolesEnum = typeof TeamMemberResponseFlexRolesEnum[keyof typeof TeamMemberResponseFlexRolesEnum];
export const TeamMemberResponseRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type TeamMemberResponseRolesEnum = typeof TeamMemberResponseRolesEnum[keyof typeof TeamMemberResponseRolesEnum];
export const TeamMemberResponseInvitationStatusEnum = {
    Emailed: 'EMAILED',
    EmailClicked: 'EMAIL_CLICKED',
    LinkClicked: 'LINK_CLICKED',
    Cancelled: 'CANCELLED',
    Pending: 'PENDING',
    Redeemed: 'REDEEMED',
    Created: 'CREATED',
    Started: 'STARTED',
    SignIca: 'SIGN_ICA',
    IcaSigned: 'ICA_SIGNED',
    PayFees: 'PAY_FEES',
    FeesPaid: 'FEES_PAID',
    TransferLicense: 'TRANSFER_LICENSE',
    LicenseTransferred: 'LICENSE_TRANSFERRED',
    JoinMls: 'JOIN_MLS',
    MlsJoined: 'MLS_JOINED',
    JoinBoard: 'JOIN_BOARD',
    BoardJoined: 'BOARD_JOINED',
    PendingApproval: 'PENDING_APPROVAL',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    SignLetterOfIntent: 'SIGN_LETTER_OF_INTENT',
    LetterOfIntentSigned: 'LETTER_OF_INTENT_SIGNED',
    WaitingOnLicenseTransfer: 'WAITING_ON_LICENSE_TRANSFER',
    FeesWaived: 'FEES_WAIVED'
} as const;

export type TeamMemberResponseInvitationStatusEnum = typeof TeamMemberResponseInvitationStatusEnum[keyof typeof TeamMemberResponseInvitationStatusEnum];

/**
 * 
 * @export
 * @interface TeamPermission
 */
export interface TeamPermission {
    /**
     * 
     * @type {boolean}
     * @memberof TeamPermission
     */
    'allowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamPermission
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPermission
     */
    'teamType'?: TeamPermissionTeamTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamPermission
     */
    'message'?: string;
}

export const TeamPermissionTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type TeamPermissionTeamTypeEnum = typeof TeamPermissionTeamTypeEnum[keyof typeof TeamPermissionTeamTypeEnum];

/**
 * 
 * @export
 * @interface TeamPermissionResponse
 */
export interface TeamPermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionResponse
     */
    'teamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionResponse
     */
    'teamType'?: TeamPermissionResponseTeamTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TeamPermissionResponse
     * @deprecated
     */
    'canApproveCommissionDocument'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionResponse
     * @deprecated
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPermissionResponse
     * @deprecated
     */
    'message'?: string;
    /**
     * 
     * @type {TeamPermissions}
     * @memberof TeamPermissionResponse
     */
    'teamPermissions'?: TeamPermissions;
}

export const TeamPermissionResponseTeamTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Domestic: 'DOMESTIC',
    Group: 'GROUP',
    Pro: 'PRO'
} as const;

export type TeamPermissionResponseTeamTypeEnum = typeof TeamPermissionResponseTeamTypeEnum[keyof typeof TeamPermissionResponseTeamTypeEnum];

/**
 * 
 * @export
 * @interface TeamPermissions
 */
export interface TeamPermissions {
    /**
     * 
     * @type {TeamPermission}
     * @memberof TeamPermissions
     */
    'canApproveCommissionDocument'?: TeamPermission;
    /**
     * 
     * @type {TeamPermission}
     * @memberof TeamPermissions
     */
    'canEditTransaction'?: TeamPermission;
}
/**
 * 
 * @export
 * @interface TeamPreviewResponse
 */
export interface TeamPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamPreviewResponse
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamPreviewResponse
     */
    'commissionDocumentApprover'?: TeamPreviewResponseCommissionDocumentApproverEnum;
}

export const TeamPreviewResponseCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type TeamPreviewResponseCommissionDocumentApproverEnum = typeof TeamPreviewResponseCommissionDocumentApproverEnum[keyof typeof TeamPreviewResponseCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface TeamResponse
 */
export interface TeamResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeamResponse
     */
    'flex'?: boolean;
    /**
     * 
     * @type {FlexTeamConfigDto}
     * @memberof TeamResponse
     */
    'config'?: FlexTeamConfigDto;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'type'?: TeamResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'status'?: TeamResponseStatusEnum;
    /**
     * 
     * @type {Array<TeamMemberResponse>}
     * @memberof TeamResponse
     */
    'agents'?: Array<TeamMemberResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof TeamResponse
     */
    'instantPaymentEligible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TeamResponse
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'memberCommissionSplit'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamResponse
     */
    'maxMemberCommissionSplit'?: number;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'commissionDocumentApprover'?: TeamResponseCommissionDocumentApproverEnum;
    /**
     * 
     * @type {Array<TeamInvitationSimplifiedDto>}
     * @memberof TeamResponse
     */
    'teamInvitations'?: Array<TeamInvitationSimplifiedDto>;
    /**
     * 
     * @type {Array<GenericTeamInvitationApplicationResponse>}
     * @memberof TeamResponse
     */
    'pendingGenericTeamApplications'?: Array<GenericTeamInvitationApplicationResponse>;
    /**
     * 
     * @type {string}
     * @memberof TeamResponse
     */
    'permittedTransactionEditors'?: TeamResponsePermittedTransactionEditorsEnum;
}

export const TeamResponseTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Group: 'GROUP',
    Domestic: 'DOMESTIC',
    Pro: 'PRO'
} as const;

export type TeamResponseTypeEnum = typeof TeamResponseTypeEnum[keyof typeof TeamResponseTypeEnum];
export const TeamResponseStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type TeamResponseStatusEnum = typeof TeamResponseStatusEnum[keyof typeof TeamResponseStatusEnum];
export const TeamResponseCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type TeamResponseCommissionDocumentApproverEnum = typeof TeamResponseCommissionDocumentApproverEnum[keyof typeof TeamResponseCommissionDocumentApproverEnum];
export const TeamResponsePermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type TeamResponsePermittedTransactionEditorsEnum = typeof TeamResponsePermittedTransactionEditorsEnum[keyof typeof TeamResponsePermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface TeamWithoutAgentsResponse
 */
export interface TeamWithoutAgentsResponse {
    /**
     * 
     * @type {string}
     * @memberof TeamWithoutAgentsResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TeamWithoutAgentsResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof TeamWithoutAgentsResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {FlexTeamConfigDto}
     * @memberof TeamWithoutAgentsResponse
     */
    'config'?: FlexTeamConfigDto;
    /**
     * 
     * @type {boolean}
     * @memberof TeamWithoutAgentsResponse
     */
    'flex'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TeamWithoutAgentsResponse
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamWithoutAgentsResponse
     */
    'type'?: TeamWithoutAgentsResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TeamWithoutAgentsResponse
     */
    'status'?: TeamWithoutAgentsResponseStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TeamWithoutAgentsResponse
     */
    'instantPaymentEligible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TeamWithoutAgentsResponse
     */
    'maxMemberCommissionSplit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TeamWithoutAgentsResponse
     */
    'paidAtClosing'?: boolean;
}

export const TeamWithoutAgentsResponseTypeEnum = {
    Normal: 'NORMAL',
    Platinum: 'PLATINUM',
    Group: 'GROUP',
    Domestic: 'DOMESTIC',
    Pro: 'PRO'
} as const;

export type TeamWithoutAgentsResponseTypeEnum = typeof TeamWithoutAgentsResponseTypeEnum[keyof typeof TeamWithoutAgentsResponseTypeEnum];
export const TeamWithoutAgentsResponseStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type TeamWithoutAgentsResponseStatusEnum = typeof TeamWithoutAgentsResponseStatusEnum[keyof typeof TeamWithoutAgentsResponseStatusEnum];

/**
 * 
 * @export
 * @interface TerminateAgentRequest
 */
export interface TerminateAgentRequest {
    /**
     * 
     * @type {string}
     * @memberof TerminateAgentRequest
     */
    'terminatedAsOf': string;
    /**
     * 
     * @type {string}
     * @memberof TerminateAgentRequest
     */
    'terminationReason': TerminateAgentRequestTerminationReasonEnum;
    /**
     * 
     * @type {boolean}
     * @memberof TerminateAgentRequest
     */
    'eligibleForRehire': boolean;
    /**
     * 
     * @type {string}
     * @memberof TerminateAgentRequest
     */
    'terminationReasonDetails'?: string;
}

export const TerminateAgentRequestTerminationReasonEnum = {
    LeftIndustry: 'LEFT_INDUSTRY',
    LeftBrokerageVoluntarily: 'LEFT_BROKERAGE_VOLUNTARILY',
    InitiatedByReal: 'INITIATED_BY_REAL',
    Disciplinary: 'DISCIPLINARY',
    Deceased: 'DECEASED',
    Reorg: 'REORG',
    LicenseExpired: 'LICENSE_EXPIRED',
    UnpaidFees: 'UNPAID_FEES',
    Duplicate: 'DUPLICATE',
    Other: 'OTHER'
} as const;

export type TerminateAgentRequestTerminationReasonEnum = typeof TerminateAgentRequestTerminationReasonEnum[keyof typeof TerminateAgentRequestTerminationReasonEnum];

/**
 * 
 * @export
 * @interface TierNetworkSize
 */
export interface TierNetworkSize {
    /**
     * 
     * @type {number}
     * @memberof TierNetworkSize
     */
    'tier'?: number;
    /**
     * 
     * @type {number}
     * @memberof TierNetworkSize
     */
    'networkSize'?: number;
}
/**
 * 
 * @export
 * @interface TipaltiInfoDto
 */
export interface TipaltiInfoDto {
    /**
     * 
     * @type {string}
     * @memberof TipaltiInfoDto
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TipaltiInfoDto
     */
    'tipaltiConfirmed'?: boolean;
}
/**
 * 
 * @export
 * @interface TransactionCoordinatorConnectionResponse
 */
export interface TransactionCoordinatorConnectionResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'creatorUserId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'accessDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'accessType'?: TransactionCoordinatorConnectionResponseAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'mainStatus'?: TransactionCoordinatorConnectionResponseMainStatusEnum;
    /**
     * 
     * @type {Array<EntityStatusTimelineItemResponseConnectionRequestStatus>}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'detailedStatusTimeline'?: Array<EntityStatusTimelineItemResponseConnectionRequestStatus>;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'detailedStatus'?: TransactionCoordinatorConnectionResponseDetailedStatusEnum;
    /**
     * 
     * @type {UserInfo}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'agent'?: UserInfo;
    /**
     * 
     * @type {UserInfo}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'transactionCoordinator'?: UserInfo;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionCoordinatorConnectionResponse
     */
    'external'?: boolean;
}

export const TransactionCoordinatorConnectionResponseAccessTypeEnum = {
    AllListingsAndTransactions: 'ALL_LISTINGS_AND_TRANSACTIONS',
    CreatedAfterSpecificDate: 'CREATED_AFTER_SPECIFIC_DATE',
    CreatedByOrAssignedTo: 'CREATED_BY_OR_ASSIGNED_TO'
} as const;

export type TransactionCoordinatorConnectionResponseAccessTypeEnum = typeof TransactionCoordinatorConnectionResponseAccessTypeEnum[keyof typeof TransactionCoordinatorConnectionResponseAccessTypeEnum];
export const TransactionCoordinatorConnectionResponseMainStatusEnum = {
    Pending: 'PENDING',
    Expired: 'EXPIRED',
    Rejected: 'REJECTED',
    Active: 'ACTIVE',
    Voided: 'VOIDED'
} as const;

export type TransactionCoordinatorConnectionResponseMainStatusEnum = typeof TransactionCoordinatorConnectionResponseMainStatusEnum[keyof typeof TransactionCoordinatorConnectionResponseMainStatusEnum];
export const TransactionCoordinatorConnectionResponseDetailedStatusEnum = {
    InviteStarted: 'INVITE_STARTED',
    Emailed: 'EMAILED',
    LinkClicked: 'LINK_CLICKED',
    ApplicationSubmitted: 'APPLICATION_SUBMITTED',
    AccountApproved: 'ACCOUNT_APPROVED',
    SignAssistantAmendment: 'SIGN_ASSISTANT_AMENDMENT',
    Active: 'ACTIVE',
    Rejected: 'REJECTED',
    EmailResent: 'EMAIL_RESENT',
    Expired: 'EXPIRED'
} as const;

export type TransactionCoordinatorConnectionResponseDetailedStatusEnum = typeof TransactionCoordinatorConnectionResponseDetailedStatusEnum[keyof typeof TransactionCoordinatorConnectionResponseDetailedStatusEnum];

/**
 * 
 * @export
 * @interface TransactionCoordinatorSearchResponse
 */
export interface TransactionCoordinatorSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'transactionCoordinatorId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'userId'?: string;
    /**
     * 
     * @type {EmailAddress}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'email'?: EmailAddress;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCoordinatorSearchResponse
     */
    'emailAddress'?: string;
}
/**
 * 
 * @export
 * @interface TransferAgentBetweenTeamsRequest
 */
export interface TransferAgentBetweenTeamsRequest {
    /**
     * 
     * @type {string}
     * @memberof TransferAgentBetweenTeamsRequest
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferAgentBetweenTeamsRequest
     */
    'fromTeamId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferAgentBetweenTeamsRequest
     */
    'toTeamId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferAgentBetweenTeamsRequest
     */
    'toTeamCommissionSplit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TransferAgentBetweenTeamsRequest
     */
    'toTeamRoles'?: Array<TransferAgentBetweenTeamsRequestToTeamRolesEnum>;
}

export const TransferAgentBetweenTeamsRequestToTeamRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type TransferAgentBetweenTeamsRequestToTeamRolesEnum = typeof TransferAgentBetweenTeamsRequestToTeamRolesEnum[keyof typeof TransferAgentBetweenTeamsRequestToTeamRolesEnum];

/**
 * 
 * @export
 * @interface TransferFlexTeamMember
 */
export interface TransferFlexTeamMember {
    /**
     * 
     * @type {string}
     * @memberof TransferFlexTeamMember
     */
    'memberId': string;
    /**
     * 
     * @type {string}
     * @memberof TransferFlexTeamMember
     */
    'fromTeam': string;
    /**
     * 
     * @type {string}
     * @memberof TransferFlexTeamMember
     */
    'toTeam': string;
}
/**
 * 
 * @export
 * @interface UpdateAgentAdminRequest
 */
export interface UpdateAgentAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentAdminRequest
     */
    'tipaltiConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     * @deprecated
     */
    'sponsorCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentAdminRequest
     * @deprecated
     */
    'optIntoEquityPlan'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentAdminRequest
     */
    'restricted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAgentAdminRequest
     */
    'revshareBan'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentAdminRequest
     */
    'nonReportable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentAdminRequest
     */
    'awardsBanned'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentAdminRequest
     */
    'priorUserId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAgentNeedsMentorshipRequest
 */
export interface UpdateAgentNeedsMentorshipRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentNeedsMentorshipRequest
     */
    'needsMentorship'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateAgentRequestAdmin
 */
export interface UpdateAgentRequestAdmin {
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentRequestAdmin
     */
    'tipaltiConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAgentRequestAdmin
     */
    'sponsorCode'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAgentSingleCheckPreferenceRequest
 */
export interface UpdateAgentSingleCheckPreferenceRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAgentSingleCheckPreferenceRequest
     */
    'prefersSingleCheck'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRequest
 */
export interface UpdateApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     * @deprecated
     */
    'boardOrMls'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     * @deprecated
     */
    'board'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     * @deprecated
     */
    'mls'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'currentBrokerage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicationRequest
     */
    'hasPendingTransactionsWithCurrentBrokerage'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'estimatedSales'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicationRequest
     */
    'commercialAgent'?: boolean;
    /**
     * 
     * @type {Array<LicenseRequest>}
     * @memberof UpdateApplicationRequest
     * @deprecated
     */
    'doesBusinessIn'?: Array<LicenseRequest>;
    /**
     * 
     * @type {Array<LicenseBoardMLSRequest>}
     * @memberof UpdateApplicationRequest
     */
    'doesBusinessInExtended'?: Array<LicenseBoardMLSRequest>;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'teamName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'teamLeaderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'teamRole'?: UpdateApplicationRequestTeamRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateApplicationRequest
     */
    'preferredTitleVendor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicationRequest
     */
    'preferredTitleVendorFlag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateApplicationRequest
     */
    'individualAgentFlag'?: boolean;
}

export const UpdateApplicationRequestTeamRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type UpdateApplicationRequestTeamRoleEnum = typeof UpdateApplicationRequestTeamRoleEnum[keyof typeof UpdateApplicationRequestTeamRoleEnum];

/**
 * 
 * @export
 * @interface UpdateBannerRequest
 */
export interface UpdateBannerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBannerRequest
     */
    'status': UpdateBannerRequestStatusEnum;
}

export const UpdateBannerRequestStatusEnum = {
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type UpdateBannerRequestStatusEnum = typeof UpdateBannerRequestStatusEnum[keyof typeof UpdateBannerRequestStatusEnum];

/**
 * 
 * @export
 * @interface UpdateBuyerInfoRequest
 */
export interface UpdateBuyerInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBuyerInfoRequest
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDefaultChecklistsAndJourneysRequest
 */
export interface UpdateDefaultChecklistsAndJourneysRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultJourneyDefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultTxChecklistDefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultReferralJourneyDefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultReferralChecklistDefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultListingJourneyDefId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDefaultChecklistsAndJourneysRequest
     */
    'defaultListingChecklistDefId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateExperimentGroupRequest
 */
export interface UpdateExperimentGroupRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateExperimentGroupRequest
     */
    'weight': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateExperimentGroupRequest
     */
    'overrideIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateExperimentRequest
 */
export interface UpdateExperimentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateExperimentRequest
     */
    'state'?: UpdateExperimentRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateExperimentRequest
     */
    'filterScript'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateExperimentRequest
     */
    'rolloutStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateExperimentRequest
     */
    'rolloutEnd'?: number;
}

export const UpdateExperimentRequestStateEnum = {
    PreRollout: 'PRE_ROLLOUT',
    Active: 'ACTIVE',
    Archived: 'ARCHIVED'
} as const;

export type UpdateExperimentRequestStateEnum = typeof UpdateExperimentRequestStateEnum[keyof typeof UpdateExperimentRequestStateEnum];

/**
 * 
 * @export
 * @interface UpdateFailedMessageStatusBatch
 */
export interface UpdateFailedMessageStatusBatch {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFailedMessageStatusBatch
     */
    'status': UpdateFailedMessageStatusBatchStatusEnum;
}

export const UpdateFailedMessageStatusBatchStatusEnum = {
    Blocked: 'BLOCKED',
    Unprocessed: 'UNPROCESSED',
    Processed: 'PROCESSED',
    Failed: 'FAILED',
    ManualOverride: 'MANUAL_OVERRIDE',
    NoActionRequired: 'NO_ACTION_REQUIRED'
} as const;

export type UpdateFailedMessageStatusBatchStatusEnum = typeof UpdateFailedMessageStatusBatchStatusEnum[keyof typeof UpdateFailedMessageStatusBatchStatusEnum];

/**
 * 
 * @export
 * @interface UpdateFlexTeam
 */
export interface UpdateFlexTeam {
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeam
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeam
     */
    'enforceLeaderSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeam
     */
    'paymentDetailsVisibility'?: UpdateFlexTeamPaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeam
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeam
     */
    'cdaApprover': UpdateFlexTeamCdaApproverEnum;
    /**
     * 
     * @type {TeamCap}
     * @memberof UpdateFlexTeam
     */
    'teamCap'?: TeamCap;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeam
     */
    'permittedTransactionEditors': UpdateFlexTeamPermittedTransactionEditorsEnum;
}

export const UpdateFlexTeamPaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type UpdateFlexTeamPaymentDetailsVisibilityEnum = typeof UpdateFlexTeamPaymentDetailsVisibilityEnum[keyof typeof UpdateFlexTeamPaymentDetailsVisibilityEnum];
export const UpdateFlexTeamCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type UpdateFlexTeamCdaApproverEnum = typeof UpdateFlexTeamCdaApproverEnum[keyof typeof UpdateFlexTeamCdaApproverEnum];
export const UpdateFlexTeamPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type UpdateFlexTeamPermittedTransactionEditorsEnum = typeof UpdateFlexTeamPermittedTransactionEditorsEnum[keyof typeof UpdateFlexTeamPermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface UpdateFlexTeamConfig
 */
export interface UpdateFlexTeamConfig {
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamConfig
     */
    'commissionPlanId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamConfig
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamConfig
     */
    'minTeammates'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamConfig
     */
    'maxTeammates'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamConfig
     */
    'minLeaders'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamConfig
     */
    'maxLeaders'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamConfig
     */
    'paymentDetailsVisibility': UpdateFlexTeamConfigPaymentDetailsVisibilityEnum;
    /**
     * 
     * @type {UpdateLeaderSplitConfig}
     * @memberof UpdateFlexTeamConfig
     */
    'leaderSplitConfig'?: UpdateLeaderSplitConfig;
    /**
     * 
     * @type {UpdateRealCapConfig}
     * @memberof UpdateFlexTeamConfig
     */
    'realCapConfig'?: UpdateRealCapConfig;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamConfig
     */
    'cdaApprover': UpdateFlexTeamConfigCdaApproverEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFlexTeamConfig
     */
    'leaderOverridableProperties'?: Array<UpdateFlexTeamConfigLeaderOverridablePropertiesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeamConfig
     */
    'paidAtClosing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamConfig
     */
    'permittedTransactionEditors': UpdateFlexTeamConfigPermittedTransactionEditorsEnum;
}

export const UpdateFlexTeamConfigPaymentDetailsVisibilityEnum = {
    None: 'NONE',
    Full: 'FULL'
} as const;

export type UpdateFlexTeamConfigPaymentDetailsVisibilityEnum = typeof UpdateFlexTeamConfigPaymentDetailsVisibilityEnum[keyof typeof UpdateFlexTeamConfigPaymentDetailsVisibilityEnum];
export const UpdateFlexTeamConfigCdaApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type UpdateFlexTeamConfigCdaApproverEnum = typeof UpdateFlexTeamConfigCdaApproverEnum[keyof typeof UpdateFlexTeamConfigCdaApproverEnum];
export const UpdateFlexTeamConfigLeaderOverridablePropertiesEnum = {
    LeaderSplitEnforcement: 'LEADER_SPLIT_ENFORCEMENT',
    PaymentDetailsVisibility: 'PAYMENT_DETAILS_VISIBILITY',
    PaidAtClosing: 'PAID_AT_CLOSING',
    CdaApprovalRoles: 'CDA_APPROVAL_ROLES',
    PermittedTransactionEditorRoles: 'PERMITTED_TRANSACTION_EDITOR_ROLES'
} as const;

export type UpdateFlexTeamConfigLeaderOverridablePropertiesEnum = typeof UpdateFlexTeamConfigLeaderOverridablePropertiesEnum[keyof typeof UpdateFlexTeamConfigLeaderOverridablePropertiesEnum];
export const UpdateFlexTeamConfigPermittedTransactionEditorsEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type UpdateFlexTeamConfigPermittedTransactionEditorsEnum = typeof UpdateFlexTeamConfigPermittedTransactionEditorsEnum[keyof typeof UpdateFlexTeamConfigPermittedTransactionEditorsEnum];

/**
 * 
 * @export
 * @interface UpdateFlexTeamLeader
 */
export interface UpdateFlexTeamLeader extends UpdateFlexTeamMember {
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamLeader
     */
    'leaderSplit': number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof UpdateFlexTeamLeader
     */
    'feeSplits'?: Array<FeeSplit>;
}


/**
 * 
 * @export
 * @interface UpdateFlexTeamLeaderMonomorphic
 */
export interface UpdateFlexTeamLeaderMonomorphic {
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeamLeaderMonomorphic
     */
    'leaderSplit': number;
    /**
     * 
     * @type {Array<FeeSplit>}
     * @memberof UpdateFlexTeamLeaderMonomorphic
     */
    'feeSplits'?: Array<FeeSplit>;
}
/**
 * 
 * @export
 * @interface UpdateFlexTeamMember
 */
export interface UpdateFlexTeamMember {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFlexTeamMember
     */
    'roles'?: Array<UpdateFlexTeamMemberRolesEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateFlexTeamMember
     */
    'type'?: UpdateFlexTeamMemberTypeEnum;
}

export const UpdateFlexTeamMemberRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type UpdateFlexTeamMemberRolesEnum = typeof UpdateFlexTeamMemberRolesEnum[keyof typeof UpdateFlexTeamMemberRolesEnum];
export const UpdateFlexTeamMemberTypeEnum = {
    Teammate: 'TEAMMATE',
    Leader: 'LEADER'
} as const;

export type UpdateFlexTeamMemberTypeEnum = typeof UpdateFlexTeamMemberTypeEnum[keyof typeof UpdateFlexTeamMemberTypeEnum];

/**
 * 
 * @export
 * @interface UpdateFlexTeamMemberMonomorphic
 */
export interface UpdateFlexTeamMemberMonomorphic {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFlexTeamMemberMonomorphic
     */
    'roles'?: Array<UpdateFlexTeamMemberMonomorphicRolesEnum>;
    /**
     * 
     * @type {UpdateFlexTeammateMonomorphic}
     * @memberof UpdateFlexTeamMemberMonomorphic
     */
    'teammateUpdate'?: UpdateFlexTeammateMonomorphic;
    /**
     * 
     * @type {UpdateFlexTeamLeaderMonomorphic}
     * @memberof UpdateFlexTeamMemberMonomorphic
     */
    'leaderUpdate'?: UpdateFlexTeamLeaderMonomorphic;
}

export const UpdateFlexTeamMemberMonomorphicRolesEnum = {
    Admin: 'ADMIN'
} as const;

export type UpdateFlexTeamMemberMonomorphicRolesEnum = typeof UpdateFlexTeamMemberMonomorphicRolesEnum[keyof typeof UpdateFlexTeamMemberMonomorphicRolesEnum];

/**
 * 
 * @export
 * @interface UpdateFlexTeammate
 */
export interface UpdateFlexTeammate extends UpdateFlexTeamMember {
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeammate
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof UpdateFlexTeammate
     */
    'leaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof UpdateFlexTeammate
     */
    'feeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof UpdateFlexTeammate
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeammate
     */
    'paysLeaderSplit'?: boolean;
}


/**
 * 
 * @export
 * @interface UpdateFlexTeammateAllOf
 */
export interface UpdateFlexTeammateAllOf {
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeammateAllOf
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof UpdateFlexTeammateAllOf
     */
    'leaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof UpdateFlexTeammateAllOf
     */
    'feeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof UpdateFlexTeammateAllOf
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeammateAllOf
     */
    'paysLeaderSplit'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateFlexTeammateMonomorphic
 */
export interface UpdateFlexTeammateMonomorphic {
    /**
     * 
     * @type {number}
     * @memberof UpdateFlexTeammateMonomorphic
     */
    'realCap'?: number;
    /**
     * 
     * @type {Array<MemberLeaderSplit>}
     * @memberof UpdateFlexTeammateMonomorphic
     */
    'leaderSplits'?: Array<MemberLeaderSplit>;
    /**
     * 
     * @type {Array<MemberFeeSplit>}
     * @memberof UpdateFlexTeammateMonomorphic
     */
    'feeSplits'?: Array<MemberFeeSplit>;
    /**
     * 
     * @type {MemberTeamCap}
     * @memberof UpdateFlexTeammateMonomorphic
     */
    'teamCap'?: MemberTeamCap;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFlexTeammateMonomorphic
     */
    'paysLeaderSplit'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateGroupRequest
 */
export interface UpdateGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGroupRequest
     */
    'groupName': string;
}
/**
 * 
 * @export
 * @interface UpdateJobExecutionRequest
 */
export interface UpdateJobExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateJobExecutionRequest
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateJobExecutionRequest
     */
    'errorLog'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLeaderSplitConfig
 */
export interface UpdateLeaderSplitConfig {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaderSplitConfig
     */
    'enforceSplits': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaderSplitConfig
     */
    'minSplitPercent': number;
}
/**
 * 
 * @export
 * @interface UpdateNoteRequest
 */
export interface UpdateNoteRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequest
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNoteRequest
     */
    'allowableRole': UpdateNoteRequestAllowableRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateNoteRequest
     */
    'followupDate'?: number;
}

export const UpdateNoteRequestAllowableRoleEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type UpdateNoteRequestAllowableRoleEnum = typeof UpdateNoteRequestAllowableRoleEnum[keyof typeof UpdateNoteRequestAllowableRoleEnum];

/**
 * 
 * @export
 * @interface UpdateOfficeRequest
 */
export interface UpdateOfficeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof UpdateOfficeRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {AddressRequest}
     * @memberof UpdateOfficeRequest
     */
    'stateOfficeAddress'?: AddressRequest;
    /**
     * 
     * @type {AddressRequest}
     * @memberof UpdateOfficeRequest
     */
    'branchOfficeAddress'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'faxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'brokerageLicenseNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'licenseExpirationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'transactionType'?: UpdateOfficeRequestTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'designatedBrokerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'managingBrokerId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'paysOtherAgent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'leasePaysOtherAgent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'cdaDocumentTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'invoiceDocumentTitle'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'acceptsEscrow'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'usesTipalti'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'paymentSystem'?: UpdateOfficeRequestPaymentSystemEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'paymentBatchName'?: UpdateOfficeRequestPaymentBatchNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'bankAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'trustAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'operatingAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'walletCommissionAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'walletTrustAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'walletOperatingAccountId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'companyId'?: string;
    /**
     * 
     * @type {Array<TaxValue>}
     * @memberof UpdateOfficeRequest
     */
    'taxes'?: Array<TaxValue>;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'coordinatorFeeType'?: UpdateOfficeRequestCoordinatorFeeTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'paysCommissionFromTrust'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'allowsLease'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'realWithholdsTax'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateOfficeRequest
     */
    'metabaseDashboardId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'taxPaidFromCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'allowsLeaseTransactions'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     * @deprecated
     */
    'additionalCommission'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     * @deprecated
     */
    'rebate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     * @deprecated
     */
    'transactionCoordinatorFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     * @deprecated
     */
    'reimbursementFee'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOfficeRequest
     * @deprecated
     */
    'mlsFee'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOfficeRequest
     */
    'additionalFeesAndRebates'?: Array<UpdateOfficeRequestAdditionalFeesAndRebatesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOfficeRequest
     */
    'voiceCallPriorityOrder'?: Array<UpdateOfficeRequestVoiceCallPriorityOrderEnum>;
    /**
     * 
     * @type {Array<VoiceCallHoldTime>}
     * @memberof UpdateOfficeRequest
     */
    'voiceCallHoldTimeSecs'?: Array<VoiceCallHoldTime>;
}

export const UpdateOfficeRequestTransactionTypeEnum = {
    SplitCheck: 'SPLIT_CHECK',
    SingleCheck: 'SINGLE_CHECK'
} as const;

export type UpdateOfficeRequestTransactionTypeEnum = typeof UpdateOfficeRequestTransactionTypeEnum[keyof typeof UpdateOfficeRequestTransactionTypeEnum];
export const UpdateOfficeRequestPaymentSystemEnum = {
    Tipalti: 'TIPALTI',
    MicrosoftDynamics: 'MICROSOFT_DYNAMICS',
    MicrosoftDynamicsPaymentJournal: 'MICROSOFT_DYNAMICS_PAYMENT_JOURNAL',
    Wallet: 'WALLET'
} as const;

export type UpdateOfficeRequestPaymentSystemEnum = typeof UpdateOfficeRequestPaymentSystemEnum[keyof typeof UpdateOfficeRequestPaymentSystemEnum];
export const UpdateOfficeRequestPaymentBatchNameEnum = {
    Rezen: 'REZEN',
    Unit: 'UNIT',
    RezenRi: 'REZEN_RI',
    UnitRi: 'UNIT_RI',
    RezenVa: 'REZEN_VA',
    UnitVa: 'UNIT_VA',
    RAbCt: 'R_AB_CT',
    RAbOp: 'R_AB_OP',
    RAbTrust: 'R_AB_TRUST',
    RBcCt: 'R_BC_CT',
    RBcOp: 'R_BC_OP',
    RBcTrust: 'R_BC_TRUST',
    RMbCt: 'R_MB_CT',
    RMbOp: 'R_MB_OP',
    RMbTrust: 'R_MB_TRUST',
    ROnCt: 'R_ON_CT',
    ROnOp: 'R_ON_OP',
    ROnTrust: 'R_ON_TRUST'
} as const;

export type UpdateOfficeRequestPaymentBatchNameEnum = typeof UpdateOfficeRequestPaymentBatchNameEnum[keyof typeof UpdateOfficeRequestPaymentBatchNameEnum];
export const UpdateOfficeRequestCoordinatorFeeTypeEnum = {
    NetCommission: 'NET_COMMISSION',
    GrossCommission: 'GROSS_COMMISSION'
} as const;

export type UpdateOfficeRequestCoordinatorFeeTypeEnum = typeof UpdateOfficeRequestCoordinatorFeeTypeEnum[keyof typeof UpdateOfficeRequestCoordinatorFeeTypeEnum];
export const UpdateOfficeRequestAdditionalFeesAndRebatesEnum = {
    AdditionalCommission: 'ADDITIONAL_COMMISSION',
    Rebate: 'REBATE',
    TransactionCoordinatorFee: 'TRANSACTION_COORDINATOR_FEE',
    ReimbursementFee: 'REIMBURSEMENT_FEE',
    MlsFee: 'MLS_FEE',
    OneRealImpactFund: 'ONE_REAL_IMPACT_FUND'
} as const;

export type UpdateOfficeRequestAdditionalFeesAndRebatesEnum = typeof UpdateOfficeRequestAdditionalFeesAndRebatesEnum[keyof typeof UpdateOfficeRequestAdditionalFeesAndRebatesEnum];
export const UpdateOfficeRequestVoiceCallPriorityOrderEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type UpdateOfficeRequestVoiceCallPriorityOrderEnum = typeof UpdateOfficeRequestVoiceCallPriorityOrderEnum[keyof typeof UpdateOfficeRequestVoiceCallPriorityOrderEnum];

/**
 * 
 * @export
 * @interface UpdateOfficeVoiceSettingsRequest
 */
export interface UpdateOfficeVoiceSettingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOfficeVoiceSettingsRequest
     */
    'voiceCallPriorityOrder'?: Array<UpdateOfficeVoiceSettingsRequestVoiceCallPriorityOrderEnum>;
    /**
     * 
     * @type {Array<VoiceCallHoldTime>}
     * @memberof UpdateOfficeVoiceSettingsRequest
     */
    'voiceCallHoldTimeSecs'?: Array<VoiceCallHoldTime>;
}

export const UpdateOfficeVoiceSettingsRequestVoiceCallPriorityOrderEnum = {
    DesignatedBroker: 'DESIGNATED_BROKER',
    RegionalBroker: 'REGIONAL_BROKER',
    ManagingBroker: 'MANAGING_BROKER',
    SettlementSpecialist: 'SETTLEMENT_SPECIALIST',
    FinanceSpecialist: 'FINANCE_SPECIALIST',
    ContractSpecialist: 'CONTRACT_SPECIALIST',
    AgentExperience: 'AGENT_EXPERIENCE',
    None: 'NONE'
} as const;

export type UpdateOfficeVoiceSettingsRequestVoiceCallPriorityOrderEnum = typeof UpdateOfficeVoiceSettingsRequestVoiceCallPriorityOrderEnum[keyof typeof UpdateOfficeVoiceSettingsRequestVoiceCallPriorityOrderEnum];

/**
 * 
 * @export
 * @interface UpdatePaymentDetailsInvalidRequest
 */
export interface UpdatePaymentDetailsInvalidRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentDetailsInvalidRequest
     */
    'invalid'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdatePaymentMethod
 */
export interface UpdatePaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'nickname': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'bankName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'accountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'usRoutingNumber': string;
    /**
     * 
     * @type {CanadaRoutingNumber}
     * @memberof UpdatePaymentMethod
     */
    'canadaRoutingNumber'?: CanadaRoutingNumber;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'type': UpdatePaymentMethodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'purpose': UpdatePaymentMethodPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'status': UpdatePaymentMethodStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethod
     */
    'typeValue'?: UpdatePaymentMethodTypeValueEnum;
}

export const UpdatePaymentMethodTypeEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type UpdatePaymentMethodTypeEnum = typeof UpdatePaymentMethodTypeEnum[keyof typeof UpdatePaymentMethodTypeEnum];
export const UpdatePaymentMethodPurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type UpdatePaymentMethodPurposeEnum = typeof UpdatePaymentMethodPurposeEnum[keyof typeof UpdatePaymentMethodPurposeEnum];
export const UpdatePaymentMethodStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type UpdatePaymentMethodStatusEnum = typeof UpdatePaymentMethodStatusEnum[keyof typeof UpdatePaymentMethodStatusEnum];
export const UpdatePaymentMethodTypeValueEnum = {
    Checking: 'CHECKING',
    Savings: 'SAVINGS'
} as const;

export type UpdatePaymentMethodTypeValueEnum = typeof UpdatePaymentMethodTypeValueEnum[keyof typeof UpdatePaymentMethodTypeValueEnum];

/**
 * 
 * @export
 * @interface UpdatePaymentMethodPurposeRequest
 */
export interface UpdatePaymentMethodPurposeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodPurposeRequest
     */
    'purpose': UpdatePaymentMethodPurposeRequestPurposeEnum;
}

export const UpdatePaymentMethodPurposeRequestPurposeEnum = {
    Transaction: 'TRANSACTION',
    Revshare: 'REVSHARE',
    Any: 'ANY',
    None: 'NONE'
} as const;

export type UpdatePaymentMethodPurposeRequestPurposeEnum = typeof UpdatePaymentMethodPurposeRequestPurposeEnum[keyof typeof UpdatePaymentMethodPurposeRequestPurposeEnum];

/**
 * 
 * @export
 * @interface UpdatePersonalAccountRequest
 */
export interface UpdatePersonalAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePersonalAccountRequest
     */
    'personalAccountId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRealCapConfig
 */
export interface UpdateRealCapConfig {
    /**
     * 
     * @type {number}
     * @memberof UpdateRealCapConfig
     */
    'leaderCap': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateRealCapConfig
     */
    'excludedMemberCaps'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateSponsorsRequest
 */
export interface UpdateSponsorsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSponsorsRequest
     */
    'sponsorCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateTaxForm
 */
export interface UpdateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxForm
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxForm
     */
    'type'?: UpdateTaxFormTypeEnum;
}

export const UpdateTaxFormTypeEnum = {
    W9: 'W9',
    W8Eci: 'W8ECI',
    W8Ben: 'W8BEN',
    Canada: 'CANADA',
    Legacy: 'LEGACY'
} as const;

export type UpdateTaxFormTypeEnum = typeof UpdateTaxFormTypeEnum[keyof typeof UpdateTaxFormTypeEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormCanada
 */
export interface UpdateTaxFormCanada extends UpdateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormCanada
     */
    'taxpayerName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormCanada
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormCanada
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormCanada
     */
    'residenceAddress': Address;
}


/**
 * 
 * @export
 * @interface UpdateTaxFormCanadaAllOf
 */
export interface UpdateTaxFormCanadaAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormCanadaAllOf
     */
    'taxpayerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormCanadaAllOf
     */
    'businessName'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormCanadaAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormCanadaAllOf
     */
    'residenceAddress'?: Address;
}
/**
 * 
 * @export
 * @interface UpdateTaxFormW8BEN
 */
export interface UpdateTaxFormW8BEN extends UpdateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BEN
     */
    'name': string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW8BEN
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BEN
     */
    'foreignTaxId': string;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8BEN
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8BEN
     */
    'mailingAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BEN
     */
    'countryOfIncorporation': UpdateTaxFormW8BENCountryOfIncorporationEnum;
}

export const UpdateTaxFormW8BENCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type UpdateTaxFormW8BENCountryOfIncorporationEnum = typeof UpdateTaxFormW8BENCountryOfIncorporationEnum[keyof typeof UpdateTaxFormW8BENCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormW8BENAllOf
 */
export interface UpdateTaxFormW8BENAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'mailingAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8BENAllOf
     */
    'countryOfIncorporation'?: UpdateTaxFormW8BENAllOfCountryOfIncorporationEnum;
}

export const UpdateTaxFormW8BENAllOfCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type UpdateTaxFormW8BENAllOfCountryOfIncorporationEnum = typeof UpdateTaxFormW8BENAllOfCountryOfIncorporationEnum[keyof typeof UpdateTaxFormW8BENAllOfCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormW8ECI
 */
export interface UpdateTaxFormW8ECI extends UpdateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'taxEntityType': UpdateTaxFormW8ECITaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW8ECI
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'foreignTaxId': string;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8ECI
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8ECI
     */
    'businessAddress': Address;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECI
     */
    'countryOfIncorporation': UpdateTaxFormW8ECICountryOfIncorporationEnum;
}

export const UpdateTaxFormW8ECITaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type UpdateTaxFormW8ECITaxEntityTypeEnum = typeof UpdateTaxFormW8ECITaxEntityTypeEnum[keyof typeof UpdateTaxFormW8ECITaxEntityTypeEnum];
export const UpdateTaxFormW8ECICountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type UpdateTaxFormW8ECICountryOfIncorporationEnum = typeof UpdateTaxFormW8ECICountryOfIncorporationEnum[keyof typeof UpdateTaxFormW8ECICountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormW8ECIAllOf
 */
export interface UpdateTaxFormW8ECIAllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'taxEntityType'?: UpdateTaxFormW8ECIAllOfTaxEntityTypeEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'foreignTaxId'?: string;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'businessAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'specifyIncome'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW8ECIAllOf
     */
    'countryOfIncorporation'?: UpdateTaxFormW8ECIAllOfCountryOfIncorporationEnum;
}

export const UpdateTaxFormW8ECIAllOfTaxEntityTypeEnum = {
    Individual: 'INDIVIDUAL',
    Partnership: 'PARTNERSHIP',
    Corporation: 'CORPORATION',
    Government: 'GOVERNMENT',
    PrivateFoundation: 'PRIVATE_FOUNDATION',
    SimpleTrust: 'SIMPLE_TRUST',
    ComplexTrust: 'COMPLEX_TRUST',
    GrantorTrust: 'GRANTOR_TRUST',
    InternationalOrganization: 'INTERNATIONAL_ORGANIZATION',
    Estate: 'ESTATE',
    CentralBankOfIssue: 'CENTRAL_BANK_OF_ISSUE',
    TaxExemptOrganization: 'TAX_EXEMPT_ORGANIZATION'
} as const;

export type UpdateTaxFormW8ECIAllOfTaxEntityTypeEnum = typeof UpdateTaxFormW8ECIAllOfTaxEntityTypeEnum[keyof typeof UpdateTaxFormW8ECIAllOfTaxEntityTypeEnum];
export const UpdateTaxFormW8ECIAllOfCountryOfIncorporationEnum = {
    Undefined: 'UNDEFINED',
    Ac: 'AC',
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bl: 'BL',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Bq: 'BQ',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bu: 'BU',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cp: 'CP',
    Cr: 'CR',
    Cs: 'CS',
    Cu: 'CU',
    Cv: 'CV',
    Cw: 'CW',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dg: 'DG',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ea: 'EA',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Eu: 'EU',
    Ez: 'EZ',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Fx: 'FX',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Ic: 'IC',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mf: 'MF',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nt: 'NT',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sf: 'SF',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    Ss: 'SS',
    St: 'ST',
    Su: 'SU',
    Sv: 'SV',
    Sx: 'SX',
    Sy: 'SY',
    Sz: 'SZ',
    Ta: 'TA',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tp: 'TP',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Uk: 'UK',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Xi: 'XI',
    Xu: 'XU',
    Xk: 'XK',
    Ye: 'YE',
    Yt: 'YT',
    Yu: 'YU',
    Za: 'ZA',
    Zm: 'ZM',
    Zr: 'ZR',
    Zw: 'ZW'
} as const;

export type UpdateTaxFormW8ECIAllOfCountryOfIncorporationEnum = typeof UpdateTaxFormW8ECIAllOfCountryOfIncorporationEnum[keyof typeof UpdateTaxFormW8ECIAllOfCountryOfIncorporationEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormW9
 */
export interface UpdateTaxFormW9 extends UpdateTaxForm {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9
     */
    'taxClassification': UpdateTaxFormW9TaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW9
     */
    'nationalIds': Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW9
     */
    'residenceAddress': Address;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaxFormW9
     */
    'electronicDelivery'?: boolean;
}

export const UpdateTaxFormW9TaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type UpdateTaxFormW9TaxClassificationEnum = typeof UpdateTaxFormW9TaxClassificationEnum[keyof typeof UpdateTaxFormW9TaxClassificationEnum];

/**
 * 
 * @export
 * @interface UpdateTaxFormW9AllOf
 */
export interface UpdateTaxFormW9AllOf {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9AllOf
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9AllOf
     */
    'disregardedEntityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaxFormW9AllOf
     */
    'taxClassification'?: UpdateTaxFormW9AllOfTaxClassificationEnum;
    /**
     * 
     * @type {Array<NationalIdentification>}
     * @memberof UpdateTaxFormW9AllOf
     */
    'nationalIds'?: Array<NationalIdentification>;
    /**
     * 
     * @type {Address}
     * @memberof UpdateTaxFormW9AllOf
     */
    'residenceAddress'?: Address;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaxFormW9AllOf
     */
    'electronicDelivery'?: boolean;
}

export const UpdateTaxFormW9AllOfTaxClassificationEnum = {
    Individual: 'INDIVIDUAL',
    IndividualLlc: 'INDIVIDUAL_LLC',
    CCorporation: 'C_CORPORATION',
    CCorporationLlc: 'C_CORPORATION_LLC',
    SCorporation: 'S_CORPORATION',
    SCorporationLlc: 'S_CORPORATION_LLC',
    Partnership: 'PARTNERSHIP',
    PartnershipLlc: 'PARTNERSHIP_LLC',
    Estate: 'ESTATE',
    Exempt: 'EXEMPT'
} as const;

export type UpdateTaxFormW9AllOfTaxClassificationEnum = typeof UpdateTaxFormW9AllOfTaxClassificationEnum[keyof typeof UpdateTaxFormW9AllOfTaxClassificationEnum];

/**
 * 
 * @export
 * @interface UpdateTeamInstantPaymentEligibleRequest
 */
export interface UpdateTeamInstantPaymentEligibleRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTeamInstantPaymentEligibleRequest
     */
    'instantPaymentEligible'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTeamMemberCommissionSplitRequest
 */
export interface UpdateTeamMemberCommissionSplitRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateTeamMemberCommissionSplitRequest
     */
    'commissionSplit'?: number;
}
/**
 * 
 * @export
 * @interface UpdateTeamMemberRequest
 */
export interface UpdateTeamMemberRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamMemberRequest
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTeamMemberRequest
     */
    'commissionSplit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTeamMemberRequest
     */
    'roles'?: Array<UpdateTeamMemberRequestRolesEnum>;
}

export const UpdateTeamMemberRequestRolesEnum = {
    Member: 'MEMBER',
    Leader: 'LEADER',
    Admin: 'ADMIN'
} as const;

export type UpdateTeamMemberRequestRolesEnum = typeof UpdateTeamMemberRequestRolesEnum[keyof typeof UpdateTeamMemberRequestRolesEnum];

/**
 * 
 * @export
 * @interface UpdateTeamMembersRequest
 */
export interface UpdateTeamMembersRequest {
    /**
     * 
     * @type {Array<CreateTeamMemberRequest>}
     * @memberof UpdateTeamMembersRequest
     */
    'createRequests'?: Array<CreateTeamMemberRequest>;
    /**
     * 
     * @type {Array<UpdateTeamMemberRequest>}
     * @memberof UpdateTeamMembersRequest
     */
    'updateRequests'?: Array<UpdateTeamMemberRequest>;
    /**
     * 
     * @type {Array<TransferAgentBetweenTeamsRequest>}
     * @memberof UpdateTeamMembersRequest
     */
    'transferAgentBetweenTeamsRequests'?: Array<TransferAgentBetweenTeamsRequest>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTeamMembersRequest
     */
    'userIdsToDelete'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateTeamPaidAtClosingRequest
 */
export interface UpdateTeamPaidAtClosingRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTeamPaidAtClosingRequest
     */
    'paidAtClosing'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateTeamRequest
 */
export interface UpdateTeamRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamRequest
     */
    'commissionDocumentApprover'?: UpdateTeamRequestCommissionDocumentApproverEnum;
}

export const UpdateTeamRequestCommissionDocumentApproverEnum = {
    TeamLeader: 'TEAM_LEADER',
    TeamLeaderAndTeamAdmins: 'TEAM_LEADER_AND_TEAM_ADMINS',
    AnyTeamMembers: 'ANY_TEAM_MEMBERS'
} as const;

export type UpdateTeamRequestCommissionDocumentApproverEnum = typeof UpdateTeamRequestCommissionDocumentApproverEnum[keyof typeof UpdateTeamRequestCommissionDocumentApproverEnum];

/**
 * 
 * @export
 * @interface UpdateTransactionCoordinatorConnectionAccessRequest
 */
export interface UpdateTransactionCoordinatorConnectionAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionCoordinatorConnectionAccessRequest
     */
    'accessType': UpdateTransactionCoordinatorConnectionAccessRequestAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransactionCoordinatorConnectionAccessRequest
     */
    'accessDate'?: string;
}

export const UpdateTransactionCoordinatorConnectionAccessRequestAccessTypeEnum = {
    AllListingsAndTransactions: 'ALL_LISTINGS_AND_TRANSACTIONS',
    CreatedAfterSpecificDate: 'CREATED_AFTER_SPECIFIC_DATE',
    CreatedByOrAssignedTo: 'CREATED_BY_OR_ASSIGNED_TO'
} as const;

export type UpdateTransactionCoordinatorConnectionAccessRequestAccessTypeEnum = typeof UpdateTransactionCoordinatorConnectionAccessRequestAccessTypeEnum[keyof typeof UpdateTransactionCoordinatorConnectionAccessRequestAccessTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'qualiaConnectUserId'?: string;
}
/**
 * 
 * @export
 * @interface UpdateWillBeneficiaryRequest
 */
export interface UpdateWillBeneficiaryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'lastName': string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'address'?: AddressRequest;
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateWillBeneficiaryRequest
     * @deprecated
     */
    'typedSignature'?: string;
}
/**
 * 
 * @export
 * @interface UserAvailabilityRequest
 */
export interface UserAvailabilityRequest {
    /**
     * 
     * @type {Array<DayHourRange>}
     * @memberof UserAvailabilityRequest
     */
    'officeSchedule'?: Array<DayHourRange>;
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof UserAvailabilityRequest
     */
    'outOfOffice'?: Array<DateRange>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAvailabilityRequest
     */
    'doNotDisturb'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAvailabilityRequest
     */
    'timeZone': string;
}
/**
 * 
 * @export
 * @interface UserAvailabilityResponse
 */
export interface UserAvailabilityResponse {
    /**
     * 
     * @type {Array<DayHourRange>}
     * @memberof UserAvailabilityResponse
     */
    'officeSchedule'?: Array<DayHourRange>;
    /**
     * 
     * @type {Array<DateRange>}
     * @memberof UserAvailabilityResponse
     */
    'outOfOffice'?: Array<DateRange>;
    /**
     * 
     * @type {boolean}
     * @memberof UserAvailabilityResponse
     */
    'doNotDisturb'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserAvailabilityResponse
     */
    'timeZone': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAvailabilityResponse
     */
    'available'?: boolean;
}
/**
 * 
 * @export
 * @interface UserDetailsForComment
 */
export interface UserDetailsForComment {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsForComment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsForComment
     */
    'keymakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsForComment
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsForComment
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsForComment
     */
    'avatar'?: string;
}
/**
 * 
 * @export
 * @interface UserIdsRequest
 */
export interface UserIdsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserIdsRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'joinRealEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'stripeCustomerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'intercomId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'personalEmailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'personalPhoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface UserJwtInfoBulkResponse
 */
export interface UserJwtInfoBulkResponse {
    /**
     * 
     * @type {Array<UserJwtInfoResponse>}
     * @memberof UserJwtInfoBulkResponse
     */
    'users'?: Array<UserJwtInfoResponse>;
}
/**
 * 
 * @export
 * @interface UserJwtInfoResponse
 */
export interface UserJwtInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof UserJwtInfoResponse
     */
    'yentaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserJwtInfoResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {EmailAddress}
     * @memberof UserJwtInfoResponse
     */
    'emailAddress'?: EmailAddress;
}
/**
 * 
 * @export
 * @interface UserListResponse
 */
export interface UserListResponse {
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof UserListResponse
     */
    'users'?: Array<UserResponse>;
}
/**
 * 
 * @export
 * @interface UserPatchRequest
 */
export interface UserPatchRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UserPatchRequest
     */
    'optedIntoSms'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserPatchRequest
     */
    'voiceVectorDisclaimerAccepted'?: boolean;
    /**
     * 
     * @type {UserAvailabilityRequest}
     * @memberof UserPatchRequest
     */
    'userAvailability'?: UserAvailabilityRequest;
}
/**
 * 
 * @export
 * @interface UserPreviewResponse
 */
export interface UserPreviewResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPreviewResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPreviewResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'type'?: UserPreviewResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'agentStatus'?: UserPreviewResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'agentAccountCountry'?: UserPreviewResponseAgentAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'anniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'lastAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'nextAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPreviewResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof UserPreviewResponse
     */
    'divisions'?: Array<DivisionResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPreviewResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {UserAvailabilityResponse}
     * @memberof UserPreviewResponse
     */
    'availability'?: UserAvailabilityResponse;
    /**
     * 
     * @type {boolean}
     * @memberof UserPreviewResponse
     */
    'optedIntoSms'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserPreviewResponse
     */
    'voiceVectorDisclaimerAcceptedAt'?: number;
}

export const UserPreviewResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type UserPreviewResponseTypeEnum = typeof UserPreviewResponseTypeEnum[keyof typeof UserPreviewResponseTypeEnum];
export const UserPreviewResponseAgentStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type UserPreviewResponseAgentStatusEnum = typeof UserPreviewResponseAgentStatusEnum[keyof typeof UserPreviewResponseAgentStatusEnum];
export const UserPreviewResponseAgentAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type UserPreviewResponseAgentAccountCountryEnum = typeof UserPreviewResponseAgentAccountCountryEnum[keyof typeof UserPreviewResponseAgentAccountCountryEnum];

/**
 * 
 * @export
 * @interface UserPrincipal
 */
export interface UserPrincipal {
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'yentaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrincipal
     */
    'roles'?: Array<UserPrincipalRolesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserPrincipal
     */
    'authRoles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserPrincipal
     */
    'email'?: string;
}

export const UserPrincipalRolesEnum = {
    User: 'ROLE_USER',
    Admin: 'ROLE_ADMIN'
} as const;

export type UserPrincipalRolesEnum = typeof UserPrincipalRolesEnum[keyof typeof UserPrincipalRolesEnum];

/**
 * 
 * @export
 * @interface UserPublicInfo
 */
export interface UserPublicInfo {
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicInfo
     */
    'slug'?: string;
}
/**
 * 
 * @export
 * @interface UserPublicInfosResponse
 */
export interface UserPublicInfosResponse {
    /**
     * 
     * @type {Array<UserPublicInfo>}
     * @memberof UserPublicInfosResponse
     */
    'userInfos'?: Array<UserPublicInfo>;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'createdAt'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'updatedAt'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'type'?: UserResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'company'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'skySlopeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'skySlopePublicId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'keyMakerId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'agentStatus'?: UserResponseAgentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'agentAccountCountry'?: UserResponseAgentAccountCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'about'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'personalWebsiteURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'facebookURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'youtubeURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'twitterURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'instagramURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'clubhouseURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'linkedInURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'zillowURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'yelpURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'googleBusinessProfileURL'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'joinDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'anniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'lastAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'nextAnniversaryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'paymentInstructions'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'tipaltiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'qualiaConnectUserId'?: string;
    /**
     * 
     * @type {Array<DivisionResponse>}
     * @memberof UserResponse
     */
    'divisions'?: Array<DivisionResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    'administrativeAreaIds'?: Array<string>;
    /**
     * 
     * @type {UserAvailabilityResponse}
     * @memberof UserResponse
     */
    'availability'?: UserAvailabilityResponse;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'optedIntoSms'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'voiceVectorDisclaimerAcceptedAt'?: number;
    /**
     * 
     * @type {Array<AddressResponse>}
     * @memberof UserResponse
     */
    'addresses'?: Array<AddressResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'notifyLeadsInSMS'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'receivesLeadsOnlyFromZipcodes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'receivesTransactionEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'receivesTransactionPushNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'receivesPaymentEmailNotifications'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'receivesPaymentPushNotifications'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponse
     */
    'competencies'?: Array<UserResponseCompetenciesEnum>;
    /**
     * 
     * @type {Array<LicenseResponse>}
     * @memberof UserResponse
     */
    'licenses'?: Array<LicenseResponse>;
    /**
     * 
     * @type {PlanMembershipResponse}
     * @memberof UserResponse
     */
    'planMembership'?: PlanMembershipResponse;
    /**
     * 
     * @type {Array<ApplicationResponse>}
     * @memberof UserResponse
     */
    'applications'?: Array<ApplicationResponse>;
    /**
     * 
     * @type {Array<BoardPreviewResponse>}
     * @memberof UserResponse
     */
    'boards'?: Array<BoardPreviewResponse>;
    /**
     * 
     * @type {Array<MlsPreviewResponse>}
     * @memberof UserResponse
     */
    'mls'?: Array<MlsPreviewResponse>;
    /**
     * 
     * @type {Array<OfficePreviewResponse>}
     * @memberof UserResponse
     */
    'offices'?: Array<OfficePreviewResponse>;
    /**
     * 
     * @type {Array<AgentTeamMembershipResponse>}
     * @memberof UserResponse
     */
    'teamMemberships'?: Array<AgentTeamMembershipResponse>;
    /**
     * 
     * @type {Array<OnboardingResponse>}
     * @memberof UserResponse
     */
    'onboardings'?: Array<OnboardingResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     * @deprecated
     */
    'sponsorId'?: string;
    /**
     * 
     * @type {Array<SponsorResponse>}
     * @memberof UserResponse
     */
    'sponsors'?: Array<SponsorResponse>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'dropboxId'?: string;
}

export const UserResponseTypeEnum = {
    Agent: 'AGENT',
    Broker: 'BROKER',
    LoanOfficer: 'LOAN_OFFICER',
    Generic: 'GENERIC'
} as const;

export type UserResponseTypeEnum = typeof UserResponseTypeEnum[keyof typeof UserResponseTypeEnum];
export const UserResponseAgentStatusEnum = {
    Candidate: 'CANDIDATE',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Rejected: 'REJECTED'
} as const;

export type UserResponseAgentStatusEnum = typeof UserResponseAgentStatusEnum[keyof typeof UserResponseAgentStatusEnum];
export const UserResponseAgentAccountCountryEnum = {
    UnitedStates: 'UNITED_STATES',
    Canada: 'CANADA'
} as const;

export type UserResponseAgentAccountCountryEnum = typeof UserResponseAgentAccountCountryEnum[keyof typeof UserResponseAgentAccountCountryEnum];
export const UserResponseCompetenciesEnum = {
    Residential: 'RESIDENTIAL',
    Commercial: 'COMMERCIAL',
    Buyers: 'BUYERS',
    Sellers: 'SELLERS',
    Investors: 'INVESTORS',
    FarmAndRanch: 'FARM_AND_RANCH',
    ProvidingReferrals: 'PROVIDING_REFERRALS',
    ReceivingReferrals: 'RECEIVING_REFERRALS'
} as const;

export type UserResponseCompetenciesEnum = typeof UserResponseCompetenciesEnum[keyof typeof UserResponseCompetenciesEnum];

/**
 * 
 * @export
 * @interface ValidateGenericTeamInvitationResponse
 */
export interface ValidateGenericTeamInvitationResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateGenericTeamInvitationResponse
     */
    'valid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ValidateGenericTeamInvitationResponse
     */
    'reason'?: ValidateGenericTeamInvitationResponseReasonEnum;
    /**
     * 
     * @type {GenericTeamInvitationResponse}
     * @memberof ValidateGenericTeamInvitationResponse
     */
    'invitation'?: GenericTeamInvitationResponse;
}

export const ValidateGenericTeamInvitationResponseReasonEnum = {
    InvitationExpired: 'INVITATION_EXPIRED',
    InactiveTeam: 'INACTIVE_TEAM'
} as const;

export type ValidateGenericTeamInvitationResponseReasonEnum = typeof ValidateGenericTeamInvitationResponseReasonEnum[keyof typeof ValidateGenericTeamInvitationResponseReasonEnum];

/**
 * 
 * @export
 * @interface VoiceCallHoldTime
 */
export interface VoiceCallHoldTime {
    /**
     * 
     * @type {string}
     * @memberof VoiceCallHoldTime
     */
    'group': VoiceCallHoldTimeGroupEnum;
    /**
     * 
     * @type {number}
     * @memberof VoiceCallHoldTime
     */
    'holdTimeSecs'?: number;
}

export const VoiceCallHoldTimeGroupEnum = {
    BrokerTeam: 'BROKER_TEAM',
    FinanceTeam: 'FINANCE_TEAM',
    SupportTeam: 'SUPPORT_TEAM',
    TransactionTeam: 'TRANSACTION_TEAM'
} as const;

export type VoiceCallHoldTimeGroupEnum = typeof VoiceCallHoldTimeGroupEnum[keyof typeof VoiceCallHoldTimeGroupEnum];

/**
 * 
 * @export
 * @interface WaiveApplicationFeesRequest
 */
export interface WaiveApplicationFeesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof WaiveApplicationFeesRequest
     */
    'applicationIds': Array<string>;
}
/**
 * 
 * @export
 * @interface WillBeneficiaryAndTypeResponse
 */
export interface WillBeneficiaryAndTypeResponse {
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryAndTypeResponse
     */
    'type': WillBeneficiaryAndTypeResponseTypeEnum;
    /**
     * 
     * @type {WillBeneficiaryResponse}
     * @memberof WillBeneficiaryAndTypeResponse
     */
    'individualBeneficiary'?: WillBeneficiaryResponse;
    /**
     * 
     * @type {WillBeneficiaryTrustResponse}
     * @memberof WillBeneficiaryAndTypeResponse
     */
    'trustBeneficiary'?: WillBeneficiaryTrustResponse;
}

export const WillBeneficiaryAndTypeResponseTypeEnum = {
    None: 'NONE',
    Individual: 'INDIVIDUAL',
    Trust: 'TRUST'
} as const;

export type WillBeneficiaryAndTypeResponseTypeEnum = typeof WillBeneficiaryAndTypeResponseTypeEnum[keyof typeof WillBeneficiaryAndTypeResponseTypeEnum];

/**
 * 
 * @export
 * @interface WillBeneficiaryOnboardingRequest
 */
export interface WillBeneficiaryOnboardingRequest {
    /**
     * 
     * @type {SignatureDisplayContextDto}
     * @memberof WillBeneficiaryOnboardingRequest
     */
    'displayContext'?: SignatureDisplayContextDto;
}
/**
 * 
 * @export
 * @interface WillBeneficiaryResponse
 */
export interface WillBeneficiaryResponse {
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'lastName'?: string;
    /**
     * 
     * @type {AddressResponse}
     * @memberof WillBeneficiaryResponse
     */
    'address'?: AddressResponse;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryResponse
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface WillBeneficiaryTrustRequest
 */
export interface WillBeneficiaryTrustRequest {
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryTrustRequest
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface WillBeneficiaryTrustResponse
 */
export interface WillBeneficiaryTrustResponse {
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryTrustResponse
     */
    'agentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof WillBeneficiaryTrustResponse
     */
    'text'?: string;
}
/**
 * 
 * @export
 * @interface WorkvivoJwtResponse
 */
export interface WorkvivoJwtResponse {
    /**
     * 
     * @type {string}
     * @memberof WorkvivoJwtResponse
     */
    'jwt'?: string;
}
/**
 * 
 * @export
 * @interface WrapperBaseMessage
 */
export interface WrapperBaseMessage {
    /**
     * 
     * @type {BaseMessage}
     * @memberof WrapperBaseMessage
     */
    'message'?: BaseMessage;
    /**
     * 
     * @type {string}
     * @memberof WrapperBaseMessage
     */
    '_class'?: string;
}

/**
 * ActivityTrackerControllerApi - axios parameter creator
 * @export
 */
export const ActivityTrackerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Increments page counter
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordActivity: async (xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/activity-tracker/increment-page-visit-counter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName !== undefined && xRealAppName !== null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityTrackerControllerApi - functional programming interface
 * @export
 */
export const ActivityTrackerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityTrackerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Increments page counter
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordActivity(xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordActivity(xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityTrackerControllerApi - factory interface
 * @export
 */
export const ActivityTrackerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityTrackerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Increments page counter
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordActivity(xRealAppName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.recordActivity(xRealAppName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityTrackerControllerApi - object-oriented interface
 * @export
 * @class ActivityTrackerControllerApi
 * @extends {BaseAPI}
 */
export class ActivityTrackerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Increments page counter
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityTrackerControllerApi
     */
    public recordActivity(xRealAppName?: string, options?: AxiosRequestConfig) {
        return ActivityTrackerControllerApiFp(this.configuration).recordActivity(xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdministrativeAreaControllerApi - axios parameter creator
 * @export
 */
export const AdministrativeAreaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search administrative areas
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>} [sortBy] 
         * @param {string} [id] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdministrativeAreas: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>, id?: string, country?: Array<'UNITED_STATES' | 'CANADA'>, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/administrative-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdministrativeAreaControllerApi - functional programming interface
 * @export
 */
export const AdministrativeAreaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdministrativeAreaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search administrative areas
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>} [sortBy] 
         * @param {string} [id] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAdministrativeAreas(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>, id?: string, country?: Array<'UNITED_STATES' | 'CANADA'>, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAdministrativeAreaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAdministrativeAreas(pageNumber, pageSize, sortDirection, sortBy, id, country, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdministrativeAreaControllerApi - factory interface
 * @export
 */
export const AdministrativeAreaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdministrativeAreaControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search administrative areas
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>} [sortBy] 
         * @param {string} [id] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAdministrativeAreas(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>, id?: string, country?: Array<'UNITED_STATES' | 'CANADA'>, searchText?: string, options?: any): AxiosPromise<GenericSearchResponseAdministrativeAreaResponse> {
            return localVarFp.searchAdministrativeAreas(pageNumber, pageSize, sortDirection, sortBy, id, country, searchText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdministrativeAreaControllerApi - object-oriented interface
 * @export
 * @class AdministrativeAreaControllerApi
 * @extends {BaseAPI}
 */
export class AdministrativeAreaControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search administrative areas
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>} [sortBy] 
     * @param {string} [id] 
     * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdministrativeAreaControllerApi
     */
    public searchAdministrativeAreas(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'COUNTRY' | 'LICENSE_TRANSFER_FEE'>, id?: string, country?: Array<'UNITED_STATES' | 'CANADA'>, searchText?: string, options?: AxiosRequestConfig) {
        return AdministrativeAreaControllerApiFp(this.configuration).searchAdministrativeAreas(pageNumber, pageSize, sortDirection, sortBy, id, country, searchText, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentControllerApi - axios parameter creator
 * @export
 */
export const AgentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add an office to an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOffice: async (id: string, officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addOffice', 'id', id)
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('addOffice', 'officeId', officeId)
            const localVarPath = `/api/v1/agents/{id}/office/{officeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach multiple division designation to the agent
         * @param {string} id 
         * @param {AgentDivisionRequestWrapper} agentDivisionRequestWrapper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachDivisionDesignations: async (id: string, agentDivisionRequestWrapper: AgentDivisionRequestWrapper, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('attachDivisionDesignations', 'id', id)
            // verify required parameter 'agentDivisionRequestWrapper' is not null or undefined
            assertParamExists('attachDivisionDesignations', 'agentDivisionRequestWrapper', agentDivisionRequestWrapper)
            const localVarPath = `/api/v1/agents/{id}/divisions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentDivisionRequestWrapper, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove the cap deferment for an agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDeferredCap: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelDeferredCap', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/cancel-deferred-cap`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the agent to be paid as a personal entity, not a business entity.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPaidViaBusinessEntity: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearPaidViaBusinessEntity', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/paid-via-business-entity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates agent\'s license
         * @param {string} id 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLicense: async (id: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createAgentLicense', 'id', id)
            // verify required parameter 'license' is not null or undefined
            assertParamExists('createAgentLicense', 'license', license)
            const localVarPath = `/api/v1/agents/{id}/license`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (license !== undefined) { 
                localVarFormParams.append('license', new Blob([JSON.stringify(license)], { type: "application/json", }));
            }
    
            if (licenseImage !== undefined) { 
                localVarFormParams.append('licenseImage', licenseImage as any);
            }
    
            if (signatureImage !== undefined) { 
                localVarFormParams.append('signatureImage', signatureImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {CreateWillBeneficiaryRequest} createWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWillBeneficiaryForAgent: async (agentId: string, createWillBeneficiaryRequest: CreateWillBeneficiaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('createWillBeneficiaryForAgent', 'agentId', agentId)
            // verify required parameter 'createWillBeneficiaryRequest' is not null or undefined
            assertParamExists('createWillBeneficiaryForAgent', 'createWillBeneficiaryRequest', createWillBeneficiaryRequest)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWillBeneficiaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWillBeneficiaryTrustForAgent: async (agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('createWillBeneficiaryTrustForAgent', 'agentId', agentId)
            // verify required parameter 'willBeneficiaryTrustRequest' is not null or undefined
            assertParamExists('createWillBeneficiaryTrustForAgent', 'willBeneficiaryTrustRequest', willBeneficiaryTrustRequest)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary-trust`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(willBeneficiaryTrustRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentLicenseById: async (id: string, licenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAgentLicenseById', 'id', id)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('deleteAgentLicenseById', 'licenseId', licenseId)
            const localVarPath = `/api/v1/agents/{id}/license/{licenseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach a division designation from  agent
         * @param {string} id 
         * @param {string} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachDivisionDesignation: async (id: string, divisionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('detachDivisionDesignation', 'id', id)
            // verify required parameter 'divisionId' is not null or undefined
            assertParamExists('detachDivisionDesignation', 'divisionId', divisionId)
            const localVarPath = `/api/v1/agents/{id}/divisions/{divisionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"divisionId"}}`, encodeURIComponent(String(divisionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active agent ids created on or after a given date
         * @param {string} joinedAfter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAgentIds: async (joinedAfter: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'joinedAfter' is not null or undefined
            assertParamExists('getActiveAgentIds', 'joinedAfter', joinedAfter)
            const localVarPath = `/api/v1/agents/active-agent-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (joinedAfter !== undefined) {
                localVarQueryParameter['joinedAfter'] = (joinedAfter as any instanceof Date) ?
                    (joinedAfter as any).toISOString().substr(0,10) :
                    joinedAfter;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is faster than /{id} because it fetches data from only one table.
         * @summary Get agent basic-information of user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBasicInfoById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentBasicInfoById', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/basic-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is faster than /{ids} because it fetches data from cache.
         * @summary Get basic-information for multiple agents
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBasicInfoByIds: async (agentInfosRequest: AgentInfosRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentInfosRequest' is not null or undefined
            assertParamExists('getAgentBasicInfoByIds', 'agentInfosRequest', agentInfosRequest)
            const localVarPath = `/api/v1/agents/basic-infos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentInfosRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentById', 'id', id)
            const localVarPath = `/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBySlug: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('getAgentBySlug', 'slug', slug)
            const localVarPath = `/api/v1/agents/slug/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the minimal agent details needed to render a comment. Can request multiple keymaker ids
         * @param {Array<string>} keymakerIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentCommentDetails: async (keymakerIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keymakerIds' is not null or undefined
            assertParamExists('getAgentCommentDetails', 'keymakerIds', keymakerIds)
            const localVarPath = `/api/v1/agents/comment-details/{keymakerIds}`
                .replace(`{${"keymakerIds"}}`, encodeURIComponent(String(keymakerIds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent designation division
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDesignationDivision: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentDesignationDivision', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/divisions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets agent\'s driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDriverLicenseImage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgentDriverLicenseImage', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/driver-license-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent ids by shareworks ids
         * @param {GetAgentIdsByShareworksIdsRequest} getAgentIdsByShareworksIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentIdsByShareworksId: async (getAgentIdsByShareworksIdsRequest: GetAgentIdsByShareworksIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getAgentIdsByShareworksIdsRequest' is not null or undefined
            assertParamExists('getAgentIdsByShareworksId', 'getAgentIdsByShareworksIdsRequest', getAgentIdsByShareworksIdsRequest)
            const localVarPath = `/api/v1/agents/agent-ids-by-shareworks-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getAgentIdsByShareworksIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentInfos: async (agentInfosRequest: AgentInfosRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentInfosRequest' is not null or undefined
            assertParamExists('getAgentInfos', 'agentInfosRequest', agentInfosRequest)
            const localVarPath = `/api/v1/agents/getAgentInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentInfosRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets agent\'s license and signature images
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLicenseImages: async (licenseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('getAgentLicenseImages', 'licenseId', licenseId)
            const localVarPath = `/api/v1/agents/license/{licenseId}/images`
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent(s) by email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByEmail: async (emailAddress: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('getAgentsByEmail', 'emailAddress', emailAddress)
            const localVarPath = `/api/v1/agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agents by ids (limit 20)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByIds: async (ids: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getAgentsByIds', 'ids', ids)
            const localVarPath = `/api/v1/agents/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ids) {
                localVarQueryParameter['ids'] = Array.from(ids);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Agents\' ReferralCentral Details
         * @param {Array<string>} [agentIds] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsReferralCentralDetails: async (agentIds?: Array<string>, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/referral-central`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentIds) {
                localVarQueryParameter['agentIds'] = Array.from(agentIds);
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all active agent IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveAgents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if agent is cap deferred
         * @param {string} agentId 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapDefermentStatus: async (agentId: string, asOf?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getCapDefermentStatus', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/cap-deferred`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOf !== undefined) {
                localVarQueryParameter['asOf'] = (asOf as any instanceof Date) ?
                    (asOf as any).toISOString().substr(0,10) :
                    asOf;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agents in the network within a specific tier of the given agent
         * @param {string} id 
         * @param {number} tier 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownLineAgents: async (id: string, tier: number, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDownLineAgents', 'id', id)
            // verify required parameter 'tier' is not null or undefined
            assertParamExists('getDownLineAgents', 'tier', tier)
            const localVarPath = `/api/v1/agents/{id}/down-line/{tier}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tier"}}`, encodeURIComponent(String(tier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get down line agents by ids
         * @param {GetDownLineAgentsRequest} getDownLineAgentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownLineAgentsByIds: async (getDownLineAgentsRequest: GetDownLineAgentsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDownLineAgentsRequest' is not null or undefined
            assertParamExists('getDownLineAgentsByIds', 'getDownLineAgentsRequest', getDownLineAgentsRequest)
            const localVarPath = `/api/v1/agents/get-down-line-agents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDownLineAgentsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get front line agents basic information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontLineAgentsInfo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFrontLineAgentsInfo', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/front-line-agents-info`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the agent we use as a stand-in for LOI agents in revShare trees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLOIAgent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/loiAgent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNationalIdentifications: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNationalIdentifications', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/national-identifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get network size by tier
         * @param {string} agentId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkSizeByTier: async (agentId: string, asOfDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getNetworkSizeByTier', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/network-size-by-tier`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (asOfDate !== undefined) {
                localVarQueryParameter['asOfDate'] = (asOfDate as any instanceof Date) ?
                    (asOfDate as any).toISOString().substr(0,10) :
                    asOfDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpcity: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/opcity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScore: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProfileScore', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/profile-score`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAgentInfos: async (agentInfosRequest: AgentInfosRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentInfosRequest' is not null or undefined
            assertParamExists('getPublicAgentInfos', 'agentInfosRequest', agentInfosRequest)
            const localVarPath = `/api/v1/agents/getPublicAgentInfos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentInfosRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s last and next anniversaries relative to provided date
         * @param {string} agentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelativeAnniversaries: async (agentId: string, date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getRelativeAnniversaries', 'agentId', agentId)
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getRelativeAnniversaries', 'date', date)
            const localVarPath = `/api/v1/agents/{agentId}/relative-anniversaries/{date}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSponsorTree: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSponsorTree', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/sponsor-tree`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipaltiSignUpUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/tipaltiUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWillBeneficiaryAndTypeForAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getWillBeneficiaryAndTypeForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary-typed`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWillBeneficiaryForAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getWillBeneficiaryForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return whether or not a slug is available
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSlugAvailable: async (slug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('isSlugAvailable', 'slug', slug)
            const localVarPath = `/api/v1/agents/slugs/{slug}/available`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move agents from one mls to other
         * @param {MigrateMlsRequest} migrateMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateBoard: async (migrateMlsRequest: MigrateMlsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrateMlsRequest' is not null or undefined
            assertParamExists('migrateBoard', 'migrateMlsRequest', migrateMlsRequest)
            const localVarPath = `/api/v1/agents/migrate-mls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateMlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move agents from one board to other
         * @param {MigrateBoardRequest} migrateBoardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateBoard1: async (migrateBoardRequest: MigrateBoardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'migrateBoardRequest' is not null or undefined
            assertParamExists('migrateBoard1', 'migrateBoardRequest', migrateBoardRequest)
            const localVarPath = `/api/v1/agents/migrate-board`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateBoardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Onboards a given user to the external payment system
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardToPaymentSystem: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('onboardToPaymentSystem', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/onboard-to-payment-system`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opt in an agent to equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optInToEquityPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optInToEquityPlan', 'id', id)
            const localVarPath = `/api/v1/agents/admin/{id}/opt-in-to-equity-plan`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Opt out an agent from equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optOutOfEquityPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('optOutOfEquityPlan', 'id', id)
            const localVarPath = `/api/v1/agents/admin/{id}/opt-out-of-equity-plan`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearch: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/search/all-public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (nonReportable !== undefined) {
                localVarQueryParameter['nonReportable'] = nonReportable;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (stateOrProvince) {
                localVarQueryParameter['stateOrProvince'] = Array.from(stateOrProvince);
            }

            if (officeId) {
                localVarQueryParameter['officeId'] = Array.from(officeId);
            }

            if (mlsId) {
                localVarQueryParameter['mlsId'] = Array.from(mlsId);
            }

            if (boardId) {
                localVarQueryParameter['boardId'] = Array.from(boardId);
            }

            if (divisionIds) {
                localVarQueryParameter['divisionIds'] = Array.from(divisionIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an office from an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOffice: async (id: string, officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeOffice', 'id', id)
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('removeOffice', 'officeId', officeId)
            const localVarPath = `/api/v1/agents/{id}/office/{officeId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove sponsor for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        removeSponsorForAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSponsorForAgent', 'id', id)
            const localVarPath = `/api/v1/agents/admin/{id}/remove-sponsor`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove sponsors for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSponsorsForAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeSponsorsForAgent', 'id', id)
            const localVarPath = `/api/v1/agents/admin/{id}/remove-sponsors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request subscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChimeSubscribe: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestChimeSubscribe', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/chime/request-subscribe`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request unsubscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChimeUnsubscribe: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('requestChimeUnsubscribe', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/chime/request-unsubscribe`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for active agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [name] 
         * @param {Array<boolean>} [nonReportable] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActiveAgents: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>, name?: string, nonReportable?: Array<boolean>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/search/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nonReportable) {
                localVarQueryParameter['nonReportable'] = Array.from(nonReportable);
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (stateOrProvince) {
                localVarQueryParameter['stateOrProvince'] = Array.from(stateOrProvince);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (nonReportable !== undefined) {
                localVarQueryParameter['nonReportable'] = nonReportable;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }

            if (country) {
                localVarQueryParameter['country'] = Array.from(country);
            }

            if (stateOrProvince) {
                localVarQueryParameter['stateOrProvince'] = Array.from(stateOrProvince);
            }

            if (officeId) {
                localVarQueryParameter['officeId'] = Array.from(officeId);
            }

            if (mlsId) {
                localVarQueryParameter['mlsId'] = Array.from(mlsId);
            }

            if (boardId) {
                localVarQueryParameter['boardId'] = Array.from(boardId);
            }

            if (divisionIds) {
                localVarQueryParameter['divisionIds'] = Array.from(divisionIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNonBrokerAgents: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/search/non-broker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Treat the agent as if they\'ve capped for their first specified number of months at Real
         * @param {string} id 
         * @param {number} numberOfMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeferredCap: async (id: string, numberOfMonths: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setDeferredCap', 'id', id)
            // verify required parameter 'numberOfMonths' is not null or undefined
            assertParamExists('setDeferredCap', 'numberOfMonths', numberOfMonths)
            const localVarPath = `/api/v1/agents/{id}/defer-cap/{numberOfMonths}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"numberOfMonths"}}`, encodeURIComponent(String(numberOfMonths)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {SetNationalIdentficationsRequest} setNationalIdentficationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNationalIdentifications: async (id: string, setNationalIdentficationsRequest: SetNationalIdentficationsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setNationalIdentifications', 'id', id)
            // verify required parameter 'setNationalIdentficationsRequest' is not null or undefined
            assertParamExists('setNationalIdentifications', 'setNationalIdentficationsRequest', setNationalIdentficationsRequest)
            const localVarPath = `/api/v1/agents/{id}/national-identifications`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setNationalIdentficationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Terminate an Agent
         * @param {string} id 
         * @param {TerminateAgentRequest} terminateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateAgent: async (id: string, terminateAgentRequest: TerminateAgentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('terminateAgent', 'id', id)
            // verify required parameter 'terminateAgentRequest' is not null or undefined
            assertParamExists('terminateAgent', 'terminateAgentRequest', terminateAgentRequest)
            const localVarPath = `/api/v1/agents/{id}/terminate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(terminateAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unterminate an Agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unTerminateAgent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unTerminateAgent', 'id', id)
            const localVarPath = `/api/v1/agents/{id}/unterminate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s boards by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateBoardsRequest} agentUpdateBoardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentBoardsById: async (id: string, agentUpdateBoardsRequest: AgentUpdateBoardsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentBoardsById', 'id', id)
            // verify required parameter 'agentUpdateBoardsRequest' is not null or undefined
            assertParamExists('updateAgentBoardsById', 'agentUpdateBoardsRequest', agentUpdateBoardsRequest)
            const localVarPath = `/api/v1/agents/{id}/boards`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateBoardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {AgentUpdateRequest} agentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentById: async (id: string, agentUpdateRequest: AgentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentById', 'id', id)
            // verify required parameter 'agentUpdateRequest' is not null or undefined
            assertParamExists('updateAgentById', 'agentUpdateRequest', agentUpdateRequest)
            const localVarPath = `/api/v1/agents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentRequestAdmin} updateAgentRequestAdmin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAgentByIdAdmin: async (id: string, updateAgentRequestAdmin: UpdateAgentRequestAdmin, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentByIdAdmin', 'id', id)
            // verify required parameter 'updateAgentRequestAdmin' is not null or undefined
            assertParamExists('updateAgentByIdAdmin', 'updateAgentRequestAdmin', updateAgentRequestAdmin)
            const localVarPath = `/api/v1/agents/admin/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgentRequestAdmin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentAdminRequest} updateAgentAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdAdmin1: async (id: string, updateAgentAdminRequest: UpdateAgentAdminRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentByIdAdmin1', 'id', id)
            // verify required parameter 'updateAgentAdminRequest' is not null or undefined
            assertParamExists('updateAgentByIdAdmin1', 'updateAgentAdminRequest', updateAgentAdminRequest)
            const localVarPath = `/api/v1/agents/admin/{id}/admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgentAdminRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates agent\'s driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentDriverLicenseImage: async (id: string, driverLicenseImage: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentDriverLicenseImage', 'id', id)
            // verify required parameter 'driverLicenseImage' is not null or undefined
            assertParamExists('updateAgentDriverLicenseImage', 'driverLicenseImage', driverLicenseImage)
            const localVarPath = `/api/v1/agents/{id}/driver-license-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (driverLicenseImage !== undefined) { 
                localVarFormParams.append('driverLicenseImage', driverLicenseImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseBoardMlsById: async (id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentLicenseBoardMlsById', 'id', id)
            // verify required parameter 'agentUpdateLicenseBoardMlsRequest' is not null or undefined
            assertParamExists('updateAgentLicenseBoardMlsById', 'agentUpdateLicenseBoardMlsRequest', agentUpdateLicenseBoardMlsRequest)
            const localVarPath = `/api/v1/agents/licensesBoardMls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateLicenseBoardMlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseById: async (id: string, licenseId: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentLicenseById', 'id', id)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('updateAgentLicenseById', 'licenseId', licenseId)
            // verify required parameter 'license' is not null or undefined
            assertParamExists('updateAgentLicenseById', 'license', license)
            const localVarPath = `/api/v1/agents/{id}/license/{licenseId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (license !== undefined) { 
                localVarFormParams.append('license', new Blob([JSON.stringify(license)], { type: "application/json", }));
            }
    
            if (licenseImage !== undefined) { 
                localVarFormParams.append('licenseImage', licenseImage as any);
            }
    
            if (signatureImage !== undefined) { 
                localVarFormParams.append('signatureImage', signatureImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates agent\'s license image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseImage: async (id: string, licenseId: string, imageFile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentLicenseImage', 'id', id)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('updateAgentLicenseImage', 'licenseId', licenseId)
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('updateAgentLicenseImage', 'imageFile', imageFile)
            const localVarPath = `/api/v1/agents/{id}/license/{licenseId}/license-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update license image approval status
         * @param {string} agentId 
         * @param {string} licenseId 
         * @param {AgentLicenseImageApprovalStatusRequest} agentLicenseImageApprovalStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseImageApprovalStatus: async (agentId: string, licenseId: string, agentLicenseImageApprovalStatusRequest: AgentLicenseImageApprovalStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateAgentLicenseImageApprovalStatus', 'agentId', agentId)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('updateAgentLicenseImageApprovalStatus', 'licenseId', licenseId)
            // verify required parameter 'agentLicenseImageApprovalStatusRequest' is not null or undefined
            assertParamExists('updateAgentLicenseImageApprovalStatus', 'agentLicenseImageApprovalStatusRequest', agentLicenseImageApprovalStatusRequest)
            const localVarPath = `/api/v1/agents/{agentId}/license/{licenseId}/image-approval-status`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentLicenseImageApprovalStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s licenses by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} licenses 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicensesById: async (id: string, licenses: AgentUpdateLicenseBoardMlsRequest, licenseImage?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentLicensesById', 'id', id)
            // verify required parameter 'licenses' is not null or undefined
            assertParamExists('updateAgentLicensesById', 'licenses', licenses)
            const localVarPath = `/api/v1/agents/{id}/licenses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (licenses !== undefined) { 
                localVarFormParams.append('licenses', new Blob([JSON.stringify(licenses)], { type: "application/json", }));
            }
                if (licenseImage) {
                licenseImage.forEach((element) => {
                    localVarFormParams.append('licenseImage', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s MLSes by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateMlsesRequest} agentUpdateMlsesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentMlsesById: async (id: string, agentUpdateMlsesRequest: AgentUpdateMlsesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentMlsesById', 'id', id)
            // verify required parameter 'agentUpdateMlsesRequest' is not null or undefined
            assertParamExists('updateAgentMlsesById', 'agentUpdateMlsesRequest', agentUpdateMlsesRequest)
            const localVarPath = `/api/v1/agents/{id}/mlses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateMlsesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s website onboarding flag to true
         * @param {string} id 
         * @param {AgentUpdateNeedsWebsiteOnboardingRequest} agentUpdateNeedsWebsiteOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentNeedsWebsiteOnboarding: async (id: string, agentUpdateNeedsWebsiteOnboardingRequest: AgentUpdateNeedsWebsiteOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentNeedsWebsiteOnboarding', 'id', id)
            // verify required parameter 'agentUpdateNeedsWebsiteOnboardingRequest' is not null or undefined
            assertParamExists('updateAgentNeedsWebsiteOnboarding', 'agentUpdateNeedsWebsiteOnboardingRequest', agentUpdateNeedsWebsiteOnboardingRequest)
            const localVarPath = `/api/v1/agents/{id}/needs-website-onboarding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateNeedsWebsiteOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates agent\'s signature image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAgentSignatureImage: async (id: string, licenseId: string, imageFile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAgentSignatureImage', 'id', id)
            // verify required parameter 'licenseId' is not null or undefined
            assertParamExists('updateAgentSignatureImage', 'licenseId', licenseId)
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('updateAgentSignatureImage', 'imageFile', imageFile)
            const localVarPath = `/api/v1/agents/{id}/license/{licenseId}/signature-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"licenseId"}}`, encodeURIComponent(String(licenseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent business email address
         * @param {string} id 
         * @param {AgentUpdateBusinessEmailRequest} agentUpdateBusinessEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessEmailAddress: async (id: string, agentUpdateBusinessEmailRequest: AgentUpdateBusinessEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBusinessEmailAddress', 'id', id)
            // verify required parameter 'agentUpdateBusinessEmailRequest' is not null or undefined
            assertParamExists('updateBusinessEmailAddress', 'agentUpdateBusinessEmailRequest', agentUpdateBusinessEmailRequest)
            const localVarPath = `/api/v1/agents/{id}/business-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateBusinessEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s join date and anniversary date
         * @param {string} id 
         * @param {AgentUpdateJoinDateAndAnniversaryDateRequest} agentUpdateJoinDateAndAnniversaryDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoinDateAndAnniversaryDate: async (id: string, agentUpdateJoinDateAndAnniversaryDateRequest: AgentUpdateJoinDateAndAnniversaryDateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJoinDateAndAnniversaryDate', 'id', id)
            // verify required parameter 'agentUpdateJoinDateAndAnniversaryDateRequest' is not null or undefined
            assertParamExists('updateJoinDateAndAnniversaryDate', 'agentUpdateJoinDateAndAnniversaryDateRequest', agentUpdateJoinDateAndAnniversaryDateRequest)
            const localVarPath = `/api/v1/agents/{id}/join-date-and-anniversary-date`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateJoinDateAndAnniversaryDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s join real email
         * @param {string} id 
         * @param {AgentUpdateJoinRealEmailRequest} agentUpdateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoinRealEmail: async (id: string, agentUpdateJoinRealEmailRequest: AgentUpdateJoinRealEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateJoinRealEmail', 'id', id)
            // verify required parameter 'agentUpdateJoinRealEmailRequest' is not null or undefined
            assertParamExists('updateJoinRealEmail', 'agentUpdateJoinRealEmailRequest', agentUpdateJoinRealEmailRequest)
            const localVarPath = `/api/v1/agents/{id}/join-real-email`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateJoinRealEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateLicenseVerifiedRequest} agentUpdateLicenseVerifiedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseVerified: async (id: string, agentUpdateLicenseVerifiedRequest: AgentUpdateLicenseVerifiedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLicenseVerified', 'id', id)
            // verify required parameter 'agentUpdateLicenseVerifiedRequest' is not null or undefined
            assertParamExists('updateLicenseVerified', 'agentUpdateLicenseVerifiedRequest', agentUpdateLicenseVerifiedRequest)
            const localVarPath = `/api/v1/agents/licenseVerified/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateLicenseVerifiedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateNeedOnBoardingRequest} agentUpdateNeedOnBoardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedOnBoarding: async (id: string, agentUpdateNeedOnBoardingRequest: AgentUpdateNeedOnBoardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNeedOnBoarding', 'id', id)
            // verify required parameter 'agentUpdateNeedOnBoardingRequest' is not null or undefined
            assertParamExists('updateNeedOnBoarding', 'agentUpdateNeedOnBoardingRequest', agentUpdateNeedOnBoardingRequest)
            const localVarPath = `/api/v1/agents/needOnBoarding/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateNeedOnBoardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update needs mentorship for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentNeedsMentorshipRequest} updateAgentNeedsMentorshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsMentorship: async (agentId: string, updateAgentNeedsMentorshipRequest: UpdateAgentNeedsMentorshipRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateNeedsMentorship', 'agentId', agentId)
            // verify required parameter 'updateAgentNeedsMentorshipRequest' is not null or undefined
            assertParamExists('updateNeedsMentorship', 'updateAgentNeedsMentorshipRequest', updateAgentNeedsMentorshipRequest)
            const localVarPath = `/api/v1/agents/{agentId}/set-needs-mentorship`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgentNeedsMentorshipRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update if agent completed tax onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsTaxOnboardingRequest} agentUpdateNeedsTaxOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsTaxOnboarding: async (id: string, agentUpdateNeedsTaxOnboardingRequest: AgentUpdateNeedsTaxOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNeedsTaxOnboarding', 'id', id)
            // verify required parameter 'agentUpdateNeedsTaxOnboardingRequest' is not null or undefined
            assertParamExists('updateNeedsTaxOnboarding', 'agentUpdateNeedsTaxOnboardingRequest', agentUpdateNeedsTaxOnboardingRequest)
            const localVarPath = `/api/v1/agents/{id}/tax-onboarding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateNeedsTaxOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update if agent completed will beneficiary onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsWillBeneficiaryOnboardingRequest} agentUpdateNeedsWillBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsWillBeneficiaryOnboarding: async (id: string, agentUpdateNeedsWillBeneficiaryOnboardingRequest: AgentUpdateNeedsWillBeneficiaryOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNeedsWillBeneficiaryOnboarding', 'id', id)
            // verify required parameter 'agentUpdateNeedsWillBeneficiaryOnboardingRequest' is not null or undefined
            assertParamExists('updateNeedsWillBeneficiaryOnboarding', 'agentUpdateNeedsWillBeneficiaryOnboardingRequest', agentUpdateNeedsWillBeneficiaryOnboardingRequest)
            const localVarPath = `/api/v1/agents/{id}/will-beneficiary-onboarding`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdateNeedsWillBeneficiaryOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s one real impact fund config
         * @param {string} agentId 
         * @param {OneRealImpactFundConfigDto} oneRealImpactFundConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneRealImpactFund: async (agentId: string, oneRealImpactFundConfigDto: OneRealImpactFundConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateOneRealImpactFund', 'agentId', agentId)
            // verify required parameter 'oneRealImpactFundConfigDto' is not null or undefined
            assertParamExists('updateOneRealImpactFund', 'oneRealImpactFundConfigDto', oneRealImpactFundConfigDto)
            const localVarPath = `/api/v1/agents/{agentId}/one-real-impact-fund`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oneRealImpactFundConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update if agent prefers to get paid via a business entity
         * @param {string} id 
         * @param {BusinessEntityValue} businessEntityValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaidViaBusinessEntity: async (id: string, businessEntityValue: BusinessEntityValue, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePaidViaBusinessEntity', 'id', id)
            // verify required parameter 'businessEntityValue' is not null or undefined
            assertParamExists('updatePaidViaBusinessEntity', 'businessEntityValue', businessEntityValue)
            const localVarPath = `/api/v1/agents/{id}/paid-via-business-entity`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(businessEntityValue, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s plans
         * @param {string} agentId 
         * @param {AgentUpdatePlansRequest} agentUpdatePlansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlans: async (agentId: string, agentUpdatePlansRequest: AgentUpdatePlansRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updatePlans', 'agentId', agentId)
            // verify required parameter 'agentUpdatePlansRequest' is not null or undefined
            assertParamExists('updatePlans', 'agentUpdatePlansRequest', agentUpdatePlansRequest)
            const localVarPath = `/api/v1/agents/{agentId}/plans`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpdatePlansRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update single check preference for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentSingleCheckPreferenceRequest} updateAgentSingleCheckPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleCheckPreference: async (agentId: string, updateAgentSingleCheckPreferenceRequest: UpdateAgentSingleCheckPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateSingleCheckPreference', 'agentId', agentId)
            // verify required parameter 'updateAgentSingleCheckPreferenceRequest' is not null or undefined
            assertParamExists('updateSingleCheckPreference', 'updateAgentSingleCheckPreferenceRequest', updateAgentSingleCheckPreferenceRequest)
            const localVarPath = `/api/v1/agents/{agentId}/set-single-check-preference`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAgentSingleCheckPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sponsors for a given agent
         * @param {string} id 
         * @param {UpdateSponsorsRequest} updateSponsorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSponsorsForAgent: async (id: string, updateSponsorsRequest: UpdateSponsorsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSponsorsForAgent', 'id', id)
            // verify required parameter 'updateSponsorsRequest' is not null or undefined
            assertParamExists('updateSponsorsForAgent', 'updateSponsorsRequest', updateSponsorsRequest)
            const localVarPath = `/api/v1/agents/admin/{id}/update-sponsors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSponsorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {UpdateWillBeneficiaryRequest} updateWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWillBeneficiaryForAgent: async (agentId: string, updateWillBeneficiaryRequest: UpdateWillBeneficiaryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateWillBeneficiaryForAgent', 'agentId', agentId)
            // verify required parameter 'updateWillBeneficiaryRequest' is not null or undefined
            assertParamExists('updateWillBeneficiaryForAgent', 'updateWillBeneficiaryRequest', updateWillBeneficiaryRequest)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateWillBeneficiaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWillBeneficiaryTrustForAgent: async (agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateWillBeneficiaryTrustForAgent', 'agentId', agentId)
            // verify required parameter 'willBeneficiaryTrustRequest' is not null or undefined
            assertParamExists('updateWillBeneficiaryTrustForAgent', 'willBeneficiaryTrustRequest', willBeneficiaryTrustRequest)
            const localVarPath = `/api/v1/agents/{agentId}/will-beneficiary-trust`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(willBeneficiaryTrustRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert agent\'s referral central data
         * @param {string} agentId 
         * @param {AgentUpsertReferralCentralOnboardingDataRequest} agentUpsertReferralCentralOnboardingDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertReferralCentralData: async (agentId: string, agentUpsertReferralCentralOnboardingDataRequest: AgentUpsertReferralCentralOnboardingDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('upsertReferralCentralData', 'agentId', agentId)
            // verify required parameter 'agentUpsertReferralCentralOnboardingDataRequest' is not null or undefined
            assertParamExists('upsertReferralCentralData', 'agentUpsertReferralCentralOnboardingDataRequest', agentUpsertReferralCentralOnboardingDataRequest)
            const localVarPath = `/api/v1/agents/{agentId}/referral-central`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentUpsertReferralCentralOnboardingDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentControllerApi - functional programming interface
 * @export
 */
export const AgentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add an office to an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOffice(id: string, officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOffice(id, officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attach multiple division designation to the agent
         * @param {string} id 
         * @param {AgentDivisionRequestWrapper} agentDivisionRequestWrapper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async attachDivisionDesignations(id: string, agentDivisionRequestWrapper: AgentDivisionRequestWrapper, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.attachDivisionDesignations(id, agentDivisionRequestWrapper, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove the cap deferment for an agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelDeferredCap(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelDeferredCap(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set the agent to be paid as a personal entity, not a business entity.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearPaidViaBusinessEntity(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearPaidViaBusinessEntity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates agent\'s license
         * @param {string} id 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgentLicense(id: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgentLicense(id, license, licenseImage, signatureImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {CreateWillBeneficiaryRequest} createWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWillBeneficiaryForAgent(agentId: string, createWillBeneficiaryRequest: CreateWillBeneficiaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWillBeneficiaryForAgent(agentId, createWillBeneficiaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryTrustResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgentLicenseById(id: string, licenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgentLicenseById(id, licenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Detach a division designation from  agent
         * @param {string} id 
         * @param {string} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async detachDivisionDesignation(id: string, divisionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.detachDivisionDesignation(id, divisionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active agent ids created on or after a given date
         * @param {string} joinedAfter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveAgentIds(joinedAfter: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveAgentIds(joinedAfter, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is faster than /{id} because it fetches data from only one table.
         * @summary Get agent basic-information of user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentBasicInfoById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentBasicInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentBasicInfoById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is faster than /{ids} because it fetches data from cache.
         * @summary Get basic-information for multiple agents
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentBasicInfoByIds(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentBasicInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentBasicInfoByIds(agentInfosRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentBySlug(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentBySlugResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentBySlug(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the minimal agent details needed to render a comment. Can request multiple keymaker ids
         * @param {Array<string>} keymakerIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentCommentDetails(keymakerIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentCommentDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentCommentDetails(keymakerIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent designation division
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentDesignationDivision(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionResponseWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentDesignationDivision(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets agent\'s driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentDriverLicenseImage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDriverLicenseImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentDriverLicenseImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent ids by shareworks ids
         * @param {GetAgentIdsByShareworksIdsRequest} getAgentIdsByShareworksIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest: GetAgentIdsByShareworksIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentIdsByShareworksIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentInfos(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentInfos(agentInfosRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets agent\'s license and signature images
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentLicenseImages(licenseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentLicenseImagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentLicenseImages(licenseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent(s) by email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsByEmail(emailAddress: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsByEmail(emailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agents by ids (limit 20)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsByIds(ids: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsByIds(ids, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Agents\' ReferralCentral Details
         * @param {Array<string>} [agentIds] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsReferralCentralDetails(agentIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentsReferralCentralDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsReferralCentralDetails(agentIds, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all active agent IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActiveAgents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveAgentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActiveAgents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if agent is cap deferred
         * @param {string} agentId 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCapDefermentStatus(agentId: string, asOf?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CapDefermentStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCapDefermentStatus(agentId, asOf, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agents in the network within a specific tier of the given agent
         * @param {string} id 
         * @param {number} tier 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownLineAgents(id: string, tier: number, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownLineAgentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownLineAgents(id, tier, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get down line agents by ids
         * @param {GetDownLineAgentsRequest} getDownLineAgentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownLineAgentsByIds(getDownLineAgentsRequest: GetDownLineAgentsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownLineAgentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownLineAgentsByIds(getDownLineAgentsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get front line agents basic information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFrontLineAgentsInfo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontLineAgentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFrontLineAgentsInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the agent we use as a stand-in for LOI agents in revShare trees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLOIAgent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLOIAgent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNationalIdentifications(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentNationalIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNationalIdentifications(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get network size by tier
         * @param {string} agentId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetworkSizeByTier(agentId: string, asOfDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NetworkSizeByTierResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNetworkSizeByTier(agentId, asOfDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpcity(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpcity(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileScore(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentProfileScoreResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileScore(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicAgentInfos(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentPublicInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicAgentInfos(agentInfosRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s last and next anniversaries relative to provided date
         * @param {string} agentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelativeAnniversaries(agentId: string, date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RelativeAnniversariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelativeAnniversaries(agentId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSponsorTree(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SponsorTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSponsorTree(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTipaltiSignUpUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayeeDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTipaltiSignUpUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWillBeneficiaryAndTypeForAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryAndTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWillBeneficiaryAndTypeForAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getWillBeneficiaryForAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWillBeneficiaryForAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return whether or not a slug is available
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isSlugAvailable(slug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AvailabilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isSlugAvailable(slug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move agents from one mls to other
         * @param {MigrateMlsRequest} migrateMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateBoard(migrateMlsRequest: MigrateMlsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateBoard(migrateMlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move agents from one board to other
         * @param {MigrateBoardRequest} migrateBoardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async migrateBoard1(migrateBoardRequest: MigrateBoardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.migrateBoard1(migrateBoardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Onboards a given user to the external payment system
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardToPaymentSystem(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardToPaymentSystem(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Opt in an agent to equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optInToEquityPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optInToEquityPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Opt out an agent from equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async optOutOfEquityPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.optOutOfEquityPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicSearch(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgentPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicSearch(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an office from an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOffice(id: string, officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOffice(id, officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove sponsor for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async removeSponsorForAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSponsorForAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove sponsors for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSponsorsForAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSponsorsForAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request subscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestChimeSubscribe(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestChimeSubscribe(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request unsubscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestChimeUnsubscribe(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestChimeUnsubscribe(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for active agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [name] 
         * @param {Array<boolean>} [nonReportable] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchActiveAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>, name?: string, nonReportable?: Array<boolean>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgentPublicInfoWithAdminAreas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchActiveAgents(pageNumber, pageSize, sortDirection, sortBy, name, nonReportable, country, stateOrProvince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchNonBrokerAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchNonBrokerAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Treat the agent as if they\'ve capped for their first specified number of months at Real
         * @param {string} id 
         * @param {number} numberOfMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDeferredCap(id: string, numberOfMonths: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDeferredCap(id, numberOfMonths, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {SetNationalIdentficationsRequest} setNationalIdentficationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setNationalIdentifications(id: string, setNationalIdentficationsRequest: SetNationalIdentficationsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentNationalIdsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setNationalIdentifications(id, setNationalIdentficationsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Terminate an Agent
         * @param {string} id 
         * @param {TerminateAgentRequest} terminateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async terminateAgent(id: string, terminateAgentRequest: TerminateAgentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.terminateAgent(id, terminateAgentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unterminate an Agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unTerminateAgent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unTerminateAgent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s boards by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateBoardsRequest} agentUpdateBoardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentBoardsById(id: string, agentUpdateBoardsRequest: AgentUpdateBoardsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentBoardsById(id, agentUpdateBoardsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {AgentUpdateRequest} agentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentById(id: string, agentUpdateRequest: AgentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentById(id, agentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentRequestAdmin} updateAgentRequestAdmin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateAgentByIdAdmin(id: string, updateAgentRequestAdmin: UpdateAgentRequestAdmin, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentByIdAdmin(id, updateAgentRequestAdmin, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentAdminRequest} updateAgentAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentByIdAdmin1(id: string, updateAgentAdminRequest: UpdateAgentAdminRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentByIdAdmin1(id, updateAgentAdminRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates agent\'s driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentDriverLicenseImage(id: string, driverLicenseImage: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentDriverLicenseImage(id, driverLicenseImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicenseBoardMlsById(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicenseBoardMlsById(id, agentUpdateLicenseBoardMlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicenseById(id: string, licenseId: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicenseById(id, licenseId, license, licenseImage, signatureImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates agent\'s license image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicenseImage(id: string, licenseId: string, imageFile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicenseImage(id, licenseId, imageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update license image approval status
         * @param {string} agentId 
         * @param {string} licenseId 
         * @param {AgentLicenseImageApprovalStatusRequest} agentLicenseImageApprovalStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicenseImageApprovalStatus(agentId: string, licenseId: string, agentLicenseImageApprovalStatusRequest: AgentLicenseImageApprovalStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicenseImageApprovalStatus(agentId, licenseId, agentLicenseImageApprovalStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s licenses by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} licenses 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentLicensesById(id: string, licenses: AgentUpdateLicenseBoardMlsRequest, licenseImage?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentLicensesById(id, licenses, licenseImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s MLSes by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateMlsesRequest} agentUpdateMlsesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentMlsesById(id: string, agentUpdateMlsesRequest: AgentUpdateMlsesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentMlsesById(id, agentUpdateMlsesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s website onboarding flag to true
         * @param {string} id 
         * @param {AgentUpdateNeedsWebsiteOnboardingRequest} agentUpdateNeedsWebsiteOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgentNeedsWebsiteOnboarding(id: string, agentUpdateNeedsWebsiteOnboardingRequest: AgentUpdateNeedsWebsiteOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentNeedsWebsiteOnboarding(id, agentUpdateNeedsWebsiteOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates agent\'s signature image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateAgentSignatureImage(id: string, licenseId: string, imageFile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgentSignatureImage(id, licenseId, imageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent business email address
         * @param {string} id 
         * @param {AgentUpdateBusinessEmailRequest} agentUpdateBusinessEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusinessEmailAddress(id: string, agentUpdateBusinessEmailRequest: AgentUpdateBusinessEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusinessEmailAddress(id, agentUpdateBusinessEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s join date and anniversary date
         * @param {string} id 
         * @param {AgentUpdateJoinDateAndAnniversaryDateRequest} agentUpdateJoinDateAndAnniversaryDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJoinDateAndAnniversaryDate(id: string, agentUpdateJoinDateAndAnniversaryDateRequest: AgentUpdateJoinDateAndAnniversaryDateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJoinDateAndAnniversaryDate(id, agentUpdateJoinDateAndAnniversaryDateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s join real email
         * @param {string} id 
         * @param {AgentUpdateJoinRealEmailRequest} agentUpdateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJoinRealEmail(id: string, agentUpdateJoinRealEmailRequest: AgentUpdateJoinRealEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJoinRealEmail(id, agentUpdateJoinRealEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateLicenseVerifiedRequest} agentUpdateLicenseVerifiedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicenseVerified(id: string, agentUpdateLicenseVerifiedRequest: AgentUpdateLicenseVerifiedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLicenseVerified(id, agentUpdateLicenseVerifiedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateNeedOnBoardingRequest} agentUpdateNeedOnBoardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeedOnBoarding(id: string, agentUpdateNeedOnBoardingRequest: AgentUpdateNeedOnBoardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeedOnBoarding(id, agentUpdateNeedOnBoardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update needs mentorship for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentNeedsMentorshipRequest} updateAgentNeedsMentorshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeedsMentorship(agentId: string, updateAgentNeedsMentorshipRequest: UpdateAgentNeedsMentorshipRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeedsMentorship(agentId, updateAgentNeedsMentorshipRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update if agent completed tax onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsTaxOnboardingRequest} agentUpdateNeedsTaxOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeedsTaxOnboarding(id: string, agentUpdateNeedsTaxOnboardingRequest: AgentUpdateNeedsTaxOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeedsTaxOnboarding(id, agentUpdateNeedsTaxOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update if agent completed will beneficiary onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsWillBeneficiaryOnboardingRequest} agentUpdateNeedsWillBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNeedsWillBeneficiaryOnboarding(id: string, agentUpdateNeedsWillBeneficiaryOnboardingRequest: AgentUpdateNeedsWillBeneficiaryOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNeedsWillBeneficiaryOnboarding(id, agentUpdateNeedsWillBeneficiaryOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s one real impact fund config
         * @param {string} agentId 
         * @param {OneRealImpactFundConfigDto} oneRealImpactFundConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOneRealImpactFund(agentId: string, oneRealImpactFundConfigDto: OneRealImpactFundConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOneRealImpactFund(agentId, oneRealImpactFundConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update if agent prefers to get paid via a business entity
         * @param {string} id 
         * @param {BusinessEntityValue} businessEntityValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaidViaBusinessEntity(id: string, businessEntityValue: BusinessEntityValue, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaidViaBusinessEntity(id, businessEntityValue, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s plans
         * @param {string} agentId 
         * @param {AgentUpdatePlansRequest} agentUpdatePlansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlans(agentId: string, agentUpdatePlansRequest: AgentUpdatePlansRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlans(agentId, agentUpdatePlansRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update single check preference for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentSingleCheckPreferenceRequest} updateAgentSingleCheckPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSingleCheckPreference(agentId: string, updateAgentSingleCheckPreferenceRequest: UpdateAgentSingleCheckPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSingleCheckPreference(agentId, updateAgentSingleCheckPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update sponsors for a given agent
         * @param {string} id 
         * @param {UpdateSponsorsRequest} updateSponsorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSponsorsForAgent(id: string, updateSponsorsRequest: UpdateSponsorsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSponsorsForAgent(id, updateSponsorsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {UpdateWillBeneficiaryRequest} updateWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWillBeneficiaryForAgent(agentId: string, updateWillBeneficiaryRequest: UpdateWillBeneficiaryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWillBeneficiaryForAgent(agentId, updateWillBeneficiaryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WillBeneficiaryTrustResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upsert agent\'s referral central data
         * @param {string} agentId 
         * @param {AgentUpsertReferralCentralOnboardingDataRequest} agentUpsertReferralCentralOnboardingDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertReferralCentralData(agentId: string, agentUpsertReferralCentralOnboardingDataRequest: AgentUpsertReferralCentralOnboardingDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertReferralCentralData(agentId, agentUpsertReferralCentralOnboardingDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentControllerApi - factory interface
 * @export
 */
export const AgentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add an office to an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOffice(id: string, officeId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addOffice(id, officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach multiple division designation to the agent
         * @param {string} id 
         * @param {AgentDivisionRequestWrapper} agentDivisionRequestWrapper 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        attachDivisionDesignations(id: string, agentDivisionRequestWrapper: AgentDivisionRequestWrapper, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.attachDivisionDesignations(id, agentDivisionRequestWrapper, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove the cap deferment for an agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDeferredCap(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.cancelDeferredCap(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set the agent to be paid as a personal entity, not a business entity.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearPaidViaBusinessEntity(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.clearPaidViaBusinessEntity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates agent\'s license
         * @param {string} id 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLicense(id: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.createAgentLicense(id, license, licenseImage, signatureImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {CreateWillBeneficiaryRequest} createWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWillBeneficiaryForAgent(agentId: string, createWillBeneficiaryRequest: CreateWillBeneficiaryRequest, options?: any): AxiosPromise<WillBeneficiaryResponse> {
            return localVarFp.createWillBeneficiaryForAgent(agentId, createWillBeneficiaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: any): AxiosPromise<WillBeneficiaryTrustResponse> {
            return localVarFp.createWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgentLicenseById(id: string, licenseId: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.deleteAgentLicenseById(id, licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach a division designation from  agent
         * @param {string} id 
         * @param {string} divisionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        detachDivisionDesignation(id: string, divisionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.detachDivisionDesignation(id, divisionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active agent ids created on or after a given date
         * @param {string} joinedAfter 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveAgentIds(joinedAfter: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<AgentIdsResponse> {
            return localVarFp.getActiveAgentIds(joinedAfter, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is faster than /{id} because it fetches data from only one table.
         * @summary Get agent basic-information of user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBasicInfoById(id: string, options?: any): AxiosPromise<AgentBasicInfoResponse> {
            return localVarFp.getAgentBasicInfoById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is faster than /{ids} because it fetches data from cache.
         * @summary Get basic-information for multiple agents
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBasicInfoByIds(agentInfosRequest: AgentInfosRequest, options?: any): AxiosPromise<AgentBasicInfosResponse> {
            return localVarFp.getAgentBasicInfoByIds(agentInfosRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentById(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getAgentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent by slug
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentBySlug(slug: string, options?: any): AxiosPromise<AgentBySlugResponse> {
            return localVarFp.getAgentBySlug(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the minimal agent details needed to render a comment. Can request multiple keymaker ids
         * @param {Array<string>} keymakerIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentCommentDetails(keymakerIds: Array<string>, options?: any): AxiosPromise<Array<AgentCommentDetails>> {
            return localVarFp.getAgentCommentDetails(keymakerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent designation division
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDesignationDivision(id: string, options?: any): AxiosPromise<DivisionResponseWrapper> {
            return localVarFp.getAgentDesignationDivision(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets agent\'s driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentDriverLicenseImage(id: string, options?: any): AxiosPromise<AgentDriverLicenseImageResponse> {
            return localVarFp.getAgentDriverLicenseImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent ids by shareworks ids
         * @param {GetAgentIdsByShareworksIdsRequest} getAgentIdsByShareworksIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest: GetAgentIdsByShareworksIdsRequest, options?: any): AxiosPromise<AgentIdsByShareworksIdsResponse> {
            return localVarFp.getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentInfos(agentInfosRequest: AgentInfosRequest, options?: any): AxiosPromise<AgentInfosResponse> {
            return localVarFp.getAgentInfos(agentInfosRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets agent\'s license and signature images
         * @param {string} licenseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentLicenseImages(licenseId: string, options?: any): AxiosPromise<AgentLicenseImagesResponse> {
            return localVarFp.getAgentLicenseImages(licenseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent(s) by email address
         * @param {string} emailAddress 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByEmail(emailAddress: string, options?: any): AxiosPromise<Array<AgentResponse>> {
            return localVarFp.getAgentsByEmail(emailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agents by ids (limit 20)
         * @param {Array<string>} ids 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsByIds(ids: Array<string>, options?: any): AxiosPromise<AgentListResponse> {
            return localVarFp.getAgentsByIds(ids, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Agents\' ReferralCentral Details
         * @param {Array<string>} [agentIds] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsReferralCentralDetails(agentIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<AgentsReferralCentralDetailsResponse> {
            return localVarFp.getAgentsReferralCentralDetails(agentIds, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all active agent IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiveAgents(options?: any): AxiosPromise<ActiveAgentsResponse> {
            return localVarFp.getAllActiveAgents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if agent is cap deferred
         * @param {string} agentId 
         * @param {string} [asOf] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCapDefermentStatus(agentId: string, asOf?: string, options?: any): AxiosPromise<CapDefermentStatus> {
            return localVarFp.getCapDefermentStatus(agentId, asOf, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agents in the network within a specific tier of the given agent
         * @param {string} id 
         * @param {number} tier 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownLineAgents(id: string, tier: number, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<DownLineAgentsResponse> {
            return localVarFp.getDownLineAgents(id, tier, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get down line agents by ids
         * @param {GetDownLineAgentsRequest} getDownLineAgentsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownLineAgentsByIds(getDownLineAgentsRequest: GetDownLineAgentsRequest, options?: any): AxiosPromise<DownLineAgentsResponse> {
            return localVarFp.getDownLineAgentsByIds(getDownLineAgentsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get front line agents basic information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFrontLineAgentsInfo(id: string, options?: any): AxiosPromise<FrontLineAgentInfoResponse> {
            return localVarFp.getFrontLineAgentsInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the agent we use as a stand-in for LOI agents in revShare trees
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLOIAgent(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getLOIAgent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNationalIdentifications(id: string, options?: any): AxiosPromise<AgentNationalIdsResponse> {
            return localVarFp.getNationalIdentifications(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get network size by tier
         * @param {string} agentId 
         * @param {string} [asOfDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkSizeByTier(agentId: string, asOfDate?: string, options?: any): AxiosPromise<NetworkSizeByTierResponse> {
            return localVarFp.getNetworkSizeByTier(agentId, asOfDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Opcity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpcity(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getOpcity(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User\'s Profile Score
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileScore(id: string, options?: any): AxiosPromise<AgentProfileScoreResponse> {
            return localVarFp.getProfileScore(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public agent info for a list of agents, limited to no more than 100 agent IDs
         * @param {AgentInfosRequest} agentInfosRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicAgentInfos(agentInfosRequest: AgentInfosRequest, options?: any): AxiosPromise<AgentPublicInfosResponse> {
            return localVarFp.getPublicAgentInfos(agentInfosRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s last and next anniversaries relative to provided date
         * @param {string} agentId 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelativeAnniversaries(agentId: string, date: string, options?: any): AxiosPromise<RelativeAnniversariesResponse> {
            return localVarFp.getRelativeAnniversaries(agentId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s sponsor tree
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSponsorTree(id: string, options?: any): AxiosPromise<SponsorTreeResponse> {
            return localVarFp.getSponsorTree(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipaltiSignUpUrl(options?: any): AxiosPromise<PayeeDetailsResponse> {
            return localVarFp.getTipaltiSignUpUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWillBeneficiaryAndTypeForAgent(agentId: string, options?: any): AxiosPromise<WillBeneficiaryAndTypeResponse> {
            return localVarFp.getWillBeneficiaryAndTypeForAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s will beneficiary
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getWillBeneficiaryForAgent(agentId: string, options?: any): AxiosPromise<WillBeneficiaryResponse> {
            return localVarFp.getWillBeneficiaryForAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return whether or not a slug is available
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isSlugAvailable(slug: string, options?: any): AxiosPromise<AvailabilityResponse> {
            return localVarFp.isSlugAvailable(slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move agents from one mls to other
         * @param {MigrateMlsRequest} migrateMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateBoard(migrateMlsRequest: MigrateMlsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.migrateBoard(migrateMlsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move agents from one board to other
         * @param {MigrateBoardRequest} migrateBoardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateBoard1(migrateBoardRequest: MigrateBoardRequest, options?: any): AxiosPromise<void> {
            return localVarFp.migrateBoard1(migrateBoardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Onboards a given user to the external payment system
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardToPaymentSystem(agentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.onboardToPaymentSystem(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Opt in an agent to equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optInToEquityPlan(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.optInToEquityPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Opt out an agent from equity plan
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        optOutOfEquityPlan(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.optOutOfEquityPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearch(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: any): AxiosPromise<GenericSearchResponseAgentPublicResponse> {
            return localVarFp.publicSearch(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an office from an agent
         * @param {string} id 
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOffice(id: string, officeId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeOffice(id, officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove sponsor for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        removeSponsorForAgent(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.removeSponsorForAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove sponsors for a given agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSponsorsForAgent(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.removeSponsorsForAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request subscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChimeSubscribe(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.requestChimeSubscribe(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request unsubscribe to Chime
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestChimeUnsubscribe(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.requestChimeUnsubscribe(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for active agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [name] 
         * @param {Array<boolean>} [nonReportable] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchActiveAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>, name?: string, nonReportable?: Array<boolean>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: any): AxiosPromise<GenericSearchResponseAgentPublicInfoWithAdminAreas> {
            return localVarFp.searchActiveAgents(pageNumber, pageSize, sortDirection, sortBy, name, nonReportable, country, stateOrProvince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {string} [phoneNumber] 
         * @param {boolean} [nonReportable] 
         * @param {string} [id] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
         * @param {Array<string>} [officeId] 
         * @param {Array<string>} [mlsId] 
         * @param {Array<string>} [boardId] 
         * @param {Array<string>} [divisionIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: any): AxiosPromise<GenericSearchResponseAgentResponse> {
            return localVarFp.searchAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for agents
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchNonBrokerAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, options?: any): AxiosPromise<GenericSearchResponseAgentInfo> {
            return localVarFp.searchNonBrokerAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Treat the agent as if they\'ve capped for their first specified number of months at Real
         * @param {string} id 
         * @param {number} numberOfMonths 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDeferredCap(id: string, numberOfMonths: number, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.setDeferredCap(id, numberOfMonths, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set an agent\'s national identifications
         * @param {string} id 
         * @param {SetNationalIdentficationsRequest} setNationalIdentficationsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setNationalIdentifications(id: string, setNationalIdentficationsRequest: SetNationalIdentficationsRequest, options?: any): AxiosPromise<AgentNationalIdsResponse> {
            return localVarFp.setNationalIdentifications(id, setNationalIdentficationsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Terminate an Agent
         * @param {string} id 
         * @param {TerminateAgentRequest} terminateAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        terminateAgent(id: string, terminateAgentRequest: TerminateAgentRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.terminateAgent(id, terminateAgentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unterminate an Agent
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unTerminateAgent(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.unTerminateAgent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s boards by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateBoardsRequest} agentUpdateBoardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentBoardsById(id: string, agentUpdateBoardsRequest: AgentUpdateBoardsRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentBoardsById(id, agentUpdateBoardsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {AgentUpdateRequest} agentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentById(id: string, agentUpdateRequest: AgentUpdateRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentById(id, agentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentRequestAdmin} updateAgentRequestAdmin 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAgentByIdAdmin(id: string, updateAgentRequestAdmin: UpdateAgentRequestAdmin, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentByIdAdmin(id, updateAgentRequestAdmin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent by id
         * @param {string} id 
         * @param {UpdateAgentAdminRequest} updateAgentAdminRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentByIdAdmin1(id: string, updateAgentAdminRequest: UpdateAgentAdminRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentByIdAdmin1(id, updateAgentAdminRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates agent\'s driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentDriverLicenseImage(id: string, driverLicenseImage: any, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentDriverLicenseImage(id, driverLicenseImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Licenses/Board/MLS by id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseBoardMlsById(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicenseBoardMlsById(id, agentUpdateLicenseBoardMlsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s license by id
         * @param {string} id 
         * @param {string} licenseId 
         * @param {LicenseRequest} license 
         * @param {any} [licenseImage] 
         * @param {any} [signatureImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseById(id: string, licenseId: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicenseById(id, licenseId, license, licenseImage, signatureImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates agent\'s license image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseImage(id: string, licenseId: string, imageFile: any, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicenseImage(id, licenseId, imageFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update license image approval status
         * @param {string} agentId 
         * @param {string} licenseId 
         * @param {AgentLicenseImageApprovalStatusRequest} agentLicenseImageApprovalStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicenseImageApprovalStatus(agentId: string, licenseId: string, agentLicenseImageApprovalStatusRequest: AgentLicenseImageApprovalStatusRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicenseImageApprovalStatus(agentId, licenseId, agentLicenseImageApprovalStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s licenses by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateLicenseBoardMlsRequest} licenses 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentLicensesById(id: string, licenses: AgentUpdateLicenseBoardMlsRequest, licenseImage?: Array<any>, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentLicensesById(id, licenses, licenseImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s MLSes by agent\'s id
         * @param {string} id 
         * @param {AgentUpdateMlsesRequest} agentUpdateMlsesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentMlsesById(id: string, agentUpdateMlsesRequest: AgentUpdateMlsesRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentMlsesById(id, agentUpdateMlsesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s website onboarding flag to true
         * @param {string} id 
         * @param {AgentUpdateNeedsWebsiteOnboardingRequest} agentUpdateNeedsWebsiteOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgentNeedsWebsiteOnboarding(id: string, agentUpdateNeedsWebsiteOnboardingRequest: AgentUpdateNeedsWebsiteOnboardingRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentNeedsWebsiteOnboarding(id, agentUpdateNeedsWebsiteOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates agent\'s signature image
         * @param {string} id 
         * @param {string} licenseId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateAgentSignatureImage(id: string, licenseId: string, imageFile: any, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateAgentSignatureImage(id, licenseId, imageFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent business email address
         * @param {string} id 
         * @param {AgentUpdateBusinessEmailRequest} agentUpdateBusinessEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusinessEmailAddress(id: string, agentUpdateBusinessEmailRequest: AgentUpdateBusinessEmailRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateBusinessEmailAddress(id, agentUpdateBusinessEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s join date and anniversary date
         * @param {string} id 
         * @param {AgentUpdateJoinDateAndAnniversaryDateRequest} agentUpdateJoinDateAndAnniversaryDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoinDateAndAnniversaryDate(id: string, agentUpdateJoinDateAndAnniversaryDateRequest: AgentUpdateJoinDateAndAnniversaryDateRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateJoinDateAndAnniversaryDate(id, agentUpdateJoinDateAndAnniversaryDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s join real email
         * @param {string} id 
         * @param {AgentUpdateJoinRealEmailRequest} agentUpdateJoinRealEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJoinRealEmail(id: string, agentUpdateJoinRealEmailRequest: AgentUpdateJoinRealEmailRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateJoinRealEmail(id, agentUpdateJoinRealEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateLicenseVerifiedRequest} agentUpdateLicenseVerifiedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicenseVerified(id: string, agentUpdateLicenseVerifiedRequest: AgentUpdateLicenseVerifiedRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateLicenseVerified(id, agentUpdateLicenseVerifiedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent licenseVerified by id
         * @param {string} id 
         * @param {AgentUpdateNeedOnBoardingRequest} agentUpdateNeedOnBoardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedOnBoarding(id: string, agentUpdateNeedOnBoardingRequest: AgentUpdateNeedOnBoardingRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateNeedOnBoarding(id, agentUpdateNeedOnBoardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update needs mentorship for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentNeedsMentorshipRequest} updateAgentNeedsMentorshipRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsMentorship(agentId: string, updateAgentNeedsMentorshipRequest: UpdateAgentNeedsMentorshipRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateNeedsMentorship(agentId, updateAgentNeedsMentorshipRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update if agent completed tax onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsTaxOnboardingRequest} agentUpdateNeedsTaxOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsTaxOnboarding(id: string, agentUpdateNeedsTaxOnboardingRequest: AgentUpdateNeedsTaxOnboardingRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateNeedsTaxOnboarding(id, agentUpdateNeedsTaxOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update if agent completed will beneficiary onboarding
         * @param {string} id 
         * @param {AgentUpdateNeedsWillBeneficiaryOnboardingRequest} agentUpdateNeedsWillBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNeedsWillBeneficiaryOnboarding(id: string, agentUpdateNeedsWillBeneficiaryOnboardingRequest: AgentUpdateNeedsWillBeneficiaryOnboardingRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateNeedsWillBeneficiaryOnboarding(id, agentUpdateNeedsWillBeneficiaryOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s one real impact fund config
         * @param {string} agentId 
         * @param {OneRealImpactFundConfigDto} oneRealImpactFundConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOneRealImpactFund(agentId: string, oneRealImpactFundConfigDto: OneRealImpactFundConfigDto, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateOneRealImpactFund(agentId, oneRealImpactFundConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update if agent prefers to get paid via a business entity
         * @param {string} id 
         * @param {BusinessEntityValue} businessEntityValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaidViaBusinessEntity(id: string, businessEntityValue: BusinessEntityValue, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updatePaidViaBusinessEntity(id, businessEntityValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s plans
         * @param {string} agentId 
         * @param {AgentUpdatePlansRequest} agentUpdatePlansRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlans(agentId: string, agentUpdatePlansRequest: AgentUpdatePlansRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updatePlans(agentId, agentUpdatePlansRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update single check preference for a given agent
         * @param {string} agentId 
         * @param {UpdateAgentSingleCheckPreferenceRequest} updateAgentSingleCheckPreferenceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSingleCheckPreference(agentId: string, updateAgentSingleCheckPreferenceRequest: UpdateAgentSingleCheckPreferenceRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateSingleCheckPreference(agentId, updateAgentSingleCheckPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sponsors for a given agent
         * @param {string} id 
         * @param {UpdateSponsorsRequest} updateSponsorsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSponsorsForAgent(id: string, updateSponsorsRequest: UpdateSponsorsRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.updateSponsorsForAgent(id, updateSponsorsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s individual will beneficiary
         * @param {string} agentId 
         * @param {UpdateWillBeneficiaryRequest} updateWillBeneficiaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWillBeneficiaryForAgent(agentId: string, updateWillBeneficiaryRequest: UpdateWillBeneficiaryRequest, options?: any): AxiosPromise<WillBeneficiaryResponse> {
            return localVarFp.updateWillBeneficiaryForAgent(agentId, updateWillBeneficiaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent\'s will beneficiary trust
         * @param {string} agentId 
         * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: any): AxiosPromise<WillBeneficiaryTrustResponse> {
            return localVarFp.updateWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert agent\'s referral central data
         * @param {string} agentId 
         * @param {AgentUpsertReferralCentralOnboardingDataRequest} agentUpsertReferralCentralOnboardingDataRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertReferralCentralData(agentId: string, agentUpsertReferralCentralOnboardingDataRequest: AgentUpsertReferralCentralOnboardingDataRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.upsertReferralCentralData(agentId, agentUpsertReferralCentralOnboardingDataRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentControllerApi - object-oriented interface
 * @export
 * @class AgentControllerApi
 * @extends {BaseAPI}
 */
export class AgentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add an office to an agent
     * @param {string} id 
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public addOffice(id: string, officeId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).addOffice(id, officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach multiple division designation to the agent
     * @param {string} id 
     * @param {AgentDivisionRequestWrapper} agentDivisionRequestWrapper 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public attachDivisionDesignations(id: string, agentDivisionRequestWrapper: AgentDivisionRequestWrapper, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).attachDivisionDesignations(id, agentDivisionRequestWrapper, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove the cap deferment for an agent
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public cancelDeferredCap(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).cancelDeferredCap(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set the agent to be paid as a personal entity, not a business entity.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public clearPaidViaBusinessEntity(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).clearPaidViaBusinessEntity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates agent\'s license
     * @param {string} id 
     * @param {LicenseRequest} license 
     * @param {any} [licenseImage] 
     * @param {any} [signatureImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public createAgentLicense(id: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).createAgentLicense(id, license, licenseImage, signatureImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create agent\'s individual will beneficiary
     * @param {string} agentId 
     * @param {CreateWillBeneficiaryRequest} createWillBeneficiaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public createWillBeneficiaryForAgent(agentId: string, createWillBeneficiaryRequest: CreateWillBeneficiaryRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).createWillBeneficiaryForAgent(agentId, createWillBeneficiaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create agent\'s will beneficiary trust
     * @param {string} agentId 
     * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public createWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).createWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete agent\'s license by id
     * @param {string} id 
     * @param {string} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public deleteAgentLicenseById(id: string, licenseId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).deleteAgentLicenseById(id, licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach a division designation from  agent
     * @param {string} id 
     * @param {string} divisionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public detachDivisionDesignation(id: string, divisionId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).detachDivisionDesignation(id, divisionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active agent ids created on or after a given date
     * @param {string} joinedAfter 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getActiveAgentIds(joinedAfter: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getActiveAgentIds(joinedAfter, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is faster than /{id} because it fetches data from only one table.
     * @summary Get agent basic-information of user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentBasicInfoById(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentBasicInfoById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is faster than /{ids} because it fetches data from cache.
     * @summary Get basic-information for multiple agents
     * @param {AgentInfosRequest} agentInfosRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentBasicInfoByIds(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentBasicInfoByIds(agentInfosRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentById(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent by slug
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentBySlug(slug: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentBySlug(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the minimal agent details needed to render a comment. Can request multiple keymaker ids
     * @param {Array<string>} keymakerIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentCommentDetails(keymakerIds: Array<string>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentCommentDetails(keymakerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent designation division
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentDesignationDivision(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentDesignationDivision(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets agent\'s driver license image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentDriverLicenseImage(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentDriverLicenseImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent ids by shareworks ids
     * @param {GetAgentIdsByShareworksIdsRequest} getAgentIdsByShareworksIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest: GetAgentIdsByShareworksIdsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentIdsByShareworksId(getAgentIdsByShareworksIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent info for a list of agents, limited to no more than 100 agent IDs
     * @param {AgentInfosRequest} agentInfosRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentInfos(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentInfos(agentInfosRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets agent\'s license and signature images
     * @param {string} licenseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentLicenseImages(licenseId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentLicenseImages(licenseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent(s) by email address
     * @param {string} emailAddress 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentsByEmail(emailAddress: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentsByEmail(emailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agents by ids (limit 20)
     * @param {Array<string>} ids 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentsByIds(ids: Array<string>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentsByIds(ids, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Agents\' ReferralCentral Details
     * @param {Array<string>} [agentIds] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAgentsReferralCentralDetails(agentIds?: Array<string>, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAgentsReferralCentralDetails(agentIds, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all active agent IDs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getAllActiveAgents(options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getAllActiveAgents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if agent is cap deferred
     * @param {string} agentId 
     * @param {string} [asOf] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getCapDefermentStatus(agentId: string, asOf?: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getCapDefermentStatus(agentId, asOf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agents in the network within a specific tier of the given agent
     * @param {string} id 
     * @param {number} tier 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getDownLineAgents(id: string, tier: number, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getDownLineAgents(id, tier, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get down line agents by ids
     * @param {GetDownLineAgentsRequest} getDownLineAgentsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getDownLineAgentsByIds(getDownLineAgentsRequest: GetDownLineAgentsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getDownLineAgentsByIds(getDownLineAgentsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get front line agents basic information
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getFrontLineAgentsInfo(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getFrontLineAgentsInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the agent we use as a stand-in for LOI agents in revShare trees
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getLOIAgent(options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getLOIAgent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set an agent\'s national identifications
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getNationalIdentifications(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getNationalIdentifications(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get network size by tier
     * @param {string} agentId 
     * @param {string} [asOfDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getNetworkSizeByTier(agentId: string, asOfDate?: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getNetworkSizeByTier(agentId, asOfDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Opcity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getOpcity(options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getOpcity(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User\'s Profile Score
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getProfileScore(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getProfileScore(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public agent info for a list of agents, limited to no more than 100 agent IDs
     * @param {AgentInfosRequest} agentInfosRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getPublicAgentInfos(agentInfosRequest: AgentInfosRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getPublicAgentInfos(agentInfosRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s last and next anniversaries relative to provided date
     * @param {string} agentId 
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getRelativeAnniversaries(agentId: string, date: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getRelativeAnniversaries(agentId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s sponsor tree
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getSponsorTree(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getSponsorTree(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getTipaltiSignUpUrl(options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getTipaltiSignUpUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s will beneficiary
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getWillBeneficiaryAndTypeForAgent(agentId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getWillBeneficiaryAndTypeForAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s will beneficiary
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public getWillBeneficiaryForAgent(agentId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).getWillBeneficiaryForAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return whether or not a slug is available
     * @param {string} slug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public isSlugAvailable(slug: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).isSlugAvailable(slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move agents from one mls to other
     * @param {MigrateMlsRequest} migrateMlsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public migrateBoard(migrateMlsRequest: MigrateMlsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).migrateBoard(migrateMlsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move agents from one board to other
     * @param {MigrateBoardRequest} migrateBoardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public migrateBoard1(migrateBoardRequest: MigrateBoardRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).migrateBoard1(migrateBoardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Onboards a given user to the external payment system
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public onboardToPaymentSystem(agentId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).onboardToPaymentSystem(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Opt in an agent to equity plan
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public optInToEquityPlan(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).optInToEquityPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Opt out an agent from equity plan
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public optOutOfEquityPlan(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).optOutOfEquityPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for agents
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
     * @param {string} [searchText] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {string} [phoneNumber] 
     * @param {boolean} [nonReportable] 
     * @param {string} [id] 
     * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
     * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
     * @param {Array<string>} [officeId] 
     * @param {Array<string>} [mlsId] 
     * @param {Array<string>} [boardId] 
     * @param {Array<string>} [divisionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public publicSearch(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).publicSearch(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an office from an agent
     * @param {string} id 
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public removeOffice(id: string, officeId: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).removeOffice(id, officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove sponsor for a given agent
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public removeSponsorForAgent(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).removeSponsorForAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove sponsors for a given agent
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public removeSponsorsForAgent(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).removeSponsorsForAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request subscribe to Chime
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public requestChimeSubscribe(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).requestChimeSubscribe(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request unsubscribe to Chime
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public requestChimeUnsubscribe(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).requestChimeUnsubscribe(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for active agents
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>} [sortBy] 
     * @param {string} [name] 
     * @param {Array<boolean>} [nonReportable] 
     * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public searchActiveAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'ACCOUNT_COUNTRY'>, name?: string, nonReportable?: Array<boolean>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).searchActiveAgents(pageNumber, pageSize, sortDirection, sortBy, name, nonReportable, country, stateOrProvince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for agents
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
     * @param {string} [searchText] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {string} [phoneNumber] 
     * @param {boolean} [nonReportable] 
     * @param {string} [id] 
     * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
     * @param {Array<'UNITED_STATES' | 'CANADA'>} [country] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvince] 
     * @param {Array<string>} [officeId] 
     * @param {Array<string>} [mlsId] 
     * @param {Array<string>} [boardId] 
     * @param {Array<string>} [divisionIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public searchAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, firstName?: string, lastName?: string, email?: string, phoneNumber?: string, nonReportable?: boolean, id?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, country?: Array<'UNITED_STATES' | 'CANADA'>, stateOrProvince?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, officeId?: Array<string>, mlsId?: Array<string>, boardId?: Array<string>, divisionIds?: Array<string>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).searchAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, firstName, lastName, email, phoneNumber, nonReportable, id, status, country, stateOrProvince, officeId, mlsId, boardId, divisionIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for agents
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>} [sortBy] 
     * @param {string} [searchText] 
     * @param {Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public searchNonBrokerAgents(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'STATUS' | 'ANNIVERSARY_DATE' | 'CREATED_AT' | 'ACCOUNT_COUNTRY'>, searchText?: string, status?: Array<'CANDIDATE' | 'ACTIVE' | 'INACTIVE' | 'REJECTED'>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).searchNonBrokerAgents(pageNumber, pageSize, sortDirection, sortBy, searchText, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Treat the agent as if they\'ve capped for their first specified number of months at Real
     * @param {string} id 
     * @param {number} numberOfMonths 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public setDeferredCap(id: string, numberOfMonths: number, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).setDeferredCap(id, numberOfMonths, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set an agent\'s national identifications
     * @param {string} id 
     * @param {SetNationalIdentficationsRequest} setNationalIdentficationsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public setNationalIdentifications(id: string, setNationalIdentficationsRequest: SetNationalIdentficationsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).setNationalIdentifications(id, setNationalIdentficationsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Terminate an Agent
     * @param {string} id 
     * @param {TerminateAgentRequest} terminateAgentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public terminateAgent(id: string, terminateAgentRequest: TerminateAgentRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).terminateAgent(id, terminateAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unterminate an Agent
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public unTerminateAgent(id: string, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).unTerminateAgent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s boards by agent\'s id
     * @param {string} id 
     * @param {AgentUpdateBoardsRequest} agentUpdateBoardsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentBoardsById(id: string, agentUpdateBoardsRequest: AgentUpdateBoardsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentBoardsById(id, agentUpdateBoardsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent by id
     * @param {string} id 
     * @param {AgentUpdateRequest} agentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentById(id: string, agentUpdateRequest: AgentUpdateRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentById(id, agentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent by id
     * @param {string} id 
     * @param {UpdateAgentRequestAdmin} updateAgentRequestAdmin 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentByIdAdmin(id: string, updateAgentRequestAdmin: UpdateAgentRequestAdmin, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentByIdAdmin(id, updateAgentRequestAdmin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent by id
     * @param {string} id 
     * @param {UpdateAgentAdminRequest} updateAgentAdminRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentByIdAdmin1(id: string, updateAgentAdminRequest: UpdateAgentAdminRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentByIdAdmin1(id, updateAgentAdminRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates agent\'s driver license image
     * @param {string} id 
     * @param {any} driverLicenseImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentDriverLicenseImage(id: string, driverLicenseImage: any, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentDriverLicenseImage(id, driverLicenseImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Licenses/Board/MLS by id
     * @param {string} id 
     * @param {AgentUpdateLicenseBoardMlsRequest} agentUpdateLicenseBoardMlsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicenseBoardMlsById(id: string, agentUpdateLicenseBoardMlsRequest: AgentUpdateLicenseBoardMlsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentLicenseBoardMlsById(id, agentUpdateLicenseBoardMlsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s license by id
     * @param {string} id 
     * @param {string} licenseId 
     * @param {LicenseRequest} license 
     * @param {any} [licenseImage] 
     * @param {any} [signatureImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicenseById(id: string, licenseId: string, license: LicenseRequest, licenseImage?: any, signatureImage?: any, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentLicenseById(id, licenseId, license, licenseImage, signatureImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates agent\'s license image
     * @param {string} id 
     * @param {string} licenseId 
     * @param {any} imageFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicenseImage(id: string, licenseId: string, imageFile: any, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentLicenseImage(id, licenseId, imageFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update license image approval status
     * @param {string} agentId 
     * @param {string} licenseId 
     * @param {AgentLicenseImageApprovalStatusRequest} agentLicenseImageApprovalStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicenseImageApprovalStatus(agentId: string, licenseId: string, agentLicenseImageApprovalStatusRequest: AgentLicenseImageApprovalStatusRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentLicenseImageApprovalStatus(agentId, licenseId, agentLicenseImageApprovalStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s licenses by agent\'s id
     * @param {string} id 
     * @param {AgentUpdateLicenseBoardMlsRequest} licenses 
     * @param {Array<any>} [licenseImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentLicensesById(id: string, licenses: AgentUpdateLicenseBoardMlsRequest, licenseImage?: Array<any>, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentLicensesById(id, licenses, licenseImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s MLSes by agent\'s id
     * @param {string} id 
     * @param {AgentUpdateMlsesRequest} agentUpdateMlsesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentMlsesById(id: string, agentUpdateMlsesRequest: AgentUpdateMlsesRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentMlsesById(id, agentUpdateMlsesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s website onboarding flag to true
     * @param {string} id 
     * @param {AgentUpdateNeedsWebsiteOnboardingRequest} agentUpdateNeedsWebsiteOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentNeedsWebsiteOnboarding(id: string, agentUpdateNeedsWebsiteOnboardingRequest: AgentUpdateNeedsWebsiteOnboardingRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentNeedsWebsiteOnboarding(id, agentUpdateNeedsWebsiteOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates agent\'s signature image
     * @param {string} id 
     * @param {string} licenseId 
     * @param {any} imageFile 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateAgentSignatureImage(id: string, licenseId: string, imageFile: any, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateAgentSignatureImage(id, licenseId, imageFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent business email address
     * @param {string} id 
     * @param {AgentUpdateBusinessEmailRequest} agentUpdateBusinessEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateBusinessEmailAddress(id: string, agentUpdateBusinessEmailRequest: AgentUpdateBusinessEmailRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateBusinessEmailAddress(id, agentUpdateBusinessEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s join date and anniversary date
     * @param {string} id 
     * @param {AgentUpdateJoinDateAndAnniversaryDateRequest} agentUpdateJoinDateAndAnniversaryDateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateJoinDateAndAnniversaryDate(id: string, agentUpdateJoinDateAndAnniversaryDateRequest: AgentUpdateJoinDateAndAnniversaryDateRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateJoinDateAndAnniversaryDate(id, agentUpdateJoinDateAndAnniversaryDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s join real email
     * @param {string} id 
     * @param {AgentUpdateJoinRealEmailRequest} agentUpdateJoinRealEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateJoinRealEmail(id: string, agentUpdateJoinRealEmailRequest: AgentUpdateJoinRealEmailRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateJoinRealEmail(id, agentUpdateJoinRealEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent licenseVerified by id
     * @param {string} id 
     * @param {AgentUpdateLicenseVerifiedRequest} agentUpdateLicenseVerifiedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateLicenseVerified(id: string, agentUpdateLicenseVerifiedRequest: AgentUpdateLicenseVerifiedRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateLicenseVerified(id, agentUpdateLicenseVerifiedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent licenseVerified by id
     * @param {string} id 
     * @param {AgentUpdateNeedOnBoardingRequest} agentUpdateNeedOnBoardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateNeedOnBoarding(id: string, agentUpdateNeedOnBoardingRequest: AgentUpdateNeedOnBoardingRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateNeedOnBoarding(id, agentUpdateNeedOnBoardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update needs mentorship for a given agent
     * @param {string} agentId 
     * @param {UpdateAgentNeedsMentorshipRequest} updateAgentNeedsMentorshipRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateNeedsMentorship(agentId: string, updateAgentNeedsMentorshipRequest: UpdateAgentNeedsMentorshipRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateNeedsMentorship(agentId, updateAgentNeedsMentorshipRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update if agent completed tax onboarding
     * @param {string} id 
     * @param {AgentUpdateNeedsTaxOnboardingRequest} agentUpdateNeedsTaxOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateNeedsTaxOnboarding(id: string, agentUpdateNeedsTaxOnboardingRequest: AgentUpdateNeedsTaxOnboardingRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateNeedsTaxOnboarding(id, agentUpdateNeedsTaxOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update if agent completed will beneficiary onboarding
     * @param {string} id 
     * @param {AgentUpdateNeedsWillBeneficiaryOnboardingRequest} agentUpdateNeedsWillBeneficiaryOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateNeedsWillBeneficiaryOnboarding(id: string, agentUpdateNeedsWillBeneficiaryOnboardingRequest: AgentUpdateNeedsWillBeneficiaryOnboardingRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateNeedsWillBeneficiaryOnboarding(id, agentUpdateNeedsWillBeneficiaryOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s one real impact fund config
     * @param {string} agentId 
     * @param {OneRealImpactFundConfigDto} oneRealImpactFundConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateOneRealImpactFund(agentId: string, oneRealImpactFundConfigDto: OneRealImpactFundConfigDto, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateOneRealImpactFund(agentId, oneRealImpactFundConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update if agent prefers to get paid via a business entity
     * @param {string} id 
     * @param {BusinessEntityValue} businessEntityValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updatePaidViaBusinessEntity(id: string, businessEntityValue: BusinessEntityValue, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updatePaidViaBusinessEntity(id, businessEntityValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s plans
     * @param {string} agentId 
     * @param {AgentUpdatePlansRequest} agentUpdatePlansRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updatePlans(agentId: string, agentUpdatePlansRequest: AgentUpdatePlansRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updatePlans(agentId, agentUpdatePlansRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update single check preference for a given agent
     * @param {string} agentId 
     * @param {UpdateAgentSingleCheckPreferenceRequest} updateAgentSingleCheckPreferenceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateSingleCheckPreference(agentId: string, updateAgentSingleCheckPreferenceRequest: UpdateAgentSingleCheckPreferenceRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateSingleCheckPreference(agentId, updateAgentSingleCheckPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sponsors for a given agent
     * @param {string} id 
     * @param {UpdateSponsorsRequest} updateSponsorsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateSponsorsForAgent(id: string, updateSponsorsRequest: UpdateSponsorsRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateSponsorsForAgent(id, updateSponsorsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s individual will beneficiary
     * @param {string} agentId 
     * @param {UpdateWillBeneficiaryRequest} updateWillBeneficiaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateWillBeneficiaryForAgent(agentId: string, updateWillBeneficiaryRequest: UpdateWillBeneficiaryRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateWillBeneficiaryForAgent(agentId, updateWillBeneficiaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent\'s will beneficiary trust
     * @param {string} agentId 
     * @param {WillBeneficiaryTrustRequest} willBeneficiaryTrustRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public updateWillBeneficiaryTrustForAgent(agentId: string, willBeneficiaryTrustRequest: WillBeneficiaryTrustRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).updateWillBeneficiaryTrustForAgent(agentId, willBeneficiaryTrustRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert agent\'s referral central data
     * @param {string} agentId 
     * @param {AgentUpsertReferralCentralOnboardingDataRequest} agentUpsertReferralCentralOnboardingDataRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentControllerApi
     */
    public upsertReferralCentralData(agentId: string, agentUpsertReferralCentralOnboardingDataRequest: AgentUpsertReferralCentralOnboardingDataRequest, options?: AxiosRequestConfig) {
        return AgentControllerApiFp(this.configuration).upsertReferralCentralData(agentId, agentUpsertReferralCentralOnboardingDataRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentDocumentControllerApi - axios parameter creator
 * @export
 */
export const AgentDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (agentId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('deleteDocument', 'agentId', agentId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('deleteDocument', 'documentId', documentId)
            const localVarPath = `/api/v1/agent-documents/{agentId}/doc/{documentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {AgentDocumentUpdateRequest} agentDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocument: async (agentId: string, documentId: string, agentDocumentUpdateRequest: AgentDocumentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('editDocument', 'agentId', agentId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('editDocument', 'documentId', documentId)
            // verify required parameter 'agentDocumentUpdateRequest' is not null or undefined
            assertParamExists('editDocument', 'agentDocumentUpdateRequest', agentDocumentUpdateRequest)
            const localVarPath = `/api/v1/agent-documents/{agentId}/doc/{documentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agentDocumentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get documents for agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getDocuments', 'agentId', agentId)
            const localVarPath = `/api/v1/agent-documents/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get link for document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLink: async (agentId: string, documentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getLink', 'agentId', agentId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getLink', 'documentId', documentId)
            const localVarPath = `/api/v1/agent-documents/{agentId}/doc/{documentId}/link`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file
         * @param {string} agentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument: async (agentId: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('uploadDocument', 'agentId', agentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDocument', 'file', file)
            const localVarPath = `/api/v1/agent-documents/{agentId}/upload`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentDocumentControllerApi - functional programming interface
 * @export
 */
export const AgentDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(agentId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(agentId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {AgentDocumentUpdateRequest} agentDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editDocument(agentId: string, documentId: string, agentDocumentUpdateRequest: AgentDocumentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editDocument(agentId, documentId, agentDocumentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get documents for agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get link for document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLink(agentId: string, documentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLink(agentId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload file
         * @param {string} agentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDocument(agentId: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDocument(agentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentDocumentControllerApi - factory interface
 * @export
 */
export const AgentDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(agentId: string, documentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocument(agentId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary edit an agent document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {AgentDocumentUpdateRequest} agentDocumentUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editDocument(agentId: string, documentId: string, agentDocumentUpdateRequest: AgentDocumentUpdateRequest, options?: any): AxiosPromise<AgentDocumentResponse> {
            return localVarFp.editDocument(agentId, documentId, agentDocumentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get documents for agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(agentId: string, options?: any): AxiosPromise<AgentDocumentsResponse> {
            return localVarFp.getDocuments(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get link for document
         * @param {string} agentId 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLink(agentId: string, documentId: string, options?: any): AxiosPromise<string> {
            return localVarFp.getLink(agentId, documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file
         * @param {string} agentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDocument(agentId: string, file: any, options?: any): AxiosPromise<AgentDocumentResponse> {
            return localVarFp.uploadDocument(agentId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentDocumentControllerApi - object-oriented interface
 * @export
 * @class AgentDocumentControllerApi
 * @extends {BaseAPI}
 */
export class AgentDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary edit an agent document
     * @param {string} agentId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentDocumentControllerApi
     */
    public deleteDocument(agentId: string, documentId: string, options?: AxiosRequestConfig) {
        return AgentDocumentControllerApiFp(this.configuration).deleteDocument(agentId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary edit an agent document
     * @param {string} agentId 
     * @param {string} documentId 
     * @param {AgentDocumentUpdateRequest} agentDocumentUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentDocumentControllerApi
     */
    public editDocument(agentId: string, documentId: string, agentDocumentUpdateRequest: AgentDocumentUpdateRequest, options?: AxiosRequestConfig) {
        return AgentDocumentControllerApiFp(this.configuration).editDocument(agentId, documentId, agentDocumentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get documents for agent
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentDocumentControllerApi
     */
    public getDocuments(agentId: string, options?: AxiosRequestConfig) {
        return AgentDocumentControllerApiFp(this.configuration).getDocuments(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get link for document
     * @param {string} agentId 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentDocumentControllerApi
     */
    public getLink(agentId: string, documentId: string, options?: AxiosRequestConfig) {
        return AgentDocumentControllerApiFp(this.configuration).getLink(agentId, documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file
     * @param {string} agentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentDocumentControllerApi
     */
    public uploadDocument(agentId: string, file: any, options?: AxiosRequestConfig) {
        return AgentDocumentControllerApiFp(this.configuration).uploadDocument(agentId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentLeadControllerApi - axios parameter creator
 * @export
 */
export const AgentLeadControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create agent lead by agent id
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLeads: async (createAgentLeadRequest: CreateAgentLeadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgentLeadRequest' is not null or undefined
            assertParamExists('createAgentLeads', 'createAgentLeadRequest', createAgentLeadRequest)
            const localVarPath = `/api/v1/leads/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgentLeadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent leads by agent id
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgentLeadsForAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getAllAgentLeadsForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/leads/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentLeadControllerApi - functional programming interface
 * @export
 */
export const AgentLeadControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentLeadControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create agent lead by agent id
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgentLeads(createAgentLeadRequest: CreateAgentLeadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentLeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgentLeads(createAgentLeadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent leads by agent id
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAgentLeadsForAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentLeadResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAgentLeadsForAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentLeadControllerApi - factory interface
 * @export
 */
export const AgentLeadControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentLeadControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create agent lead by agent id
         * @param {CreateAgentLeadRequest} createAgentLeadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgentLeads(createAgentLeadRequest: CreateAgentLeadRequest, options?: any): AxiosPromise<AgentLeadResponse> {
            return localVarFp.createAgentLeads(createAgentLeadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent leads by agent id
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAgentLeadsForAgent(agentId: string, options?: any): AxiosPromise<Array<AgentLeadResponse>> {
            return localVarFp.getAllAgentLeadsForAgent(agentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentLeadControllerApi - object-oriented interface
 * @export
 * @class AgentLeadControllerApi
 * @extends {BaseAPI}
 */
export class AgentLeadControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create agent lead by agent id
     * @param {CreateAgentLeadRequest} createAgentLeadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLeadControllerApi
     */
    public createAgentLeads(createAgentLeadRequest: CreateAgentLeadRequest, options?: AxiosRequestConfig) {
        return AgentLeadControllerApiFp(this.configuration).createAgentLeads(createAgentLeadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent leads by agent id
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentLeadControllerApi
     */
    public getAllAgentLeadsForAgent(agentId: string, options?: AxiosRequestConfig) {
        return AgentLeadControllerApiFp(this.configuration).getAllAgentLeadsForAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentPlanControllerApi - axios parameter creator
 * @export
 */
export const AgentPlanControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} createCommissionPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionPlan: async (createCommissionPlanRequest: CreateCommissionPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCommissionPlanRequest' is not null or undefined
            assertParamExists('createCommissionPlan', 'createCommissionPlanRequest', createCommissionPlanRequest)
            const localVarPath = `/api/v1/agents/plans/commission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommissionPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} createEliteEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEliteEquityAwardPlan: async (createEliteEquityAwardPlanRequest: CreateEliteEquityAwardPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEliteEquityAwardPlanRequest' is not null or undefined
            assertParamExists('createEliteEquityAwardPlan', 'createEliteEquityAwardPlanRequest', createEliteEquityAwardPlanRequest)
            const localVarPath = `/api/v1/agents/plans/elite_equity_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEliteEquityAwardPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} createEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityAwardPlan: async (createEquityAwardPlanRequest: CreateEquityAwardPlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEquityAwardPlanRequest' is not null or undefined
            assertParamExists('createEquityAwardPlan', 'createEquityAwardPlanRequest', createEquityAwardPlanRequest)
            const localVarPath = `/api/v1/agents/plans/equity_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEquityAwardPlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} createEquityPurchasePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityPurchasePlan: async (createEquityPurchasePlanRequest: CreateEquityPurchasePlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEquityPurchasePlanRequest' is not null or undefined
            assertParamExists('createEquityPurchasePlan', 'createEquityPurchasePlanRequest', createEquityPurchasePlanRequest)
            const localVarPath = `/api/v1/agents/plans/equity_purchase`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEquityPurchasePlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} createRevenueSharePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevenueSharePlan: async (createRevenueSharePlanRequest: CreateRevenueSharePlanRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRevenueSharePlanRequest' is not null or undefined
            assertParamExists('createRevenueSharePlan', 'createRevenueSharePlanRequest', createRevenueSharePlanRequest)
            const localVarPath = `/api/v1/agents/plans/revenue_share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRevenueSharePlanRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all commission plan basic info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommissionPlansBasicInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agents/plans/commission/basic-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCommissionPlan', 'id', id)
            const localVarPath = `/api/v1/agents/plans/commission/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get official commission plan by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommissionPlanByCountry: async (country: 'UNITED_STATES' | 'CANADA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getCommissionPlanByCountry', 'country', country)
            const localVarPath = `/api/v1/agents/plans/commission/official`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteEquityAwardPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEliteEquityAwardPlan', 'id', id)
            const localVarPath = `/api/v1/agents/plans/elite_equity_award/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityAwardPlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEquityAwardPlan', 'id', id)
            const localVarPath = `/api/v1/agents/plans/equity_award/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityPurchasePlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEquityPurchasePlan', 'id', id)
            const localVarPath = `/api/v1/agents/plans/equity_purchase/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get official plan & award by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialPlanByCountry: async (country: 'UNITED_STATES' | 'CANADA', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('getOfficialPlanByCountry', 'country', country)
            const localVarPath = `/api/v1/agents/plans/official`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueSharePlan: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRevenueSharePlan', 'id', id)
            const localVarPath = `/api/v1/agents/plans/revenue_share/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentPlanControllerApi - functional programming interface
 * @export
 */
export const AgentPlanControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentPlanControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} createCommissionPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommissionPlan(createCommissionPlanRequest: CreateCommissionPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommissionPlan(createCommissionPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} createEliteEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest: CreateEliteEquityAwardPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteEquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} createEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquityAwardPlan(createEquityAwardPlanRequest: CreateEquityAwardPlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquityAwardPlan(createEquityAwardPlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} createEquityPurchasePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEquityPurchasePlan(createEquityPurchasePlanRequest: CreateEquityPurchasePlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityPurchasePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEquityPurchasePlan(createEquityPurchasePlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} createRevenueSharePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRevenueSharePlan(createRevenueSharePlanRequest: CreateRevenueSharePlanRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueSharePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRevenueSharePlan(createRevenueSharePlanRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all commission plan basic info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCommissionPlansBasicInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllCommissionPlansBasicInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCommissionPlansBasicInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommissionPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get official commission plan by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommissionPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommissionPlanByCountry(country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEliteEquityAwardPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EliteEquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEliteEquityAwardPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquityAwardPlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityAwardPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquityAwardPlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEquityPurchasePlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquityPurchasePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEquityPurchasePlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get official plan & award by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficialPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficialPlanByCountry(country, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRevenueSharePlan(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RevenueSharePlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRevenueSharePlan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentPlanControllerApi - factory interface
 * @export
 */
export const AgentPlanControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentPlanControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a commission plan
         * @param {CreateCommissionPlanRequest} createCommissionPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommissionPlan(createCommissionPlanRequest: CreateCommissionPlanRequest, options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.createCommissionPlan(createCommissionPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an elite equity award plan
         * @param {CreateEliteEquityAwardPlanRequest} createEliteEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest: CreateEliteEquityAwardPlanRequest, options?: any): AxiosPromise<EliteEquityAwardPlanResponse> {
            return localVarFp.createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an equity award plan
         * @param {CreateEquityAwardPlanRequest} createEquityAwardPlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityAwardPlan(createEquityAwardPlanRequest: CreateEquityAwardPlanRequest, options?: any): AxiosPromise<EquityAwardPlanResponse> {
            return localVarFp.createEquityAwardPlan(createEquityAwardPlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an equity purchase plan
         * @param {CreateEquityPurchasePlanRequest} createEquityPurchasePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEquityPurchasePlan(createEquityPurchasePlanRequest: CreateEquityPurchasePlanRequest, options?: any): AxiosPromise<EquityPurchasePlanResponse> {
            return localVarFp.createEquityPurchasePlan(createEquityPurchasePlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a revenue sharing plan
         * @param {CreateRevenueSharePlanRequest} createRevenueSharePlanRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevenueSharePlan(createRevenueSharePlanRequest: CreateRevenueSharePlanRequest, options?: any): AxiosPromise<RevenueSharePlanResponse> {
            return localVarFp.createRevenueSharePlan(createRevenueSharePlanRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all commission plan basic info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCommissionPlansBasicInfo(options?: any): AxiosPromise<AllCommissionPlansBasicInfoResponse> {
            return localVarFp.getAllCommissionPlansBasicInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get commission plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommissionPlan(id: string, options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.getCommissionPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get official commission plan by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: any): AxiosPromise<CommissionPlanResponse> {
            return localVarFp.getCommissionPlanByCountry(country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get elite equity award plan id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEliteEquityAwardPlan(id: string, options?: any): AxiosPromise<EliteEquityAwardPlanResponse> {
            return localVarFp.getEliteEquityAwardPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equity award plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityAwardPlan(id: string, options?: any): AxiosPromise<EquityAwardPlanResponse> {
            return localVarFp.getEquityAwardPlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get equity purchase plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEquityPurchasePlan(id: string, options?: any): AxiosPromise<EquityPurchasePlanResponse> {
            return localVarFp.getEquityPurchasePlan(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get official plan & award by country
         * @param {'UNITED_STATES' | 'CANADA'} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: any): AxiosPromise<OfficialPlanResponse> {
            return localVarFp.getOfficialPlanByCountry(country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get revenue sharing plan by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevenueSharePlan(id: string, options?: any): AxiosPromise<RevenueSharePlanResponse> {
            return localVarFp.getRevenueSharePlan(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentPlanControllerApi - object-oriented interface
 * @export
 * @class AgentPlanControllerApi
 * @extends {BaseAPI}
 */
export class AgentPlanControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a commission plan
     * @param {CreateCommissionPlanRequest} createCommissionPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createCommissionPlan(createCommissionPlanRequest: CreateCommissionPlanRequest, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).createCommissionPlan(createCommissionPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an elite equity award plan
     * @param {CreateEliteEquityAwardPlanRequest} createEliteEquityAwardPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest: CreateEliteEquityAwardPlanRequest, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).createEliteEquityAwardPlan(createEliteEquityAwardPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an equity award plan
     * @param {CreateEquityAwardPlanRequest} createEquityAwardPlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEquityAwardPlan(createEquityAwardPlanRequest: CreateEquityAwardPlanRequest, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).createEquityAwardPlan(createEquityAwardPlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an equity purchase plan
     * @param {CreateEquityPurchasePlanRequest} createEquityPurchasePlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createEquityPurchasePlan(createEquityPurchasePlanRequest: CreateEquityPurchasePlanRequest, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).createEquityPurchasePlan(createEquityPurchasePlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a revenue sharing plan
     * @param {CreateRevenueSharePlanRequest} createRevenueSharePlanRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public createRevenueSharePlan(createRevenueSharePlanRequest: CreateRevenueSharePlanRequest, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).createRevenueSharePlan(createRevenueSharePlanRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all commission plan basic info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getAllCommissionPlansBasicInfo(options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getAllCommissionPlansBasicInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get commission plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getCommissionPlan(id: string, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getCommissionPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get official commission plan by country
     * @param {'UNITED_STATES' | 'CANADA'} country 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getCommissionPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getCommissionPlanByCountry(country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get elite equity award plan id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEliteEquityAwardPlan(id: string, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getEliteEquityAwardPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equity award plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEquityAwardPlan(id: string, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getEquityAwardPlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get equity purchase plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getEquityPurchasePlan(id: string, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getEquityPurchasePlan(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get official plan & award by country
     * @param {'UNITED_STATES' | 'CANADA'} country 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getOfficialPlanByCountry(country: 'UNITED_STATES' | 'CANADA', options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getOfficialPlanByCountry(country, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get revenue sharing plan by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentPlanControllerApi
     */
    public getRevenueSharePlan(id: string, options?: AxiosRequestConfig) {
        return AgentPlanControllerApiFp(this.configuration).getRevenueSharePlan(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgentWebsiteControllerApi - axios parameter creator
 * @export
 */
export const AgentWebsiteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create agent website by id or slug
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebsiteForAgent: async (createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrUpdateAgentWebsiteRequest' is not null or undefined
            assertParamExists('createWebsiteForAgent', 'createOrUpdateAgentWebsiteRequest', createOrUpdateAgentWebsiteRequest)
            const localVarPath = `/api/v1/website/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAgentWebsiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent website by id or slug
         * @param {string} agentIdOrSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteByAgentIdOrSlug: async (agentIdOrSlug: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentIdOrSlug' is not null or undefined
            assertParamExists('getWebsiteByAgentIdOrSlug', 'agentIdOrSlug', agentIdOrSlug)
            const localVarPath = `/api/v1/website/{agentIdOrSlug}`
                .replace(`{${"agentIdOrSlug"}}`, encodeURIComponent(String(agentIdOrSlug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update agent website by id
         * @param {string} websiteId 
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebsiteById: async (websiteId: string, createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'websiteId' is not null or undefined
            assertParamExists('updateWebsiteById', 'websiteId', websiteId)
            // verify required parameter 'createOrUpdateAgentWebsiteRequest' is not null or undefined
            assertParamExists('updateWebsiteById', 'createOrUpdateAgentWebsiteRequest', createOrUpdateAgentWebsiteRequest)
            const localVarPath = `/api/v1/website/{websiteId}`
                .replace(`{${"websiteId"}}`, encodeURIComponent(String(websiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateAgentWebsiteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgentWebsiteControllerApi - functional programming interface
 * @export
 */
export const AgentWebsiteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgentWebsiteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create agent website by id or slug
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebsiteForAgent(createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentWebsiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebsiteForAgent(createOrUpdateAgentWebsiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent website by id or slug
         * @param {string} agentIdOrSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebsiteByAgentIdOrSlug(agentIdOrSlug: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentWebsiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebsiteByAgentIdOrSlug(agentIdOrSlug, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update agent website by id
         * @param {string} websiteId 
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebsiteById(websiteId: string, createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentWebsiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebsiteById(websiteId, createOrUpdateAgentWebsiteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgentWebsiteControllerApi - factory interface
 * @export
 */
export const AgentWebsiteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgentWebsiteControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create agent website by id or slug
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebsiteForAgent(createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: any): AxiosPromise<AgentWebsiteResponse> {
            return localVarFp.createWebsiteForAgent(createOrUpdateAgentWebsiteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent website by id or slug
         * @param {string} agentIdOrSlug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebsiteByAgentIdOrSlug(agentIdOrSlug: string, options?: any): AxiosPromise<AgentWebsiteResponse> {
            return localVarFp.getWebsiteByAgentIdOrSlug(agentIdOrSlug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update agent website by id
         * @param {string} websiteId 
         * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebsiteById(websiteId: string, createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: any): AxiosPromise<AgentWebsiteResponse> {
            return localVarFp.updateWebsiteById(websiteId, createOrUpdateAgentWebsiteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgentWebsiteControllerApi - object-oriented interface
 * @export
 * @class AgentWebsiteControllerApi
 * @extends {BaseAPI}
 */
export class AgentWebsiteControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create agent website by id or slug
     * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWebsiteControllerApi
     */
    public createWebsiteForAgent(createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: AxiosRequestConfig) {
        return AgentWebsiteControllerApiFp(this.configuration).createWebsiteForAgent(createOrUpdateAgentWebsiteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent website by id or slug
     * @param {string} agentIdOrSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWebsiteControllerApi
     */
    public getWebsiteByAgentIdOrSlug(agentIdOrSlug: string, options?: AxiosRequestConfig) {
        return AgentWebsiteControllerApiFp(this.configuration).getWebsiteByAgentIdOrSlug(agentIdOrSlug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update agent website by id
     * @param {string} websiteId 
     * @param {CreateOrUpdateAgentWebsiteRequest} createOrUpdateAgentWebsiteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgentWebsiteControllerApi
     */
    public updateWebsiteById(websiteId: string, createOrUpdateAgentWebsiteRequest: CreateOrUpdateAgentWebsiteRequest, options?: AxiosRequestConfig) {
        return AgentWebsiteControllerApiFp(this.configuration).updateWebsiteById(websiteId, createOrUpdateAgentWebsiteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AgreementDefinitionControllerApi - axios parameter creator
 * @export
 */
export const AgreementDefinitionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create an agreement amendment definition
         * @param {string} agreementDefinitionId 
         * @param {PostAgreementAmendmentRequest} postAgreementAmendmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementAmendmentDefinition: async (agreementDefinitionId: string, postAgreementAmendmentRequest: PostAgreementAmendmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDefinitionId' is not null or undefined
            assertParamExists('createAgreementAmendmentDefinition', 'agreementDefinitionId', agreementDefinitionId)
            // verify required parameter 'postAgreementAmendmentRequest' is not null or undefined
            assertParamExists('createAgreementAmendmentDefinition', 'postAgreementAmendmentRequest', postAgreementAmendmentRequest)
            const localVarPath = `/api/v1/agreement-definitions/{agreementDefinitionId}/amendments`
                .replace(`{${"agreementDefinitionId"}}`, encodeURIComponent(String(agreementDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAgreementAmendmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an agreement definition
         * @param {AgreementDefinitionCreationRequest} agreementDefinitionCreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createAgreementDefinition: async (agreementDefinitionCreationRequest: AgreementDefinitionCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDefinitionCreationRequest' is not null or undefined
            assertParamExists('createAgreementDefinition', 'agreementDefinitionCreationRequest', agreementDefinitionCreationRequest)
            const localVarPath = `/api/v1/agreement-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreementDefinitionCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agreement definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinition: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgreementDefinition', 'id', id)
            const localVarPath = `/api/v1/agreement-definitions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all agreement definitions based on criteria
         * @param {string} commissionPlanId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinitionByPlanAndType: async (commissionPlanId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commissionPlanId' is not null or undefined
            assertParamExists('getAgreementDefinitionByPlanAndType', 'commissionPlanId', commissionPlanId)
            // verify required parameter 'agreementType' is not null or undefined
            assertParamExists('getAgreementDefinitionByPlanAndType', 'agreementType', agreementType)
            const localVarPath = `/api/v1/agreement-definitions/criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commissionPlanId !== undefined) {
                localVarQueryParameter['commissionPlanId'] = commissionPlanId;
            }

            if (agreementType !== undefined) {
                localVarQueryParameter['agreementType'] = agreementType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agreement definitions
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinitions: async (state: 'ACTIVE' | 'CREATED' | 'ARCHIVED', pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getAgreementDefinitions', 'state', state)
            const localVarPath = `/api/v1/agreement-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecentlyUpdatedAgreementDefinitions: async (agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', recentCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agreement-definitions/recently-updated`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agreementType) {
                localVarQueryParameter['agreementType'] = Array.from(agreementType);
            }

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (recentCount !== undefined) {
                localVarQueryParameter['recentCount'] = recentCount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create AgreementDefinition
         * @param {CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition} createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition Request body containing an agreement definition of any subtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAgreementDefinition: async (createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition: CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition' is not null or undefined
            assertParamExists('postAgreementDefinition', 'createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition', createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition)
            const localVarPath = `/api/v1/agreement-definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search agreement definitions
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {string} [name] 
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchAgreementDefinitions: async (agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', name?: string, state?: 'ACTIVE' | 'CREATED' | 'ARCHIVED', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/agreement-definitions/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agreementType) {
                localVarQueryParameter['agreementType'] = Array.from(agreementType);
            }

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an agreement amendment definition positions
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementDefinitionAmendmentOrderRequest} patchAgreementDefinitionAmendmentOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementAmendmentDefinitionOrder: async (agreementDefinitionId: string, patchAgreementDefinitionAmendmentOrderRequest: PatchAgreementDefinitionAmendmentOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDefinitionId' is not null or undefined
            assertParamExists('updateAgreementAmendmentDefinitionOrder', 'agreementDefinitionId', agreementDefinitionId)
            // verify required parameter 'patchAgreementDefinitionAmendmentOrderRequest' is not null or undefined
            assertParamExists('updateAgreementAmendmentDefinitionOrder', 'patchAgreementDefinitionAmendmentOrderRequest', patchAgreementDefinitionAmendmentOrderRequest)
            const localVarPath = `/api/v1/agreement-definitions/{agreementDefinitionId}/amendments/position`
                .replace(`{${"agreementDefinitionId"}}`, encodeURIComponent(String(agreementDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAgreementDefinitionAmendmentOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an agreement amendment definition state
         * @param {string} agreementDefinitionId 
         * @param {string} agreementDefinitionAmendmentId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementAmendmentDefinitionState: async (agreementDefinitionId: string, agreementDefinitionAmendmentId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDefinitionId' is not null or undefined
            assertParamExists('updateAgreementAmendmentDefinitionState', 'agreementDefinitionId', agreementDefinitionId)
            // verify required parameter 'agreementDefinitionAmendmentId' is not null or undefined
            assertParamExists('updateAgreementAmendmentDefinitionState', 'agreementDefinitionAmendmentId', agreementDefinitionAmendmentId)
            // verify required parameter 'patchAgreementAmendmentDefinitionRequest' is not null or undefined
            assertParamExists('updateAgreementAmendmentDefinitionState', 'patchAgreementAmendmentDefinitionRequest', patchAgreementAmendmentDefinitionRequest)
            const localVarPath = `/api/v1/agreement-definitions/{agreementDefinitionId}/amendments/{agreementDefinitionAmendmentId}`
                .replace(`{${"agreementDefinitionId"}}`, encodeURIComponent(String(agreementDefinitionId)))
                .replace(`{${"agreementDefinitionAmendmentId"}}`, encodeURIComponent(String(agreementDefinitionAmendmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAgreementAmendmentDefinitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an agreement definition state
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementDefinitionState: async (agreementDefinitionId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementDefinitionId' is not null or undefined
            assertParamExists('updateAgreementDefinitionState', 'agreementDefinitionId', agreementDefinitionId)
            // verify required parameter 'patchAgreementAmendmentDefinitionRequest' is not null or undefined
            assertParamExists('updateAgreementDefinitionState', 'patchAgreementAmendmentDefinitionRequest', patchAgreementAmendmentDefinitionRequest)
            const localVarPath = `/api/v1/agreement-definitions/{agreementDefinitionId}`
                .replace(`{${"agreementDefinitionId"}}`, encodeURIComponent(String(agreementDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAgreementAmendmentDefinitionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AgreementDefinitionControllerApi - functional programming interface
 * @export
 */
export const AgreementDefinitionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AgreementDefinitionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create an agreement amendment definition
         * @param {string} agreementDefinitionId 
         * @param {PostAgreementAmendmentRequest} postAgreementAmendmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgreementAmendmentDefinition(agreementDefinitionId: string, postAgreementAmendmentRequest: PostAgreementAmendmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionAmendmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreementAmendmentDefinition(agreementDefinitionId, postAgreementAmendmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an agreement definition
         * @param {AgreementDefinitionCreationRequest} agreementDefinitionCreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createAgreementDefinition(agreementDefinitionCreationRequest: AgreementDefinitionCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreementDefinition(agreementDefinitionCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agreement definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementDefinition(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionWithAmendmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all agreement definitions based on criteria
         * @param {string} commissionPlanId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementDefinitionByPlanAndType(commissionPlanId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgreementDefinitionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementDefinitionByPlanAndType(commissionPlanId, agreementType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agreement definitions
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementDefinitions(state: 'ACTIVE' | 'CREATED' | 'ARCHIVED', pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgreementDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementDefinitions(state, pageNumber, pageSize, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRecentlyUpdatedAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', recentCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionPreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyUpdatedAgreementDefinitions(agreementType, stateOrProvince, recentCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create AgreementDefinition
         * @param {CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition} createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition Request body containing an agreement definition of any subtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition: CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search agreement definitions
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {string} [name] 
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async searchAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', name?: string, state?: 'ACTIVE' | 'CREATED' | 'ARCHIVED', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgreementDefinitions(agreementType, stateOrProvince, name, state, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an agreement amendment definition positions
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementDefinitionAmendmentOrderRequest} patchAgreementDefinitionAmendmentOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgreementAmendmentDefinitionOrder(agreementDefinitionId: string, patchAgreementDefinitionAmendmentOrderRequest: PatchAgreementDefinitionAmendmentOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgreementAmendmentDefinitionOrder(agreementDefinitionId, patchAgreementDefinitionAmendmentOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an agreement amendment definition state
         * @param {string} agreementDefinitionId 
         * @param {string} agreementDefinitionAmendmentId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgreementAmendmentDefinitionState(agreementDefinitionId: string, agreementDefinitionAmendmentId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionAmendmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgreementAmendmentDefinitionState(agreementDefinitionId, agreementDefinitionAmendmentId, patchAgreementAmendmentDefinitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an agreement definition state
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAgreementDefinitionState(agreementDefinitionId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAgreementDefinitionState(agreementDefinitionId, patchAgreementAmendmentDefinitionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AgreementDefinitionControllerApi - factory interface
 * @export
 */
export const AgreementDefinitionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AgreementDefinitionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create an agreement amendment definition
         * @param {string} agreementDefinitionId 
         * @param {PostAgreementAmendmentRequest} postAgreementAmendmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementAmendmentDefinition(agreementDefinitionId: string, postAgreementAmendmentRequest: PostAgreementAmendmentRequest, options?: any): AxiosPromise<AgreementDefinitionAmendmentResponse> {
            return localVarFp.createAgreementAmendmentDefinition(agreementDefinitionId, postAgreementAmendmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an agreement definition
         * @param {AgreementDefinitionCreationRequest} agreementDefinitionCreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createAgreementDefinition(agreementDefinitionCreationRequest: AgreementDefinitionCreationRequest, options?: any): AxiosPromise<AgreementDefinitionCreationResponse> {
            return localVarFp.createAgreementDefinition(agreementDefinitionCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agreement definition by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinition(id: string, options?: any): AxiosPromise<AgreementDefinitionWithAmendmentsResponse> {
            return localVarFp.getAgreementDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all agreement definitions based on criteria
         * @param {string} commissionPlanId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinitionByPlanAndType(commissionPlanId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: any): AxiosPromise<Array<AgreementDefinitionResponse>> {
            return localVarFp.getAgreementDefinitionByPlanAndType(commissionPlanId, agreementType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agreement definitions
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementDefinitions(state: 'ACTIVE' | 'CREATED' | 'ARCHIVED', pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>, options?: any): AxiosPromise<GenericSearchResponseAgreementDefinitionResponse> {
            return localVarFp.getAgreementDefinitions(state, pageNumber, pageSize, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recently updated Document templates
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {number} [recentCount] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRecentlyUpdatedAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', recentCount?: number, options?: any): AxiosPromise<AgreementDefinitionPreviewResponse> {
            return localVarFp.getRecentlyUpdatedAgreementDefinitions(agreementType, stateOrProvince, recentCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create AgreementDefinition
         * @param {CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition} createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition Request body containing an agreement definition of any subtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition: CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition, options?: any): AxiosPromise<AgreementDefinitionResponse> {
            return localVarFp.postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search agreement definitions
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {string} [name] 
         * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} [state] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', name?: string, state?: 'ACTIVE' | 'CREATED' | 'ARCHIVED', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: any): AxiosPromise<AgreementDefinitionPageableResponse> {
            return localVarFp.searchAgreementDefinitions(agreementType, stateOrProvince, name, state, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an agreement amendment definition positions
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementDefinitionAmendmentOrderRequest} patchAgreementDefinitionAmendmentOrderRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementAmendmentDefinitionOrder(agreementDefinitionId: string, patchAgreementDefinitionAmendmentOrderRequest: PatchAgreementDefinitionAmendmentOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAgreementAmendmentDefinitionOrder(agreementDefinitionId, patchAgreementDefinitionAmendmentOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an agreement amendment definition state
         * @param {string} agreementDefinitionId 
         * @param {string} agreementDefinitionAmendmentId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementAmendmentDefinitionState(agreementDefinitionId: string, agreementDefinitionAmendmentId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: any): AxiosPromise<AgreementDefinitionAmendmentResponse> {
            return localVarFp.updateAgreementAmendmentDefinitionState(agreementDefinitionId, agreementDefinitionAmendmentId, patchAgreementAmendmentDefinitionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an agreement definition state
         * @param {string} agreementDefinitionId 
         * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAgreementDefinitionState(agreementDefinitionId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: any): AxiosPromise<AgreementDefinitionResponse> {
            return localVarFp.updateAgreementDefinitionState(agreementDefinitionId, patchAgreementAmendmentDefinitionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AgreementDefinitionControllerApi - object-oriented interface
 * @export
 * @class AgreementDefinitionControllerApi
 * @extends {BaseAPI}
 */
export class AgreementDefinitionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create an agreement amendment definition
     * @param {string} agreementDefinitionId 
     * @param {PostAgreementAmendmentRequest} postAgreementAmendmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public createAgreementAmendmentDefinition(agreementDefinitionId: string, postAgreementAmendmentRequest: PostAgreementAmendmentRequest, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).createAgreementAmendmentDefinition(agreementDefinitionId, postAgreementAmendmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an agreement definition
     * @param {AgreementDefinitionCreationRequest} agreementDefinitionCreationRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public createAgreementDefinition(agreementDefinitionCreationRequest: AgreementDefinitionCreationRequest, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).createAgreementDefinition(agreementDefinitionCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agreement definition by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public getAgreementDefinition(id: string, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).getAgreementDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all agreement definitions based on criteria
     * @param {string} commissionPlanId 
     * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public getAgreementDefinitionByPlanAndType(commissionPlanId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).getAgreementDefinitionByPlanAndType(commissionPlanId, agreementType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agreement definitions
     * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} state 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public getAgreementDefinitions(state: 'ACTIVE' | 'CREATED' | 'ARCHIVED', pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT' | 'AGREEMENT_VERSION'>, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).getAgreementDefinitions(state, pageNumber, pageSize, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recently updated Document templates
     * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
     * @param {number} [recentCount] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public getRecentlyUpdatedAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', recentCount?: number, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).getRecentlyUpdatedAgreementDefinitions(agreementType, stateOrProvince, recentCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create AgreementDefinition
     * @param {CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition} createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition Request body containing an agreement definition of any subtype
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition: CreateAgentReferralAgreementDefinition | CreateAssistantAccessAgreementDefinition | CreateAssistantPolicyAndProceduresAgreementDefinition | CreateICAAgreementDefinition | CreateLicensedAssistantAddendumAgreementDefinition | CreateUnlicensedAssistantAddendumAgreementDefinition | CreateWalletCreditAgreementDefinition | CreateWalletDebitAgreementDefinition | CreateWalletLendingAgreementDefinition, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).postAgreementDefinition(createAgentReferralAgreementDefinitionCreateAssistantAccessAgreementDefinitionCreateAssistantPolicyAndProceduresAgreementDefinitionCreateICAAgreementDefinitionCreateLicensedAssistantAddendumAgreementDefinitionCreateUnlicensedAssistantAddendumAgreementDefinitionCreateWalletCreditAgreementDefinitionCreateWalletDebitAgreementDefinitionCreateWalletLendingAgreementDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search agreement definitions
     * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
     * @param {string} [name] 
     * @param {'ACTIVE' | 'CREATED' | 'ARCHIVED'} [state] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public searchAgreementDefinitions(agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', name?: string, state?: 'ACTIVE' | 'CREATED' | 'ARCHIVED', page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).searchAgreementDefinitions(agreementType, stateOrProvince, name, state, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an agreement amendment definition positions
     * @param {string} agreementDefinitionId 
     * @param {PatchAgreementDefinitionAmendmentOrderRequest} patchAgreementDefinitionAmendmentOrderRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public updateAgreementAmendmentDefinitionOrder(agreementDefinitionId: string, patchAgreementDefinitionAmendmentOrderRequest: PatchAgreementDefinitionAmendmentOrderRequest, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).updateAgreementAmendmentDefinitionOrder(agreementDefinitionId, patchAgreementDefinitionAmendmentOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an agreement amendment definition state
     * @param {string} agreementDefinitionId 
     * @param {string} agreementDefinitionAmendmentId 
     * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public updateAgreementAmendmentDefinitionState(agreementDefinitionId: string, agreementDefinitionAmendmentId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).updateAgreementAmendmentDefinitionState(agreementDefinitionId, agreementDefinitionAmendmentId, patchAgreementAmendmentDefinitionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an agreement definition state
     * @param {string} agreementDefinitionId 
     * @param {PatchAgreementAmendmentDefinitionRequest} patchAgreementAmendmentDefinitionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AgreementDefinitionControllerApi
     */
    public updateAgreementDefinitionState(agreementDefinitionId: string, patchAgreementAmendmentDefinitionRequest: PatchAgreementAmendmentDefinitionRequest, options?: AxiosRequestConfig) {
        return AgreementDefinitionControllerApiFp(this.configuration).updateAgreementDefinitionState(agreementDefinitionId, patchAgreementAmendmentDefinitionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApplicationControllerApi - axios parameter creator
 * @export
 */
export const ApplicationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Apply to be a REAL Agent
         * @param {ApplyToRealRequest} applyToRealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyAsRealAgent: async (applyToRealRequest: ApplyToRealRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyToRealRequest' is not null or undefined
            assertParamExists('applyAsRealAgent', 'applyToRealRequest', applyToRealRequest)
            const localVarPath = `/api/v1/applications/apply-real`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyToRealRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToReal: async (applicationRequest: ApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationRequest' is not null or undefined
            assertParamExists('applyToReal', 'applicationRequest', applicationRequest)
            const localVarPath = `/api/v1/applications/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id 
         * @param {ApproveApplicationRequest} approveApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplication: async (id: string, approveApplicationRequest: ApproveApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveApplication', 'id', id)
            // verify required parameter 'approveApplicationRequest' is not null or undefined
            assertParamExists('approveApplication', 'approveApplicationRequest', approveApplicationRequest)
            const localVarPath = `/api/v1/applications/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approveApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardJoined: async (boardJoinedRequest: BoardJoinedRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardJoinedRequest' is not null or undefined
            assertParamExists('boardJoined', 'boardJoinedRequest', boardJoinedRequest)
            const localVarPath = `/api/v1/applications/board_joined`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardJoinedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate the amount discount info for a given coupon code and amount
         * @param {string} applicationId 
         * @param {string} couponCode 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAmountDiscountInfo: async (applicationId: string, couponCode: string, amount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('calculateAmountDiscountInfo', 'applicationId', applicationId)
            // verify required parameter 'couponCode' is not null or undefined
            assertParamExists('calculateAmountDiscountInfo', 'couponCode', couponCode)
            // verify required parameter 'amount' is not null or undefined
            assertParamExists('calculateAmountDiscountInfo', 'amount', amount)
            const localVarPath = `/api/v1/applications/calculate_amount_discount_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (couponCode !== undefined) {
                localVarQueryParameter['couponCode'] = couponCode;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an application for the logged in user id
         * @param {CreateApplicationRequest} createApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication: async (createApplicationRequest: CreateApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createApplicationRequest' is not null or undefined
            assertParamExists('createApplication', 'createApplicationRequest', createApplicationRequest)
            const localVarPath = `/api/v1/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplication', 'id', id)
            const localVarPath = `/api/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets application driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDriverLicenseImage: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplicationDriverLicenseImage', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/driver-license-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all applications, or specific application(s) given a set of criteria
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>} [statuses] 
         * @param {string} [phoneNumber] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvinces] 
         * @param {string} [licenseNumber] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {string} [updatedAtStart] 
         * @param {string} [updatedAtEnd] 
         * @param {boolean} [hasPendingTransactionsWithCurrentBrokerage] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>} [sortProperties] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (firstName?: string, lastName?: string, email?: string, statuses?: Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>, phoneNumber?: string, stateOrProvinces?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, licenseNumber?: string, createdAtStart?: string, createdAtEnd?: string, updatedAtStart?: string, updatedAtEnd?: string, hasPendingTransactionsWithCurrentBrokerage?: boolean, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortProperties?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (stateOrProvinces) {
                localVarQueryParameter['stateOrProvinces'] = Array.from(stateOrProvinces);
            }

            if (licenseNumber !== undefined) {
                localVarQueryParameter['licenseNumber'] = licenseNumber;
            }

            if (createdAtStart !== undefined) {
                localVarQueryParameter['createdAtStart'] = (createdAtStart as any instanceof Date) ?
                    (createdAtStart as any).toISOString().substr(0,10) :
                    createdAtStart;
            }

            if (createdAtEnd !== undefined) {
                localVarQueryParameter['createdAtEnd'] = (createdAtEnd as any instanceof Date) ?
                    (createdAtEnd as any).toISOString().substr(0,10) :
                    createdAtEnd;
            }

            if (updatedAtStart !== undefined) {
                localVarQueryParameter['updatedAtStart'] = (updatedAtStart as any instanceof Date) ?
                    (updatedAtStart as any).toISOString().substr(0,10) :
                    updatedAtStart;
            }

            if (updatedAtEnd !== undefined) {
                localVarQueryParameter['updatedAtEnd'] = (updatedAtEnd as any instanceof Date) ?
                    (updatedAtEnd as any).toISOString().substr(0,10) :
                    updatedAtEnd;
            }

            if (hasPendingTransactionsWithCurrentBrokerage !== undefined) {
                localVarQueryParameter['hasPendingTransactionsWithCurrentBrokerage'] = hasPendingTransactionsWithCurrentBrokerage;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortProperties) {
                localVarQueryParameter['sortProperties'] = sortProperties;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a URL to the signed ICA pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getICAUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getICAUrl', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/ica`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseTransferred: async (licenseTransferRequest: LicenseTransferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseTransferRequest' is not null or undefined
            assertParamExists('licenseTransferred', 'licenseTransferRequest', licenseTransferRequest)
            const localVarPath = `/api/v1/applications/license_transferred`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(licenseTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} feePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payApplicationFees: async (feePaymentRequest: FeePaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feePaymentRequest' is not null or undefined
            assertParamExists('payApplicationFees', 'feePaymentRequest', feePaymentRequest)
            const localVarPath = `/api/v1/applications/pay_fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(feePaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id 
         * @param {RejectApplicationRequest} rejectApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectApplication: async (id: string, rejectApplicationRequest: RejectApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectApplication', 'id', id)
            // verify required parameter 'rejectApplicationRequest' is not null or undefined
            assertParamExists('rejectApplication', 'rejectApplicationRequest', rejectApplicationRequest)
            const localVarPath = `/api/v1/applications/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rejectApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} iCASignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICA: async (iCASignRequest: ICASignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iCASignRequest' is not null or undefined
            assertParamExists('signICA', 'iCASignRequest', iCASignRequest)
            const localVarPath = `/api/v1/applications/sign_ica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iCASignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign ICA using agreement
         * @param {ApplicationSigningRequest} applicationSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICAUsingAgreement: async (applicationSigningRequest: ApplicationSigningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationSigningRequest' is not null or undefined
            assertParamExists('signICAUsingAgreement', 'applicationSigningRequest', applicationSigningRequest)
            const localVarPath = `/api/v1/applications/sign-ica-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationSigningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLetterOfIntent: async (letterOfIntentSignRequest: LetterOfIntentSignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'letterOfIntentSignRequest' is not null or undefined
            assertParamExists('signLetterOfIntent', 'letterOfIntentSignRequest', letterOfIntentSignRequest)
            const localVarPath = `/api/v1/applications/sign-loi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(letterOfIntentSignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id 
         * @param {UpdateApplicationRequest} updateApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (id: string, updateApplicationRequest: UpdateApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplication', 'id', id)
            // verify required parameter 'updateApplicationRequest' is not null or undefined
            assertParamExists('updateApplication', 'updateApplicationRequest', updateApplicationRequest)
            const localVarPath = `/api/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates application driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationDriverLicenseImage: async (id: string, driverLicenseImage: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationDriverLicenseImage', 'id', id)
            // verify required parameter 'driverLicenseImage' is not null or undefined
            assertParamExists('updateApplicationDriverLicenseImage', 'driverLicenseImage', driverLicenseImage)
            const localVarPath = `/api/v1/applications/{id}/driver-license-image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (driverLicenseImage !== undefined) { 
                localVarFormParams.append('driverLicenseImage', driverLicenseImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing application with license images
         * @param {string} id 
         * @param {UpdateApplicationRequest} applicationUpdateRequest 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationWithLicenseImages: async (id: string, applicationUpdateRequest: UpdateApplicationRequest, licenseImage?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApplicationWithLicenseImages', 'id', id)
            // verify required parameter 'applicationUpdateRequest' is not null or undefined
            assertParamExists('updateApplicationWithLicenseImages', 'applicationUpdateRequest', applicationUpdateRequest)
            const localVarPath = `/api/v1/applications/{id}/with-license-images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (applicationUpdateRequest !== undefined) { 
                localVarFormParams.append('applicationUpdateRequest', new Blob([JSON.stringify(applicationUpdateRequest)], { type: "application/json", }));
            }
                if (licenseImage) {
                licenseImage.forEach((element) => {
                    localVarFormParams.append('licenseImage', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Waive application fees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveApplicationFees: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('waiveApplicationFees', 'id', id)
            const localVarPath = `/api/v1/applications/{id}/waive_fees`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Waive application fees
         * @param {WaiveApplicationFeesRequest} waiveApplicationFeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveApplicationFeesInBulk: async (waiveApplicationFeesRequest: WaiveApplicationFeesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'waiveApplicationFeesRequest' is not null or undefined
            assertParamExists('waiveApplicationFeesInBulk', 'waiveApplicationFeesRequest', waiveApplicationFeesRequest)
            const localVarPath = `/api/v1/applications/waive_fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(waiveApplicationFeesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationControllerApi - functional programming interface
 * @export
 */
export const ApplicationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Apply to be a REAL Agent
         * @param {ApplyToRealRequest} applyToRealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyAsRealAgent(applyToRealRequest: ApplyToRealRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyAsRealAgent(applyToRealRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyToReal(applicationRequest: ApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyToReal(applicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id 
         * @param {ApproveApplicationRequest} approveApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveApplication(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveApplication(id, approveApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async boardJoined(boardJoinedRequest: BoardJoinedRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.boardJoined(boardJoinedRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate the amount discount info for a given coupon code and amount
         * @param {string} applicationId 
         * @param {string} couponCode 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAmountDiscountInfo(applicationId: string, couponCode: string, amount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountDiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAmountDiscountInfo(applicationId, couponCode, amount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an application for the logged in user id
         * @param {CreateApplicationRequest} createApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplication(createApplicationRequest: CreateApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createApplication(createApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets application driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationDriverLicenseImage(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationDriverLicenseImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationDriverLicenseImage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all applications, or specific application(s) given a set of criteria
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>} [statuses] 
         * @param {string} [phoneNumber] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvinces] 
         * @param {string} [licenseNumber] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {string} [updatedAtStart] 
         * @param {string} [updatedAtEnd] 
         * @param {boolean} [hasPendingTransactionsWithCurrentBrokerage] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>} [sortProperties] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(firstName?: string, lastName?: string, email?: string, statuses?: Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>, phoneNumber?: string, stateOrProvinces?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, licenseNumber?: string, createdAtStart?: string, createdAtEnd?: string, updatedAtStart?: string, updatedAtEnd?: string, hasPendingTransactionsWithCurrentBrokerage?: boolean, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortProperties?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationsPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(firstName, lastName, email, statuses, phoneNumber, stateOrProvinces, licenseNumber, createdAtStart, createdAtEnd, updatedAtStart, updatedAtEnd, hasPendingTransactionsWithCurrentBrokerage, pageNumber, pageSize, sortDirection, sortProperties, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a URL to the signed ICA pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getICAUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getICAUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async licenseTransferred(licenseTransferRequest: LicenseTransferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.licenseTransferred(licenseTransferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} feePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payApplicationFees(feePaymentRequest: FeePaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payApplicationFees(feePaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id 
         * @param {RejectApplicationRequest} rejectApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectApplication(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectApplication(id, rejectApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} iCASignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signICA(iCASignRequest: ICASignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signICA(iCASignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign ICA using agreement
         * @param {ApplicationSigningRequest} applicationSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signICAUsingAgreement(applicationSigningRequest: ApplicationSigningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signICAUsingAgreement(applicationSigningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signLetterOfIntent(letterOfIntentSignRequest: LetterOfIntentSignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signLetterOfIntent(letterOfIntentSignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id 
         * @param {UpdateApplicationRequest} updateApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(id: string, updateApplicationRequest: UpdateApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(id, updateApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates application driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationDriverLicenseImage(id: string, driverLicenseImage: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationDriverLicenseImage(id, driverLicenseImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing application with license images
         * @param {string} id 
         * @param {UpdateApplicationRequest} applicationUpdateRequest 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationWithLicenseImages(id: string, applicationUpdateRequest: UpdateApplicationRequest, licenseImage?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationWithLicenseImages(id, applicationUpdateRequest, licenseImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Waive application fees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async waiveApplicationFees(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.waiveApplicationFees(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Waive application fees
         * @param {WaiveApplicationFeesRequest} waiveApplicationFeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async waiveApplicationFeesInBulk(waiveApplicationFeesRequest: WaiveApplicationFeesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.waiveApplicationFeesInBulk(waiveApplicationFeesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationControllerApi - factory interface
 * @export
 */
export const ApplicationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Apply to be a REAL Agent
         * @param {ApplyToRealRequest} applyToRealRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyAsRealAgent(applyToRealRequest: ApplyToRealRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.applyAsRealAgent(applyToRealRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply to be Real Agent
         * @param {ApplicationRequest} applicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyToReal(applicationRequest: ApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.applyToReal(applicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve a pending application
         * @param {string} id 
         * @param {ApproveApplicationRequest} approveApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveApplication(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.approveApplication(id, approveApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent joined the board
         * @param {BoardJoinedRequest} boardJoinedRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        boardJoined(boardJoinedRequest: BoardJoinedRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.boardJoined(boardJoinedRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate the amount discount info for a given coupon code and amount
         * @param {string} applicationId 
         * @param {string} couponCode 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAmountDiscountInfo(applicationId: string, couponCode: string, amount: number, options?: any): AxiosPromise<AmountDiscountResponse> {
            return localVarFp.calculateAmountDiscountInfo(applicationId, couponCode, amount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an application for the logged in user id
         * @param {CreateApplicationRequest} createApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplication(createApplicationRequest: CreateApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.createApplication(createApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch an application by a given id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(id: string, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.getApplication(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets application driver license image
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationDriverLicenseImage(id: string, options?: any): AxiosPromise<ApplicationDriverLicenseImageResponse> {
            return localVarFp.getApplicationDriverLicenseImage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all applications, or specific application(s) given a set of criteria
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>} [statuses] 
         * @param {string} [phoneNumber] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvinces] 
         * @param {string} [licenseNumber] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {string} [updatedAtStart] 
         * @param {string} [updatedAtEnd] 
         * @param {boolean} [hasPendingTransactionsWithCurrentBrokerage] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>} [sortProperties] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(firstName?: string, lastName?: string, email?: string, statuses?: Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>, phoneNumber?: string, stateOrProvinces?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, licenseNumber?: string, createdAtStart?: string, createdAtEnd?: string, updatedAtStart?: string, updatedAtEnd?: string, hasPendingTransactionsWithCurrentBrokerage?: boolean, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortProperties?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>, searchText?: string, options?: any): AxiosPromise<ApplicationsPaginatedResponse> {
            return localVarFp.getApplications(firstName, lastName, email, statuses, phoneNumber, stateOrProvinces, licenseNumber, createdAtStart, createdAtEnd, updatedAtStart, updatedAtEnd, hasPendingTransactionsWithCurrentBrokerage, pageNumber, pageSize, sortDirection, sortProperties, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a URL to the signed ICA pdf
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getICAUrl(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getICAUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent license is transferred
         * @param {LicenseTransferRequest} licenseTransferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        licenseTransferred(licenseTransferRequest: LicenseTransferRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.licenseTransferred(licenseTransferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Pay fees for application
         * @param {FeePaymentRequest} feePaymentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payApplicationFees(feePaymentRequest: FeePaymentRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.payApplicationFees(feePaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject a pending application
         * @param {string} id 
         * @param {RejectApplicationRequest} rejectApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectApplication(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.rejectApplication(id, rejectApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign ICA
         * @param {ICASignRequest} iCASignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICA(iCASignRequest: ICASignRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.signICA(iCASignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign ICA using agreement
         * @param {ApplicationSigningRequest} applicationSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signICAUsingAgreement(applicationSigningRequest: ApplicationSigningRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.signICAUsingAgreement(applicationSigningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign Letter of Intent
         * @param {LetterOfIntentSignRequest} letterOfIntentSignRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signLetterOfIntent(letterOfIntentSignRequest: LetterOfIntentSignRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.signLetterOfIntent(letterOfIntentSignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing application
         * @param {string} id 
         * @param {UpdateApplicationRequest} updateApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(id: string, updateApplicationRequest: UpdateApplicationRequest, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.updateApplication(id, updateApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates application driver license image
         * @param {string} id 
         * @param {any} driverLicenseImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationDriverLicenseImage(id: string, driverLicenseImage: any, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.updateApplicationDriverLicenseImage(id, driverLicenseImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing application with license images
         * @param {string} id 
         * @param {UpdateApplicationRequest} applicationUpdateRequest 
         * @param {Array<any>} [licenseImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationWithLicenseImages(id: string, applicationUpdateRequest: UpdateApplicationRequest, licenseImage?: Array<any>, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.updateApplicationWithLicenseImages(id, applicationUpdateRequest, licenseImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Waive application fees
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveApplicationFees(id: string, options?: any): AxiosPromise<ApplicationResponse> {
            return localVarFp.waiveApplicationFees(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Waive application fees
         * @param {WaiveApplicationFeesRequest} waiveApplicationFeesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        waiveApplicationFeesInBulk(waiveApplicationFeesRequest: WaiveApplicationFeesRequest, options?: any): AxiosPromise<Array<ApplicationResponse>> {
            return localVarFp.waiveApplicationFeesInBulk(waiveApplicationFeesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationControllerApi - object-oriented interface
 * @export
 * @class ApplicationControllerApi
 * @extends {BaseAPI}
 */
export class ApplicationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Apply to be a REAL Agent
     * @param {ApplyToRealRequest} applyToRealRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public applyAsRealAgent(applyToRealRequest: ApplyToRealRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).applyAsRealAgent(applyToRealRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply to be Real Agent
     * @param {ApplicationRequest} applicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public applyToReal(applicationRequest: ApplicationRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).applyToReal(applicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve a pending application
     * @param {string} id 
     * @param {ApproveApplicationRequest} approveApplicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public approveApplication(id: string, approveApplicationRequest: ApproveApplicationRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).approveApplication(id, approveApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent joined the board
     * @param {BoardJoinedRequest} boardJoinedRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public boardJoined(boardJoinedRequest: BoardJoinedRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).boardJoined(boardJoinedRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate the amount discount info for a given coupon code and amount
     * @param {string} applicationId 
     * @param {string} couponCode 
     * @param {number} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public calculateAmountDiscountInfo(applicationId: string, couponCode: string, amount: number, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).calculateAmountDiscountInfo(applicationId, couponCode, amount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an application for the logged in user id
     * @param {CreateApplicationRequest} createApplicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public createApplication(createApplicationRequest: CreateApplicationRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).createApplication(createApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch an application by a given id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplication(id: string, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).getApplication(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets application driver license image
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplicationDriverLicenseImage(id: string, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).getApplicationDriverLicenseImage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all applications, or specific application(s) given a set of criteria
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [email] 
     * @param {Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>} [statuses] 
     * @param {string} [phoneNumber] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [stateOrProvinces] 
     * @param {string} [licenseNumber] 
     * @param {string} [createdAtStart] 
     * @param {string} [createdAtEnd] 
     * @param {string} [updatedAtStart] 
     * @param {string} [updatedAtEnd] 
     * @param {boolean} [hasPendingTransactionsWithCurrentBrokerage] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>} [sortProperties] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getApplications(firstName?: string, lastName?: string, email?: string, statuses?: Array<'CREATED' | 'STARTED' | 'SIGN_ICA' | 'ICA_SIGNED' | 'PAY_FEES' | 'FEES_PAID' | 'TRANSFER_LICENSE' | 'LICENSE_TRANSFERRED' | 'JOIN_MLS' | 'MLS_JOINED' | 'JOIN_BOARD' | 'BOARD_JOINED' | 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'SIGN_LETTER_OF_INTENT' | 'LETTER_OF_INTENT_SIGNED' | 'FEES_WAIVED'>, phoneNumber?: string, stateOrProvinces?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, licenseNumber?: string, createdAtStart?: string, createdAtEnd?: string, updatedAtStart?: string, updatedAtEnd?: string, hasPendingTransactionsWithCurrentBrokerage?: boolean, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortProperties?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL' | 'STATUS' | 'CREATED_AT' | 'UPDATED_AT' | 'HAS_PENDING_TRANSACTIONS_WITH_CURRENT_BROKERAGE'>, searchText?: string, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).getApplications(firstName, lastName, email, statuses, phoneNumber, stateOrProvinces, licenseNumber, createdAtStart, createdAtEnd, updatedAtStart, updatedAtEnd, hasPendingTransactionsWithCurrentBrokerage, pageNumber, pageSize, sortDirection, sortProperties, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a URL to the signed ICA pdf
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public getICAUrl(id: string, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).getICAUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent license is transferred
     * @param {LicenseTransferRequest} licenseTransferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public licenseTransferred(licenseTransferRequest: LicenseTransferRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).licenseTransferred(licenseTransferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Pay fees for application
     * @param {FeePaymentRequest} feePaymentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public payApplicationFees(feePaymentRequest: FeePaymentRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).payApplicationFees(feePaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject a pending application
     * @param {string} id 
     * @param {RejectApplicationRequest} rejectApplicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public rejectApplication(id: string, rejectApplicationRequest: RejectApplicationRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).rejectApplication(id, rejectApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign ICA
     * @param {ICASignRequest} iCASignRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public signICA(iCASignRequest: ICASignRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).signICA(iCASignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign ICA using agreement
     * @param {ApplicationSigningRequest} applicationSigningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public signICAUsingAgreement(applicationSigningRequest: ApplicationSigningRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).signICAUsingAgreement(applicationSigningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign Letter of Intent
     * @param {LetterOfIntentSignRequest} letterOfIntentSignRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public signLetterOfIntent(letterOfIntentSignRequest: LetterOfIntentSignRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).signLetterOfIntent(letterOfIntentSignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing application
     * @param {string} id 
     * @param {UpdateApplicationRequest} updateApplicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public updateApplication(id: string, updateApplicationRequest: UpdateApplicationRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).updateApplication(id, updateApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates application driver license image
     * @param {string} id 
     * @param {any} driverLicenseImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public updateApplicationDriverLicenseImage(id: string, driverLicenseImage: any, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).updateApplicationDriverLicenseImage(id, driverLicenseImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing application with license images
     * @param {string} id 
     * @param {UpdateApplicationRequest} applicationUpdateRequest 
     * @param {Array<any>} [licenseImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public updateApplicationWithLicenseImages(id: string, applicationUpdateRequest: UpdateApplicationRequest, licenseImage?: Array<any>, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).updateApplicationWithLicenseImages(id, applicationUpdateRequest, licenseImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Waive application fees
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public waiveApplicationFees(id: string, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).waiveApplicationFees(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Waive application fees
     * @param {WaiveApplicationFeesRequest} waiveApplicationFeesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationControllerApi
     */
    public waiveApplicationFeesInBulk(waiveApplicationFeesRequest: WaiveApplicationFeesRequest, options?: AxiosRequestConfig) {
        return ApplicationControllerApiFp(this.configuration).waiveApplicationFeesInBulk(waiveApplicationFeesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AwardsControllerApi - axios parameter creator
 * @export
 */
export const AwardsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new award configuration
         * @param {AwardsConfigRequest} awardsConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAwardsConfig: async (awardsConfigRequest: AwardsConfigRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'awardsConfigRequest' is not null or undefined
            assertParamExists('addAwardsConfig', 'awardsConfigRequest', awardsConfigRequest)
            const localVarPath = `/api/v1/awards/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(awardsConfigRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current award configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAwardsConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/awards/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AwardsControllerApi - functional programming interface
 * @export
 */
export const AwardsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AwardsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new award configuration
         * @param {AwardsConfigRequest} awardsConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAwardsConfig(awardsConfigRequest: AwardsConfigRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardsConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAwardsConfig(awardsConfigRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current award configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentAwardsConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AwardsConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentAwardsConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AwardsControllerApi - factory interface
 * @export
 */
export const AwardsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AwardsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new award configuration
         * @param {AwardsConfigRequest} awardsConfigRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAwardsConfig(awardsConfigRequest: AwardsConfigRequest, options?: any): AxiosPromise<AwardsConfigResponse> {
            return localVarFp.addAwardsConfig(awardsConfigRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current award configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentAwardsConfig(options?: any): AxiosPromise<AwardsConfigResponse> {
            return localVarFp.getCurrentAwardsConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AwardsControllerApi - object-oriented interface
 * @export
 * @class AwardsControllerApi
 * @extends {BaseAPI}
 */
export class AwardsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new award configuration
     * @param {AwardsConfigRequest} awardsConfigRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardsControllerApi
     */
    public addAwardsConfig(awardsConfigRequest: AwardsConfigRequest, options?: AxiosRequestConfig) {
        return AwardsControllerApiFp(this.configuration).addAwardsConfig(awardsConfigRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current award configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AwardsControllerApi
     */
    public getCurrentAwardsConfig(options?: AxiosRequestConfig) {
        return AwardsControllerApiFp(this.configuration).getCurrentAwardsConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankAccountControllerApi - axios parameter creator
 * @export
 */
export const BankAccountControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new BankAccount
         * @param {CreateBankAccountRequest} createBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccount: async (createBankAccountRequest: CreateBankAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBankAccountRequest' is not null or undefined
            assertParamExists('createBankAccount', 'createBankAccountRequest', createBankAccountRequest)
            const localVarPath = `/api/v1/bankaccounts/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary edit a BankAccount
         * @param {string} id 
         * @param {EditBankAccountRequest} editBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankAccount: async (id: string, editBankAccountRequest: EditBankAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editBankAccount', 'id', id)
            // verify required parameter 'editBankAccountRequest' is not null or undefined
            assertParamExists('editBankAccount', 'editBankAccountRequest', editBankAccountRequest)
            const localVarPath = `/api/v1/bankaccounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBankAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank account by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBankAccountById', 'id', id)
            const localVarPath = `/api/v1/bankaccounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Canada Operating BankAccount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCanadianOperatingBankAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankaccounts/canadian-operating-bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search bank accounts
         * @param {Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [accountName] 
         * @param {string} [bankName] 
         * @param {string} [streetAddress] 
         * @param {string} [accountNumber] 
         * @param {string} [bankRoutingNumber] 
         * @param {string} [wireRoutingNumber] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBankAccounts: async (sortBy?: Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, accountName?: string, bankName?: string, streetAddress?: string, accountNumber?: string, bankRoutingNumber?: string, wireRoutingNumber?: string, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bankaccounts/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (accountName !== undefined) {
                localVarQueryParameter['accountName'] = accountName;
            }

            if (bankName !== undefined) {
                localVarQueryParameter['bankName'] = bankName;
            }

            if (streetAddress !== undefined) {
                localVarQueryParameter['streetAddress'] = streetAddress;
            }

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (bankRoutingNumber !== undefined) {
                localVarQueryParameter['bankRoutingNumber'] = bankRoutingNumber;
            }

            if (wireRoutingNumber !== undefined) {
                localVarQueryParameter['wireRoutingNumber'] = wireRoutingNumber;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankAccountControllerApi - functional programming interface
 * @export
 */
export const BankAccountControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankAccountControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new BankAccount
         * @param {CreateBankAccountRequest} createBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBankAccount(createBankAccountRequest: CreateBankAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBankAccount(createBankAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary edit a BankAccount
         * @param {string} id 
         * @param {EditBankAccountRequest} editBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editBankAccount(id: string, editBankAccountRequest: EditBankAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editBankAccount(id, editBankAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBankAccounts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BankAccountResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBankAccounts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get bank account by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBankAccountById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Canada Operating BankAccount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCanadianOperatingBankAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanadianOperatingBankAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search bank accounts
         * @param {Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [accountName] 
         * @param {string} [bankName] 
         * @param {string} [streetAddress] 
         * @param {string} [accountNumber] 
         * @param {string} [bankRoutingNumber] 
         * @param {string} [wireRoutingNumber] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBankAccounts(sortBy?: Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, accountName?: string, bankName?: string, streetAddress?: string, accountNumber?: string, bankRoutingNumber?: string, wireRoutingNumber?: string, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseBankAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBankAccounts(sortBy, sortDirection, pageNumber, pageSize, accountName, bankName, streetAddress, accountNumber, bankRoutingNumber, wireRoutingNumber, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankAccountControllerApi - factory interface
 * @export
 */
export const BankAccountControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankAccountControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary create new BankAccount
         * @param {CreateBankAccountRequest} createBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccount(createBankAccountRequest: CreateBankAccountRequest, options?: any): AxiosPromise<BankAccountResponse> {
            return localVarFp.createBankAccount(createBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary edit a BankAccount
         * @param {string} id 
         * @param {EditBankAccountRequest} editBankAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editBankAccount(id: string, editBankAccountRequest: EditBankAccountRequest, options?: any): AxiosPromise<BankAccountResponse> {
            return localVarFp.editBankAccount(id, editBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all bank accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBankAccounts(options?: any): AxiosPromise<Array<BankAccountResponse>> {
            return localVarFp.getAllBankAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank account by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountById(id: string, options?: any): AxiosPromise<BankAccountResponse> {
            return localVarFp.getBankAccountById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Canada Operating BankAccount
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCanadianOperatingBankAccount(options?: any): AxiosPromise<BankAccountResponse> {
            return localVarFp.getCanadianOperatingBankAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search bank accounts
         * @param {Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [accountName] 
         * @param {string} [bankName] 
         * @param {string} [streetAddress] 
         * @param {string} [accountNumber] 
         * @param {string} [bankRoutingNumber] 
         * @param {string} [wireRoutingNumber] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBankAccounts(sortBy?: Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, accountName?: string, bankName?: string, streetAddress?: string, accountNumber?: string, bankRoutingNumber?: string, wireRoutingNumber?: string, searchText?: string, options?: any): AxiosPromise<GenericSearchResponseBankAccountResponse> {
            return localVarFp.searchBankAccounts(sortBy, sortDirection, pageNumber, pageSize, accountName, bankName, streetAddress, accountNumber, bankRoutingNumber, wireRoutingNumber, searchText, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankAccountControllerApi - object-oriented interface
 * @export
 * @class BankAccountControllerApi
 * @extends {BaseAPI}
 */
export class BankAccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary create new BankAccount
     * @param {CreateBankAccountRequest} createBankAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public createBankAccount(createBankAccountRequest: CreateBankAccountRequest, options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).createBankAccount(createBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary edit a BankAccount
     * @param {string} id 
     * @param {EditBankAccountRequest} editBankAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public editBankAccount(id: string, editBankAccountRequest: EditBankAccountRequest, options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).editBankAccount(id, editBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all bank accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public getAllBankAccounts(options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).getAllBankAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get bank account by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public getBankAccountById(id: string, options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).getBankAccountById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Canada Operating BankAccount
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public getCanadianOperatingBankAccount(options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).getCanadianOperatingBankAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search bank accounts
     * @param {Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [accountName] 
     * @param {string} [bankName] 
     * @param {string} [streetAddress] 
     * @param {string} [accountNumber] 
     * @param {string} [bankRoutingNumber] 
     * @param {string} [wireRoutingNumber] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountControllerApi
     */
    public searchBankAccounts(sortBy?: Array<'ACCOUNT_NAME' | 'BANK_NAME' | 'ACCOUNT_NUMBER' | 'BANK_ROUTING_NUMBER' | 'WIRE_ROUTING_NUMBER'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, accountName?: string, bankName?: string, streetAddress?: string, accountNumber?: string, bankRoutingNumber?: string, wireRoutingNumber?: string, searchText?: string, options?: AxiosRequestConfig) {
        return BankAccountControllerApiFp(this.configuration).searchBankAccounts(sortBy, sortDirection, pageNumber, pageSize, accountName, bankName, streetAddress, accountNumber, bankRoutingNumber, wireRoutingNumber, searchText, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BannerControllerApi - axios parameter creator
 * @export
 */
export const BannerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all banners for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBanners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/banners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update banner
         * @param {string} bannerId 
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner: async (bannerId: string, updateBannerRequest: UpdateBannerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bannerId' is not null or undefined
            assertParamExists('updateBanner', 'bannerId', bannerId)
            // verify required parameter 'updateBannerRequest' is not null or undefined
            assertParamExists('updateBanner', 'updateBannerRequest', updateBannerRequest)
            const localVarPath = `/api/v1/banners/{bannerId}`
                .replace(`{${"bannerId"}}`, encodeURIComponent(String(bannerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBannerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannerControllerApi - functional programming interface
 * @export
 */
export const BannerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all banners for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBanners(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BannerResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBanners(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update banner
         * @param {string} bannerId 
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBanner(bannerId: string, updateBannerRequest: UpdateBannerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBanner(bannerId, updateBannerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BannerControllerApi - factory interface
 * @export
 */
export const BannerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all banners for the current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBanners(options?: any): AxiosPromise<Array<BannerResponse>> {
            return localVarFp.getAllBanners(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update banner
         * @param {string} bannerId 
         * @param {UpdateBannerRequest} updateBannerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBanner(bannerId: string, updateBannerRequest: UpdateBannerRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateBanner(bannerId, updateBannerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BannerControllerApi - object-oriented interface
 * @export
 * @class BannerControllerApi
 * @extends {BaseAPI}
 */
export class BannerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get all banners for the current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerControllerApi
     */
    public getAllBanners(options?: AxiosRequestConfig) {
        return BannerControllerApiFp(this.configuration).getAllBanners(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update banner
     * @param {string} bannerId 
     * @param {UpdateBannerRequest} updateBannerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannerControllerApi
     */
    public updateBanner(bannerId: string, updateBannerRequest: UpdateBannerRequest, options?: AxiosRequestConfig) {
        return BannerControllerApiFp(this.configuration).updateBanner(bannerId, updateBannerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoardControllerApi - axios parameter creator
 * @export
 */
export const BoardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create new board
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoard: async (boardRequest: BoardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'boardRequest' is not null or undefined
            assertParamExists('createBoard', 'boardRequest', boardRequest)
            const localVarPath = `/api/v1/boards/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create dropboxes for each board without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/boards/missing-dropboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete board
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBoard', 'id', id)
            const localVarPath = `/api/v1/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get board by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardById', 'id', id)
            const localVarPath = `/api/v1/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Board with secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardSecretsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBoardSecretsById', 'id', id)
            const localVarPath = `/api/v1/boards/secrets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get boards by state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoards: async (pageNumber: number, pageSize: number, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getBoards', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getBoards', 'pageSize', pageSize)
            const localVarPath = `/api/v1/boards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Boards
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoards: async (sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, code?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, statuses?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/boards/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (states) {
                localVarQueryParameter['states'] = Array.from(states);
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = Array.from(statuses);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardById: async (id: string, boardRequest: BoardRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBoardById', 'id', id)
            // verify required parameter 'boardRequest' is not null or undefined
            assertParamExists('updateBoardById', 'boardRequest', boardRequest)
            const localVarPath = `/api/v1/boards/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(boardRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoardControllerApi - functional programming interface
 * @export
 */
export const BoardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create new board
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBoard(boardRequest: BoardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBoard(boardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create dropboxes for each board without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissingOfficeDropboxes2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BoardDropboxErrorResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissingOfficeDropboxes2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete board
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBoard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBoard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get board by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Board with secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoardSecretsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardSecretsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoardSecretsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get boards by state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoards(pageNumber: number, pageSize: number, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedBoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoards(pageNumber, pageSize, stateOrProvince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Boards
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBoards(sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, code?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, statuses?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseBoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBoards(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, code, states, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBoardById(id: string, boardRequest: BoardRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoardResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBoardById(id, boardRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoardControllerApi - factory interface
 * @export
 */
export const BoardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoardControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary create new board
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBoard(boardRequest: BoardRequest, options?: any): AxiosPromise<BoardResponse> {
            return localVarFp.createBoard(boardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create dropboxes for each board without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes2(options?: any): AxiosPromise<Array<BoardDropboxErrorResponse>> {
            return localVarFp.createMissingOfficeDropboxes2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete board
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBoard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBoard(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get board by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardById(id: string, options?: any): AxiosPromise<BoardResponse> {
            return localVarFp.getBoardById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Board with secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoardSecretsById(id: string, options?: any): AxiosPromise<BoardSecretsResponse> {
            return localVarFp.getBoardSecretsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get boards by state
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoards(pageNumber: number, pageSize: number, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: any): AxiosPromise<PagedBoardResponse> {
            return localVarFp.getBoards(pageNumber, pageSize, stateOrProvince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Boards
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBoards(sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, code?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, statuses?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: any): AxiosPromise<GenericSearchResponseBoardResponse> {
            return localVarFp.searchBoards(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, code, states, statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {BoardRequest} boardRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBoardById(id: string, boardRequest: BoardRequest, options?: any): AxiosPromise<BoardResponse> {
            return localVarFp.updateBoardById(id, boardRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoardControllerApi - object-oriented interface
 * @export
 * @class BoardControllerApi
 * @extends {BaseAPI}
 */
export class BoardControllerApi extends BaseAPI {
    /**
     * 
     * @summary create new board
     * @param {BoardRequest} boardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public createBoard(boardRequest: BoardRequest, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).createBoard(boardRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create dropboxes for each board without one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public createMissingOfficeDropboxes2(options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).createMissingOfficeDropboxes2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete board
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public deleteBoard(id: string, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).deleteBoard(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get board by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public getBoardById(id: string, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).getBoardById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Board with secrets by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public getBoardSecretsById(id: string, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).getBoardSecretsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get boards by state
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public getBoards(pageNumber: number, pageSize: number, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).getBoards(pageNumber, pageSize, stateOrProvince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Boards
     * @param {Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [code] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
     * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [statuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public searchBoards(sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'JOIN_DATE' | 'STATUS'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, code?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, statuses?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).searchBoards(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, code, states, statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update board by id
     * @param {string} id 
     * @param {BoardRequest} boardRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoardControllerApi
     */
    public updateBoardById(id: string, boardRequest: BoardRequest, options?: AxiosRequestConfig) {
        return BoardControllerApiFp(this.configuration).updateBoardById(id, boardRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrokerControllerApi - axios parameter creator
 * @export
 */
export const BrokerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Metabase Dashboard URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetabaseDashboardURL: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/brokers/metabase-dashboard-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a user as a broker
         * @param {MarkUserAsBrokerRequest} markUserAsBrokerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markUserAsBroker: async (markUserAsBrokerRequest: MarkUserAsBrokerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'markUserAsBrokerRequest' is not null or undefined
            assertParamExists('markUserAsBroker', 'markUserAsBrokerRequest', markUserAsBrokerRequest)
            const localVarPath = `/api/v1/brokers/mark-as-broker`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markUserAsBrokerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrokerControllerApi - functional programming interface
 * @export
 */
export const BrokerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrokerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Metabase Dashboard URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetabaseDashboardURL(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetabaseDashboardUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetabaseDashboardURL(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a user as a broker
         * @param {MarkUserAsBrokerRequest} markUserAsBrokerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markUserAsBroker(markUserAsBrokerRequest: MarkUserAsBrokerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markUserAsBroker(markUserAsBrokerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrokerControllerApi - factory interface
 * @export
 */
export const BrokerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrokerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Metabase Dashboard URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetabaseDashboardURL(options?: any): AxiosPromise<MetabaseDashboardUrlResponse> {
            return localVarFp.getMetabaseDashboardURL(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a user as a broker
         * @param {MarkUserAsBrokerRequest} markUserAsBrokerRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markUserAsBroker(markUserAsBrokerRequest: MarkUserAsBrokerRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.markUserAsBroker(markUserAsBrokerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrokerControllerApi - object-oriented interface
 * @export
 * @class BrokerControllerApi
 * @extends {BaseAPI}
 */
export class BrokerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Metabase Dashboard URLs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerControllerApi
     */
    public getMetabaseDashboardURL(options?: AxiosRequestConfig) {
        return BrokerControllerApiFp(this.configuration).getMetabaseDashboardURL(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a user as a broker
     * @param {MarkUserAsBrokerRequest} markUserAsBrokerRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrokerControllerApi
     */
    public markUserAsBroker(markUserAsBrokerRequest: MarkUserAsBrokerRequest, options?: AxiosRequestConfig) {
        return BrokerControllerApiFp(this.configuration).markUserAsBroker(markUserAsBrokerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuyerBrokerAgreementControllerApi - axios parameter creator
 * @export
 */
export const BuyerBrokerAgreementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a Buyer broker agreement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuyerBrokerAgreement: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBuyerBrokerAgreement', 'id', id)
            const localVarPath = `/api/v1/buyer-broker-agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a Buyer broker agreement and send email to buyer
         * @param {BuyerBrokerAgreementCreationRequest} buyerBrokerAgreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBuyerBrokerAgreement: async (buyerBrokerAgreementCreationRequest: BuyerBrokerAgreementCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerBrokerAgreementCreationRequest' is not null or undefined
            assertParamExists('generateBuyerBrokerAgreement', 'buyerBrokerAgreementCreationRequest', buyerBrokerAgreementCreationRequest)
            const localVarPath = `/api/v1/buyer-broker-agreements/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerBrokerAgreementCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the buyer broker agreement file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerBrokerAgreementDownloadFileUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBuyerBrokerAgreementDownloadFileUrl', 'id', id)
            const localVarPath = `/api/v1/buyer-broker-agreements/{id}/download-url`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend email to buyer for signing buyer broker agreement
         * @param {BuyerBrokerAgreementEmailResendRequest} buyerBrokerAgreementEmailResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendBuyerBrokerAgreement: async (buyerBrokerAgreementEmailResendRequest: BuyerBrokerAgreementEmailResendRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerBrokerAgreementEmailResendRequest' is not null or undefined
            assertParamExists('resendBuyerBrokerAgreement', 'buyerBrokerAgreementEmailResendRequest', buyerBrokerAgreementEmailResendRequest)
            const localVarPath = `/api/v1/buyer-broker-agreements/resend-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerBrokerAgreementEmailResendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search a Buyer broker agreement
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBuyerBrokerAgreement: async (viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', agreementDefinitionId?: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'viewType' is not null or undefined
            assertParamExists('searchBuyerBrokerAgreement', 'viewType', viewType)
            const localVarPath = `/api/v1/buyer-broker-agreements/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agreementDefinitionId !== undefined) {
                localVarQueryParameter['agreementDefinitionId'] = agreementDefinitionId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (viewType !== undefined) {
                localVarQueryParameter['viewType'] = viewType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyerBrokerAgreementControllerApi - functional programming interface
 * @export
 */
export const BuyerBrokerAgreementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyerBrokerAgreementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a Buyer broker agreement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBuyerBrokerAgreement(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBuyerBrokerAgreement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate a Buyer broker agreement and send email to buyer
         * @param {BuyerBrokerAgreementCreationRequest} buyerBrokerAgreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest: BuyerBrokerAgreementCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerBrokerAgreementCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the buyer broker agreement file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerBrokerAgreementDownloadFileUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementFileUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerBrokerAgreementDownloadFileUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend email to buyer for signing buyer broker agreement
         * @param {BuyerBrokerAgreementEmailResendRequest} buyerBrokerAgreementEmailResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest: BuyerBrokerAgreementEmailResendRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search a Buyer broker agreement
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchBuyerBrokerAgreement(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', agreementDefinitionId?: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerBrokerAgreementPageableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchBuyerBrokerAgreement(viewType, agreementDefinitionId, name, page, size, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuyerBrokerAgreementControllerApi - factory interface
 * @export
 */
export const BuyerBrokerAgreementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyerBrokerAgreementControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a Buyer broker agreement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBuyerBrokerAgreement(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBuyerBrokerAgreement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a Buyer broker agreement and send email to buyer
         * @param {BuyerBrokerAgreementCreationRequest} buyerBrokerAgreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest: BuyerBrokerAgreementCreationRequest, options?: any): AxiosPromise<BuyerBrokerAgreementCreationResponse> {
            return localVarFp.generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the buyer broker agreement file URL
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerBrokerAgreementDownloadFileUrl(id: string, options?: any): AxiosPromise<AgreementFileUrlResponse> {
            return localVarFp.getBuyerBrokerAgreementDownloadFileUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend email to buyer for signing buyer broker agreement
         * @param {BuyerBrokerAgreementEmailResendRequest} buyerBrokerAgreementEmailResendRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest: BuyerBrokerAgreementEmailResendRequest, options?: any): AxiosPromise<void> {
            return localVarFp.resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search a Buyer broker agreement
         * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [name] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchBuyerBrokerAgreement(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', agreementDefinitionId?: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: any): AxiosPromise<BuyerBrokerAgreementPageableResponse> {
            return localVarFp.searchBuyerBrokerAgreement(viewType, agreementDefinitionId, name, page, size, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyerBrokerAgreementControllerApi - object-oriented interface
 * @export
 * @class BuyerBrokerAgreementControllerApi
 * @extends {BaseAPI}
 */
export class BuyerBrokerAgreementControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete a Buyer broker agreement
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerBrokerAgreementControllerApi
     */
    public deleteBuyerBrokerAgreement(id: string, options?: AxiosRequestConfig) {
        return BuyerBrokerAgreementControllerApiFp(this.configuration).deleteBuyerBrokerAgreement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a Buyer broker agreement and send email to buyer
     * @param {BuyerBrokerAgreementCreationRequest} buyerBrokerAgreementCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerBrokerAgreementControllerApi
     */
    public generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest: BuyerBrokerAgreementCreationRequest, options?: AxiosRequestConfig) {
        return BuyerBrokerAgreementControllerApiFp(this.configuration).generateBuyerBrokerAgreement(buyerBrokerAgreementCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the buyer broker agreement file URL
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerBrokerAgreementControllerApi
     */
    public getBuyerBrokerAgreementDownloadFileUrl(id: string, options?: AxiosRequestConfig) {
        return BuyerBrokerAgreementControllerApiFp(this.configuration).getBuyerBrokerAgreementDownloadFileUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend email to buyer for signing buyer broker agreement
     * @param {BuyerBrokerAgreementEmailResendRequest} buyerBrokerAgreementEmailResendRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerBrokerAgreementControllerApi
     */
    public resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest: BuyerBrokerAgreementEmailResendRequest, options?: AxiosRequestConfig) {
        return BuyerBrokerAgreementControllerApiFp(this.configuration).resendBuyerBrokerAgreement(buyerBrokerAgreementEmailResendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search a Buyer broker agreement
     * @param {'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED'} viewType 
     * @param {string} [agreementDefinitionId] 
     * @param {string} [name] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerBrokerAgreementControllerApi
     */
    public searchBuyerBrokerAgreement(viewType: 'ACTIVE' | 'SIGNED' | 'INCOMPLETE' | 'VOIDED', agreementDefinitionId?: string, name?: string, page?: number, size?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'NAME' | 'CREATED_AT' | 'UPDATED_AT'>, options?: AxiosRequestConfig) {
        return BuyerBrokerAgreementControllerApiFp(this.configuration).searchBuyerBrokerAgreement(viewType, agreementDefinitionId, name, page, size, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BuyerFlowControllerApi - axios parameter creator
 * @export
 */
export const BuyerFlowControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available services for buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableServices: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getAvailableServices', 'token', token)
            const localVarPath = `/api/v1/buyers/available-services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get buyer broker agreement members info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerBrokerAgreementMembersInfo: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getBuyerBrokerAgreementMembersInfo', 'token', token)
            const localVarPath = `/api/v1/buyers/buyer-broker-agreement-members-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get buyer by email address
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerByEmailAddress: async (token: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getBuyerByEmailAddress', 'token', token)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getBuyerByEmailAddress', 'email', email)
            const localVarPath = `/api/v1/buyers/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a user as a buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makerAsBuyer: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('makerAsBuyer', 'token', token)
            const localVarPath = `/api/v1/buyers/mark-as-buyer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign the buyer broker agreement
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signBuyerBrokerAgreement: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('signBuyerBrokerAgreement', 'token', token)
            const localVarPath = `/api/v1/buyers/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up as a buyer
         * @param {string} token 
         * @param {BuyerSignupRequest} buyerSignupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup1: async (token: string, buyerSignupRequest: BuyerSignupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('signup1', 'token', token)
            // verify required parameter 'buyerSignupRequest' is not null or undefined
            assertParamExists('signup1', 'buyerSignupRequest', buyerSignupRequest)
            const localVarPath = `/api/v1/buyers/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerSignupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update buyer information
         * @param {string} token 
         * @param {UpdateBuyerInfoRequest} updateBuyerInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuyerInfo: async (token: string, updateBuyerInfoRequest: UpdateBuyerInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateBuyerInfo', 'token', token)
            // verify required parameter 'updateBuyerInfoRequest' is not null or undefined
            assertParamExists('updateBuyerInfo', 'updateBuyerInfoRequest', updateBuyerInfoRequest)
            const localVarPath = `/api/v1/buyers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBuyerInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update buyer service preferences
         * @param {string} token 
         * @param {BuyerOptedServicesRequest} buyerOptedServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServicePreferences: async (token: string, buyerOptedServicesRequest: BuyerOptedServicesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateServicePreferences', 'token', token)
            // verify required parameter 'buyerOptedServicesRequest' is not null or undefined
            assertParamExists('updateServicePreferences', 'buyerOptedServicesRequest', buyerOptedServicesRequest)
            const localVarPath = `/api/v1/buyers/service-preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerOptedServicesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyerFlowControllerApi - functional programming interface
 * @export
 */
export const BuyerFlowControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyerFlowControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available services for buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableServices(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerServicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableServices(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get buyer broker agreement members info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerBrokerAgreementMembersInfo(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerBrokerAgreementMembersInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerBrokerAgreementMembersInfo(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get buyer by email address
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuyerByEmailAddress(token: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuyerByEmailAddress(token, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a user as a buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makerAsBuyer(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makerAsBuyer(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign the buyer broker agreement
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signBuyerBrokerAgreement(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementSigningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signBuyerBrokerAgreement(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up as a buyer
         * @param {string} token 
         * @param {BuyerSignupRequest} buyerSignupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup1(token: string, buyerSignupRequest: BuyerSignupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup1(token, buyerSignupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update buyer information
         * @param {string} token 
         * @param {UpdateBuyerInfoRequest} updateBuyerInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBuyerInfo(token: string, updateBuyerInfoRequest: UpdateBuyerInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBuyerInfo(token, updateBuyerInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update buyer service preferences
         * @param {string} token 
         * @param {BuyerOptedServicesRequest} buyerOptedServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServicePreferences(token: string, buyerOptedServicesRequest: BuyerOptedServicesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerOptedServicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServicePreferences(token, buyerOptedServicesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuyerFlowControllerApi - factory interface
 * @export
 */
export const BuyerFlowControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyerFlowControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available services for buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableServices(token: string, options?: any): AxiosPromise<BuyerServicesResponse> {
            return localVarFp.getAvailableServices(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get buyer broker agreement members info
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerBrokerAgreementMembersInfo(token: string, options?: any): AxiosPromise<BuyerBrokerAgreementMembersInfo> {
            return localVarFp.getBuyerBrokerAgreementMembersInfo(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get buyer by email address
         * @param {string} token 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuyerByEmailAddress(token: string, email: string, options?: any): AxiosPromise<BuyerResponse> {
            return localVarFp.getBuyerByEmailAddress(token, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a user as a buyer
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makerAsBuyer(token: string, options?: any): AxiosPromise<void> {
            return localVarFp.makerAsBuyer(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign the buyer broker agreement
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signBuyerBrokerAgreement(token: string, options?: any): AxiosPromise<AgreementSigningResponse> {
            return localVarFp.signBuyerBrokerAgreement(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up as a buyer
         * @param {string} token 
         * @param {BuyerSignupRequest} buyerSignupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup1(token: string, buyerSignupRequest: BuyerSignupRequest, options?: any): AxiosPromise<BuyerResponse> {
            return localVarFp.signup1(token, buyerSignupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update buyer information
         * @param {string} token 
         * @param {UpdateBuyerInfoRequest} updateBuyerInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBuyerInfo(token: string, updateBuyerInfoRequest: UpdateBuyerInfoRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateBuyerInfo(token, updateBuyerInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update buyer service preferences
         * @param {string} token 
         * @param {BuyerOptedServicesRequest} buyerOptedServicesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServicePreferences(token: string, buyerOptedServicesRequest: BuyerOptedServicesRequest, options?: any): AxiosPromise<BuyerOptedServicesResponse> {
            return localVarFp.updateServicePreferences(token, buyerOptedServicesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyerFlowControllerApi - object-oriented interface
 * @export
 * @class BuyerFlowControllerApi
 * @extends {BaseAPI}
 */
export class BuyerFlowControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get available services for buyer
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public getAvailableServices(token: string, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).getAvailableServices(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get buyer broker agreement members info
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public getBuyerBrokerAgreementMembersInfo(token: string, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).getBuyerBrokerAgreementMembersInfo(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get buyer by email address
     * @param {string} token 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public getBuyerByEmailAddress(token: string, email: string, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).getBuyerByEmailAddress(token, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a user as a buyer
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public makerAsBuyer(token: string, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).makerAsBuyer(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign the buyer broker agreement
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public signBuyerBrokerAgreement(token: string, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).signBuyerBrokerAgreement(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up as a buyer
     * @param {string} token 
     * @param {BuyerSignupRequest} buyerSignupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public signup1(token: string, buyerSignupRequest: BuyerSignupRequest, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).signup1(token, buyerSignupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update buyer information
     * @param {string} token 
     * @param {UpdateBuyerInfoRequest} updateBuyerInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public updateBuyerInfo(token: string, updateBuyerInfoRequest: UpdateBuyerInfoRequest, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).updateBuyerInfo(token, updateBuyerInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update buyer service preferences
     * @param {string} token 
     * @param {BuyerOptedServicesRequest} buyerOptedServicesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerFlowControllerApi
     */
    public updateServicePreferences(token: string, buyerOptedServicesRequest: BuyerOptedServicesRequest, options?: AxiosRequestConfig) {
        return BuyerFlowControllerApiFp(this.configuration).updateServicePreferences(token, buyerOptedServicesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalendarControllerApi - axios parameter creator
 * @export
 */
export const CalendarControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Counts the number of all calendar events for all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/calendar/events/all/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Counts the number of calendar events for an agent for the corresponding tab
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countEventsForAgent: async (agentId: string, calendarIds?: Array<string>, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('countEventsForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/calendar/events/agent/{agentId}/count`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calendarIds) {
                localVarQueryParameter['calendarIds'] = Array.from(calendarIds);
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates and syncs a new calendar
         * @param {CalendarCreateRequest} calendarCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendar: async (calendarCreateRequest: CalendarCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarCreateRequest' is not null or undefined
            assertParamExists('createCalendar', 'calendarCreateRequest', calendarCreateRequest)
            const localVarPath = `/api/v1/calendar/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar: async (calendarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('deleteCalendar', 'calendarId', calendarId)
            const localVarPath = `/api/v1/calendar/delete/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes calendar event image by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventImage: async (calendarEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('deleteCalendarEventImage', 'calendarEventId', calendarEventId)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/image`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all calendar infos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarInfos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/calendar/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all calendar events for all calendars
         * @param {Array<string>} [calendarIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvents: async (calendarIds?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/calendar/events/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calendarIds) {
                localVarQueryParameter['calendarIds'] = Array.from(calendarIds);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets calendar event by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvent: async (calendarEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEvent', 'calendarEventId', calendarEventId)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets count of invitees by RSVP type
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventInviteesCount: async (calendarEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEventInviteesCount', 'calendarEventId', calendarEventId)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/invitees/count`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of calendar event invitees
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCalendarEventInviteesDeprecated: async (calendarEventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEventInviteesDeprecated', 'calendarEventId', calendarEventId)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/invitees`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated list of invitees for a given event for a particular rsvp status
         * @param {string} calendarEventId 
         * @param {'YES' | 'NO' | 'MAYBE'} rsvpStatus 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventInviteesPaginated: async (calendarEventId: string, rsvpStatus: 'YES' | 'NO' | 'MAYBE', pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('getCalendarEventInviteesPaginated', 'calendarEventId', calendarEventId)
            // verify required parameter 'rsvpStatus' is not null or undefined
            assertParamExists('getCalendarEventInviteesPaginated', 'rsvpStatus', rsvpStatus)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getCalendarEventInviteesPaginated', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getCalendarEventInviteesPaginated', 'pageSize', pageSize)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/invitees/rsvp/{rsvpStatus}/{pageNumber}/{pageSize}`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)))
                .replace(`{${"rsvpStatus"}}`, encodeURIComponent(String(rsvpStatus)))
                .replace(`{${"pageNumber"}}`, encodeURIComponent(String(pageNumber)))
                .replace(`{${"pageSize"}}`, encodeURIComponent(String(pageSize)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all calendars for a given agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsForAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getCalendarsForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/calendar/agent/{agentId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets calendar events for an agent for the corresponding tab
         * @param {'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE'} tab 
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForAgent: async (tab: 'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE', agentId: string, calendarIds?: Array<string>, searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tab' is not null or undefined
            assertParamExists('getEventsForAgent', 'tab', tab)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getEventsForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/calendar/events/{tab}/agent/{agentId}`
                .replace(`{${"tab"}}`, encodeURIComponent(String(tab)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (calendarIds) {
                localVarQueryParameter['calendarIds'] = Array.from(calendarIds);
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets upcoming events for a given calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingEvents: async (calendarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('getUpcomingEvents', 'calendarId', calendarId)
            const localVarPath = `/api/v1/calendar/{calendarId}/events/upcoming`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Agent rvsps to calendar event
         * @param {string} calendarEventId 
         * @param {string} agentId 
         * @param {'YES' | 'NO' | 'MAYBE'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rsvpToCalendarEvent: async (calendarEventId: string, agentId: string, status: 'YES' | 'NO' | 'MAYBE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('rsvpToCalendarEvent', 'calendarEventId', calendarEventId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('rsvpToCalendarEvent', 'agentId', agentId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('rsvpToCalendarEvent', 'status', status)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/agent/{agentId}/rsvp/{status}`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Syncs all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllCalendars: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/calendar/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Syncs calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendar: async (calendarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('syncCalendar', 'calendarId', calendarId)
            const localVarPath = `/api/v1/calendar/sync/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates and syncs a calendar
         * @param {string} calendarId 
         * @param {CalendarUpdateRequest} calendarUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendar: async (calendarId: string, calendarUpdateRequest: CalendarUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('updateCalendar', 'calendarId', calendarId)
            // verify required parameter 'calendarUpdateRequest' is not null or undefined
            assertParamExists('updateCalendar', 'calendarUpdateRequest', calendarUpdateRequest)
            const localVarPath = `/api/v1/calendar/update/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates calendar event by id
         * @param {string} calendarEventId 
         * @param {CalendarEventUpdateRequest} calendarEventUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent: async (calendarEventId: string, calendarEventUpdateRequest: CalendarEventUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('updateCalendarEvent', 'calendarEventId', calendarEventId)
            // verify required parameter 'calendarEventUpdateRequest' is not null or undefined
            assertParamExists('updateCalendarEvent', 'calendarEventUpdateRequest', calendarEventUpdateRequest)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarEventUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates calendar event image by id
         * @param {string} calendarEventId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventImage: async (calendarEventId: string, imageFile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarEventId' is not null or undefined
            assertParamExists('updateCalendarEventImage', 'calendarEventId', calendarEventId)
            // verify required parameter 'imageFile' is not null or undefined
            assertParamExists('updateCalendarEventImage', 'imageFile', imageFile)
            const localVarPath = `/api/v1/calendar/event/{calendarEventId}/image`
                .replace(`{${"calendarEventId"}}`, encodeURIComponent(String(calendarEventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (imageFile !== undefined) { 
                localVarFormParams.append('imageFile', imageFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarControllerApi - functional programming interface
 * @export
 */
export const CalendarControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Counts the number of all calendar events for all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countAllEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countAllEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Counts the number of calendar events for an agent for the corresponding tab
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countEventsForAgent(agentId: string, calendarIds?: Array<string>, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarCountOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countEventsForAgent(agentId, calendarIds, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates and syncs a new calendar
         * @param {CalendarCreateRequest} calendarCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCalendar(calendarCreateRequest: CalendarCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCalendar(calendarCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendar(calendarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendar(calendarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes calendar event image by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCalendarEventImage(calendarEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCalendarEventImage(calendarEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all calendar infos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCalendarInfos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCalendarInfos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all calendar events for all calendars
         * @param {Array<string>} [calendarIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEvents(calendarIds?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEvents(calendarIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets calendar event by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEvent(calendarEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEvent(calendarEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets count of invitees by RSVP type
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventInviteesCount(calendarEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarInviteesCountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventInviteesCount(calendarEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of calendar event invitees
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCalendarEventInviteesDeprecated(calendarEventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventInviteeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventInviteesDeprecated(calendarEventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated list of invitees for a given event for a particular rsvp status
         * @param {string} calendarEventId 
         * @param {'YES' | 'NO' | 'MAYBE'} rsvpStatus 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarEventInviteesPaginated(calendarEventId: string, rsvpStatus: 'YES' | 'NO' | 'MAYBE', pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventInviteePaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarEventInviteesPaginated(calendarEventId, rsvpStatus, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all calendars for a given agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarsForAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarsForAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets calendar events for an agent for the corresponding tab
         * @param {'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE'} tab 
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsForAgent(tab: 'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE', agentId: string, calendarIds?: Array<string>, searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsForAgent(tab, agentId, calendarIds, searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets upcoming events for a given calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUpcomingEvents(calendarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarEventResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUpcomingEvents(calendarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Agent rvsps to calendar event
         * @param {string} calendarEventId 
         * @param {string} agentId 
         * @param {'YES' | 'NO' | 'MAYBE'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rsvpToCalendarEvent(calendarEventId: string, agentId: string, status: 'YES' | 'NO' | 'MAYBE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rsvpToCalendarEvent(calendarEventId, agentId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Syncs all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllCalendars(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllCalendars(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Syncs calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCalendar(calendarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCalendar(calendarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates and syncs a calendar
         * @param {string} calendarId 
         * @param {CalendarUpdateRequest} calendarUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendar(calendarId: string, calendarUpdateRequest: CalendarUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendar(calendarId, calendarUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates calendar event by id
         * @param {string} calendarEventId 
         * @param {CalendarEventUpdateRequest} calendarEventUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEvent(calendarEventId: string, calendarEventUpdateRequest: CalendarEventUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEvent(calendarEventId, calendarEventUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates calendar event image by id
         * @param {string} calendarEventId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCalendarEventImage(calendarEventId: string, imageFile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalendarEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCalendarEventImage(calendarEventId, imageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarControllerApi - factory interface
 * @export
 */
export const CalendarControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Counts the number of all calendar events for all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllEvents(options?: any): AxiosPromise<number> {
            return localVarFp.countAllEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Counts the number of calendar events for an agent for the corresponding tab
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countEventsForAgent(agentId: string, calendarIds?: Array<string>, searchText?: string, options?: any): AxiosPromise<CalendarCountOverviewResponse> {
            return localVarFp.countEventsForAgent(agentId, calendarIds, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates and syncs a new calendar
         * @param {CalendarCreateRequest} calendarCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCalendar(calendarCreateRequest: CalendarCreateRequest, options?: any): AxiosPromise<CalendarResponse> {
            return localVarFp.createCalendar(calendarCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendar(calendarId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCalendar(calendarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes calendar event image by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCalendarEventImage(calendarEventId: string, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.deleteCalendarEventImage(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all calendar infos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCalendarInfos(options?: any): AxiosPromise<Array<CalendarInfoResponse>> {
            return localVarFp.getAllCalendarInfos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all calendar events for all calendars
         * @param {Array<string>} [calendarIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvents(calendarIds?: Array<string>, options?: any): AxiosPromise<Array<CalendarEventResponse>> {
            return localVarFp.getAllEvents(calendarIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets calendar event by id
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEvent(calendarEventId: string, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.getCalendarEvent(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets count of invitees by RSVP type
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventInviteesCount(calendarEventId: string, options?: any): AxiosPromise<CalendarInviteesCountResponse> {
            return localVarFp.getCalendarEventInviteesCount(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of calendar event invitees
         * @param {string} calendarEventId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCalendarEventInviteesDeprecated(calendarEventId: string, options?: any): AxiosPromise<Array<CalendarEventInviteeResponse>> {
            return localVarFp.getCalendarEventInviteesDeprecated(calendarEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated list of invitees for a given event for a particular rsvp status
         * @param {string} calendarEventId 
         * @param {'YES' | 'NO' | 'MAYBE'} rsvpStatus 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarEventInviteesPaginated(calendarEventId: string, rsvpStatus: 'YES' | 'NO' | 'MAYBE', pageNumber: number, pageSize: number, options?: any): AxiosPromise<CalendarEventInviteePaginatedResponse> {
            return localVarFp.getCalendarEventInviteesPaginated(calendarEventId, rsvpStatus, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all calendars for a given agent
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarsForAgent(agentId: string, options?: any): AxiosPromise<CalendarInfosResponse> {
            return localVarFp.getCalendarsForAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets calendar events for an agent for the corresponding tab
         * @param {'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE'} tab 
         * @param {string} agentId 
         * @param {Array<string>} [calendarIds] 
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsForAgent(tab: 'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE', agentId: string, calendarIds?: Array<string>, searchText?: string, options?: any): AxiosPromise<Array<CalendarEventResponse>> {
            return localVarFp.getEventsForAgent(tab, agentId, calendarIds, searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets upcoming events for a given calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUpcomingEvents(calendarId: string, options?: any): AxiosPromise<Array<CalendarEventResponse>> {
            return localVarFp.getUpcomingEvents(calendarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Agent rvsps to calendar event
         * @param {string} calendarEventId 
         * @param {string} agentId 
         * @param {'YES' | 'NO' | 'MAYBE'} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rsvpToCalendarEvent(calendarEventId: string, agentId: string, status: 'YES' | 'NO' | 'MAYBE', options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.rsvpToCalendarEvent(calendarEventId, agentId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Syncs all calendars
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllCalendars(options?: any): AxiosPromise<Array<CalendarResponse>> {
            return localVarFp.syncAllCalendars(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Syncs calendar
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCalendar(calendarId: string, options?: any): AxiosPromise<CalendarResponse> {
            return localVarFp.syncCalendar(calendarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates and syncs a calendar
         * @param {string} calendarId 
         * @param {CalendarUpdateRequest} calendarUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendar(calendarId: string, calendarUpdateRequest: CalendarUpdateRequest, options?: any): AxiosPromise<CalendarResponse> {
            return localVarFp.updateCalendar(calendarId, calendarUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates calendar event by id
         * @param {string} calendarEventId 
         * @param {CalendarEventUpdateRequest} calendarEventUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEvent(calendarEventId: string, calendarEventUpdateRequest: CalendarEventUpdateRequest, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.updateCalendarEvent(calendarEventId, calendarEventUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates calendar event image by id
         * @param {string} calendarEventId 
         * @param {any} imageFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCalendarEventImage(calendarEventId: string, imageFile: any, options?: any): AxiosPromise<CalendarEventResponse> {
            return localVarFp.updateCalendarEventImage(calendarEventId, imageFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarControllerApi - object-oriented interface
 * @export
 * @class CalendarControllerApi
 * @extends {BaseAPI}
 */
export class CalendarControllerApi extends BaseAPI {
    /**
     * 
     * @summary Counts the number of all calendar events for all calendars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public countAllEvents(options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).countAllEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Counts the number of calendar events for an agent for the corresponding tab
     * @param {string} agentId 
     * @param {Array<string>} [calendarIds] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public countEventsForAgent(agentId: string, calendarIds?: Array<string>, searchText?: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).countEventsForAgent(agentId, calendarIds, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates and syncs a new calendar
     * @param {CalendarCreateRequest} calendarCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public createCalendar(calendarCreateRequest: CalendarCreateRequest, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).createCalendar(calendarCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a calendar
     * @param {string} calendarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public deleteCalendar(calendarId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).deleteCalendar(calendarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes calendar event image by id
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public deleteCalendarEventImage(calendarEventId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).deleteCalendarEventImage(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all calendar infos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getAllCalendarInfos(options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getAllCalendarInfos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all calendar events for all calendars
     * @param {Array<string>} [calendarIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getAllEvents(calendarIds?: Array<string>, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getAllEvents(calendarIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets calendar event by id
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getCalendarEvent(calendarEventId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getCalendarEvent(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets count of invitees by RSVP type
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getCalendarEventInviteesCount(calendarEventId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getCalendarEventInviteesCount(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of calendar event invitees
     * @param {string} calendarEventId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getCalendarEventInviteesDeprecated(calendarEventId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getCalendarEventInviteesDeprecated(calendarEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated list of invitees for a given event for a particular rsvp status
     * @param {string} calendarEventId 
     * @param {'YES' | 'NO' | 'MAYBE'} rsvpStatus 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getCalendarEventInviteesPaginated(calendarEventId: string, rsvpStatus: 'YES' | 'NO' | 'MAYBE', pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getCalendarEventInviteesPaginated(calendarEventId, rsvpStatus, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all calendars for a given agent
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getCalendarsForAgent(agentId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getCalendarsForAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets calendar events for an agent for the corresponding tab
     * @param {'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE'} tab 
     * @param {string} agentId 
     * @param {Array<string>} [calendarIds] 
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getEventsForAgent(tab: 'ALL' | 'PAST' | 'UPCOMING' | 'YES' | 'NO' | 'MAYBE', agentId: string, calendarIds?: Array<string>, searchText?: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getEventsForAgent(tab, agentId, calendarIds, searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets upcoming events for a given calendar
     * @param {string} calendarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public getUpcomingEvents(calendarId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).getUpcomingEvents(calendarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Agent rvsps to calendar event
     * @param {string} calendarEventId 
     * @param {string} agentId 
     * @param {'YES' | 'NO' | 'MAYBE'} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public rsvpToCalendarEvent(calendarEventId: string, agentId: string, status: 'YES' | 'NO' | 'MAYBE', options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).rsvpToCalendarEvent(calendarEventId, agentId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Syncs all calendars
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public syncAllCalendars(options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).syncAllCalendars(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Syncs calendar
     * @param {string} calendarId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public syncCalendar(calendarId: string, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).syncCalendar(calendarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates and syncs a calendar
     * @param {string} calendarId 
     * @param {CalendarUpdateRequest} calendarUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public updateCalendar(calendarId: string, calendarUpdateRequest: CalendarUpdateRequest, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).updateCalendar(calendarId, calendarUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates calendar event by id
     * @param {string} calendarEventId 
     * @param {CalendarEventUpdateRequest} calendarEventUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public updateCalendarEvent(calendarEventId: string, calendarEventUpdateRequest: CalendarEventUpdateRequest, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).updateCalendarEvent(calendarEventId, calendarEventUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates calendar event image by id
     * @param {string} calendarEventId 
     * @param {any} imageFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarControllerApi
     */
    public updateCalendarEventImage(calendarEventId: string, imageFile: any, options?: AxiosRequestConfig) {
        return CalendarControllerApiFp(this.configuration).updateCalendarEventImage(calendarEventId, imageFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CheckPermissionsControllerApi - axios parameter creator
 * @export
 */
export const CheckPermissionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} officeId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission1: async (officeId: string, action?: 'READ' | 'WRITE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('checkPermission1', 'officeId', officeId)
            const localVarPath = `/api/v1/offices/{officeId}/check-permissions`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckPermissionsControllerApi - functional programming interface
 * @export
 */
export const CheckPermissionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckPermissionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} officeId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPermission1(officeId: string, action?: 'READ' | 'WRITE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPermission1(officeId, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckPermissionsControllerApi - factory interface
 * @export
 */
export const CheckPermissionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckPermissionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} officeId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission1(officeId: string, action?: 'READ' | 'WRITE', options?: any): AxiosPromise<void> {
            return localVarFp.checkPermission1(officeId, action, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckPermissionsControllerApi - object-oriented interface
 * @export
 * @class CheckPermissionsControllerApi
 * @extends {BaseAPI}
 */
export class CheckPermissionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} officeId 
     * @param {'READ' | 'WRITE'} [action] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckPermissionsControllerApi
     */
    public checkPermission1(officeId: string, action?: 'READ' | 'WRITE', options?: AxiosRequestConfig) {
        return CheckPermissionsControllerApiFp(this.configuration).checkPermission1(officeId, action, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyControllerApi - axios parameter creator
 * @export
 */
export const CompanyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompany', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/api/v1/companies/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a company
         * @param {string} id 
         * @param {EditCompanyRequest} editCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompany: async (id: string, editCompanyRequest: EditCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editCompany', 'id', id)
            // verify required parameter 'editCompanyRequest' is not null or undefined
            assertParamExists('editCompany', 'editCompanyRequest', editCompanyRequest)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCompanyById', 'id', id)
            const localVarPath = `/api/v1/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search companies
         * @param {Array<'ID' | 'NAME' | 'EIN' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [ein] 
         * @param {string} [streetAddress] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies: async (sortBy?: Array<'ID' | 'NAME' | 'EIN' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, ein?: string, streetAddress?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/companies/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (ein !== undefined) {
                localVarQueryParameter['ein'] = ein;
            }

            if (streetAddress !== undefined) {
                localVarQueryParameter['streetAddress'] = streetAddress;
            }

            if (states) {
                localVarQueryParameter['states'] = Array.from(states);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyControllerApi - functional programming interface
 * @export
 */
export const CompanyControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompany(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompany(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a company
         * @param {string} id 
         * @param {EditCompanyRequest} editCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCompany(id: string, editCompanyRequest: EditCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCompany(id, editCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search companies
         * @param {Array<'ID' | 'NAME' | 'EIN' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [ein] 
         * @param {string} [streetAddress] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchCompanies(sortBy?: Array<'ID' | 'NAME' | 'EIN' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, ein?: string, streetAddress?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseCompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchCompanies(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, ein, streetAddress, states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyControllerApi - factory interface
 * @export
 */
export const CompanyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompany(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompany(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a company
         * @param {string} id 
         * @param {EditCompanyRequest} editCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCompany(id: string, editCompanyRequest: EditCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.editCompany(id, editCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCompanies(options?: any): AxiosPromise<Array<CompanyResponse>> {
            return localVarFp.getAllCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyById(id: string, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.getCompanyById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search companies
         * @param {Array<'ID' | 'NAME' | 'EIN' | 'STATE'>} [sortBy] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchText] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [ein] 
         * @param {string} [streetAddress] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchCompanies(sortBy?: Array<'ID' | 'NAME' | 'EIN' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, ein?: string, streetAddress?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: any): AxiosPromise<GenericSearchResponseCompanyResponse> {
            return localVarFp.searchCompanies(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, ein, streetAddress, states, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyControllerApi - object-oriented interface
 * @export
 * @class CompanyControllerApi
 * @extends {BaseAPI}
 */
export class CompanyControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create new company
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public createCompany(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).createCompany(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a company
     * @param {string} id 
     * @param {EditCompanyRequest} editCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public editCompany(id: string, editCompanyRequest: EditCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).editCompany(id, editCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getAllCompanies(options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getAllCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public getCompanyById(id: string, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).getCompanyById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search companies
     * @param {Array<'ID' | 'NAME' | 'EIN' | 'STATE'>} [sortBy] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchText] 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [ein] 
     * @param {string} [streetAddress] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [states] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyControllerApi
     */
    public searchCompanies(sortBy?: Array<'ID' | 'NAME' | 'EIN' | 'STATE'>, sortDirection?: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, searchText?: string, id?: string, name?: string, ein?: string, streetAddress?: string, states?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig) {
        return CompanyControllerApiFp(this.configuration).searchCompanies(sortBy, sortDirection, pageNumber, pageSize, searchText, id, name, ein, streetAddress, states, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryControllerApi - axios parameter creator
 * @export
 */
export const CountryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves the names of all available countries for input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryControllerApi - functional programming interface
 * @export
 */
export const CountryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves the names of all available countries for input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCountriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryControllerApi - factory interface
 * @export
 */
export const CountryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves the names of all available countries for input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountries(options?: any): AxiosPromise<GetCountriesResponse> {
            return localVarFp.getCountries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CountryControllerApi - object-oriented interface
 * @export
 * @class CountryControllerApi
 * @extends {BaseAPI}
 */
export class CountryControllerApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves the names of all available countries for input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryControllerApi
     */
    public getCountries(options?: AxiosRequestConfig) {
        return CountryControllerApiFp(this.configuration).getCountries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DesignCenterControllerApi - axios parameter creator
 * @export
 */
export const DesignCenterControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get JWT for Real Design Center for a given agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwtForAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getJwtForAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/design-center/{agentId}/jwt`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DesignCenterControllerApi - functional programming interface
 * @export
 */
export const DesignCenterControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DesignCenterControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get JWT for Real Design Center for a given agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJwtForAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesignCenterJwtResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJwtForAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DesignCenterControllerApi - factory interface
 * @export
 */
export const DesignCenterControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DesignCenterControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get JWT for Real Design Center for a given agentId
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJwtForAgent(agentId: string, options?: any): AxiosPromise<DesignCenterJwtResponse> {
            return localVarFp.getJwtForAgent(agentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DesignCenterControllerApi - object-oriented interface
 * @export
 * @class DesignCenterControllerApi
 * @extends {BaseAPI}
 */
export class DesignCenterControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get JWT for Real Design Center for a given agentId
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesignCenterControllerApi
     */
    public getJwtForAgent(agentId: string, options?: AxiosRequestConfig) {
        return DesignCenterControllerApiFp(this.configuration).getJwtForAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DirectoryControllerApi - axios parameter creator
 * @export
 */
export const DirectoryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive or unarchive a person
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePerson: async (id: string, archive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archivePerson', 'id', id)
            const localVarPath = `/api/v1/directory/persons/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (archive !== undefined) {
                localVarQueryParameter['archive'] = archive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive or unarchive a vendor
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveVendor: async (id: string, archive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveVendor', 'id', id)
            const localVarPath = `/api/v1/directory/vendors/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (archive !== undefined) {
                localVarQueryParameter['archive'] = archive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a person
         * @param {DirectoryPersonCreateRequest} directoryPersonCreateRequest 
         * @param {string} [ownerAgentId] 
         * @param {string} [ownerTeamId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson: async (directoryPersonCreateRequest: DirectoryPersonCreateRequest, ownerAgentId?: string, ownerTeamId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directoryPersonCreateRequest' is not null or undefined
            assertParamExists('createPerson', 'directoryPersonCreateRequest', directoryPersonCreateRequest)
            const localVarPath = `/api/v1/directory/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ownerAgentId !== undefined) {
                localVarQueryParameter['ownerAgentId'] = ownerAgentId;
            }

            if (ownerTeamId !== undefined) {
                localVarQueryParameter['ownerTeamId'] = ownerTeamId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directoryPersonCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a vendor
         * @param {DirectoryVendorCreateRequest} directoryVendorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendor: async (directoryVendorCreateRequest: DirectoryVendorCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'directoryVendorCreateRequest' is not null or undefined
            assertParamExists('createVendor', 'directoryVendorCreateRequest', directoryVendorCreateRequest)
            const localVarPath = `/api/v1/directory/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directoryVendorCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vendors and persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {string} [createdBy] 
         * @param {string} [id] 
         * @param {Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntries: async (pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, createdBy?: string, id?: string, sortBy?: Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllEntries', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllEntries', 'pageSize', pageSize)
            const localVarPath = `/api/v1/directory/entries/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }

            if (isVerified !== undefined) {
                localVarQueryParameter['isVerified'] = isVerified;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (nationalBusinessId !== undefined) {
                localVarQueryParameter['nationalBusinessId'] = nationalBusinessId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (postal !== undefined) {
                localVarQueryParameter['postal'] = postal;
            }

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (administrativeAreaIds) {
                localVarQueryParameter['administrativeAreaIds'] = Array.from(administrativeAreaIds);
            }

            if (roles) {
                localVarQueryParameter['roles'] = Array.from(roles);
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isLinkedToVendor] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersons: async (pageNumber: number, pageSize: number, isArchived?: boolean, isPublic?: boolean, isLinkedToVendor?: boolean, searchText?: string, firstName?: string, lastName?: string, emailAddress?: string, phoneNumber?: string, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllPersons', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllPersons', 'pageSize', pageSize)
            const localVarPath = `/api/v1/directory/persons/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (isLinkedToVendor !== undefined) {
                localVarQueryParameter['isLinkedToVendor'] = isLinkedToVendor;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (roles) {
                localVarQueryParameter['roles'] = Array.from(roles);
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vendors (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {boolean} [hasLinkedPersons] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVendors: async (pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, hasLinkedPersons?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllVendors', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllVendors', 'pageSize', pageSize)
            const localVarPath = `/api/v1/directory/vendors/search/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isArchived !== undefined) {
                localVarQueryParameter['isArchived'] = isArchived;
            }

            if (isVerified !== undefined) {
                localVarQueryParameter['isVerified'] = isVerified;
            }

            if (hasLinkedPersons !== undefined) {
                localVarQueryParameter['hasLinkedPersons'] = hasLinkedPersons;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (nationalBusinessId !== undefined) {
                localVarQueryParameter['nationalBusinessId'] = nationalBusinessId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (street !== undefined) {
                localVarQueryParameter['street'] = street;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (postal !== undefined) {
                localVarQueryParameter['postal'] = postal;
            }

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (administrativeAreaIds) {
                localVarQueryParameter['administrativeAreaIds'] = Array.from(administrativeAreaIds);
            }

            if (roles) {
                localVarQueryParameter['roles'] = Array.from(roles);
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get roles available for directory entries. May be filtered by entry type.
         * @param {'VENDOR' | 'PERSON'} [entryType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedRoles: async (entryType?: 'VENDOR' | 'PERSON', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/directory/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (entryType !== undefined) {
                localVarQueryParameter['entryType'] = entryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get person by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPerson', 'id', id)
            const localVarPath = `/api/v1/directory/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get vendor by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVendorById', 'id', id)
            const localVarPath = `/api/v1/directory/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a vendor\'s w9 url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorW9Path: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVendorW9Path', 'id', id)
            const localVarPath = `/api/v1/directory/vendors/{id}/w9`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link a person to a vendor
         * @param {string} id 
         * @param {DirectoryPersonLinkRequest} directoryPersonLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPerson: async (id: string, directoryPersonLinkRequest: DirectoryPersonLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('linkPerson', 'id', id)
            // verify required parameter 'directoryPersonLinkRequest' is not null or undefined
            assertParamExists('linkPerson', 'directoryPersonLinkRequest', directoryPersonLinkRequest)
            const localVarPath = `/api/v1/directory/persons/{id}/link`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directoryPersonLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink a person from its linked vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkPerson: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unlinkPerson', 'id', id)
            const localVarPath = `/api/v1/directory/persons/{id}/unlink`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update person by id
         * @param {string} id 
         * @param {DirectoryPersonUpdateRequest} directoryPersonUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson: async (id: string, directoryPersonUpdateRequest: DirectoryPersonUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePerson', 'id', id)
            // verify required parameter 'directoryPersonUpdateRequest' is not null or undefined
            assertParamExists('updatePerson', 'directoryPersonUpdateRequest', directoryPersonUpdateRequest)
            const localVarPath = `/api/v1/directory/persons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directoryPersonUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vendor by id
         * @param {string} id 
         * @param {DirectoryVendorUpdateRequest} directoryVendorUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendor: async (id: string, directoryVendorUpdateRequest: DirectoryVendorUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVendor', 'id', id)
            // verify required parameter 'directoryVendorUpdateRequest' is not null or undefined
            assertParamExists('updateVendor', 'directoryVendorUpdateRequest', directoryVendorUpdateRequest)
            const localVarPath = `/api/v1/directory/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(directoryVendorUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update vendor\'s set of administrative areas
         * @param {string} id 
         * @param {Array<AdministrativeAreaRequest>} administrativeAreaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorAdminAreas: async (id: string, administrativeAreaRequest: Array<AdministrativeAreaRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVendorAdminAreas', 'id', id)
            // verify required parameter 'administrativeAreaRequest' is not null or undefined
            assertParamExists('updateVendorAdminAreas', 'administrativeAreaRequest', administrativeAreaRequest)
            const localVarPath = `/api/v1/directory/vendors/{id}/administrative-areas`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(administrativeAreaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a vendor\'s w9 file
         * @param {string} id 
         * @param {any} w9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorW9: async (id: string, w9: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVendorW9', 'id', id)
            // verify required parameter 'w9' is not null or undefined
            assertParamExists('updateVendorW9', 'w9', w9)
            const localVarPath = `/api/v1/directory/vendors/{id}/w9`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (w9 !== undefined) { 
                localVarFormParams.append('w9', w9 as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark vendor as verified
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyVendor: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('verifyVendor', 'id', id)
            const localVarPath = `/api/v1/directory/vendors/{id}/verify`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectoryControllerApi - functional programming interface
 * @export
 */
export const DirectoryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectoryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive or unarchive a person
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archivePerson(id: string, archive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archivePerson(id, archive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive or unarchive a vendor
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveVendor(id: string, archive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveVendor(id, archive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a person
         * @param {DirectoryPersonCreateRequest} directoryPersonCreateRequest 
         * @param {string} [ownerAgentId] 
         * @param {string} [ownerTeamId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerson(directoryPersonCreateRequest: DirectoryPersonCreateRequest, ownerAgentId?: string, ownerTeamId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(directoryPersonCreateRequest, ownerAgentId, ownerTeamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a vendor
         * @param {DirectoryVendorCreateRequest} directoryVendorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVendor(directoryVendorCreateRequest: DirectoryVendorCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVendor(directoryVendorCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all vendors and persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {string} [createdBy] 
         * @param {string} [id] 
         * @param {Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEntries(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, createdBy?: string, id?: string, sortBy?: Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedDirectoryEntryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEntries(pageNumber, pageSize, isArchived, isVerified, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, createdBy, id, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isLinkedToVendor] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPersons(pageNumber: number, pageSize: number, isArchived?: boolean, isPublic?: boolean, isLinkedToVendor?: boolean, searchText?: string, firstName?: string, lastName?: string, emailAddress?: string, phoneNumber?: string, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPersons(pageNumber, pageSize, isArchived, isPublic, isLinkedToVendor, searchText, firstName, lastName, emailAddress, phoneNumber, roles, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all vendors (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {boolean} [hasLinkedPersons] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVendors(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, hasLinkedPersons?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagedVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVendors(pageNumber, pageSize, isArchived, isVerified, hasLinkedPersons, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get roles available for directory entries. May be filtered by entry type.
         * @param {'VENDOR' | 'PERSON'} [entryType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermittedRoles(entryType?: 'VENDOR' | 'PERSON', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryEntryPermittedRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermittedRoles(entryType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get person by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get vendor by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVendorById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVendorById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a vendor\'s w9 url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVendorW9Path(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVendorW9Path(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Link a person to a vendor
         * @param {string} id 
         * @param {DirectoryPersonLinkRequest} directoryPersonLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkPerson(id: string, directoryPersonLinkRequest: DirectoryPersonLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkPerson(id, directoryPersonLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unlink a person from its linked vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkPerson(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkPerson(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update person by id
         * @param {string} id 
         * @param {DirectoryPersonUpdateRequest} directoryPersonUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerson(id: string, directoryPersonUpdateRequest: DirectoryPersonUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryPersonResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(id, directoryPersonUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vendor by id
         * @param {string} id 
         * @param {DirectoryVendorUpdateRequest} directoryVendorUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendor(id: string, directoryVendorUpdateRequest: DirectoryVendorUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendor(id, directoryVendorUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update vendor\'s set of administrative areas
         * @param {string} id 
         * @param {Array<AdministrativeAreaRequest>} administrativeAreaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorAdminAreas(id: string, administrativeAreaRequest: Array<AdministrativeAreaRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorAdminAreas(id, administrativeAreaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a vendor\'s w9 file
         * @param {string} id 
         * @param {any} w9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVendorW9(id: string, w9: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVendorW9(id, w9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark vendor as verified
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyVendor(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DirectoryVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyVendor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectoryControllerApi - factory interface
 * @export
 */
export const DirectoryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectoryControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive or unarchive a person
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePerson(id: string, archive?: boolean, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.archivePerson(id, archive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive or unarchive a vendor
         * @param {string} id 
         * @param {boolean} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveVendor(id: string, archive?: boolean, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.archiveVendor(id, archive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a person
         * @param {DirectoryPersonCreateRequest} directoryPersonCreateRequest 
         * @param {string} [ownerAgentId] 
         * @param {string} [ownerTeamId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerson(directoryPersonCreateRequest: DirectoryPersonCreateRequest, ownerAgentId?: string, ownerTeamId?: string, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.createPerson(directoryPersonCreateRequest, ownerAgentId, ownerTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a vendor
         * @param {DirectoryVendorCreateRequest} directoryVendorCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVendor(directoryVendorCreateRequest: DirectoryVendorCreateRequest, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.createVendor(directoryVendorCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all vendors and persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {string} [createdBy] 
         * @param {string} [id] 
         * @param {Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEntries(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, createdBy?: string, id?: string, sortBy?: Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: any): AxiosPromise<PagedDirectoryEntryResponse> {
            return localVarFp.getAllEntries(pageNumber, pageSize, isArchived, isVerified, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, createdBy, id, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all persons (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isPublic] 
         * @param {boolean} [isLinkedToVendor] 
         * @param {string} [searchText] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPersons(pageNumber: number, pageSize: number, isArchived?: boolean, isPublic?: boolean, isLinkedToVendor?: boolean, searchText?: string, firstName?: string, lastName?: string, emailAddress?: string, phoneNumber?: string, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>, options?: any): AxiosPromise<PagedPersonResponse> {
            return localVarFp.getAllPersons(pageNumber, pageSize, isArchived, isPublic, isLinkedToVendor, searchText, firstName, lastName, emailAddress, phoneNumber, roles, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all vendors (accessible to logged-in user) with paging, sorting, and filtering.
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {boolean} [isArchived] 
         * @param {boolean} [isVerified] 
         * @param {boolean} [hasLinkedPersons] 
         * @param {string} [searchText] 
         * @param {string} [nationalBusinessId] 
         * @param {string} [name] 
         * @param {string} [emailAddress] 
         * @param {string} [phoneNumber] 
         * @param {string} [street] 
         * @param {string} [city] 
         * @param {string} [postal] 
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
         * @param {'UNITED_STATES' | 'CANADA'} [country] 
         * @param {Array<string>} [administrativeAreaIds] 
         * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
         * @param {Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVendors(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, hasLinkedPersons?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: any): AxiosPromise<PagedVendorResponse> {
            return localVarFp.getAllVendors(pageNumber, pageSize, isArchived, isVerified, hasLinkedPersons, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get roles available for directory entries. May be filtered by entry type.
         * @param {'VENDOR' | 'PERSON'} [entryType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermittedRoles(entryType?: 'VENDOR' | 'PERSON', options?: any): AxiosPromise<DirectoryEntryPermittedRolesResponse> {
            return localVarFp.getPermittedRoles(entryType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get person by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerson(id: string, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.getPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get vendor by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorById(id: string, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.getVendorById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a vendor\'s w9 url
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVendorW9Path(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getVendorW9Path(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link a person to a vendor
         * @param {string} id 
         * @param {DirectoryPersonLinkRequest} directoryPersonLinkRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkPerson(id: string, directoryPersonLinkRequest: DirectoryPersonLinkRequest, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.linkPerson(id, directoryPersonLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink a person from its linked vendor
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkPerson(id: string, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.unlinkPerson(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update person by id
         * @param {string} id 
         * @param {DirectoryPersonUpdateRequest} directoryPersonUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerson(id: string, directoryPersonUpdateRequest: DirectoryPersonUpdateRequest, options?: any): AxiosPromise<DirectoryPersonResponse> {
            return localVarFp.updatePerson(id, directoryPersonUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vendor by id
         * @param {string} id 
         * @param {DirectoryVendorUpdateRequest} directoryVendorUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendor(id: string, directoryVendorUpdateRequest: DirectoryVendorUpdateRequest, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.updateVendor(id, directoryVendorUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update vendor\'s set of administrative areas
         * @param {string} id 
         * @param {Array<AdministrativeAreaRequest>} administrativeAreaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorAdminAreas(id: string, administrativeAreaRequest: Array<AdministrativeAreaRequest>, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.updateVendorAdminAreas(id, administrativeAreaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a vendor\'s w9 file
         * @param {string} id 
         * @param {any} w9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVendorW9(id: string, w9: any, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.updateVendorW9(id, w9, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark vendor as verified
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyVendor(id: string, options?: any): AxiosPromise<DirectoryVendorResponse> {
            return localVarFp.verifyVendor(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectoryControllerApi - object-oriented interface
 * @export
 * @class DirectoryControllerApi
 * @extends {BaseAPI}
 */
export class DirectoryControllerApi extends BaseAPI {
    /**
     * 
     * @summary Archive or unarchive a person
     * @param {string} id 
     * @param {boolean} [archive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public archivePerson(id: string, archive?: boolean, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).archivePerson(id, archive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive or unarchive a vendor
     * @param {string} id 
     * @param {boolean} [archive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public archiveVendor(id: string, archive?: boolean, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).archiveVendor(id, archive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a person
     * @param {DirectoryPersonCreateRequest} directoryPersonCreateRequest 
     * @param {string} [ownerAgentId] 
     * @param {string} [ownerTeamId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public createPerson(directoryPersonCreateRequest: DirectoryPersonCreateRequest, ownerAgentId?: string, ownerTeamId?: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).createPerson(directoryPersonCreateRequest, ownerAgentId, ownerTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a vendor
     * @param {DirectoryVendorCreateRequest} directoryVendorCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public createVendor(directoryVendorCreateRequest: DirectoryVendorCreateRequest, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).createVendor(directoryVendorCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all vendors and persons (accessible to logged-in user) with paging, sorting, and filtering.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {boolean} [isArchived] 
     * @param {boolean} [isVerified] 
     * @param {string} [searchText] 
     * @param {string} [nationalBusinessId] 
     * @param {string} [name] 
     * @param {string} [emailAddress] 
     * @param {string} [phoneNumber] 
     * @param {string} [street] 
     * @param {string} [city] 
     * @param {string} [postal] 
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
     * @param {'UNITED_STATES' | 'CANADA'} [country] 
     * @param {Array<string>} [administrativeAreaIds] 
     * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
     * @param {string} [createdBy] 
     * @param {string} [id] 
     * @param {Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getAllEntries(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, createdBy?: string, id?: string, sortBy?: Array<'NAME' | 'NAME_LOGICAL' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getAllEntries(pageNumber, pageSize, isArchived, isVerified, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, createdBy, id, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all persons (accessible to logged-in user) with paging, sorting, and filtering.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {boolean} [isArchived] 
     * @param {boolean} [isPublic] 
     * @param {boolean} [isLinkedToVendor] 
     * @param {string} [searchText] 
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [emailAddress] 
     * @param {string} [phoneNumber] 
     * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
     * @param {Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getAllPersons(pageNumber: number, pageSize: number, isArchived?: boolean, isPublic?: boolean, isLinkedToVendor?: boolean, searchText?: string, firstName?: string, lastName?: string, emailAddress?: string, phoneNumber?: string, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'FIRST_NAME' | 'LAST_NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'IS_PUBLIC'>, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getAllPersons(pageNumber, pageSize, isArchived, isPublic, isLinkedToVendor, searchText, firstName, lastName, emailAddress, phoneNumber, roles, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all vendors (accessible to logged-in user) with paging, sorting, and filtering.
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {boolean} [isArchived] 
     * @param {boolean} [isVerified] 
     * @param {boolean} [hasLinkedPersons] 
     * @param {string} [searchText] 
     * @param {string} [nationalBusinessId] 
     * @param {string} [name] 
     * @param {string} [emailAddress] 
     * @param {string} [phoneNumber] 
     * @param {string} [street] 
     * @param {string} [city] 
     * @param {string} [postal] 
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} [stateOrProvince] 
     * @param {'UNITED_STATES' | 'CANADA'} [country] 
     * @param {Array<string>} [administrativeAreaIds] 
     * @param {Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>} [roles] 
     * @param {Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getAllVendors(pageNumber: number, pageSize: number, isArchived?: boolean, isVerified?: boolean, hasLinkedPersons?: boolean, searchText?: string, nationalBusinessId?: string, name?: string, emailAddress?: string, phoneNumber?: string, street?: string, city?: string, postal?: string, stateOrProvince?: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', country?: 'UNITED_STATES' | 'CANADA', administrativeAreaIds?: Array<string>, roles?: Array<'CLIENT' | 'LANDLORD' | 'TENANT' | 'TITLE_ESCROW' | 'LENDER' | 'LAWYER' | 'TRUSTEE' | 'OTHER_AGENT' | 'VENDOR' | 'REFERRAL' | 'OTHER'>, sortBy?: Array<'NAME' | 'EMAIL_ADDRESS' | 'PHONE_NUMBER' | 'CITY' | 'STATE_OR_PROVINCE' | 'STATUS'>, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getAllVendors(pageNumber, pageSize, isArchived, isVerified, hasLinkedPersons, searchText, nationalBusinessId, name, emailAddress, phoneNumber, street, city, postal, stateOrProvince, country, administrativeAreaIds, roles, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get roles available for directory entries. May be filtered by entry type.
     * @param {'VENDOR' | 'PERSON'} [entryType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getPermittedRoles(entryType?: 'VENDOR' | 'PERSON', options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getPermittedRoles(entryType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get person by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getPerson(id: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get vendor by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getVendorById(id: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getVendorById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a vendor\'s w9 url
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public getVendorW9Path(id: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).getVendorW9Path(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link a person to a vendor
     * @param {string} id 
     * @param {DirectoryPersonLinkRequest} directoryPersonLinkRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public linkPerson(id: string, directoryPersonLinkRequest: DirectoryPersonLinkRequest, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).linkPerson(id, directoryPersonLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink a person from its linked vendor
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public unlinkPerson(id: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).unlinkPerson(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update person by id
     * @param {string} id 
     * @param {DirectoryPersonUpdateRequest} directoryPersonUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public updatePerson(id: string, directoryPersonUpdateRequest: DirectoryPersonUpdateRequest, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).updatePerson(id, directoryPersonUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vendor by id
     * @param {string} id 
     * @param {DirectoryVendorUpdateRequest} directoryVendorUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public updateVendor(id: string, directoryVendorUpdateRequest: DirectoryVendorUpdateRequest, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).updateVendor(id, directoryVendorUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update vendor\'s set of administrative areas
     * @param {string} id 
     * @param {Array<AdministrativeAreaRequest>} administrativeAreaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public updateVendorAdminAreas(id: string, administrativeAreaRequest: Array<AdministrativeAreaRequest>, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).updateVendorAdminAreas(id, administrativeAreaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a vendor\'s w9 file
     * @param {string} id 
     * @param {any} w9 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public updateVendorW9(id: string, w9: any, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).updateVendorW9(id, w9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark vendor as verified
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectoryControllerApi
     */
    public verifyVendor(id: string, options?: AxiosRequestConfig) {
        return DirectoryControllerApiFp(this.configuration).verifyVendor(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DivisionControllerApi - axios parameter creator
 * @export
 */
export const DivisionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add new division
         * @param {DivisionRequest} divisionRequest 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDivision: async (divisionRequest: DivisionRequest, logo?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'divisionRequest' is not null or undefined
            assertParamExists('addDivision', 'divisionRequest', divisionRequest)
            const localVarPath = `/api/v1/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (divisionRequest !== undefined) { 
                localVarFormParams.append('divisionRequest', new Blob([JSON.stringify(divisionRequest)], { type: "application/json", }));
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dummy endpoint so that DivisionRequest can get generated by OpenAPI, which doesn\'t work if its only in a MULTIPART_FORM_DATA_VALUE endpoint
         * @param {DivisionRequest} divisionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummy: async (divisionRequest: DivisionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'divisionRequest' is not null or undefined
            assertParamExists('dummy', 'divisionRequest', divisionRequest)
            const localVarPath = `/api/v1/divisions/dummy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(divisionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all divisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivisions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/divisions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update division
         * @param {string} id 
         * @param {DivisionRequest} [divisionRequest] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDivision: async (id: string, divisionRequest?: DivisionRequest, logo?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyDivision', 'id', id)
            const localVarPath = `/api/v1/divisions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (divisionRequest !== undefined) { 
                localVarFormParams.append('divisionRequest', new Blob([JSON.stringify(divisionRequest)], { type: "application/json", }));
            }
    
            if (logo !== undefined) { 
                localVarFormParams.append('logo', logo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DivisionControllerApi - functional programming interface
 * @export
 */
export const DivisionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DivisionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add new division
         * @param {DivisionRequest} divisionRequest 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDivision(divisionRequest: DivisionRequest, logo?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addDivision(divisionRequest, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dummy endpoint so that DivisionRequest can get generated by OpenAPI, which doesn\'t work if its only in a MULTIPART_FORM_DATA_VALUE endpoint
         * @param {DivisionRequest} divisionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummy(divisionRequest: DivisionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dummy(divisionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all divisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivisions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionResponseWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivisions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update division
         * @param {string} id 
         * @param {DivisionRequest} [divisionRequest] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyDivision(id: string, divisionRequest?: DivisionRequest, logo?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DivisionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyDivision(id, divisionRequest, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DivisionControllerApi - factory interface
 * @export
 */
export const DivisionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DivisionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add new division
         * @param {DivisionRequest} divisionRequest 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDivision(divisionRequest: DivisionRequest, logo?: any, options?: any): AxiosPromise<DivisionResponse> {
            return localVarFp.addDivision(divisionRequest, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dummy endpoint so that DivisionRequest can get generated by OpenAPI, which doesn\'t work if its only in a MULTIPART_FORM_DATA_VALUE endpoint
         * @param {DivisionRequest} divisionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummy(divisionRequest: DivisionRequest, options?: any): AxiosPromise<DivisionResponse> {
            return localVarFp.dummy(divisionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all divisions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivisions(options?: any): AxiosPromise<DivisionResponseWrapper> {
            return localVarFp.getDivisions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update division
         * @param {string} id 
         * @param {DivisionRequest} [divisionRequest] 
         * @param {any} [logo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyDivision(id: string, divisionRequest?: DivisionRequest, logo?: any, options?: any): AxiosPromise<DivisionResponse> {
            return localVarFp.modifyDivision(id, divisionRequest, logo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DivisionControllerApi - object-oriented interface
 * @export
 * @class DivisionControllerApi
 * @extends {BaseAPI}
 */
export class DivisionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add new division
     * @param {DivisionRequest} divisionRequest 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionControllerApi
     */
    public addDivision(divisionRequest: DivisionRequest, logo?: any, options?: AxiosRequestConfig) {
        return DivisionControllerApiFp(this.configuration).addDivision(divisionRequest, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dummy endpoint so that DivisionRequest can get generated by OpenAPI, which doesn\'t work if its only in a MULTIPART_FORM_DATA_VALUE endpoint
     * @param {DivisionRequest} divisionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionControllerApi
     */
    public dummy(divisionRequest: DivisionRequest, options?: AxiosRequestConfig) {
        return DivisionControllerApiFp(this.configuration).dummy(divisionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all divisions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionControllerApi
     */
    public getDivisions(options?: AxiosRequestConfig) {
        return DivisionControllerApiFp(this.configuration).getDivisions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update division
     * @param {string} id 
     * @param {DivisionRequest} [divisionRequest] 
     * @param {any} [logo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionControllerApi
     */
    public modifyDivision(id: string, divisionRequest?: DivisionRequest, logo?: any, options?: AxiosRequestConfig) {
        return DivisionControllerApiFp(this.configuration).modifyDivision(id, divisionRequest, logo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EarningsCalculatorControllerApi - axios parameter creator
 * @export
 */
export const EarningsCalculatorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate a potential agent\'s projected earnings given estimated transaction volume details, rev-share, equity, etc
         * @param {CalculateRequest} calculateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate: async (calculateRequest: CalculateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calculateRequest' is not null or undefined
            assertParamExists('calculate', 'calculateRequest', calculateRequest)
            const localVarPath = `/api/v1/public/earnings-calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the Real Brokerage values for commission, awards, stock, etc used during calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/earnings-calculator/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EarningsCalculatorControllerApi - functional programming interface
 * @export
 */
export const EarningsCalculatorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EarningsCalculatorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate a potential agent\'s projected earnings given estimated transaction volume details, rev-share, equity, etc
         * @param {CalculateRequest} calculateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculate(calculateRequest: CalculateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculate(calculateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the Real Brokerage values for commission, awards, stock, etc used during calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatorParameters>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EarningsCalculatorControllerApi - factory interface
 * @export
 */
export const EarningsCalculatorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EarningsCalculatorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate a potential agent\'s projected earnings given estimated transaction volume details, rev-share, equity, etc
         * @param {CalculateRequest} calculateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate(calculateRequest: CalculateRequest, options?: any): AxiosPromise<CalculateResponse> {
            return localVarFp.calculate(calculateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the Real Brokerage values for commission, awards, stock, etc used during calculation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters(options?: any): AxiosPromise<CalculatorParameters> {
            return localVarFp.getParameters(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EarningsCalculatorControllerApi - object-oriented interface
 * @export
 * @class EarningsCalculatorControllerApi
 * @extends {BaseAPI}
 */
export class EarningsCalculatorControllerApi extends BaseAPI {
    /**
     * 
     * @summary Calculate a potential agent\'s projected earnings given estimated transaction volume details, rev-share, equity, etc
     * @param {CalculateRequest} calculateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EarningsCalculatorControllerApi
     */
    public calculate(calculateRequest: CalculateRequest, options?: AxiosRequestConfig) {
        return EarningsCalculatorControllerApiFp(this.configuration).calculate(calculateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the Real Brokerage values for commission, awards, stock, etc used during calculation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EarningsCalculatorControllerApi
     */
    public getParameters(options?: AxiosRequestConfig) {
        return EarningsCalculatorControllerApiFp(this.configuration).getParameters(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExperimentControllerApi - axios parameter creator
 * @export
 */
export const ExperimentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activate', 'id', id)
            const localVarPath = `/api/v1/experiments/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveExperiment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archiveExperiment', 'id', id)
            const localVarPath = `/api/v1/experiments/{id}/archive`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new default experiment
         * @param {CreateDefaultExperimentRequest} createDefaultExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultExperiment: async (createDefaultExperimentRequest: CreateDefaultExperimentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDefaultExperimentRequest' is not null or undefined
            assertParamExists('createDefaultExperiment', 'createDefaultExperimentRequest', createDefaultExperimentRequest)
            const localVarPath = `/api/v1/experiments/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDefaultExperimentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new experiment
         * @param {CreateExperimentRequest} createExperimentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createExperiment: async (createExperimentRequest: CreateExperimentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExperimentRequest' is not null or undefined
            assertParamExists('createExperiment', 'createExperimentRequest', createExperimentRequest)
            const localVarPath = `/api/v1/experiments/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExperimentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get experiment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExperimentById', 'id', id)
            const localVarPath = `/api/v1/experiments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentByName: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getExperimentByName', 'name', name)
            const localVarPath = `/api/v1/experiments/name/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch all experiments for a given criteria
         * @param {'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiments: async (state: 'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED', pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getExperiments', 'state', state)
            const localVarPath = `/api/v1/experiments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an experiment
         * @param {string} id 
         * @param {UpdateExperimentRequest} updateExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperiment: async (id: string, updateExperimentRequest: UpdateExperimentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExperiment', 'id', id)
            // verify required parameter 'updateExperimentRequest' is not null or undefined
            assertParamExists('updateExperiment', 'updateExperimentRequest', updateExperimentRequest)
            const localVarPath = `/api/v1/experiments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperimentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an experiment group
         * @param {string} groupId 
         * @param {UpdateExperimentGroupRequest} updateExperimentGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperimentGroup: async (groupId: string, updateExperimentGroupRequest: UpdateExperimentGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateExperimentGroup', 'groupId', groupId)
            // verify required parameter 'updateExperimentGroupRequest' is not null or undefined
            assertParamExists('updateExperimentGroup', 'updateExperimentGroupRequest', updateExperimentGroupRequest)
            const localVarPath = `/api/v1/experiments/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExperimentGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperimentControllerApi - functional programming interface
 * @export
 */
export const ExperimentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperimentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveExperiment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveExperiment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new default experiment
         * @param {CreateDefaultExperimentRequest} createDefaultExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDefaultExperiment(createDefaultExperimentRequest: CreateDefaultExperimentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDefaultExperiment(createDefaultExperimentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new experiment
         * @param {CreateExperimentRequest} createExperimentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createExperiment(createExperimentRequest: CreateExperimentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExperiment(createExperimentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get experiment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperimentByName(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperimentByName(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Fetch all experiments for a given criteria
         * @param {'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExperiments(state: 'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedExperimentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExperiments(state, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an experiment
         * @param {string} id 
         * @param {UpdateExperimentRequest} updateExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperiment(id: string, updateExperimentRequest: UpdateExperimentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperiment(id, updateExperimentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an experiment group
         * @param {string} groupId 
         * @param {UpdateExperimentGroupRequest} updateExperimentGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperimentGroup(groupId: string, updateExperimentGroupRequest: UpdateExperimentGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperimentGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperimentGroup(groupId, updateExperimentGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExperimentControllerApi - factory interface
 * @export
 */
export const ExperimentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperimentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(id: string, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.activate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive an experiment
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveExperiment(id: string, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.archiveExperiment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new default experiment
         * @param {CreateDefaultExperimentRequest} createDefaultExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultExperiment(createDefaultExperimentRequest: CreateDefaultExperimentRequest, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.createDefaultExperiment(createDefaultExperimentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new experiment
         * @param {CreateExperimentRequest} createExperimentRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createExperiment(createExperimentRequest: CreateExperimentRequest, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.createExperiment(createExperimentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get experiment by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentById(id: string, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.getExperimentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperimentByName(name: string, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.getExperimentByName(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch all experiments for a given criteria
         * @param {'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED'} state 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExperiments(state: 'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED', pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedExperimentsResponse> {
            return localVarFp.getExperiments(state, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an experiment
         * @param {string} id 
         * @param {UpdateExperimentRequest} updateExperimentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperiment(id: string, updateExperimentRequest: UpdateExperimentRequest, options?: any): AxiosPromise<ExperimentResponse> {
            return localVarFp.updateExperiment(id, updateExperimentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an experiment group
         * @param {string} groupId 
         * @param {UpdateExperimentGroupRequest} updateExperimentGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperimentGroup(groupId: string, updateExperimentGroupRequest: UpdateExperimentGroupRequest, options?: any): AxiosPromise<ExperimentGroupResponse> {
            return localVarFp.updateExperimentGroup(groupId, updateExperimentGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExperimentControllerApi - object-oriented interface
 * @export
 * @class ExperimentControllerApi
 * @extends {BaseAPI}
 */
export class ExperimentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Activate an experiment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public activate(id: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).activate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive an experiment
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public archiveExperiment(id: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).archiveExperiment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new default experiment
     * @param {CreateDefaultExperimentRequest} createDefaultExperimentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public createDefaultExperiment(createDefaultExperimentRequest: CreateDefaultExperimentRequest, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).createDefaultExperiment(createDefaultExperimentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new experiment
     * @param {CreateExperimentRequest} createExperimentRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public createExperiment(createExperimentRequest: CreateExperimentRequest, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).createExperiment(createExperimentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get experiment by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentById(id: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get experiment by name
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperimentByName(name: string, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperimentByName(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch all experiments for a given criteria
     * @param {'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED'} state 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public getExperiments(state: 'PRE_ROLLOUT' | 'ACTIVE' | 'ARCHIVED', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).getExperiments(state, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an experiment
     * @param {string} id 
     * @param {UpdateExperimentRequest} updateExperimentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public updateExperiment(id: string, updateExperimentRequest: UpdateExperimentRequest, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).updateExperiment(id, updateExperimentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an experiment group
     * @param {string} groupId 
     * @param {UpdateExperimentGroupRequest} updateExperimentGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperimentControllerApi
     */
    public updateExperimentGroup(groupId: string, updateExperimentGroupRequest: UpdateExperimentGroupRequest, options?: AxiosRequestConfig) {
        return ExperimentControllerApiFp(this.configuration).updateExperimentGroup(groupId, updateExperimentGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlexTeamConfigControllerApi - axios parameter creator
 * @export
 */
export const FlexTeamConfigControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new flex team configuration
         * @param {CreateFlexTeamConfig} createFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamConfig: async (createFlexTeamConfig: CreateFlexTeamConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlexTeamConfig' is not null or undefined
            assertParamExists('createFlexTeamConfig', 'createFlexTeamConfig', createFlexTeamConfig)
            const localVarPath = `/api/v1/flex-team-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlexTeamConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new flex team configuration based on an existing legacy team configuration
         * @param {CreateFlexTeamConfigForLegacyTeam} createFlexTeamConfigForLegacyTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamConfigFromLegacyTeam: async (createFlexTeamConfigForLegacyTeam: CreateFlexTeamConfigForLegacyTeam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlexTeamConfigForLegacyTeam' is not null or undefined
            assertParamExists('createFlexTeamConfigFromLegacyTeam', 'createFlexTeamConfigForLegacyTeam', createFlexTeamConfigForLegacyTeam)
            const localVarPath = `/api/v1/flex-team-config/legacy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlexTeamConfigForLegacyTeam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete flex team configuration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlexTeamConfig: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFlexTeamConfig', 'id', id)
            const localVarPath = `/api/v1/flex-team-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flex team configuration by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlexTeamConfigById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlexTeamConfigById', 'id', id)
            const localVarPath = `/api/v1/flex-team-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search flex team configurations given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFlexTeamConfigs: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/flex-team-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (teamType !== undefined) {
                localVarQueryParameter['teamType'] = teamType;
            }

            if (createdAtStart !== undefined) {
                localVarQueryParameter['createdAtStart'] = (createdAtStart as any instanceof Date) ?
                    (createdAtStart as any).toISOString().substr(0,10) :
                    createdAtStart;
            }

            if (createdAtEnd !== undefined) {
                localVarQueryParameter['createdAtEnd'] = (createdAtEnd as any instanceof Date) ?
                    (createdAtEnd as any).toISOString().substr(0,10) :
                    createdAtEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update existing flex team configuration
         * @param {string} id 
         * @param {UpdateFlexTeamConfig} updateFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamConfig: async (id: string, updateFlexTeamConfig: UpdateFlexTeamConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlexTeamConfig', 'id', id)
            // verify required parameter 'updateFlexTeamConfig' is not null or undefined
            assertParamExists('updateFlexTeamConfig', 'updateFlexTeamConfig', updateFlexTeamConfig)
            const localVarPath = `/api/v1/flex-team-config/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlexTeamConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlexTeamConfigControllerApi - functional programming interface
 * @export
 */
export const FlexTeamConfigControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlexTeamConfigControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new flex team configuration
         * @param {CreateFlexTeamConfig} createFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlexTeamConfig(createFlexTeamConfig: CreateFlexTeamConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlexTeamConfig(createFlexTeamConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new flex team configuration based on an existing legacy team configuration
         * @param {CreateFlexTeamConfigForLegacyTeam} createFlexTeamConfigForLegacyTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam: CreateFlexTeamConfigForLegacyTeam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete flex team configuration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFlexTeamConfig(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFlexTeamConfig(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flex team configuration by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlexTeamConfigById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlexTeamConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlexTeamConfigById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search flex team configurations given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFlexTeamConfigs(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFlexTeamConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFlexTeamConfigs(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, teamType, createdAtStart, createdAtEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update existing flex team configuration
         * @param {string} id 
         * @param {UpdateFlexTeamConfig} updateFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlexTeamConfig(id: string, updateFlexTeamConfig: UpdateFlexTeamConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlexTeamConfig(id, updateFlexTeamConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlexTeamConfigControllerApi - factory interface
 * @export
 */
export const FlexTeamConfigControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlexTeamConfigControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new flex team configuration
         * @param {CreateFlexTeamConfig} createFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamConfig(createFlexTeamConfig: CreateFlexTeamConfig, options?: any): AxiosPromise<string> {
            return localVarFp.createFlexTeamConfig(createFlexTeamConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new flex team configuration based on an existing legacy team configuration
         * @param {CreateFlexTeamConfigForLegacyTeam} createFlexTeamConfigForLegacyTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam: CreateFlexTeamConfigForLegacyTeam, options?: any): AxiosPromise<string> {
            return localVarFp.createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete flex team configuration
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlexTeamConfig(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFlexTeamConfig(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flex team configuration by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlexTeamConfigById(id: string, options?: any): AxiosPromise<FlexTeamConfigDto> {
            return localVarFp.getFlexTeamConfigById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search flex team configurations given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFlexTeamConfigs(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: any): AxiosPromise<GenericSearchResponseFlexTeamConfigDto> {
            return localVarFp.searchFlexTeamConfigs(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, teamType, createdAtStart, createdAtEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update existing flex team configuration
         * @param {string} id 
         * @param {UpdateFlexTeamConfig} updateFlexTeamConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamConfig(id: string, updateFlexTeamConfig: UpdateFlexTeamConfig, options?: any): AxiosPromise<void> {
            return localVarFp.updateFlexTeamConfig(id, updateFlexTeamConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlexTeamConfigControllerApi - object-oriented interface
 * @export
 * @class FlexTeamConfigControllerApi
 * @extends {BaseAPI}
 */
export class FlexTeamConfigControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a new flex team configuration
     * @param {CreateFlexTeamConfig} createFlexTeamConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public createFlexTeamConfig(createFlexTeamConfig: CreateFlexTeamConfig, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).createFlexTeamConfig(createFlexTeamConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new flex team configuration based on an existing legacy team configuration
     * @param {CreateFlexTeamConfigForLegacyTeam} createFlexTeamConfigForLegacyTeam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam: CreateFlexTeamConfigForLegacyTeam, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).createFlexTeamConfigFromLegacyTeam(createFlexTeamConfigForLegacyTeam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete flex team configuration
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public deleteFlexTeamConfig(id: string, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).deleteFlexTeamConfig(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flex team configuration by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public getFlexTeamConfigById(id: string, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).getFlexTeamConfigById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search flex team configurations given a set of criteria
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [searchText] 
     * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
     * @param {string} [createdAtStart] 
     * @param {string} [createdAtEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public searchFlexTeamConfigs(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).searchFlexTeamConfigs(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, teamType, createdAtStart, createdAtEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update existing flex team configuration
     * @param {string} id 
     * @param {UpdateFlexTeamConfig} updateFlexTeamConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamConfigControllerApi
     */
    public updateFlexTeamConfig(id: string, updateFlexTeamConfig: UpdateFlexTeamConfig, options?: AxiosRequestConfig) {
        return FlexTeamConfigControllerApiFp(this.configuration).updateFlexTeamConfig(id, updateFlexTeamConfig, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FlexTeamControllerApi - axios parameter creator
 * @export
 */
export const FlexTeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader} addFlexTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamLeader: async (teamId: string, addFlexTeamLeader: AddFlexTeamLeader, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addFlexTeamLeader', 'teamId', teamId)
            // verify required parameter 'addFlexTeamLeader' is not null or undefined
            assertParamExists('addFlexTeamLeader', 'addFlexTeamLeader', addFlexTeamLeader)
            const localVarPath = `/api/v1/flex-team/{teamId}/leaders`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFlexTeamLeader, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeaderMonomorphic} addFlexTeamLeaderMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamLeaderMonomorphic: async (teamId: string, addFlexTeamLeaderMonomorphic: AddFlexTeamLeaderMonomorphic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addFlexTeamLeaderMonomorphic', 'teamId', teamId)
            // verify required parameter 'addFlexTeamLeaderMonomorphic' is not null or undefined
            assertParamExists('addFlexTeamLeaderMonomorphic', 'addFlexTeamLeaderMonomorphic', addFlexTeamLeaderMonomorphic)
            const localVarPath = `/api/v1/flex-team/{teamId}/leaders/monomorphic`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFlexTeamLeaderMonomorphic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add member to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader | AddFlexTeammate} addFlexTeamLeaderAddFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamMember: async (teamId: string, addFlexTeamLeaderAddFlexTeammate: AddFlexTeamLeader | AddFlexTeammate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('addFlexTeamMember', 'teamId', teamId)
            // verify required parameter 'addFlexTeamLeaderAddFlexTeammate' is not null or undefined
            assertParamExists('addFlexTeamMember', 'addFlexTeamLeaderAddFlexTeammate', addFlexTeamLeaderAddFlexTeammate)
            const localVarPath = `/api/v1/flex-team/{teamId}/members`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addFlexTeamLeaderAddFlexTeammate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new flex team
         * @param {CreateFlexTeam} createFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeam: async (createFlexTeam: CreateFlexTeam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlexTeam' is not null or undefined
            assertParamExists('createFlexTeam', 'createFlexTeam', createFlexTeam)
            const localVarPath = `/api/v1/flex-team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlexTeam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new flex team and config
         * @param {CreateFlexTeamAndConfig} createFlexTeamAndConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamAndConfig: async (createFlexTeamAndConfig: CreateFlexTeamAndConfig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlexTeamAndConfig' is not null or undefined
            assertParamExists('createFlexTeamAndConfig', 'createFlexTeamAndConfig', createFlexTeamAndConfig)
            const localVarPath = `/api/v1/flex-team/create-with-config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlexTeamAndConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new flex team monomorphic
         * @param {CreateFlexTeamMonomorphic} createFlexTeamMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamMonomorphic: async (createFlexTeamMonomorphic: CreateFlexTeamMonomorphic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFlexTeamMonomorphic' is not null or undefined
            assertParamExists('createFlexTeamMonomorphic', 'createFlexTeamMonomorphic', createFlexTeamMonomorphic)
            const localVarPath = `/api/v1/flex-team/monomorphic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFlexTeamMonomorphic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete flex team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlexTeam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFlexTeam', 'id', id)
            const localVarPath = `/api/v1/flex-team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get flex team by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlexTeamById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFlexTeamById', 'id', id)
            const localVarPath = `/api/v1/flex-team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change a member into a teammate
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeammate} makeMemberTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLeaderIntoMember: async (teamId: string, memberId: string, makeMemberTeammate: MakeMemberTeammate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('makeLeaderIntoMember', 'teamId', teamId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('makeLeaderIntoMember', 'memberId', memberId)
            // verify required parameter 'makeMemberTeammate' is not null or undefined
            assertParamExists('makeLeaderIntoMember', 'makeMemberTeammate', makeMemberTeammate)
            const localVarPath = `/api/v1/flex-team/{teamId}/members/{memberId}/make-teammate`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(makeMemberTeammate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change a member into a leader
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeamLeader} makeMemberTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeMemberIntoLeader: async (teamId: string, memberId: string, makeMemberTeamLeader: MakeMemberTeamLeader, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('makeMemberIntoLeader', 'teamId', teamId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('makeMemberIntoLeader', 'memberId', memberId)
            // verify required parameter 'makeMemberTeamLeader' is not null or undefined
            assertParamExists('makeMemberIntoLeader', 'makeMemberTeamLeader', makeMemberTeamLeader)
            const localVarPath = `/api/v1/flex-team/{teamId}/members/{memberId}/make-leader`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(makeMemberTeamLeader, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove member from flex team
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFlexTeamMember: async (teamId: string, memberId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('removeFlexTeamMember', 'teamId', teamId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('removeFlexTeamMember', 'memberId', memberId)
            const localVarPath = `/api/v1/flex-team/{teamId}/members/{memberId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search flex teams
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFlexTeams: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/flex-team`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (teamType !== undefined) {
                localVarQueryParameter['teamType'] = teamType;
            }

            if (createdAtStart !== undefined) {
                localVarQueryParameter['createdAtStart'] = (createdAtStart as any instanceof Date) ?
                    (createdAtStart as any).toISOString().substr(0,10) :
                    createdAtStart;
            }

            if (createdAtEnd !== undefined) {
                localVarQueryParameter['createdAtEnd'] = (createdAtEnd as any instanceof Date) ?
                    (createdAtEnd as any).toISOString().substr(0,10) :
                    createdAtEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer a member between two teams
         * @param {TransferFlexTeamMember} transferFlexTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferFlexTeamMember: async (transferFlexTeamMember: TransferFlexTeamMember, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferFlexTeamMember' is not null or undefined
            assertParamExists('transferFlexTeamMember', 'transferFlexTeamMember', transferFlexTeamMember)
            const localVarPath = `/api/v1/flex-team/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferFlexTeamMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update flex team by Id
         * @param {string} id 
         * @param {UpdateFlexTeam} updateFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeam: async (id: string, updateFlexTeam: UpdateFlexTeam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFlexTeam', 'id', id)
            // verify required parameter 'updateFlexTeam' is not null or undefined
            assertParamExists('updateFlexTeam', 'updateFlexTeam', updateFlexTeam)
            const localVarPath = `/api/v1/flex-team/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlexTeam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update flex team member by id
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamLeader | UpdateFlexTeammate} updateFlexTeamLeaderUpdateFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamMember: async (teamId: string, memberId: string, updateFlexTeamLeaderUpdateFlexTeammate: UpdateFlexTeamLeader | UpdateFlexTeammate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateFlexTeamMember', 'teamId', teamId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateFlexTeamMember', 'memberId', memberId)
            // verify required parameter 'updateFlexTeamLeaderUpdateFlexTeammate' is not null or undefined
            assertParamExists('updateFlexTeamMember', 'updateFlexTeamLeaderUpdateFlexTeammate', updateFlexTeamLeaderUpdateFlexTeammate)
            const localVarPath = `/api/v1/flex-team/{teamId}/members/{memberId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlexTeamLeaderUpdateFlexTeammate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update flex team member by id monomorphic
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamMemberMonomorphic} updateFlexTeamMemberMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamMemberMonomorphic: async (teamId: string, memberId: string, updateFlexTeamMemberMonomorphic: UpdateFlexTeamMemberMonomorphic, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateFlexTeamMemberMonomorphic', 'teamId', teamId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('updateFlexTeamMemberMonomorphic', 'memberId', memberId)
            // verify required parameter 'updateFlexTeamMemberMonomorphic' is not null or undefined
            assertParamExists('updateFlexTeamMemberMonomorphic', 'updateFlexTeamMemberMonomorphic', updateFlexTeamMemberMonomorphic)
            const localVarPath = `/api/v1/flex-team/monomorphic/{teamId}/members/{memberId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFlexTeamMemberMonomorphic, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FlexTeamControllerApi - functional programming interface
 * @export
 */
export const FlexTeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FlexTeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader} addFlexTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFlexTeamLeader(teamId: string, addFlexTeamLeader: AddFlexTeamLeader, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFlexTeamLeader(teamId, addFlexTeamLeader, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeaderMonomorphic} addFlexTeamLeaderMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFlexTeamLeaderMonomorphic(teamId: string, addFlexTeamLeaderMonomorphic: AddFlexTeamLeaderMonomorphic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFlexTeamLeaderMonomorphic(teamId, addFlexTeamLeaderMonomorphic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add member to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader | AddFlexTeammate} addFlexTeamLeaderAddFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFlexTeamMember(teamId: string, addFlexTeamLeaderAddFlexTeammate: AddFlexTeamLeader | AddFlexTeammate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFlexTeamMember(teamId, addFlexTeamLeaderAddFlexTeammate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new flex team
         * @param {CreateFlexTeam} createFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlexTeam(createFlexTeam: CreateFlexTeam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlexTeam(createFlexTeam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new flex team and config
         * @param {CreateFlexTeamAndConfig} createFlexTeamAndConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlexTeamAndConfig(createFlexTeamAndConfig: CreateFlexTeamAndConfig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlexTeamAndConfig(createFlexTeamAndConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new flex team monomorphic
         * @param {CreateFlexTeamMonomorphic} createFlexTeamMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFlexTeamMonomorphic(createFlexTeamMonomorphic: CreateFlexTeamMonomorphic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFlexTeamMonomorphic(createFlexTeamMonomorphic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete flex team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFlexTeam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFlexTeam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get flex team by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlexTeamById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlexTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlexTeamById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change a member into a teammate
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeammate} makeMemberTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeLeaderIntoMember(teamId: string, memberId: string, makeMemberTeammate: MakeMemberTeammate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeLeaderIntoMember(teamId, memberId, makeMemberTeammate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change a member into a leader
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeamLeader} makeMemberTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeMemberIntoLeader(teamId: string, memberId: string, makeMemberTeamLeader: MakeMemberTeamLeader, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeMemberIntoLeader(teamId, memberId, makeMemberTeamLeader, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove member from flex team
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFlexTeamMember(teamId: string, memberId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFlexTeamMember(teamId, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search flex teams
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchFlexTeams(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseSearchFlexTeamDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchFlexTeams(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, teamType, createdAtStart, createdAtEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer a member between two teams
         * @param {TransferFlexTeamMember} transferFlexTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferFlexTeamMember(transferFlexTeamMember: TransferFlexTeamMember, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferFlexTeamMember(transferFlexTeamMember, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update flex team by Id
         * @param {string} id 
         * @param {UpdateFlexTeam} updateFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlexTeam(id: string, updateFlexTeam: UpdateFlexTeam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlexTeam(id, updateFlexTeam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update flex team member by id
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamLeader | UpdateFlexTeammate} updateFlexTeamLeaderUpdateFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlexTeamMember(teamId: string, memberId: string, updateFlexTeamLeaderUpdateFlexTeammate: UpdateFlexTeamLeader | UpdateFlexTeammate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlexTeamMember(teamId, memberId, updateFlexTeamLeaderUpdateFlexTeammate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update flex team member by id monomorphic
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamMemberMonomorphic} updateFlexTeamMemberMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFlexTeamMemberMonomorphic(teamId: string, memberId: string, updateFlexTeamMemberMonomorphic: UpdateFlexTeamMemberMonomorphic, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFlexTeamMemberMonomorphic(teamId, memberId, updateFlexTeamMemberMonomorphic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FlexTeamControllerApi - factory interface
 * @export
 */
export const FlexTeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FlexTeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader} addFlexTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamLeader(teamId: string, addFlexTeamLeader: AddFlexTeamLeader, options?: any): AxiosPromise<string> {
            return localVarFp.addFlexTeamLeader(teamId, addFlexTeamLeader, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add leader to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeaderMonomorphic} addFlexTeamLeaderMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamLeaderMonomorphic(teamId: string, addFlexTeamLeaderMonomorphic: AddFlexTeamLeaderMonomorphic, options?: any): AxiosPromise<string> {
            return localVarFp.addFlexTeamLeaderMonomorphic(teamId, addFlexTeamLeaderMonomorphic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add member to flex team
         * @param {string} teamId 
         * @param {AddFlexTeamLeader | AddFlexTeammate} addFlexTeamLeaderAddFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFlexTeamMember(teamId: string, addFlexTeamLeaderAddFlexTeammate: AddFlexTeamLeader | AddFlexTeammate, options?: any): AxiosPromise<string> {
            return localVarFp.addFlexTeamMember(teamId, addFlexTeamLeaderAddFlexTeammate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new flex team
         * @param {CreateFlexTeam} createFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeam(createFlexTeam: CreateFlexTeam, options?: any): AxiosPromise<string> {
            return localVarFp.createFlexTeam(createFlexTeam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new flex team and config
         * @param {CreateFlexTeamAndConfig} createFlexTeamAndConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamAndConfig(createFlexTeamAndConfig: CreateFlexTeamAndConfig, options?: any): AxiosPromise<string> {
            return localVarFp.createFlexTeamAndConfig(createFlexTeamAndConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new flex team monomorphic
         * @param {CreateFlexTeamMonomorphic} createFlexTeamMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFlexTeamMonomorphic(createFlexTeamMonomorphic: CreateFlexTeamMonomorphic, options?: any): AxiosPromise<string> {
            return localVarFp.createFlexTeamMonomorphic(createFlexTeamMonomorphic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete flex team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFlexTeam(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFlexTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get flex team by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFlexTeamById(id: string, options?: any): AxiosPromise<FlexTeamDto> {
            return localVarFp.getFlexTeamById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change a member into a teammate
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeammate} makeMemberTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeLeaderIntoMember(teamId: string, memberId: string, makeMemberTeammate: MakeMemberTeammate, options?: any): AxiosPromise<string> {
            return localVarFp.makeLeaderIntoMember(teamId, memberId, makeMemberTeammate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change a member into a leader
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {MakeMemberTeamLeader} makeMemberTeamLeader 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeMemberIntoLeader(teamId: string, memberId: string, makeMemberTeamLeader: MakeMemberTeamLeader, options?: any): AxiosPromise<string> {
            return localVarFp.makeMemberIntoLeader(teamId, memberId, makeMemberTeamLeader, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove member from flex team
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFlexTeamMember(teamId: string, memberId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeFlexTeamMember(teamId, memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search flex teams
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchFlexTeams(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: any): AxiosPromise<GenericSearchResponseSearchFlexTeamDto> {
            return localVarFp.searchFlexTeams(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, teamType, createdAtStart, createdAtEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer a member between two teams
         * @param {TransferFlexTeamMember} transferFlexTeamMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferFlexTeamMember(transferFlexTeamMember: TransferFlexTeamMember, options?: any): AxiosPromise<string> {
            return localVarFp.transferFlexTeamMember(transferFlexTeamMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update flex team by Id
         * @param {string} id 
         * @param {UpdateFlexTeam} updateFlexTeam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeam(id: string, updateFlexTeam: UpdateFlexTeam, options?: any): AxiosPromise<void> {
            return localVarFp.updateFlexTeam(id, updateFlexTeam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update flex team member by id
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamLeader | UpdateFlexTeammate} updateFlexTeamLeaderUpdateFlexTeammate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamMember(teamId: string, memberId: string, updateFlexTeamLeaderUpdateFlexTeammate: UpdateFlexTeamLeader | UpdateFlexTeammate, options?: any): AxiosPromise<void> {
            return localVarFp.updateFlexTeamMember(teamId, memberId, updateFlexTeamLeaderUpdateFlexTeammate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update flex team member by id monomorphic
         * @param {string} teamId 
         * @param {string} memberId 
         * @param {UpdateFlexTeamMemberMonomorphic} updateFlexTeamMemberMonomorphic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFlexTeamMemberMonomorphic(teamId: string, memberId: string, updateFlexTeamMemberMonomorphic: UpdateFlexTeamMemberMonomorphic, options?: any): AxiosPromise<void> {
            return localVarFp.updateFlexTeamMemberMonomorphic(teamId, memberId, updateFlexTeamMemberMonomorphic, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FlexTeamControllerApi - object-oriented interface
 * @export
 * @class FlexTeamControllerApi
 * @extends {BaseAPI}
 */
export class FlexTeamControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add leader to flex team
     * @param {string} teamId 
     * @param {AddFlexTeamLeader} addFlexTeamLeader 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public addFlexTeamLeader(teamId: string, addFlexTeamLeader: AddFlexTeamLeader, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).addFlexTeamLeader(teamId, addFlexTeamLeader, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add leader to flex team
     * @param {string} teamId 
     * @param {AddFlexTeamLeaderMonomorphic} addFlexTeamLeaderMonomorphic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public addFlexTeamLeaderMonomorphic(teamId: string, addFlexTeamLeaderMonomorphic: AddFlexTeamLeaderMonomorphic, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).addFlexTeamLeaderMonomorphic(teamId, addFlexTeamLeaderMonomorphic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add member to flex team
     * @param {string} teamId 
     * @param {AddFlexTeamLeader | AddFlexTeammate} addFlexTeamLeaderAddFlexTeammate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public addFlexTeamMember(teamId: string, addFlexTeamLeaderAddFlexTeammate: AddFlexTeamLeader | AddFlexTeammate, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).addFlexTeamMember(teamId, addFlexTeamLeaderAddFlexTeammate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new flex team
     * @param {CreateFlexTeam} createFlexTeam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public createFlexTeam(createFlexTeam: CreateFlexTeam, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).createFlexTeam(createFlexTeam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new flex team and config
     * @param {CreateFlexTeamAndConfig} createFlexTeamAndConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public createFlexTeamAndConfig(createFlexTeamAndConfig: CreateFlexTeamAndConfig, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).createFlexTeamAndConfig(createFlexTeamAndConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new flex team monomorphic
     * @param {CreateFlexTeamMonomorphic} createFlexTeamMonomorphic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public createFlexTeamMonomorphic(createFlexTeamMonomorphic: CreateFlexTeamMonomorphic, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).createFlexTeamMonomorphic(createFlexTeamMonomorphic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete flex team by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public deleteFlexTeam(id: string, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).deleteFlexTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get flex team by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public getFlexTeamById(id: string, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).getFlexTeamById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change a member into a teammate
     * @param {string} teamId 
     * @param {string} memberId 
     * @param {MakeMemberTeammate} makeMemberTeammate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public makeLeaderIntoMember(teamId: string, memberId: string, makeMemberTeammate: MakeMemberTeammate, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).makeLeaderIntoMember(teamId, memberId, makeMemberTeammate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change a member into a leader
     * @param {string} teamId 
     * @param {string} memberId 
     * @param {MakeMemberTeamLeader} makeMemberTeamLeader 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public makeMemberIntoLeader(teamId: string, memberId: string, makeMemberTeamLeader: MakeMemberTeamLeader, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).makeMemberIntoLeader(teamId, memberId, makeMemberTeamLeader, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove member from flex team
     * @param {string} teamId 
     * @param {string} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public removeFlexTeamMember(teamId: string, memberId: string, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).removeFlexTeamMember(teamId, memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search flex teams
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [searchText] 
     * @param {'ACTIVE' | 'INACTIVE'} [status] 
     * @param {'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO'} [teamType] 
     * @param {string} [createdAtStart] 
     * @param {string} [createdAtEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public searchFlexTeams(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', teamType?: 'NORMAL' | 'PLATINUM' | 'DOMESTIC' | 'GROUP' | 'PRO', createdAtStart?: string, createdAtEnd?: string, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).searchFlexTeams(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, teamType, createdAtStart, createdAtEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer a member between two teams
     * @param {TransferFlexTeamMember} transferFlexTeamMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public transferFlexTeamMember(transferFlexTeamMember: TransferFlexTeamMember, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).transferFlexTeamMember(transferFlexTeamMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update flex team by Id
     * @param {string} id 
     * @param {UpdateFlexTeam} updateFlexTeam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public updateFlexTeam(id: string, updateFlexTeam: UpdateFlexTeam, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).updateFlexTeam(id, updateFlexTeam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update flex team member by id
     * @param {string} teamId 
     * @param {string} memberId 
     * @param {UpdateFlexTeamLeader | UpdateFlexTeammate} updateFlexTeamLeaderUpdateFlexTeammate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public updateFlexTeamMember(teamId: string, memberId: string, updateFlexTeamLeaderUpdateFlexTeammate: UpdateFlexTeamLeader | UpdateFlexTeammate, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).updateFlexTeamMember(teamId, memberId, updateFlexTeamLeaderUpdateFlexTeammate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update flex team member by id monomorphic
     * @param {string} teamId 
     * @param {string} memberId 
     * @param {UpdateFlexTeamMemberMonomorphic} updateFlexTeamMemberMonomorphic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FlexTeamControllerApi
     */
    public updateFlexTeamMemberMonomorphic(teamId: string, memberId: string, updateFlexTeamMemberMonomorphic: UpdateFlexTeamMemberMonomorphic, options?: AxiosRequestConfig) {
        return FlexTeamControllerApiFp(this.configuration).updateFlexTeamMemberMonomorphic(teamId, memberId, updateFlexTeamMemberMonomorphic, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupControllerApi - axios parameter creator
 * @export
 */
export const GroupControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a member to an existing group
         * @param {string} id 
         * @param {AddGroupMemberRequest} addGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToGroup: async (id: string, addGroupMemberRequest: AddGroupMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToGroup', 'id', id)
            // verify required parameter 'addGroupMemberRequest' is not null or undefined
            assertParamExists('addMemberToGroup', 'addGroupMemberRequest', addGroupMemberRequest)
            const localVarPath = `/api/v1/groups/{id}/member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addGroupMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (createGroupRequest: CreateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createGroupRequest' is not null or undefined
            assertParamExists('createGroup', 'createGroupRequest', createGroupRequest)
            const localVarPath = `/api/v1/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Designate a group as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateGroup', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get group by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroupById', 'id', id)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a member from an existing group
         * @param {string} id 
         * @param {RemoveGroupMemberRequest} removeGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromGroup: async (id: string, removeGroupMemberRequest: RemoveGroupMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMemberFromGroup', 'id', id)
            // verify required parameter 'removeGroupMemberRequest' is not null or undefined
            assertParamExists('removeMemberFromGroup', 'removeGroupMemberRequest', removeGroupMemberRequest)
            const localVarPath = `/api/v1/groups/{id}/member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeGroupMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update group
         * @param {string} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (id: string, updateGroupRequest: UpdateGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGroup', 'id', id)
            // verify required parameter 'updateGroupRequest' is not null or undefined
            assertParamExists('updateGroup', 'updateGroupRequest', updateGroupRequest)
            const localVarPath = `/api/v1/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupControllerApi - functional programming interface
 * @export
 */
export const GroupControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing group
         * @param {string} id 
         * @param {AddGroupMemberRequest} addGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToGroup(id: string, addGroupMemberRequest: AddGroupMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToGroup(id, addGroupMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(createGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Designate a group as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get group by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a member from an existing group
         * @param {string} id 
         * @param {RemoveGroupMemberRequest} removeGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMemberFromGroup(id: string, removeGroupMemberRequest: RemoveGroupMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMemberFromGroup(id, removeGroupMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update group
         * @param {string} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(id: string, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(id, updateGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupControllerApi - factory interface
 * @export
 */
export const GroupControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing group
         * @param {string} id 
         * @param {AddGroupMemberRequest} addGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToGroup(id: string, addGroupMemberRequest: AddGroupMemberRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.addMemberToGroup(id, addGroupMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a group
         * @param {CreateGroupRequest} createGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(createGroupRequest: CreateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.createGroup(createGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Designate a group as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateGroup(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deactivateGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get group by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupById(id: string, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.getGroupById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a member from an existing group
         * @param {string} id 
         * @param {RemoveGroupMemberRequest} removeGroupMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMemberFromGroup(id: string, removeGroupMemberRequest: RemoveGroupMemberRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.removeMemberFromGroup(id, removeGroupMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update group
         * @param {string} id 
         * @param {UpdateGroupRequest} updateGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(id: string, updateGroupRequest: UpdateGroupRequest, options?: any): AxiosPromise<GroupResponse> {
            return localVarFp.updateGroup(id, updateGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupControllerApi - object-oriented interface
 * @export
 * @class GroupControllerApi
 * @extends {BaseAPI}
 */
export class GroupControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a member to an existing group
     * @param {string} id 
     * @param {AddGroupMemberRequest} addGroupMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public addMemberToGroup(id: string, addGroupMemberRequest: AddGroupMemberRequest, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).addMemberToGroup(id, addGroupMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a group
     * @param {CreateGroupRequest} createGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public createGroup(createGroupRequest: CreateGroupRequest, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).createGroup(createGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Designate a group as inactive
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public deactivateGroup(id: string, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).deactivateGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get group by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public getGroupById(id: string, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).getGroupById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a member from an existing group
     * @param {string} id 
     * @param {RemoveGroupMemberRequest} removeGroupMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public removeMemberFromGroup(id: string, removeGroupMemberRequest: RemoveGroupMemberRequest, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).removeMemberFromGroup(id, removeGroupMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update group
     * @param {string} id 
     * @param {UpdateGroupRequest} updateGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupControllerApi
     */
    public updateGroup(id: string, updateGroupRequest: UpdateGroupRequest, options?: AxiosRequestConfig) {
        return GroupControllerApiFp(this.configuration).updateGroup(id, updateGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IcaControllerApi - axios parameter creator
 * @export
 */
export const IcaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get latest ica that is not signed
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getLatestICAAgreement: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getLatestICAAgreement', 'agentId', agentId)
            const localVarPath = `/api/v1/ica/latest-not-signed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IcaControllerApi - functional programming interface
 * @export
 */
export const IcaControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IcaControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get latest ica that is not signed
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getLatestICAAgreement(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestICAAgreement(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IcaControllerApi - factory interface
 * @export
 */
export const IcaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IcaControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get latest ica that is not signed
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getLatestICAAgreement(agentId: string, options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.getLatestICAAgreement(agentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IcaControllerApi - object-oriented interface
 * @export
 * @class IcaControllerApi
 * @extends {BaseAPI}
 */
export class IcaControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get latest ica that is not signed
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof IcaControllerApi
     */
    public getLatestICAAgreement(agentId: string, options?: AxiosRequestConfig) {
        return IcaControllerApiFp(this.configuration).getLatestICAAgreement(agentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalControllerApi - axios parameter creator
 * @export
 */
export const InternalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update a user\'s email address
         * @param {FixEmailRequest} fixEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixEmail: async (fixEmailRequest: FixEmailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fixEmailRequest' is not null or undefined
            assertParamExists('fixEmail', 'fixEmailRequest', fixEmailRequest)
            const localVarPath = `/api/v1/internal/fix-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fixEmailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalControllerApi - functional programming interface
 * @export
 */
export const InternalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update a user\'s email address
         * @param {FixEmailRequest} fixEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fixEmail(fixEmailRequest: FixEmailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fixEmail(fixEmailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalControllerApi - factory interface
 * @export
 */
export const InternalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update a user\'s email address
         * @param {FixEmailRequest} fixEmailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fixEmail(fixEmailRequest: FixEmailRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.fixEmail(fixEmailRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalControllerApi - object-oriented interface
 * @export
 * @class InternalControllerApi
 * @extends {BaseAPI}
 */
export class InternalControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update a user\'s email address
     * @param {FixEmailRequest} fixEmailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalControllerApi
     */
    public fixEmail(fixEmailRequest: FixEmailRequest, options?: AxiosRequestConfig) {
        return InternalControllerApiFp(this.configuration).fixEmail(fixEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobExecutionControllerApi - axios parameter creator
 * @export
 */
export const JobExecutionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failJobExecution: async (jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('failJobExecution', 'jobExecutionId', jobExecutionId)
            // verify required parameter 'updateJobExecutionRequest' is not null or undefined
            assertParamExists('failJobExecution', 'updateJobExecutionRequest', updateJobExecutionRequest)
            const localVarPath = `/api/v1/jobExecutions/failJobExecution/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJobExecutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionById: async (jobExecutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('getJobExecutionById', 'jobExecutionId', jobExecutionId)
            const localVarPath = `/api/v1/jobExecutions/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutions: async (jobExecutionName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionName' is not null or undefined
            assertParamExists('getJobExecutions', 'jobExecutionName', jobExecutionName)
            const localVarPath = `/api/v1/jobExecutions/getJobExecutions/{jobExecutionName}`
                .replace(`{${"jobExecutionName"}}`, encodeURIComponent(String(jobExecutionName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestJobExecutions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobExecutions/getLatestJobExecutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} startJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobExecution: async (startJobExecutionRequest: StartJobExecutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startJobExecutionRequest' is not null or undefined
            assertParamExists('startJobExecution', 'startJobExecutionRequest', startJobExecutionRequest)
            const localVarPath = `/api/v1/jobExecutions/startJobExecution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startJobExecutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        succeedJobExecution: async (jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobExecutionId' is not null or undefined
            assertParamExists('succeedJobExecution', 'jobExecutionId', jobExecutionId)
            // verify required parameter 'updateJobExecutionRequest' is not null or undefined
            assertParamExists('succeedJobExecution', 'updateJobExecutionRequest', updateJobExecutionRequest)
            const localVarPath = `/api/v1/jobExecutions/succeedJobExecution/{jobExecutionId}`
                .replace(`{${"jobExecutionId"}}`, encodeURIComponent(String(jobExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateJobExecutionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobExecutionControllerApi - functional programming interface
 * @export
 */
export const JobExecutionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobExecutionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async failJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.failJobExecution(jobExecutionId, updateJobExecutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobExecutionById(jobExecutionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobExecutionById(jobExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobExecutions(jobExecutionName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobExecutions(jobExecutionName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestJobExecutions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestJobExecutions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} startJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startJobExecution(startJobExecutionRequest: StartJobExecutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startJobExecution(startJobExecutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async succeedJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobExecutionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.succeedJobExecution(jobExecutionId, updateJobExecutionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobExecutionControllerApi - factory interface
 * @export
 */
export const JobExecutionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobExecutionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Mark a job failed.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        failJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.failJobExecution(jobExecutionId, updateJobExecutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get JobExecution by id
         * @param {string} jobExecutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutionById(jobExecutionId: string, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.getJobExecutionById(jobExecutionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get JobExecutions for a given jobExecutionType
         * @param {string} jobExecutionName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobExecutions(jobExecutionName: string, options?: any): AxiosPromise<JobExecutionsResponse> {
            return localVarFp.getJobExecutions(jobExecutionName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Latest JobExecution grouped by jobExecutionType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestJobExecutions(options?: any): AxiosPromise<JobExecutionsResponse> {
            return localVarFp.getLatestJobExecutions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start tracking a job
         * @param {StartJobExecutionRequest} startJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startJobExecution(startJobExecutionRequest: StartJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.startJobExecution(startJobExecutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a job completed successfully.
         * @param {string} jobExecutionId 
         * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        succeedJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: any): AxiosPromise<JobExecutionResponse> {
            return localVarFp.succeedJobExecution(jobExecutionId, updateJobExecutionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobExecutionControllerApi - object-oriented interface
 * @export
 * @class JobExecutionControllerApi
 * @extends {BaseAPI}
 */
export class JobExecutionControllerApi extends BaseAPI {
    /**
     * 
     * @summary Mark a job failed.
     * @param {string} jobExecutionId 
     * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public failJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).failJobExecution(jobExecutionId, updateJobExecutionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get JobExecution by id
     * @param {string} jobExecutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getJobExecutionById(jobExecutionId: string, options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).getJobExecutionById(jobExecutionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get JobExecutions for a given jobExecutionType
     * @param {string} jobExecutionName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getJobExecutions(jobExecutionName: string, options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).getJobExecutions(jobExecutionName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Latest JobExecution grouped by jobExecutionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public getLatestJobExecutions(options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).getLatestJobExecutions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start tracking a job
     * @param {StartJobExecutionRequest} startJobExecutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public startJobExecution(startJobExecutionRequest: StartJobExecutionRequest, options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).startJobExecution(startJobExecutionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a job completed successfully.
     * @param {string} jobExecutionId 
     * @param {UpdateJobExecutionRequest} updateJobExecutionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobExecutionControllerApi
     */
    public succeedJobExecution(jobExecutionId: string, updateJobExecutionRequest: UpdateJobExecutionRequest, options?: AxiosRequestConfig) {
        return JobExecutionControllerApiFp(this.configuration).succeedJobExecution(jobExecutionId, updateJobExecutionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryConsumerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryConsumerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus1', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/consumer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/consumer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage1', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages1', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus1', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/consumer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryConsumerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage1(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages1(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryConsumerControllerApi - factory interface
 * @export
 */
export const KafkaRetryConsumerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryConsumerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryConsumerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryConsumerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryConsumerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus1(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public getFailedMessages1(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).getFailedMessages1(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessage1(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessage1(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public retryFailedMessages1(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).retryFailedMessages1(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryConsumerControllerApi
     */
    public updateFailedMessageStatus1(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryConsumerControllerApiFp(this.configuration).updateFailedMessageStatus1(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KafkaRetryProducerControllerApi - axios parameter creator
 * @export
 */
export const KafkaRetryProducerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus: async (updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFailedMessageStatusBatch' is not null or undefined
            assertParamExists('batchUpdateFailedMessageStatus', 'updateFailedMessageStatusBatch', updateFailedMessageStatusBatch)
            const localVarPath = `/api/v1/kafka-retry/producer/status-batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFailedMessageStatusBatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/kafka-retry/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (topic !== undefined) {
                localVarQueryParameter['topic'] = topic;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtFrom !== undefined) {
                localVarQueryParameter['createdAtFrom'] = createdAtFrom;
            }

            if (createdAtTo !== undefined) {
                localVarQueryParameter['createdAtTo'] = createdAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage: async (failedMessageRetryRequest: FailedMessageRetryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageRetryRequest' is not null or undefined
            assertParamExists('retryFailedMessage', 'failedMessageRetryRequest', failedMessageRetryRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageRetryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages: async (failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessagesCriteriaRequest' is not null or undefined
            assertParamExists('retryFailedMessages', 'failedMessagesCriteriaRequest', failedMessagesCriteriaRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/retry-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessagesCriteriaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus: async (failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'failedMessageUpdateStatusRequest' is not null or undefined
            assertParamExists('updateFailedMessageStatus', 'failedMessageUpdateStatusRequest', failedMessageUpdateStatusRequest)
            const localVarPath = `/api/v1/kafka-retry/producer/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(failedMessageUpdateStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - functional programming interface
 * @export
 */
export const KafkaRetryProducerControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KafkaRetryProducerControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseFailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessage(failedMessageRetryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retryFailedMessages(failedMessagesCriteriaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FailedMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KafkaRetryProducerControllerApi - factory interface
 * @export
 */
export const KafkaRetryProducerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KafkaRetryProducerControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Update provided failed messages status
         * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: any): AxiosPromise<number> {
            return localVarFp.batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of failed messages
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
         * @param {string} [topic] 
         * @param {string} [key] 
         * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
         * @param {number} [createdAtFrom] 
         * @param {number} [createdAtTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: any): AxiosPromise<GenericSearchResponseFailedMessage> {
            return localVarFp.getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry one message by id
         * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: any): AxiosPromise<void> {
            return localVarFp.retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry messages by criteria
         * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: any): AxiosPromise<number> {
            return localVarFp.retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update failed event status
         * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: any): AxiosPromise<FailedMessage> {
            return localVarFp.updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KafkaRetryProducerControllerApi - object-oriented interface
 * @export
 * @class KafkaRetryProducerControllerApi
 * @extends {BaseAPI}
 */
export class KafkaRetryProducerControllerApi extends BaseAPI {
    /**
     * 
     * @summary Update provided failed messages status
     * @param {UpdateFailedMessageStatusBatch} updateFailedMessageStatusBatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch: UpdateFailedMessageStatusBatch, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).batchUpdateFailedMessageStatus(updateFailedMessageStatusBatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of failed messages
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'CREATED_AT' | 'STATUS'>} [sortBy] 
     * @param {string} [topic] 
     * @param {string} [key] 
     * @param {'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED'} [status] 
     * @param {number} [createdAtFrom] 
     * @param {number} [createdAtTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public getFailedMessages(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'CREATED_AT' | 'STATUS'>, topic?: string, key?: string, status?: 'BLOCKED' | 'UNPROCESSED' | 'PROCESSED' | 'FAILED' | 'MANUAL_OVERRIDE' | 'NO_ACTION_REQUIRED', createdAtFrom?: number, createdAtTo?: number, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).getFailedMessages(pageNumber, pageSize, sortDirection, sortBy, topic, key, status, createdAtFrom, createdAtTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry one message by id
     * @param {FailedMessageRetryRequest} failedMessageRetryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessage(failedMessageRetryRequest: FailedMessageRetryRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessage(failedMessageRetryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry messages by criteria
     * @param {FailedMessagesCriteriaRequest} failedMessagesCriteriaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public retryFailedMessages(failedMessagesCriteriaRequest: FailedMessagesCriteriaRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).retryFailedMessages(failedMessagesCriteriaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update failed event status
     * @param {FailedMessageUpdateStatusRequest} failedMessageUpdateStatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof KafkaRetryProducerControllerApi
     */
    public updateFailedMessageStatus(failedMessageUpdateStatusRequest: FailedMessageUpdateStatusRequest, options?: AxiosRequestConfig) {
        return KafkaRetryProducerControllerApiFp(this.configuration).updateFailedMessageStatus(failedMessageUpdateStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LearnworldsControllerApi - axios parameter creator
 * @export
 */
export const LearnworldsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetches a token to login to Learnworlds using SSO.
         * @param {string} agentId 
         * @param {LearnworldsSsoRequest} learnworldsSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInToLearnworlds: async (agentId: string, learnworldsSsoRequest: LearnworldsSsoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('signInToLearnworlds', 'agentId', agentId)
            // verify required parameter 'learnworldsSsoRequest' is not null or undefined
            assertParamExists('signInToLearnworlds', 'learnworldsSsoRequest', learnworldsSsoRequest)
            const localVarPath = `/api/v1/agents/{agentId}/learnworlds/sso`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(learnworldsSsoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LearnworldsControllerApi - functional programming interface
 * @export
 */
export const LearnworldsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LearnworldsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetches a token to login to Learnworlds using SSO.
         * @param {string} agentId 
         * @param {LearnworldsSsoRequest} learnworldsSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signInToLearnworlds(agentId: string, learnworldsSsoRequest: LearnworldsSsoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LearnworldsSsoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signInToLearnworlds(agentId, learnworldsSsoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LearnworldsControllerApi - factory interface
 * @export
 */
export const LearnworldsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LearnworldsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetches a token to login to Learnworlds using SSO.
         * @param {string} agentId 
         * @param {LearnworldsSsoRequest} learnworldsSsoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signInToLearnworlds(agentId: string, learnworldsSsoRequest: LearnworldsSsoRequest, options?: any): AxiosPromise<LearnworldsSsoResponse> {
            return localVarFp.signInToLearnworlds(agentId, learnworldsSsoRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LearnworldsControllerApi - object-oriented interface
 * @export
 * @class LearnworldsControllerApi
 * @extends {BaseAPI}
 */
export class LearnworldsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Fetches a token to login to Learnworlds using SSO.
     * @param {string} agentId 
     * @param {LearnworldsSsoRequest} learnworldsSsoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LearnworldsControllerApi
     */
    public signInToLearnworlds(agentId: string, learnworldsSsoRequest: LearnworldsSsoRequest, options?: AxiosRequestConfig) {
        return LearnworldsControllerApiFp(this.configuration).signInToLearnworlds(agentId, learnworldsSsoRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocalTaxControllerApi - axios parameter creator
 * @export
 */
export const LocalTaxControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Local Tax rates by State, City and zip Code
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {string} city 
         * @param {string} zipOrPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalTax: async (stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', city: string, zipOrPostalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateOrProvince' is not null or undefined
            assertParamExists('getLocalTax', 'stateOrProvince', stateOrProvince)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('getLocalTax', 'city', city)
            // verify required parameter 'zipOrPostalCode' is not null or undefined
            assertParamExists('getLocalTax', 'zipOrPostalCode', zipOrPostalCode)
            const localVarPath = `/api/v1/local-tax`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stateOrProvince !== undefined) {
                localVarQueryParameter['stateOrProvince'] = stateOrProvince;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (zipOrPostalCode !== undefined) {
                localVarQueryParameter['zipOrPostalCode'] = zipOrPostalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload data into Local Tax
         * @param {string} stateOrProvince 
         * @param {any} localTaxDataFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLocalTaxesForStateOrProvince: async (stateOrProvince: string, localTaxDataFile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateOrProvince' is not null or undefined
            assertParamExists('uploadLocalTaxesForStateOrProvince', 'stateOrProvince', stateOrProvince)
            // verify required parameter 'localTaxDataFile' is not null or undefined
            assertParamExists('uploadLocalTaxesForStateOrProvince', 'localTaxDataFile', localTaxDataFile)
            const localVarPath = `/api/v1/local-tax/upload/{stateOrProvince}`
                .replace(`{${"stateOrProvince"}}`, encodeURIComponent(String(stateOrProvince)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (localTaxDataFile !== undefined) { 
                localVarFormParams.append('localTaxDataFile', localTaxDataFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalTaxControllerApi - functional programming interface
 * @export
 */
export const LocalTaxControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalTaxControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Local Tax rates by State, City and zip Code
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {string} city 
         * @param {string} zipOrPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalTax(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', city: string, zipOrPostalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocalTaxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalTax(stateOrProvince, city, zipOrPostalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload data into Local Tax
         * @param {string} stateOrProvince 
         * @param {any} localTaxDataFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadLocalTaxesForStateOrProvince(stateOrProvince: string, localTaxDataFile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLocalTaxesForStateOrProvince(stateOrProvince, localTaxDataFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocalTaxControllerApi - factory interface
 * @export
 */
export const LocalTaxControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalTaxControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Local Tax rates by State, City and zip Code
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {string} city 
         * @param {string} zipOrPostalCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalTax(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', city: string, zipOrPostalCode: string, options?: any): AxiosPromise<LocalTaxResponse> {
            return localVarFp.getLocalTax(stateOrProvince, city, zipOrPostalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload data into Local Tax
         * @param {string} stateOrProvince 
         * @param {any} localTaxDataFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadLocalTaxesForStateOrProvince(stateOrProvince: string, localTaxDataFile: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadLocalTaxesForStateOrProvince(stateOrProvince, localTaxDataFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalTaxControllerApi - object-oriented interface
 * @export
 * @class LocalTaxControllerApi
 * @extends {BaseAPI}
 */
export class LocalTaxControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Local Tax rates by State, City and zip Code
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
     * @param {string} city 
     * @param {string} zipOrPostalCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalTaxControllerApi
     */
    public getLocalTax(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', city: string, zipOrPostalCode: string, options?: AxiosRequestConfig) {
        return LocalTaxControllerApiFp(this.configuration).getLocalTax(stateOrProvince, city, zipOrPostalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload data into Local Tax
     * @param {string} stateOrProvince 
     * @param {any} localTaxDataFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalTaxControllerApi
     */
    public uploadLocalTaxesForStateOrProvince(stateOrProvince: string, localTaxDataFile: any, options?: AxiosRequestConfig) {
        return LocalTaxControllerApiFp(this.configuration).uploadLocalTaxesForStateOrProvince(stateOrProvince, localTaxDataFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MlsControllerApi - axios parameter creator
 * @export
 */
export const MlsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create dropboxes for each mls without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mls/missing-dropboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create mls board
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMls: async (mlsRequest: MlsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mlsRequest' is not null or undefined
            assertParamExists('createMls', 'mlsRequest', mlsRequest)
            const localVarPath = `/api/v1/mls/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete mls
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMls: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMls', 'id', id)
            const localVarPath = `/api/v1/mls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMlsById', 'id', id)
            const localVarPath = `/api/v1/mls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get MLS Secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsSecretsById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMlsSecretsById', 'id', id)
            const localVarPath = `/api/v1/mls/secrets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search MLSs with limited information
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [name] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLiteMls: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', name?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mls/search/lite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search MLSs
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [code] 
         * @param {string} [id] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMls: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>, searchText?: string, name?: string, email?: string, code?: string, id?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, status?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (state) {
                localVarQueryParameter['state'] = Array.from(state);
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMlsById: async (id: string, mlsRequest: MlsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMlsById', 'id', id)
            // verify required parameter 'mlsRequest' is not null or undefined
            assertParamExists('updateMlsById', 'mlsRequest', mlsRequest)
            const localVarPath = `/api/v1/mls/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mlsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MlsControllerApi - functional programming interface
 * @export
 */
export const MlsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MlsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create dropboxes for each mls without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissingOfficeDropboxes1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MlsDropboxErrorResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissingOfficeDropboxes1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create mls board
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMls(mlsRequest: MlsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMls(mlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete mls
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMls(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMls(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMlsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMlsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get MLS Secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMlsSecretsById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsSecretsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMlsSecretsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search MLSs with limited information
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [name] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchLiteMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', name?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseMlsPublicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchLiteMls(pageNumber, pageSize, sortDirection, name, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search MLSs
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [code] 
         * @param {string} [id] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>, searchText?: string, name?: string, email?: string, code?: string, id?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, status?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseMlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchMls(pageNumber, pageSize, sortDirection, sortBy, searchText, name, email, code, id, state, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMlsById(id: string, mlsRequest: MlsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMlsById(id, mlsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MlsControllerApi - factory interface
 * @export
 */
export const MlsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MlsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create dropboxes for each mls without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes1(options?: any): AxiosPromise<Array<MlsDropboxErrorResponse>> {
            return localVarFp.createMissingOfficeDropboxes1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create mls board
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMls(mlsRequest: MlsRequest, options?: any): AxiosPromise<MlsResponse> {
            return localVarFp.createMls(mlsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete mls
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMls(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMls(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MLS by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsById(id: string, options?: any): AxiosPromise<MlsResponse> {
            return localVarFp.getMlsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get MLS Secrets by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMlsSecretsById(id: string, options?: any): AxiosPromise<MlsSecretsResponse> {
            return localVarFp.getMlsSecretsById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search MLSs with limited information
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {string} [name] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchLiteMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', name?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: any): AxiosPromise<GenericSearchResponseMlsPublicResponse> {
            return localVarFp.searchLiteMls(pageNumber, pageSize, sortDirection, name, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search MLSs
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [code] 
         * @param {string} [id] 
         * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
         * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>, searchText?: string, name?: string, email?: string, code?: string, id?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, status?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: any): AxiosPromise<GenericSearchResponseMlsResponse> {
            return localVarFp.searchMls(pageNumber, pageSize, sortDirection, sortBy, searchText, name, email, code, id, state, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update board by id
         * @param {string} id 
         * @param {MlsRequest} mlsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMlsById(id: string, mlsRequest: MlsRequest, options?: any): AxiosPromise<MlsResponse> {
            return localVarFp.updateMlsById(id, mlsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MlsControllerApi - object-oriented interface
 * @export
 * @class MlsControllerApi
 * @extends {BaseAPI}
 */
export class MlsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create dropboxes for each mls without one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public createMissingOfficeDropboxes1(options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).createMissingOfficeDropboxes1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create mls board
     * @param {MlsRequest} mlsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public createMls(mlsRequest: MlsRequest, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).createMls(mlsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete mls
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public deleteMls(id: string, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).deleteMls(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MLS by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public getMlsById(id: string, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).getMlsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get MLS Secrets by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public getMlsSecretsById(id: string, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).getMlsSecretsById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search MLSs with limited information
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {string} [name] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public searchLiteMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', name?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).searchLiteMls(pageNumber, pageSize, sortDirection, name, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search MLSs
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>} [sortBy] 
     * @param {string} [searchText] 
     * @param {string} [name] 
     * @param {string} [email] 
     * @param {string} [code] 
     * @param {string} [id] 
     * @param {Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>} [state] 
     * @param {Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public searchMls(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'CODE' | 'EMAIL' | 'JOIN_DATE' | 'STATUS'>, searchText?: string, name?: string, email?: string, code?: string, id?: string, state?: Array<'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'>, status?: Array<'NOT_MEMBERS' | 'PENDING' | 'ACTIVE' | 'INACTIVE' | 'WILLING_TO_JOIN'>, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).searchMls(pageNumber, pageSize, sortDirection, sortBy, searchText, name, email, code, id, state, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update board by id
     * @param {string} id 
     * @param {MlsRequest} mlsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsControllerApi
     */
    public updateMlsById(id: string, mlsRequest: MlsRequest, options?: AxiosRequestConfig) {
        return MlsControllerApiFp(this.configuration).updateMlsById(id, mlsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MlsPropertyTypeControllerApi - axios parameter creator
 * @export
 */
export const MlsPropertyTypeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create mls property type mapping
         * @param {MlsCreatePropertyTypeRequest} mlsCreatePropertyTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePropertyTypeMapping: async (mlsCreatePropertyTypeRequest: MlsCreatePropertyTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mlsCreatePropertyTypeRequest' is not null or undefined
            assertParamExists('createOrUpdatePropertyTypeMapping', 'mlsCreatePropertyTypeRequest', mlsCreatePropertyTypeRequest)
            const localVarPath = `/api/v1/mls-property-type-mapper/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mlsCreatePropertyTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get mls property type mapping
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypeMapping: async (mlsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mlsId' is not null or undefined
            assertParamExists('getPropertyTypeMapping', 'mlsId', mlsId)
            const localVarPath = `/api/v1/mls-property-type-mapper/{mls_id}`
                .replace(`{${"mls_id"}}`, encodeURIComponent(String(mlsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MlsPropertyTypeControllerApi - functional programming interface
 * @export
 */
export const MlsPropertyTypeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MlsPropertyTypeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create mls property type mapping
         * @param {MlsCreatePropertyTypeRequest} mlsCreatePropertyTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest: MlsCreatePropertyTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get mls property type mapping
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyTypeMapping(mlsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MlsGetPropertyTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyTypeMapping(mlsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MlsPropertyTypeControllerApi - factory interface
 * @export
 */
export const MlsPropertyTypeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MlsPropertyTypeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary create mls property type mapping
         * @param {MlsCreatePropertyTypeRequest} mlsCreatePropertyTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest: MlsCreatePropertyTypeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get mls property type mapping
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyTypeMapping(mlsId: string, options?: any): AxiosPromise<MlsGetPropertyTypeResponse> {
            return localVarFp.getPropertyTypeMapping(mlsId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MlsPropertyTypeControllerApi - object-oriented interface
 * @export
 * @class MlsPropertyTypeControllerApi
 * @extends {BaseAPI}
 */
export class MlsPropertyTypeControllerApi extends BaseAPI {
    /**
     * 
     * @summary create mls property type mapping
     * @param {MlsCreatePropertyTypeRequest} mlsCreatePropertyTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsPropertyTypeControllerApi
     */
    public createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest: MlsCreatePropertyTypeRequest, options?: AxiosRequestConfig) {
        return MlsPropertyTypeControllerApiFp(this.configuration).createOrUpdatePropertyTypeMapping(mlsCreatePropertyTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get mls property type mapping
     * @param {string} mlsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MlsPropertyTypeControllerApi
     */
    public getPropertyTypeMapping(mlsId: string, options?: AxiosRequestConfig) {
        return MlsPropertyTypeControllerApiFp(this.configuration).getPropertyTypeMapping(mlsId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MortgageUserControllerApi - axios parameter creator
 * @export
 */
export const MortgageUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Convert user to loan officer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertToLoanOfficer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('convertToLoanOfficer', 'id', id)
            const localVarPath = `/api/v1/users/{id}/convert-to-loan-officer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortgageUserControllerApi - functional programming interface
 * @export
 */
export const MortgageUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MortgageUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Convert user to loan officer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async convertToLoanOfficer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.convertToLoanOfficer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MortgageUserControllerApi - factory interface
 * @export
 */
export const MortgageUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MortgageUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Convert user to loan officer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertToLoanOfficer(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.convertToLoanOfficer(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortgageUserControllerApi - object-oriented interface
 * @export
 * @class MortgageUserControllerApi
 * @extends {BaseAPI}
 */
export class MortgageUserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Convert user to loan officer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageUserControllerApi
     */
    public convertToLoanOfficer(id: string, options?: AxiosRequestConfig) {
        return MortgageUserControllerApiFp(this.configuration).convertToLoanOfficer(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoteControllerApi - axios parameter creator
 * @export
 */
export const NoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNote: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('archiveNote', 'noteId', noteId)
            const localVarPath = `/api/v1/notes/archiveNote/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} createNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote: async (createNoteRequest: CreateNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNoteRequest' is not null or undefined
            assertParamExists('createNote', 'createNoteRequest', createNoteRequest)
            const localVarPath = `/api/v1/notes/createNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('getNoteById', 'noteId', noteId)
            const localVarPath = `/api/v1/notes/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD'} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById1: async (entityType: 'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD', entityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'entityType' is not null or undefined
            assertParamExists('getNoteById1', 'entityType', entityType)
            // verify required parameter 'entityId' is not null or undefined
            assertParamExists('getNoteById1', 'entityId', entityId)
            const localVarPath = `/api/v1/notes/{entityType}/{entityId}`
                .replace(`{${"entityType"}}`, encodeURIComponent(String(entityType)))
                .replace(`{${"entityId"}}`, encodeURIComponent(String(entityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Notes for a given entity Type by IDs
         * @param {NoteBatchEntityRequest} noteBatchEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesByIds: async (noteBatchEntityRequest: NoteBatchEntityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteBatchEntityRequest' is not null or undefined
            assertParamExists('getNotesByIds', 'noteBatchEntityRequest', noteBatchEntityRequest)
            const localVarPath = `/api/v1/notes/batch-entity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteBatchEntityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId 
         * @param {UpdateNoteRequest} updateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote: async (noteId: string, updateNoteRequest: UpdateNoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteId' is not null or undefined
            assertParamExists('updateNote', 'noteId', noteId)
            // verify required parameter 'updateNoteRequest' is not null or undefined
            assertParamExists('updateNote', 'updateNoteRequest', updateNoteRequest)
            const localVarPath = `/api/v1/notes/updateNote/{noteId}`
                .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteControllerApi - functional programming interface
 * @export
 */
export const NoteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveNote(noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveNote(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} createNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNote(createNoteRequest: CreateNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNote(createNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteById(noteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteById(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD'} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNoteById1(entityType: 'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD', entityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteById1(entityType, entityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Notes for a given entity Type by IDs
         * @param {NoteBatchEntityRequest} noteBatchEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotesByIds(noteBatchEntityRequest: NoteBatchEntityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotesByIds(noteBatchEntityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId 
         * @param {UpdateNoteRequest} updateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNote(noteId: string, updateNoteRequest: UpdateNoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(noteId, updateNoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteControllerApi - factory interface
 * @export
 */
export const NoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Archive Note
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveNote(noteId: string, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.archiveNote(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Note
         * @param {CreateNoteRequest} createNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNote(createNoteRequest: CreateNoteRequest, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.createNote(createNoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Note by id
         * @param {string} noteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById(noteId: string, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.getNoteById(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notes for a given entityType and entityId
         * @param {'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD'} entityType 
         * @param {string} entityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoteById1(entityType: 'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD', entityId: string, options?: any): AxiosPromise<NotesResponse> {
            return localVarFp.getNoteById1(entityType, entityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Notes for a given entity Type by IDs
         * @param {NoteBatchEntityRequest} noteBatchEntityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotesByIds(noteBatchEntityRequest: NoteBatchEntityRequest, options?: any): AxiosPromise<NotesResponse> {
            return localVarFp.getNotesByIds(noteBatchEntityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Note
         * @param {string} noteId 
         * @param {UpdateNoteRequest} updateNoteRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNote(noteId: string, updateNoteRequest: UpdateNoteRequest, options?: any): AxiosPromise<NoteResponse> {
            return localVarFp.updateNote(noteId, updateNoteRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteControllerApi - object-oriented interface
 * @export
 * @class NoteControllerApi
 * @extends {BaseAPI}
 */
export class NoteControllerApi extends BaseAPI {
    /**
     * 
     * @summary Archive Note
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public archiveNote(noteId: string, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).archiveNote(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Note
     * @param {CreateNoteRequest} createNoteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public createNote(createNoteRequest: CreateNoteRequest, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).createNote(createNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Note by id
     * @param {string} noteId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getNoteById(noteId: string, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).getNoteById(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notes for a given entityType and entityId
     * @param {'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD'} entityType 
     * @param {string} entityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getNoteById1(entityType: 'APPLICATION' | 'TRANSACTION' | 'AGENT' | 'MLS' | 'BOARD', entityId: string, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).getNoteById1(entityType, entityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Notes for a given entity Type by IDs
     * @param {NoteBatchEntityRequest} noteBatchEntityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public getNotesByIds(noteBatchEntityRequest: NoteBatchEntityRequest, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).getNotesByIds(noteBatchEntityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Note
     * @param {string} noteId 
     * @param {UpdateNoteRequest} updateNoteRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteControllerApi
     */
    public updateNote(noteId: string, updateNoteRequest: UpdateNoteRequest, options?: AxiosRequestConfig) {
        return NoteControllerApiFp(this.configuration).updateNote(noteId, updateNoteRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfficeControllerApi - axios parameter creator
 * @export
 */
export const OfficeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a board to an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBoard: async (id: string, boardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addBoard', 'id', id)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('addBoard', 'boardId', boardId)
            const localVarPath = `/api/v1/offices/{id}/board/{boardId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"boardId"}}`, encodeURIComponent(String(boardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a Checklist to an office
         * @param {string} officeId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChecklist: async (officeId: string, checklistDefinitionDto: ChecklistDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('addChecklist', 'officeId', officeId)
            // verify required parameter 'checklistDefinitionDto' is not null or undefined
            assertParamExists('addChecklist', 'checklistDefinitionDto', checklistDefinitionDto)
            const localVarPath = `/api/v1/offices/{officeId}/checklists`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an mls to an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMls: async (id: string, mlsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMls', 'id', id)
            // verify required parameter 'mlsId' is not null or undefined
            assertParamExists('addMls', 'mlsId', mlsId)
            const localVarPath = `/api/v1/offices/{id}/mls/{mlsId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"mlsId"}}`, encodeURIComponent(String(mlsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add pre-defined groups to the office
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultGroupsInOffice: async (officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('createDefaultGroupsInOffice', 'officeId', officeId)
            const localVarPath = `/api/v1/offices/{officeId}/default-groups`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create dropboxes for each office without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices/create-missing-office-dropboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice: async (createOfficeRequest: CreateOfficeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfficeRequest' is not null or undefined
            assertParamExists('createOffice', 'createOfficeRequest', createOfficeRequest)
            const localVarPath = `/api/v1/offices/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfficeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice1: async (createOfficeRequest: CreateOfficeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfficeRequest' is not null or undefined
            assertParamExists('createOffice1', 'createOfficeRequest', createOfficeRequest)
            const localVarPath = `/api/v1/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfficeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all default offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDefaultOffices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices/default/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all offices for a given administrative area ID
         * @param {string} administrativeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOfficesInAdminArea: async (administrativeAreaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'administrativeAreaId' is not null or undefined
            assertParamExists('getAllOfficesInAdminArea', 'administrativeAreaId', administrativeAreaId)
            const localVarPath = `/api/v1/offices/administrative-area/{administrativeAreaId}`
                .replace(`{${"administrativeAreaId"}}`, encodeURIComponent(String(administrativeAreaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Checklists for an Office
         * @param {string} officeId 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'SALE' | 'LEASE'} [dealType] 
         * @param {'BUYER' | 'SELLER' | 'DUAL'} [representee] 
         * @param {Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>} [propertyTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitionInformationsForOffice: async (officeId: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', dealType?: 'SALE' | 'LEASE', representee?: 'BUYER' | 'SELLER' | 'DUAL', propertyTypes?: Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('getChecklistDefinitionInformationsForOffice', 'officeId', officeId)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getChecklistDefinitionInformationsForOffice', 'type', type)
            const localVarPath = `/api/v1/offices/{officeId}/checklists/{type}`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dealType !== undefined) {
                localVarQueryParameter['dealType'] = dealType;
            }

            if (representee !== undefined) {
                localVarQueryParameter['representee'] = representee;
            }

            if (propertyTypes) {
                localVarQueryParameter['propertyTypes'] = Array.from(propertyTypes);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Office\'s default Checklist
         * @param {string} id 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [targetType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultChecklist: async (id: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDefaultChecklist', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getDefaultChecklist', 'type', type)
            const localVarPath = `/api/v1/offices/{id}/checklists/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (targetType !== undefined) {
                localVarQueryParameter['targetType'] = targetType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get default office in state/province
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultOfficeInState: async (stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateOrProvince' is not null or undefined
            assertParamExists('getDefaultOfficeInState', 'stateOrProvince', stateOrProvince)
            const localVarPath = `/api/v1/offices/default/{stateOrProvince}`
                .replace(`{${"stateOrProvince"}}`, encodeURIComponent(String(stateOrProvince)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalOffice: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices/global`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentionableOfficeGroups: async (officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('getMentionableOfficeGroups', 'officeId', officeId)
            const localVarPath = `/api/v1/offices/{officeId}/mentionable-groups`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficeById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfficeById', 'id', id)
            const localVarPath = `/api/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOfficeGroups: async (officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('getOfficeGroups', 'officeId', officeId)
            const localVarPath = `/api/v1/offices/{officeId}/groups`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all offices for a given group ID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficesByGroupId: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getOfficesByGroupId', 'groupId', groupId)
            const localVarPath = `/api/v1/offices/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of users from all default regional offices for a given role
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRegionalOfficeUsersByRole: async (role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getRegionalOfficeUsersByRole', 'role', role)
            const localVarPath = `/api/v1/offices/regional/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of users from default regional office in state for a given role and for given administrative area
         * @param {string} stateOrProvince 
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersFromDefaultOfficeInState: async (stateOrProvince: string, role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stateOrProvince' is not null or undefined
            assertParamExists('getUsersFromDefaultOfficeInState', 'stateOrProvince', stateOrProvince)
            // verify required parameter 'role' is not null or undefined
            assertParamExists('getUsersFromDefaultOfficeInState', 'role', role)
            const localVarPath = `/api/v1/offices/{stateOrProvince}/state-default/{role}`
                .replace(`{${"stateOrProvince"}}`, encodeURIComponent(String(stateOrProvince)))
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {Array<string>} [officeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleGroupsInOffices: async (officeId?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices/visible-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (officeId) {
                localVarQueryParameter['officeId'] = Array.from(officeId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOfficeGroups: async (officeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('getVisibleOfficeGroups', 'officeId', officeId)
            const localVarPath = `/api/v1/offices/{officeId}/visible-groups`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a board from an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBoard: async (id: string, boardId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeBoard', 'id', id)
            // verify required parameter 'boardId' is not null or undefined
            assertParamExists('removeBoard', 'boardId', boardId)
            const localVarPath = `/api/v1/offices/{id}/board/{boardId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"boardId"}}`, encodeURIComponent(String(boardId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a Checklist from an office
         * @param {string} id 
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChecklistDefInfo: async (id: string, checklistId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeChecklistDefInfo', 'id', id)
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('removeChecklistDefInfo', 'checklistId', checklistId)
            const localVarPath = `/api/v1/offices/{id}/checklists/{checklistId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an mls from an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMls: async (id: string, mlsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMls', 'id', id)
            // verify required parameter 'mlsId' is not null or undefined
            assertParamExists('removeMls', 'mlsId', mlsId)
            const localVarPath = `/api/v1/offices/{id}/mls/{mlsId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"mlsId"}}`, encodeURIComponent(String(mlsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for Offices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {Array<string>} [id] 
         * @param {string} [phoneNumber] 
         * @param {Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>} [transactionType] 
         * @param {string} [licenseExpirationDate] 
         * @param {string} [licenseExpirationDateGreaterThanOrEqualTo] 
         * @param {string} [licenseExpirationDateLessThanOrEqualTo] 
         * @param {boolean} [active] 
         * @param {boolean} [isUsingDefaultPaymentBatchName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOffices: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>, searchText?: string, name?: string, id?: Array<string>, phoneNumber?: string, transactionType?: Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>, licenseExpirationDate?: string, licenseExpirationDateGreaterThanOrEqualTo?: string, licenseExpirationDateLessThanOrEqualTo?: string, active?: boolean, isUsingDefaultPaymentBatchName?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id) {
                localVarQueryParameter['id'] = Array.from(id);
            }

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phoneNumber'] = phoneNumber;
            }

            if (transactionType) {
                localVarQueryParameter['transactionType'] = Array.from(transactionType);
            }

            if (licenseExpirationDate !== undefined) {
                localVarQueryParameter['licenseExpirationDate'] = (licenseExpirationDate as any instanceof Date) ?
                    (licenseExpirationDate as any).toISOString().substr(0,10) :
                    licenseExpirationDate;
            }

            if (licenseExpirationDateGreaterThanOrEqualTo !== undefined) {
                localVarQueryParameter['licenseExpirationDateGreaterThanOrEqualTo'] = (licenseExpirationDateGreaterThanOrEqualTo as any instanceof Date) ?
                    (licenseExpirationDateGreaterThanOrEqualTo as any).toISOString().substr(0,10) :
                    licenseExpirationDateGreaterThanOrEqualTo;
            }

            if (licenseExpirationDateLessThanOrEqualTo !== undefined) {
                localVarQueryParameter['licenseExpirationDateLessThanOrEqualTo'] = (licenseExpirationDateLessThanOrEqualTo as any instanceof Date) ?
                    (licenseExpirationDateLessThanOrEqualTo as any).toISOString().substr(0,10) :
                    licenseExpirationDateLessThanOrEqualTo;
            }

            if (active !== undefined) {
                localVarQueryParameter['active'] = active;
            }

            if (isUsingDefaultPaymentBatchName !== undefined) {
                localVarQueryParameter['isUsingDefaultPaymentBatchName'] = isUsingDefaultPaymentBatchName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set an Office\'s default Checklist
         * @param {string} id 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultChecklistDefInfo: async (id: string, checklistDefinitionDto: ChecklistDefinitionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setDefaultChecklistDefInfo', 'id', id)
            // verify required parameter 'checklistDefinitionDto' is not null or undefined
            assertParamExists('setDefaultChecklistDefInfo', 'checklistDefinitionDto', checklistDefinitionDto)
            const localVarPath = `/api/v1/offices/{id}/checklists/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checklistDefinitionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update checklists and journeys info on an office
         * @param {string} officeId 
         * @param {UpdateDefaultChecklistsAndJourneysRequest} updateDefaultChecklistsAndJourneysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistsAndJourneys: async (officeId: string, updateDefaultChecklistsAndJourneysRequest: UpdateDefaultChecklistsAndJourneysRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('updateChecklistsAndJourneys', 'officeId', officeId)
            // verify required parameter 'updateDefaultChecklistsAndJourneysRequest' is not null or undefined
            assertParamExists('updateChecklistsAndJourneys', 'updateDefaultChecklistsAndJourneysRequest', updateDefaultChecklistsAndJourneysRequest)
            const localVarPath = `/api/v1/offices/{officeId}/default-checklists-and-journeys`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDefaultChecklistsAndJourneysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an office
         * @param {string} id 
         * @param {UpdateOfficeRequest} updateOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffice: async (id: string, updateOfficeRequest: UpdateOfficeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOffice', 'id', id)
            // verify required parameter 'updateOfficeRequest' is not null or undefined
            assertParamExists('updateOffice', 'updateOfficeRequest', updateOfficeRequest)
            const localVarPath = `/api/v1/offices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfficeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates office\'s image
         * @param {string} officeId 
         * @param {any} officeImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficeImage: async (officeId: string, officeImage: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'officeId' is not null or undefined
            assertParamExists('updateOfficeImage', 'officeId', officeId)
            // verify required parameter 'officeImage' is not null or undefined
            assertParamExists('updateOfficeImage', 'officeImage', officeImage)
            const localVarPath = `/api/v1/offices/{officeId}/office-image`
                .replace(`{${"officeId"}}`, encodeURIComponent(String(officeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (officeImage !== undefined) { 
                localVarFormParams.append('officeImage', officeImage as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update office voice-settings
         * @param {string} id 
         * @param {UpdateOfficeVoiceSettingsRequest} updateOfficeVoiceSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficeVoiceSettings: async (id: string, updateOfficeVoiceSettingsRequest: UpdateOfficeVoiceSettingsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateOfficeVoiceSettings', 'id', id)
            // verify required parameter 'updateOfficeVoiceSettingsRequest' is not null or undefined
            assertParamExists('updateOfficeVoiceSettings', 'updateOfficeVoiceSettingsRequest', updateOfficeVoiceSettingsRequest)
            const localVarPath = `/api/v1/offices/{id}/voice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOfficeVoiceSettingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfficeControllerApi - functional programming interface
 * @export
 */
export const OfficeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfficeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a board to an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBoard(id: string, boardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBoard(id, boardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a Checklist to an office
         * @param {string} officeId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChecklist(officeId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addChecklist(officeId, checklistDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add an mls to an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMls(id: string, mlsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMls(id, mlsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add pre-defined groups to the office
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDefaultGroupsInOffice(officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDefaultGroupsInOffice(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create dropboxes for each office without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMissingOfficeDropboxes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficeDropboxErrorResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMissingOfficeDropboxes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffice(createOfficeRequest: CreateOfficeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffice(createOfficeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffice1(createOfficeRequest: CreateOfficeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffice1(createOfficeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all default offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDefaultOffices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDefaultOffices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all offices for a given administrative area ID
         * @param {string} administrativeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOfficesInAdminArea(administrativeAreaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficePreviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOfficesInAdminArea(administrativeAreaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Checklists for an Office
         * @param {string} officeId 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'SALE' | 'LEASE'} [dealType] 
         * @param {'BUYER' | 'SELLER' | 'DUAL'} [representee] 
         * @param {Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>} [propertyTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChecklistDefinitionInformationsForOffice(officeId: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', dealType?: 'SALE' | 'LEASE', representee?: 'BUYER' | 'SELLER' | 'DUAL', propertyTypes?: Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChecklistDefinitionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChecklistDefinitionInformationsForOffice(officeId, type, dealType, representee, propertyTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an Office\'s default Checklist
         * @param {string} id 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [targetType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultChecklist(id: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChecklistDefinitionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultChecklist(id, type, targetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get default office in state/province
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultOfficeInState(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultOfficeInState(stateOrProvince, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGlobalOffice(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGlobalOffice(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMentionableOfficeGroups(officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMentionableOfficeGroups(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficeById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficeById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getOfficeGroups(officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficeGroups(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all offices for a given group ID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfficesByGroupId(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfficePreviewResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfficesByGroupId(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of users from all default regional offices for a given role
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getRegionalOfficeUsersByRole(role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgentPublicInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegionalOfficeUsersByRole(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of users from default regional office in state for a given role and for given administrative area
         * @param {string} stateOrProvince 
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersFromDefaultOfficeInState(stateOrProvince: string, role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentPublicInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersFromDefaultOfficeInState(stateOrProvince, role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {Array<string>} [officeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisibleGroupsInOffices(officeId?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisibleGroupsInOffices(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisibleOfficeGroups(officeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisibleOfficeGroups(officeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a board from an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeBoard(id: string, boardId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeBoard(id, boardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a Checklist from an office
         * @param {string} id 
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeChecklistDefInfo(id: string, checklistId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeChecklistDefInfo(id, checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an mls from an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMls(id: string, mlsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMls(id, mlsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for Offices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {Array<string>} [id] 
         * @param {string} [phoneNumber] 
         * @param {Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>} [transactionType] 
         * @param {string} [licenseExpirationDate] 
         * @param {string} [licenseExpirationDateGreaterThanOrEqualTo] 
         * @param {string} [licenseExpirationDateLessThanOrEqualTo] 
         * @param {boolean} [active] 
         * @param {boolean} [isUsingDefaultPaymentBatchName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchOffices(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>, searchText?: string, name?: string, id?: Array<string>, phoneNumber?: string, transactionType?: Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>, licenseExpirationDate?: string, licenseExpirationDateGreaterThanOrEqualTo?: string, licenseExpirationDateLessThanOrEqualTo?: string, active?: boolean, isUsingDefaultPaymentBatchName?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseOfficeSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchOffices(pageNumber, pageSize, sortDirection, sortBy, searchText, name, id, phoneNumber, transactionType, licenseExpirationDate, licenseExpirationDateGreaterThanOrEqualTo, licenseExpirationDateLessThanOrEqualTo, active, isUsingDefaultPaymentBatchName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set an Office\'s default Checklist
         * @param {string} id 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDefaultChecklistDefInfo(id: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDefaultChecklistDefInfo(id, checklistDefinitionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update checklists and journeys info on an office
         * @param {string} officeId 
         * @param {UpdateDefaultChecklistsAndJourneysRequest} updateDefaultChecklistsAndJourneysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateChecklistsAndJourneys(officeId: string, updateDefaultChecklistsAndJourneysRequest: UpdateDefaultChecklistsAndJourneysRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateChecklistsAndJourneys(officeId, updateDefaultChecklistsAndJourneysRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an office
         * @param {string} id 
         * @param {UpdateOfficeRequest} updateOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOffice(id: string, updateOfficeRequest: UpdateOfficeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOffice(id, updateOfficeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates office\'s image
         * @param {string} officeId 
         * @param {any} officeImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfficeImage(officeId: string, officeImage: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfficeImage(officeId, officeImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update office voice-settings
         * @param {string} id 
         * @param {UpdateOfficeVoiceSettingsRequest} updateOfficeVoiceSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOfficeVoiceSettings(id: string, updateOfficeVoiceSettingsRequest: UpdateOfficeVoiceSettingsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfficeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOfficeVoiceSettings(id, updateOfficeVoiceSettingsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfficeControllerApi - factory interface
 * @export
 */
export const OfficeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfficeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a board to an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBoard(id: string, boardId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addBoard(id, boardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a Checklist to an office
         * @param {string} officeId 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChecklist(officeId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.addChecklist(officeId, checklistDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add an mls to an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMls(id: string, mlsId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.addMls(id, mlsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add pre-defined groups to the office
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDefaultGroupsInOffice(officeId: string, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.createDefaultGroupsInOffice(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create dropboxes for each office without one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMissingOfficeDropboxes(options?: any): AxiosPromise<Array<OfficeDropboxErrorResponse>> {
            return localVarFp.createMissingOfficeDropboxes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice(createOfficeRequest: CreateOfficeRequest, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.createOffice(createOfficeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an office
         * @param {CreateOfficeRequest} createOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffice1(createOfficeRequest: CreateOfficeRequest, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.createOffice1(createOfficeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all default offices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDefaultOffices(options?: any): AxiosPromise<Array<OfficeResponse>> {
            return localVarFp.getAllDefaultOffices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all offices for a given administrative area ID
         * @param {string} administrativeAreaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOfficesInAdminArea(administrativeAreaId: string, options?: any): AxiosPromise<Array<OfficePreviewResponse>> {
            return localVarFp.getAllOfficesInAdminArea(administrativeAreaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Checklists for an Office
         * @param {string} officeId 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'SALE' | 'LEASE'} [dealType] 
         * @param {'BUYER' | 'SELLER' | 'DUAL'} [representee] 
         * @param {Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>} [propertyTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChecklistDefinitionInformationsForOffice(officeId: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', dealType?: 'SALE' | 'LEASE', representee?: 'BUYER' | 'SELLER' | 'DUAL', propertyTypes?: Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>, options?: any): AxiosPromise<Array<ChecklistDefinitionDto>> {
            return localVarFp.getChecklistDefinitionInformationsForOffice(officeId, type, dealType, representee, propertyTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Office\'s default Checklist
         * @param {string} id 
         * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
         * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [targetType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultChecklist(id: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', options?: any): AxiosPromise<ChecklistDefinitionDto> {
            return localVarFp.getDefaultChecklist(id, type, targetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get default office in state/province
         * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultOfficeInState(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.getDefaultOfficeInState(stateOrProvince, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get global office
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalOffice(options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.getGlobalOffice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMentionableOfficeGroups(officeId: string, options?: any): AxiosPromise<OfficeGroupResponse> {
            return localVarFp.getMentionableOfficeGroups(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Office by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficeById(id: string, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.getOfficeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get office groups with fallback to regional and global office groups
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getOfficeGroups(officeId: string, options?: any): AxiosPromise<OfficeGroupResponse> {
            return localVarFp.getOfficeGroups(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all offices for a given group ID
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfficesByGroupId(groupId: string, options?: any): AxiosPromise<Array<OfficePreviewResponse>> {
            return localVarFp.getOfficesByGroupId(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of users from all default regional offices for a given role
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getRegionalOfficeUsersByRole(role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: any): AxiosPromise<Array<AgentPublicInfo>> {
            return localVarFp.getRegionalOfficeUsersByRole(role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of users from default regional office in state for a given role and for given administrative area
         * @param {string} stateOrProvince 
         * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersFromDefaultOfficeInState(stateOrProvince: string, role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: any): AxiosPromise<AgentPublicInfosResponse> {
            return localVarFp.getUsersFromDefaultOfficeInState(stateOrProvince, role, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {Array<string>} [officeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleGroupsInOffices(officeId?: Array<string>, options?: any): AxiosPromise<OfficeGroupResponse> {
            return localVarFp.getVisibleGroupsInOffices(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get groups visible to the user
         * @param {string} officeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisibleOfficeGroups(officeId: string, options?: any): AxiosPromise<OfficeGroupResponse> {
            return localVarFp.getVisibleOfficeGroups(officeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a board from an office
         * @param {string} id 
         * @param {string} boardId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBoard(id: string, boardId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeBoard(id, boardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a Checklist from an office
         * @param {string} id 
         * @param {string} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeChecklistDefInfo(id: string, checklistId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeChecklistDefInfo(id, checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an mls from an office
         * @param {string} id 
         * @param {string} mlsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMls(id: string, mlsId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.removeMls(id, mlsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for Offices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>} [sortBy] 
         * @param {string} [searchText] 
         * @param {string} [name] 
         * @param {Array<string>} [id] 
         * @param {string} [phoneNumber] 
         * @param {Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>} [transactionType] 
         * @param {string} [licenseExpirationDate] 
         * @param {string} [licenseExpirationDateGreaterThanOrEqualTo] 
         * @param {string} [licenseExpirationDateLessThanOrEqualTo] 
         * @param {boolean} [active] 
         * @param {boolean} [isUsingDefaultPaymentBatchName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchOffices(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>, searchText?: string, name?: string, id?: Array<string>, phoneNumber?: string, transactionType?: Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>, licenseExpirationDate?: string, licenseExpirationDateGreaterThanOrEqualTo?: string, licenseExpirationDateLessThanOrEqualTo?: string, active?: boolean, isUsingDefaultPaymentBatchName?: boolean, options?: any): AxiosPromise<GenericSearchResponseOfficeSearchResponse> {
            return localVarFp.searchOffices(pageNumber, pageSize, sortDirection, sortBy, searchText, name, id, phoneNumber, transactionType, licenseExpirationDate, licenseExpirationDateGreaterThanOrEqualTo, licenseExpirationDateLessThanOrEqualTo, active, isUsingDefaultPaymentBatchName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set an Office\'s default Checklist
         * @param {string} id 
         * @param {ChecklistDefinitionDto} checklistDefinitionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDefaultChecklistDefInfo(id: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: any): AxiosPromise<void> {
            return localVarFp.setDefaultChecklistDefInfo(id, checklistDefinitionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update checklists and journeys info on an office
         * @param {string} officeId 
         * @param {UpdateDefaultChecklistsAndJourneysRequest} updateDefaultChecklistsAndJourneysRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateChecklistsAndJourneys(officeId: string, updateDefaultChecklistsAndJourneysRequest: UpdateDefaultChecklistsAndJourneysRequest, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.updateChecklistsAndJourneys(officeId, updateDefaultChecklistsAndJourneysRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an office
         * @param {string} id 
         * @param {UpdateOfficeRequest} updateOfficeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffice(id: string, updateOfficeRequest: UpdateOfficeRequest, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.updateOffice(id, updateOfficeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates office\'s image
         * @param {string} officeId 
         * @param {any} officeImage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficeImage(officeId: string, officeImage: any, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.updateOfficeImage(officeId, officeImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update office voice-settings
         * @param {string} id 
         * @param {UpdateOfficeVoiceSettingsRequest} updateOfficeVoiceSettingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOfficeVoiceSettings(id: string, updateOfficeVoiceSettingsRequest: UpdateOfficeVoiceSettingsRequest, options?: any): AxiosPromise<OfficeResponse> {
            return localVarFp.updateOfficeVoiceSettings(id, updateOfficeVoiceSettingsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfficeControllerApi - object-oriented interface
 * @export
 * @class OfficeControllerApi
 * @extends {BaseAPI}
 */
export class OfficeControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a board to an office
     * @param {string} id 
     * @param {string} boardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public addBoard(id: string, boardId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).addBoard(id, boardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a Checklist to an office
     * @param {string} officeId 
     * @param {ChecklistDefinitionDto} checklistDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public addChecklist(officeId: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).addChecklist(officeId, checklistDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add an mls to an office
     * @param {string} id 
     * @param {string} mlsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public addMls(id: string, mlsId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).addMls(id, mlsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add pre-defined groups to the office
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public createDefaultGroupsInOffice(officeId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).createDefaultGroupsInOffice(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create dropboxes for each office without one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public createMissingOfficeDropboxes(options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).createMissingOfficeDropboxes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an office
     * @param {CreateOfficeRequest} createOfficeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public createOffice(createOfficeRequest: CreateOfficeRequest, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).createOffice(createOfficeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an office
     * @param {CreateOfficeRequest} createOfficeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public createOffice1(createOfficeRequest: CreateOfficeRequest, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).createOffice1(createOfficeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all default offices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getAllDefaultOffices(options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getAllDefaultOffices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all offices for a given administrative area ID
     * @param {string} administrativeAreaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getAllOfficesInAdminArea(administrativeAreaId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getAllOfficesInAdminArea(administrativeAreaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Checklists for an Office
     * @param {string} officeId 
     * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
     * @param {'SALE' | 'LEASE'} [dealType] 
     * @param {'BUYER' | 'SELLER' | 'DUAL'} [representee] 
     * @param {Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>} [propertyTypes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getChecklistDefinitionInformationsForOffice(officeId: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', dealType?: 'SALE' | 'LEASE', representee?: 'BUYER' | 'SELLER' | 'DUAL', propertyTypes?: Array<'RESIDENTIAL' | 'COMMERCIAL' | 'LAND_LOT' | 'CONDO' | 'MOBILE_HOME' | 'NEW_CONSTRUCTION'>, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getChecklistDefinitionInformationsForOffice(officeId, type, dealType, representee, propertyTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an Office\'s default Checklist
     * @param {string} id 
     * @param {'TRANSACTION' | 'JOURNEY' | 'CHECKLIST'} type 
     * @param {'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT'} [targetType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getDefaultChecklist(id: string, type: 'TRANSACTION' | 'JOURNEY' | 'CHECKLIST', targetType?: 'TRANSACTION' | 'REFERRAL' | 'INTERNAL_REFERRAL' | 'LISTING' | 'CHECKLIST_ITEM' | 'DROPBOX' | 'REAL' | 'OFFICE' | 'LOAN' | 'AGENT' | 'AGENT_DEBT' | 'BORROWER' | 'MORTGAGE_LEAD' | 'APPLICATION' | 'CHECKLIST' | 'EMAIL' | 'COMMENT' | 'TEAM' | 'OUTGOING_PAYMENT' | 'WALLET' | 'VOICE' | 'MLS' | 'BOARD' | 'USER' | 'CONVERSATION' | 'REFERRAL_CENTRAL' | 'SIGNATURE_DOCUMENT' | 'SIGNATURE_PACKET' | 'USER_AGREEMENT', options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getDefaultChecklist(id, type, targetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get default office in state/province
     * @param {'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN'} stateOrProvince 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getDefaultOfficeInState(stateOrProvince: 'ALABAMA' | 'ALASKA' | 'ARIZONA' | 'ARKANSAS' | 'CALIFORNIA' | 'COLORADO' | 'CONNECTICUT' | 'DELAWARE' | 'DISTRICT_OF_COLUMBIA' | 'FLORIDA' | 'GEORGIA' | 'HAWAII' | 'IDAHO' | 'ILLINOIS' | 'INDIANA' | 'IOWA' | 'KANSAS' | 'KENTUCKY' | 'LOUISIANA' | 'MAINE' | 'MARYLAND' | 'MASSACHUSETTS' | 'MICHIGAN' | 'MINNESOTA' | 'MISSISSIPPI' | 'MISSOURI' | 'MONTANA' | 'NEBRASKA' | 'NEVADA' | 'NEW_HAMPSHIRE' | 'NEW_JERSEY' | 'NEW_MEXICO' | 'NEW_YORK' | 'NORTH_CAROLINA' | 'NORTH_DAKOTA' | 'OHIO' | 'OKLAHOMA' | 'OREGON' | 'PENNSYLVANIA' | 'RHODE_ISLAND' | 'SOUTH_CAROLINA' | 'SOUTH_DAKOTA' | 'TENNESSEE' | 'TEXAS' | 'UTAH' | 'VERMONT' | 'VIRGINIA' | 'WASHINGTON' | 'WEST_VIRGINIA' | 'WISCONSIN' | 'WYOMING' | 'PUERTO_RICO' | 'ALBERTA' | 'BRITISH_COLUMBIA' | 'MANITOBA' | 'NEW_BRUNSWICK' | 'NEWFOUNDLAND_AND_LABRADOR' | 'NOVA_SCOTIA' | 'ONTARIO' | 'PRINCE_EDWARD_ISLAND' | 'QUEBEC' | 'SASKATCHEWAN', options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getDefaultOfficeInState(stateOrProvince, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get global office
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getGlobalOffice(options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getGlobalOffice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get office groups with fallback to regional and global office groups
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getMentionableOfficeGroups(officeId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getMentionableOfficeGroups(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Office by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getOfficeById(id: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getOfficeById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get office groups with fallback to regional and global office groups
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getOfficeGroups(officeId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getOfficeGroups(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all offices for a given group ID
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getOfficesByGroupId(groupId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getOfficesByGroupId(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of users from all default regional offices for a given role
     * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getRegionalOfficeUsersByRole(role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getRegionalOfficeUsersByRole(role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of users from default regional office in state for a given role and for given administrative area
     * @param {string} stateOrProvince 
     * @param {'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE'} role 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getUsersFromDefaultOfficeInState(stateOrProvince: string, role: 'DESIGNATED_BROKER' | 'REGIONAL_BROKER' | 'MANAGING_BROKER' | 'SETTLEMENT_SPECIALIST' | 'FINANCE_SPECIALIST' | 'CONTRACT_SPECIALIST' | 'AGENT_EXPERIENCE' | 'NONE', options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getUsersFromDefaultOfficeInState(stateOrProvince, role, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get groups visible to the user
     * @param {Array<string>} [officeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getVisibleGroupsInOffices(officeId?: Array<string>, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getVisibleGroupsInOffices(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get groups visible to the user
     * @param {string} officeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public getVisibleOfficeGroups(officeId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).getVisibleOfficeGroups(officeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a board from an office
     * @param {string} id 
     * @param {string} boardId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public removeBoard(id: string, boardId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).removeBoard(id, boardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a Checklist from an office
     * @param {string} id 
     * @param {string} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public removeChecklistDefInfo(id: string, checklistId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).removeChecklistDefInfo(id, checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an mls from an office
     * @param {string} id 
     * @param {string} mlsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public removeMls(id: string, mlsId: string, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).removeMls(id, mlsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for Offices
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>} [sortBy] 
     * @param {string} [searchText] 
     * @param {string} [name] 
     * @param {Array<string>} [id] 
     * @param {string} [phoneNumber] 
     * @param {Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>} [transactionType] 
     * @param {string} [licenseExpirationDate] 
     * @param {string} [licenseExpirationDateGreaterThanOrEqualTo] 
     * @param {string} [licenseExpirationDateLessThanOrEqualTo] 
     * @param {boolean} [active] 
     * @param {boolean} [isUsingDefaultPaymentBatchName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public searchOffices(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'TRANSACTION_TYPE' | 'LICENSE_EXPIRATION_DATE' | 'ACTIVE'>, searchText?: string, name?: string, id?: Array<string>, phoneNumber?: string, transactionType?: Array<'SPLIT_CHECK' | 'SINGLE_CHECK'>, licenseExpirationDate?: string, licenseExpirationDateGreaterThanOrEqualTo?: string, licenseExpirationDateLessThanOrEqualTo?: string, active?: boolean, isUsingDefaultPaymentBatchName?: boolean, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).searchOffices(pageNumber, pageSize, sortDirection, sortBy, searchText, name, id, phoneNumber, transactionType, licenseExpirationDate, licenseExpirationDateGreaterThanOrEqualTo, licenseExpirationDateLessThanOrEqualTo, active, isUsingDefaultPaymentBatchName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set an Office\'s default Checklist
     * @param {string} id 
     * @param {ChecklistDefinitionDto} checklistDefinitionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public setDefaultChecklistDefInfo(id: string, checklistDefinitionDto: ChecklistDefinitionDto, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).setDefaultChecklistDefInfo(id, checklistDefinitionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update checklists and journeys info on an office
     * @param {string} officeId 
     * @param {UpdateDefaultChecklistsAndJourneysRequest} updateDefaultChecklistsAndJourneysRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public updateChecklistsAndJourneys(officeId: string, updateDefaultChecklistsAndJourneysRequest: UpdateDefaultChecklistsAndJourneysRequest, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).updateChecklistsAndJourneys(officeId, updateDefaultChecklistsAndJourneysRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an office
     * @param {string} id 
     * @param {UpdateOfficeRequest} updateOfficeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public updateOffice(id: string, updateOfficeRequest: UpdateOfficeRequest, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).updateOffice(id, updateOfficeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates office\'s image
     * @param {string} officeId 
     * @param {any} officeImage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public updateOfficeImage(officeId: string, officeImage: any, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).updateOfficeImage(officeId, officeImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update office voice-settings
     * @param {string} id 
     * @param {UpdateOfficeVoiceSettingsRequest} updateOfficeVoiceSettingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeControllerApi
     */
    public updateOfficeVoiceSettings(id: string, updateOfficeVoiceSettingsRequest: UpdateOfficeVoiceSettingsRequest, options?: AxiosRequestConfig) {
        return OfficeControllerApiFp(this.configuration).updateOfficeVoiceSettings(id, updateOfficeVoiceSettingsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentDetailsControllerApi - axios parameter creator
 * @export
 */
export const PaymentDetailsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Acknowledge the agent\'s tax form for the current tax year
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeTaxForm: async (agentId: string, taxFormId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('acknowledgeTaxForm', 'agentId', agentId)
            // verify required parameter 'taxFormId' is not null or undefined
            assertParamExists('acknowledgeTaxForm', 'taxFormId', taxFormId)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms/{taxFormId}/acknowledgement`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"taxFormId"}}`, encodeURIComponent(String(taxFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive a payment method by MS dynamics code for a given user
         * @param {string} agentId 
         * @param {string} msDynamicsCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePaymentMethod: async (agentId: string, msDynamicsCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('archivePaymentMethod', 'agentId', agentId)
            // verify required parameter 'msDynamicsCode' is not null or undefined
            assertParamExists('archivePaymentMethod', 'msDynamicsCode', msDynamicsCode)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (msDynamicsCode !== undefined) {
                localVarQueryParameter['msDynamicsCode'] = msDynamicsCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePaymentMethod1: async (agentId: string, methodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('archivePaymentMethod1', 'agentId', agentId)
            // verify required parameter 'methodId' is not null or undefined
            assertParamExists('archivePaymentMethod1', 'methodId', methodId)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods/{methodId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"methodId"}}`, encodeURIComponent(String(methodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Archive a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveTaxForm: async (agentId: string, taxFormId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('archiveTaxForm', 'agentId', agentId)
            // verify required parameter 'taxFormId' is not null or undefined
            assertParamExists('archiveTaxForm', 'taxFormId', taxFormId)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms/{taxFormId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"taxFormId"}}`, encodeURIComponent(String(taxFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create many new payment methods for a given user
         * @param {string} agentId 
         * @param {BatchCreatePaymentMethods} batchCreatePaymentMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreatePaymentMethods: async (agentId: string, batchCreatePaymentMethods: BatchCreatePaymentMethods, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('batchCreatePaymentMethods', 'agentId', agentId)
            // verify required parameter 'batchCreatePaymentMethods' is not null or undefined
            assertParamExists('batchCreatePaymentMethods', 'batchCreatePaymentMethods', batchCreatePaymentMethods)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods-batch`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchCreatePaymentMethods, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new payment method for a given user
         * @param {string} agentId 
         * @param {CreatePaymentMethod} createPaymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod: async (agentId: string, createPaymentMethod: CreatePaymentMethod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('createPaymentMethod', 'agentId', agentId)
            // verify required parameter 'createPaymentMethod' is not null or undefined
            assertParamExists('createPaymentMethod', 'createPaymentMethod', createPaymentMethod)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPaymentMethod, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new tax form for a given user
         * @param {string} agentId 
         * @param {CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9} createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9 
         * @param {string} [paymentSettingsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaxForm: async (agentId: string, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9: CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9, paymentSettingsId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('createTaxForm', 'agentId', agentId)
            // verify required parameter 'createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9' is not null or undefined
            assertParamExists('createTaxForm', 'createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9', createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (paymentSettingsId !== undefined) {
                localVarQueryParameter['paymentSettingsId'] = paymentSettingsId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s payable info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayableInfo: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPayableInfo', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/payment-details/payable-info`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agent\'s payment details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDetails: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPaymentDetails', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/payment-details`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get payment method by ID for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod: async (agentId: string, methodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPaymentMethod', 'agentId', agentId)
            // verify required parameter 'methodId' is not null or undefined
            assertParamExists('getPaymentMethod', 'methodId', methodId)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods/{methodId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"methodId"}}`, encodeURIComponent(String(methodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all payment methods for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getPaymentMethods', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if tax form acknowledgment is required for an agent
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFormAcknowledgement: async (agentId: string, taxFormId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getTaxFormAcknowledgement', 'agentId', agentId)
            // verify required parameter 'taxFormId' is not null or undefined
            assertParamExists('getTaxFormAcknowledgement', 'taxFormId', taxFormId)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms/{taxFormId}/acknowledgement`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"taxFormId"}}`, encodeURIComponent(String(taxFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxForms: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getTaxForms', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFormsLite: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getTaxFormsLite', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms-lite`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethod} updatePaymentMethod 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchPaymentMethod: async (agentId: string, methodId: string, updatePaymentMethod: UpdatePaymentMethod, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('patchPaymentMethod', 'agentId', agentId)
            // verify required parameter 'methodId' is not null or undefined
            assertParamExists('patchPaymentMethod', 'methodId', methodId)
            // verify required parameter 'updatePaymentMethod' is not null or undefined
            assertParamExists('patchPaymentMethod', 'updatePaymentMethod', updatePaymentMethod)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods/{methodId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"methodId"}}`, encodeURIComponent(String(methodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentMethod, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9} updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTaxForm: async (agentId: string, taxFormId: string, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9: UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('patchTaxForm', 'agentId', agentId)
            // verify required parameter 'taxFormId' is not null or undefined
            assertParamExists('patchTaxForm', 'taxFormId', taxFormId)
            // verify required parameter 'updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9' is not null or undefined
            assertParamExists('patchTaxForm', 'updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9', updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9)
            const localVarPath = `/api/v1/agents/{agentId}/tax-forms/{taxFormId}`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"taxFormId"}}`, encodeURIComponent(String(taxFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sync an agent with MSDX
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAgent: async (agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('syncAgent', 'agentId', agentId)
            const localVarPath = `/api/v1/agents/{agentId}/sync`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update whether or not an Agent\'s payment details are valid
         * @param {string} agentId 
         * @param {UpdatePaymentDetailsInvalidRequest} updatePaymentDetailsInvalidRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentDetailsInvalid: async (agentId: string, updatePaymentDetailsInvalidRequest: UpdatePaymentDetailsInvalidRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updatePaymentDetailsInvalid', 'agentId', agentId)
            // verify required parameter 'updatePaymentDetailsInvalidRequest' is not null or undefined
            assertParamExists('updatePaymentDetailsInvalid', 'updatePaymentDetailsInvalidRequest', updatePaymentDetailsInvalidRequest)
            const localVarPath = `/api/v1/agents/{agentId}/payment-details/validity`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentDetailsInvalidRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update payment method purpose
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethodPurposeRequest} updatePaymentMethodPurposeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethodPurpose: async (agentId: string, methodId: string, updatePaymentMethodPurposeRequest: UpdatePaymentMethodPurposeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updatePaymentMethodPurpose', 'agentId', agentId)
            // verify required parameter 'methodId' is not null or undefined
            assertParamExists('updatePaymentMethodPurpose', 'methodId', methodId)
            // verify required parameter 'updatePaymentMethodPurposeRequest' is not null or undefined
            assertParamExists('updatePaymentMethodPurpose', 'updatePaymentMethodPurposeRequest', updatePaymentMethodPurposeRequest)
            const localVarPath = `/api/v1/agents/{agentId}/payment-methods/{methodId}/purpose`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)))
                .replace(`{${"methodId"}}`, encodeURIComponent(String(methodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePaymentMethodPurposeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentDetailsControllerApi - functional programming interface
 * @export
 */
export const PaymentDetailsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentDetailsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Acknowledge the agent\'s tax form for the current tax year
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acknowledgeTaxForm(agentId: string, taxFormId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acknowledgeTaxForm(agentId, taxFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive a payment method by MS dynamics code for a given user
         * @param {string} agentId 
         * @param {string} msDynamicsCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archivePaymentMethod(agentId: string, msDynamicsCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archivePaymentMethod(agentId, msDynamicsCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archivePaymentMethod1(agentId: string, methodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archivePaymentMethod1(agentId, methodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Archive a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveTaxForm(agentId: string, taxFormId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveTaxForm(agentId, taxFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create many new payment methods for a given user
         * @param {string} agentId 
         * @param {BatchCreatePaymentMethods} batchCreatePaymentMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async batchCreatePaymentMethods(agentId: string, batchCreatePaymentMethods: BatchCreatePaymentMethods, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchPaymentMethodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.batchCreatePaymentMethods(agentId, batchCreatePaymentMethods, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new payment method for a given user
         * @param {string} agentId 
         * @param {CreatePaymentMethod} createPaymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPaymentMethod(agentId: string, createPaymentMethod: CreatePaymentMethod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentMethod(agentId, createPaymentMethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new tax form for a given user
         * @param {string} agentId 
         * @param {CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9} createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9 
         * @param {string} [paymentSettingsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaxForm(agentId: string, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9: CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9, paymentSettingsId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaxForm(agentId, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9, paymentSettingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s payable info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPayableInfo(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PayableInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPayableInfo(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agent\'s payment details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentDetails(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentDetails(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get payment method by ID for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethod(agentId: string, methodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethod(agentId, methodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all payment methods for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethods(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethods(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if tax form acknowledgment is required for an agent
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxFormAcknowledgement(agentId: string, taxFormId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxFormAcknowledgementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxFormAcknowledgement(agentId, taxFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxForms(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxFormsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxForms(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxFormsLite(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxFormsLiteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxFormsLite(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethod} updatePaymentMethod 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async patchPaymentMethod(agentId: string, methodId: string, updatePaymentMethod: UpdatePaymentMethod, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchPaymentMethod(agentId, methodId, updatePaymentMethod, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9} updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTaxForm(agentId: string, taxFormId: string, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9: UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTaxForm(agentId, taxFormId, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sync an agent with MSDX
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAgent(agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAgent(agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update whether or not an Agent\'s payment details are valid
         * @param {string} agentId 
         * @param {UpdatePaymentDetailsInvalidRequest} updatePaymentDetailsInvalidRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentDetailsInvalid(agentId: string, updatePaymentDetailsInvalidRequest: UpdatePaymentDetailsInvalidRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentDetailsInvalid(agentId, updatePaymentDetailsInvalidRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update payment method purpose
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethodPurposeRequest} updatePaymentMethodPurposeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentMethodPurpose(agentId: string, methodId: string, updatePaymentMethodPurposeRequest: UpdatePaymentMethodPurposeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentMethodPurpose(agentId, methodId, updatePaymentMethodPurposeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentDetailsControllerApi - factory interface
 * @export
 */
export const PaymentDetailsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentDetailsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Acknowledge the agent\'s tax form for the current tax year
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acknowledgeTaxForm(agentId: string, taxFormId: string, options?: any): AxiosPromise<void> {
            return localVarFp.acknowledgeTaxForm(agentId, taxFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive a payment method by MS dynamics code for a given user
         * @param {string} agentId 
         * @param {string} msDynamicsCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePaymentMethod(agentId: string, msDynamicsCode: string, options?: any): AxiosPromise<void> {
            return localVarFp.archivePaymentMethod(agentId, msDynamicsCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archivePaymentMethod1(agentId: string, methodId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archivePaymentMethod1(agentId, methodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Archive a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveTaxForm(agentId: string, taxFormId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveTaxForm(agentId, taxFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create many new payment methods for a given user
         * @param {string} agentId 
         * @param {BatchCreatePaymentMethods} batchCreatePaymentMethods 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCreatePaymentMethods(agentId: string, batchCreatePaymentMethods: BatchCreatePaymentMethods, options?: any): AxiosPromise<BatchPaymentMethodsResponse> {
            return localVarFp.batchCreatePaymentMethods(agentId, batchCreatePaymentMethods, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new payment method for a given user
         * @param {string} agentId 
         * @param {CreatePaymentMethod} createPaymentMethod 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPaymentMethod(agentId: string, createPaymentMethod: CreatePaymentMethod, options?: any): AxiosPromise<CreatePaymentMethodResponse> {
            return localVarFp.createPaymentMethod(agentId, createPaymentMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new tax form for a given user
         * @param {string} agentId 
         * @param {CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9} createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9 
         * @param {string} [paymentSettingsId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaxForm(agentId: string, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9: CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9, paymentSettingsId?: string, options?: any): AxiosPromise<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response> {
            return localVarFp.createTaxForm(agentId, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9, paymentSettingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s payable info
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPayableInfo(agentId: string, options?: any): AxiosPromise<PayableInfoResponse> {
            return localVarFp.getPayableInfo(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agent\'s payment details
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentDetails(agentId: string, options?: any): AxiosPromise<PaymentDetails> {
            return localVarFp.getPaymentDetails(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get payment method by ID for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethod(agentId: string, methodId: string, options?: any): AxiosPromise<PaymentMethodResponse> {
            return localVarFp.getPaymentMethod(agentId, methodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all payment methods for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethods(agentId: string, options?: any): AxiosPromise<PaymentMethodsResponse> {
            return localVarFp.getPaymentMethods(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if tax form acknowledgment is required for an agent
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFormAcknowledgement(agentId: string, taxFormId: string, options?: any): AxiosPromise<TaxFormAcknowledgementResponse> {
            return localVarFp.getTaxFormAcknowledgement(agentId, taxFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxForms(agentId: string, options?: any): AxiosPromise<TaxFormsResponse> {
            return localVarFp.getTaxForms(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all tax forms for a given user
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxFormsLite(agentId: string, options?: any): AxiosPromise<TaxFormsLiteResponse> {
            return localVarFp.getTaxFormsLite(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch a payment method by Id for a given user
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethod} updatePaymentMethod 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        patchPaymentMethod(agentId: string, methodId: string, updatePaymentMethod: UpdatePaymentMethod, options?: any): AxiosPromise<PaymentMethodResponse> {
            return localVarFp.patchPaymentMethod(agentId, methodId, updatePaymentMethod, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch a tax form by Id for a given user
         * @param {string} agentId 
         * @param {string} taxFormId 
         * @param {UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9} updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTaxForm(agentId: string, taxFormId: string, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9: UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9, options?: any): AxiosPromise<TaxFormCanadaResponse | TaxFormLegacyResponse | TaxFormW8BENResponse | TaxFormW8ECIResponse | TaxFormW9Response> {
            return localVarFp.patchTaxForm(agentId, taxFormId, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sync an agent with MSDX
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAgent(agentId: string, options?: any): AxiosPromise<PaymentDetails> {
            return localVarFp.syncAgent(agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update whether or not an Agent\'s payment details are valid
         * @param {string} agentId 
         * @param {UpdatePaymentDetailsInvalidRequest} updatePaymentDetailsInvalidRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentDetailsInvalid(agentId: string, updatePaymentDetailsInvalidRequest: UpdatePaymentDetailsInvalidRequest, options?: any): AxiosPromise<PaymentDetails> {
            return localVarFp.updatePaymentDetailsInvalid(agentId, updatePaymentDetailsInvalidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update payment method purpose
         * @param {string} agentId 
         * @param {string} methodId 
         * @param {UpdatePaymentMethodPurposeRequest} updatePaymentMethodPurposeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentMethodPurpose(agentId: string, methodId: string, updatePaymentMethodPurposeRequest: UpdatePaymentMethodPurposeRequest, options?: any): AxiosPromise<PaymentMethodResponse> {
            return localVarFp.updatePaymentMethodPurpose(agentId, methodId, updatePaymentMethodPurposeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentDetailsControllerApi - object-oriented interface
 * @export
 * @class PaymentDetailsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentDetailsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Acknowledge the agent\'s tax form for the current tax year
     * @param {string} agentId 
     * @param {string} taxFormId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public acknowledgeTaxForm(agentId: string, taxFormId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).acknowledgeTaxForm(agentId, taxFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive a payment method by MS dynamics code for a given user
     * @param {string} agentId 
     * @param {string} msDynamicsCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public archivePaymentMethod(agentId: string, msDynamicsCode: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).archivePaymentMethod(agentId, msDynamicsCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive a payment method by Id for a given user
     * @param {string} agentId 
     * @param {string} methodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public archivePaymentMethod1(agentId: string, methodId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).archivePaymentMethod1(agentId, methodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Archive a tax form by Id for a given user
     * @param {string} agentId 
     * @param {string} taxFormId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public archiveTaxForm(agentId: string, taxFormId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).archiveTaxForm(agentId, taxFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create many new payment methods for a given user
     * @param {string} agentId 
     * @param {BatchCreatePaymentMethods} batchCreatePaymentMethods 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public batchCreatePaymentMethods(agentId: string, batchCreatePaymentMethods: BatchCreatePaymentMethods, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).batchCreatePaymentMethods(agentId, batchCreatePaymentMethods, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new payment method for a given user
     * @param {string} agentId 
     * @param {CreatePaymentMethod} createPaymentMethod 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public createPaymentMethod(agentId: string, createPaymentMethod: CreatePaymentMethod, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).createPaymentMethod(agentId, createPaymentMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new tax form for a given user
     * @param {string} agentId 
     * @param {CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9} createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9 
     * @param {string} [paymentSettingsId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public createTaxForm(agentId: string, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9: CreateTaxFormCanada | CreateTaxFormW8BEN | CreateTaxFormW8ECI | CreateTaxFormW9, paymentSettingsId?: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).createTaxForm(agentId, createTaxFormCanadaCreateTaxFormW8BENCreateTaxFormW8ECICreateTaxFormW9, paymentSettingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s payable info
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getPayableInfo(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getPayableInfo(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agent\'s payment details
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getPaymentDetails(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getPaymentDetails(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get payment method by ID for a given user
     * @param {string} agentId 
     * @param {string} methodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getPaymentMethod(agentId: string, methodId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getPaymentMethod(agentId, methodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all payment methods for a given user
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getPaymentMethods(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getPaymentMethods(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if tax form acknowledgment is required for an agent
     * @param {string} agentId 
     * @param {string} taxFormId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getTaxFormAcknowledgement(agentId: string, taxFormId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getTaxFormAcknowledgement(agentId, taxFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tax forms for a given user
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getTaxForms(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getTaxForms(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all tax forms for a given user
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public getTaxFormsLite(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).getTaxFormsLite(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch a payment method by Id for a given user
     * @param {string} agentId 
     * @param {string} methodId 
     * @param {UpdatePaymentMethod} updatePaymentMethod 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public patchPaymentMethod(agentId: string, methodId: string, updatePaymentMethod: UpdatePaymentMethod, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).patchPaymentMethod(agentId, methodId, updatePaymentMethod, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch a tax form by Id for a given user
     * @param {string} agentId 
     * @param {string} taxFormId 
     * @param {UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9} updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public patchTaxForm(agentId: string, taxFormId: string, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9: UpdateTaxFormCanada | UpdateTaxFormW8BEN | UpdateTaxFormW8ECI | UpdateTaxFormW9, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).patchTaxForm(agentId, taxFormId, updateTaxFormCanadaUpdateTaxFormW8BENUpdateTaxFormW8ECIUpdateTaxFormW9, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sync an agent with MSDX
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public syncAgent(agentId: string, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).syncAgent(agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update whether or not an Agent\'s payment details are valid
     * @param {string} agentId 
     * @param {UpdatePaymentDetailsInvalidRequest} updatePaymentDetailsInvalidRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public updatePaymentDetailsInvalid(agentId: string, updatePaymentDetailsInvalidRequest: UpdatePaymentDetailsInvalidRequest, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).updatePaymentDetailsInvalid(agentId, updatePaymentDetailsInvalidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update payment method purpose
     * @param {string} agentId 
     * @param {string} methodId 
     * @param {UpdatePaymentMethodPurposeRequest} updatePaymentMethodPurposeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentDetailsControllerApi
     */
    public updatePaymentMethodPurpose(agentId: string, methodId: string, updatePaymentMethodPurposeRequest: UpdatePaymentMethodPurposeRequest, options?: AxiosRequestConfig) {
        return PaymentDetailsControllerApiFp(this.configuration).updatePaymentMethodPurpose(agentId, methodId, updatePaymentMethodPurposeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentSettingsControllerApi - axios parameter creator
 * @export
 */
export const PaymentSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search payment settings history with the given criteria
         * @param {string} agentId 
         * @param {Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>} sortBy 
         * @param {'ASC' | 'DESC'} sortDirection 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPaymentSettings: async (agentId: string, sortBy: Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>, sortDirection: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('searchPaymentSettings', 'agentId', agentId)
            // verify required parameter 'sortBy' is not null or undefined
            assertParamExists('searchPaymentSettings', 'sortBy', sortBy)
            // verify required parameter 'sortDirection' is not null or undefined
            assertParamExists('searchPaymentSettings', 'sortDirection', sortDirection)
            const localVarPath = `/api/v1/agents/{agentId}/payment-settings/history`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentSettingsControllerApi - functional programming interface
 * @export
 */
export const PaymentSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search payment settings history with the given criteria
         * @param {string} agentId 
         * @param {Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>} sortBy 
         * @param {'ASC' | 'DESC'} sortDirection 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPaymentSettings(agentId: string, sortBy: Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>, sortDirection: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponsePaymentSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPaymentSettings(agentId, sortBy, sortDirection, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentSettingsControllerApi - factory interface
 * @export
 */
export const PaymentSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search payment settings history with the given criteria
         * @param {string} agentId 
         * @param {Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>} sortBy 
         * @param {'ASC' | 'DESC'} sortDirection 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPaymentSettings(agentId: string, sortBy: Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>, sortDirection: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<GenericSearchResponsePaymentSettingsResponse> {
            return localVarFp.searchPaymentSettings(agentId, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentSettingsControllerApi - object-oriented interface
 * @export
 * @class PaymentSettingsControllerApi
 * @extends {BaseAPI}
 */
export class PaymentSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search payment settings history with the given criteria
     * @param {string} agentId 
     * @param {Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>} sortBy 
     * @param {'ASC' | 'DESC'} sortDirection 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentSettingsControllerApi
     */
    public searchPaymentSettings(agentId: string, sortBy: Array<'ID' | 'CREATED_AT' | 'PAYMENT_METHOD'>, sortDirection: 'ASC' | 'DESC', pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return PaymentSettingsControllerApiFp(this.configuration).searchPaymentSettings(agentId, sortBy, sortDirection, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PromotionCodeControllerApi - axios parameter creator
 * @export
 */
export const PromotionCodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a promotion code
         * @param {PromotionCodeCreateRequest} promotionCodeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromotionCode: async (promotionCodeCreateRequest: PromotionCodeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionCodeCreateRequest' is not null or undefined
            assertParamExists('createPromotionCode', 'promotionCodeCreateRequest', promotionCodeCreateRequest)
            const localVarPath = `/api/v1/coupons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionCodeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the promotion code obj given the code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePromotionCode: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('retrievePromotionCode', 'code', code)
            const localVarPath = `/api/v1/coupons/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionCodeControllerApi - functional programming interface
 * @export
 */
export const PromotionCodeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromotionCodeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a promotion code
         * @param {PromotionCodeCreateRequest} promotionCodeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPromotionCode(promotionCodeCreateRequest: PromotionCodeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPromotionCode(promotionCodeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the promotion code obj given the code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrievePromotionCode(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromotionCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePromotionCode(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromotionCodeControllerApi - factory interface
 * @export
 */
export const PromotionCodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromotionCodeControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a promotion code
         * @param {PromotionCodeCreateRequest} promotionCodeCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromotionCode(promotionCodeCreateRequest: PromotionCodeCreateRequest, options?: any): AxiosPromise<PromotionCodeResponse> {
            return localVarFp.createPromotionCode(promotionCodeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the promotion code obj given the code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePromotionCode(code: string, options?: any): AxiosPromise<PromotionCodeResponse> {
            return localVarFp.retrievePromotionCode(code, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromotionCodeControllerApi - object-oriented interface
 * @export
 * @class PromotionCodeControllerApi
 * @extends {BaseAPI}
 */
export class PromotionCodeControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a promotion code
     * @param {PromotionCodeCreateRequest} promotionCodeCreateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionCodeControllerApi
     */
    public createPromotionCode(promotionCodeCreateRequest: PromotionCodeCreateRequest, options?: AxiosRequestConfig) {
        return PromotionCodeControllerApiFp(this.configuration).createPromotionCode(promotionCodeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the promotion code obj given the code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionCodeControllerApi
     */
    public retrievePromotionCode(code: string, options?: AxiosRequestConfig) {
        return PromotionCodeControllerApiFp(this.configuration).retrievePromotionCode(code, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicControllerApi - axios parameter creator
 * @export
 */
export const PublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get paginated list of license state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsPublicInfoByLicenseStatePaginated: async (administrativeAreaId: string, pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'administrativeAreaId' is not null or undefined
            assertParamExists('getAgentsPublicInfoByLicenseStatePaginated', 'administrativeAreaId', administrativeAreaId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAgentsPublicInfoByLicenseStatePaginated', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAgentsPublicInfoByLicenseStatePaginated', 'pageSize', pageSize)
            const localVarPath = `/api/v1/public/agents-public-info-by-license-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (administrativeAreaId !== undefined) {
                localVarQueryParameter['administrativeAreaId'] = administrativeAreaId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get paginated list of state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsPublicInfoByStatePaginated: async (administrativeAreaId: string, pageNumber: number, pageSize: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'administrativeAreaId' is not null or undefined
            assertParamExists('getAgentsPublicInfoByStatePaginated', 'administrativeAreaId', administrativeAreaId)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAgentsPublicInfoByStatePaginated', 'pageNumber', pageNumber)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAgentsPublicInfoByStatePaginated', 'pageSize', pageSize)
            const localVarPath = `/api/v1/public/agents-public-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (administrativeAreaId !== undefined) {
                localVarQueryParameter['administrativeAreaId'] = administrativeAreaId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connection Invite Basic Info for Populating Signup Page
         * @param {string} connectionIdentityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConnectionBasicInfo: async (connectionIdentityId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionIdentityId' is not null or undefined
            assertParamExists('getExternalConnectionBasicInfo', 'connectionIdentityId', connectionIdentityId)
            const localVarPath = `/api/v1/public/connections/external/{connectionIdentityId}/basic-info`
                .replace(`{${"connectionIdentityId"}}`, encodeURIComponent(String(connectionIdentityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/public/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply to be Real User
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSignup: async (signUpRequest: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('userSignup', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/v1/public/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicControllerApi - functional programming interface
 * @export
 */
export const PublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get paginated list of license state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentPublicInfoPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get paginated list of state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgentsPublicInfoByStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentPublicInfoPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgentsPublicInfoByStatePaginated(administrativeAreaId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Connection Invite Basic Info for Populating Signup Page
         * @param {string} connectionIdentityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExternalConnectionBasicInfo(connectionIdentityId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalConnectionRequestBasicInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExternalConnectionBasicInfo(connectionIdentityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply to be Real User
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSignup(signUpRequest: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSignup(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicControllerApi - factory interface
 * @export
 */
export const PublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get paginated list of license state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<AgentPublicInfoPaginatedResponse> {
            return localVarFp.getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get paginated list of state-wise agents with their license link
         * @param {string} administrativeAreaId 
         * @param {number} pageNumber 
         * @param {number} pageSize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgentsPublicInfoByStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: any): AxiosPromise<AgentPublicInfoPaginatedResponse> {
            return localVarFp.getAgentsPublicInfoByStatePaginated(administrativeAreaId, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connection Invite Basic Info for Populating Signup Page
         * @param {string} connectionIdentityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExternalConnectionBasicInfo(connectionIdentityId: string, options?: any): AxiosPromise<ExternalConnectionRequestBasicInfoResponse> {
            return localVarFp.getExternalConnectionBasicInfo(connectionIdentityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage2(options?: any): AxiosPromise<string> {
            return localVarFp.getMessage2(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply to be Real User
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSignup(signUpRequest: SignUpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.userSignup(signUpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicControllerApi - object-oriented interface
 * @export
 * @class PublicControllerApi
 * @extends {BaseAPI}
 */
export class PublicControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get paginated list of license state-wise agents with their license link
     * @param {string} administrativeAreaId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getAgentsPublicInfoByLicenseStatePaginated(administrativeAreaId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get paginated list of state-wise agents with their license link
     * @param {string} administrativeAreaId 
     * @param {number} pageNumber 
     * @param {number} pageSize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getAgentsPublicInfoByStatePaginated(administrativeAreaId: string, pageNumber: number, pageSize: number, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getAgentsPublicInfoByStatePaginated(administrativeAreaId, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connection Invite Basic Info for Populating Signup Page
     * @param {string} connectionIdentityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getExternalConnectionBasicInfo(connectionIdentityId: string, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getExternalConnectionBasicInfo(connectionIdentityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public getMessage2(options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).getMessage2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply to be Real User
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicControllerApi
     */
    public userSignup(signUpRequest: SignUpRequest, options?: AxiosRequestConfig) {
        return PublicControllerApiFp(this.configuration).userSignup(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PushNotificationControllerApi - axios parameter creator
 * @export
 */
export const PushNotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/pushNotifications/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId 
         * @param {'REZEN' | 'ONE_REAL'} [clientApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (pushRegistrationId: string, clientApplication?: 'REZEN' | 'ONE_REAL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushRegistrationId' is not null or undefined
            assertParamExists('register', 'pushRegistrationId', pushRegistrationId)
            const localVarPath = `/api/v1/pushNotifications/{pushRegistrationId}`
                .replace(`{${"pushRegistrationId"}}`, encodeURIComponent(String(pushRegistrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientApplication !== undefined) {
                localVarQueryParameter['clientApplication'] = clientApplication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushNotificationControllerApi - functional programming interface
 * @export
 */
export const PushNotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushNotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId 
         * @param {'REZEN' | 'ONE_REAL'} [clientApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(pushRegistrationId: string, clientApplication?: 'REZEN' | 'ONE_REAL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegistrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(pushRegistrationId, clientApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushNotificationControllerApi - factory interface
 * @export
 */
export const PushNotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushNotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage1(options?: any): AxiosPromise<string> {
            return localVarFp.getMessage1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register a push notification token for a particular user
         * @param {string} pushRegistrationId 
         * @param {'REZEN' | 'ONE_REAL'} [clientApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(pushRegistrationId: string, clientApplication?: 'REZEN' | 'ONE_REAL', options?: any): AxiosPromise<RegistrationResponse> {
            return localVarFp.register(pushRegistrationId, clientApplication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushNotificationControllerApi - object-oriented interface
 * @export
 * @class PushNotificationControllerApi
 * @extends {BaseAPI}
 */
export class PushNotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get secured message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public getMessage1(options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).getMessage1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register a push notification token for a particular user
     * @param {string} pushRegistrationId 
     * @param {'REZEN' | 'ONE_REAL'} [clientApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushNotificationControllerApi
     */
    public register(pushRegistrationId: string, clientApplication?: 'REZEN' | 'ONE_REAL', options?: AxiosRequestConfig) {
        return PushNotificationControllerApiFp(this.configuration).register(pushRegistrationId, clientApplication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecuredControllerApi - axios parameter creator
 * @export
 */
export const SecuredControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/secured/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecuredControllerApi - functional programming interface
 * @export
 */
export const SecuredControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecuredControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecuredControllerApi - factory interface
 * @export
 */
export const SecuredControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecuredControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get secured message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage(options?: any): AxiosPromise<string> {
            return localVarFp.getMessage(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecuredControllerApi - object-oriented interface
 * @export
 * @class SecuredControllerApi
 * @extends {BaseAPI}
 */
export class SecuredControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get secured message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecuredControllerApi
     */
    public getMessage(options?: AxiosRequestConfig) {
        return SecuredControllerApiFp(this.configuration).getMessage(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamControllerApi - axios parameter creator
 * @export
 */
export const TeamControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id 
         * @param {AddDomesticTeamMemberRequest} addDomesticTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToDomesticTeam: async (id: string, addDomesticTeamMemberRequest: AddDomesticTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToDomesticTeam', 'id', id)
            // verify required parameter 'addDomesticTeamMemberRequest' is not null or undefined
            assertParamExists('addMemberToDomesticTeam', 'addDomesticTeamMemberRequest', addDomesticTeamMemberRequest)
            const localVarPath = `/api/v1/teams/domestic/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDomesticTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id 
         * @param {AddNormalTeamMemberRequest} addNormalTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToNormalTeam: async (id: string, addNormalTeamMemberRequest: AddNormalTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToNormalTeam', 'id', id)
            // verify required parameter 'addNormalTeamMemberRequest' is not null or undefined
            assertParamExists('addMemberToNormalTeam', 'addNormalTeamMemberRequest', addNormalTeamMemberRequest)
            const localVarPath = `/api/v1/teams/normal/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addNormalTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id 
         * @param {AddPlatinumTeamMemberRequest} addPlatinumTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToPlatinumTeam: async (id: string, addPlatinumTeamMemberRequest: AddPlatinumTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToPlatinumTeam', 'id', id)
            // verify required parameter 'addPlatinumTeamMemberRequest' is not null or undefined
            assertParamExists('addMemberToPlatinumTeam', 'addPlatinumTeamMemberRequest', addPlatinumTeamMemberRequest)
            const localVarPath = `/api/v1/teams/platinum/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addPlatinumTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a member to an existing team
         * @param {string} id 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToTeam: async (id: string, addTeamMemberRequest: AddTeamMemberRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMemberToTeam', 'id', id)
            // verify required parameter 'addTeamMemberRequest' is not null or undefined
            assertParamExists('addMemberToTeam', 'addTeamMemberRequest', addTeamMemberRequest)
            const localVarPath = `/api/v1/teams/{id}/member`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTeamMemberRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} createDomesticTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomesticTeam: async (createDomesticTeamRequest: CreateDomesticTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDomesticTeamRequest' is not null or undefined
            assertParamExists('createDomesticTeam', 'createDomesticTeamRequest', createDomesticTeamRequest)
            const localVarPath = `/api/v1/teams/domestic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDomesticTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} createNormalTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalTeam: async (createNormalTeamRequest: CreateNormalTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNormalTeamRequest' is not null or undefined
            assertParamExists('createNormalTeam', 'createNormalTeamRequest', createNormalTeamRequest)
            const localVarPath = `/api/v1/teams/normal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNormalTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} createPlatinumTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlatinumTeam: async (createPlatinumTeamRequest: CreatePlatinumTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPlatinumTeamRequest' is not null or undefined
            assertParamExists('createPlatinumTeam', 'createPlatinumTeamRequest', createPlatinumTeamRequest)
            const localVarPath = `/api/v1/teams/platinum`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPlatinumTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a team
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam: async (createTeamRequest: CreateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTeamRequest' is not null or undefined
            assertParamExists('createTeam', 'createTeamRequest', createTeamRequest)
            const localVarPath = `/api/v1/teams/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary invite an agent to join a team
         * @param {string} teamId 
         * @param {CreateTeamInvitationRequest} createTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamInvitation: async (teamId: string, createTeamInvitationRequest: CreateTeamInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('createTeamInvitation', 'teamId', teamId)
            // verify required parameter 'createTeamInvitationRequest' is not null or undefined
            assertParamExists('createTeamInvitation', 'createTeamInvitationRequest', createTeamInvitationRequest)
            const localVarPath = `/api/v1/teams/{teamId}/invitation`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTeamInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generate an generic invitation
         * @param {string} teamId 
         * @param {CreateGenericTeamInvitationRequestDto} createGenericTeamInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGenericInvitation: async (teamId: string, createGenericTeamInvitationRequestDto: CreateGenericTeamInvitationRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('generateGenericInvitation', 'teamId', teamId)
            // verify required parameter 'createGenericTeamInvitationRequestDto' is not null or undefined
            assertParamExists('generateGenericInvitation', 'createGenericTeamInvitationRequestDto', createGenericTeamInvitationRequestDto)
            const localVarPath = `/api/v1/teams/{teamId}/generic-link/generate`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGenericTeamInvitationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamById', 'id', id)
            const localVarPath = `/api/v1/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team by id without agents
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamByIdWithoutAgents: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamByIdWithoutAgents', 'id', id)
            const localVarPath = `/api/v1/teams/{id}/without-agents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team leaders by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamLeaders: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamLeaders', 'id', id)
            const localVarPath = `/api/v1/teams/{id}/leaders`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team leaders and admins by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamLeadersAndAdmins: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamLeadersAndAdmins', 'id', id)
            const localVarPath = `/api/v1/teams/{id}/leaders-admins`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get team permissions for approver on teamOwner\'s teams
         * @param {string} teamOwnerId 
         * @param {string} approverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPermissionsForApprover: async (teamOwnerId: string, approverId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamOwnerId' is not null or undefined
            assertParamExists('getTeamPermissionsForApprover', 'teamOwnerId', teamOwnerId)
            // verify required parameter 'approverId' is not null or undefined
            assertParamExists('getTeamPermissionsForApprover', 'approverId', approverId)
            const localVarPath = `/api/v1/teams/permissions/{teamOwnerId}/{approverId}`
                .replace(`{${"teamOwnerId"}}`, encodeURIComponent(String(teamOwnerId)))
                .replace(`{${"approverId"}}`, encodeURIComponent(String(approverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get multiple teams by ids
         * @param {BatchTeamGetRequest} batchTeamGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByIdsBatch: async (batchTeamGetRequest: BatchTeamGetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchTeamGetRequest' is not null or undefined
            assertParamExists('getTeamsByIdsBatch', 'batchTeamGetRequest', batchTeamGetRequest)
            const localVarPath = `/api/v1/teams/batch-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchTeamGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Designate a team as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateTeam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inactivateTeam', 'id', id)
            const localVarPath = `/api/v1/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary redeem an generic invitation
         * @param {RedeemGenericTeamInvitationRequest} redeemGenericTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemGenericInvitation: async (redeemGenericTeamInvitationRequest: RedeemGenericTeamInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redeemGenericTeamInvitationRequest' is not null or undefined
            assertParamExists('redeemGenericInvitation', 'redeemGenericTeamInvitationRequest', redeemGenericTeamInvitationRequest)
            const localVarPath = `/api/v1/teams/generic-link/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redeemGenericTeamInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary redeem a team invitation for approved agent
         * @param {RedeemTeamInvitationRequest} redeemTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemTeamInvitation: async (redeemTeamInvitationRequest: RedeemTeamInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'redeemTeamInvitationRequest' is not null or undefined
            assertParamExists('redeemTeamInvitation', 'redeemTeamInvitationRequest', redeemTeamInvitationRequest)
            const localVarPath = `/api/v1/teams/invitations/redeem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redeemTeamInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} id 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMember: async (id: string, agentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMember', 'id', id)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('removeMember', 'agentId', agentId)
            const localVarPath = `/api/v1/teams/{id}/members/{agentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search teams given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO'} [teamType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', createdAtStart?: string, createdAtEnd?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (createdAtStart !== undefined) {
                localVarQueryParameter['createdAtStart'] = (createdAtStart as any instanceof Date) ?
                    (createdAtStart as any).toISOString().substr(0,10) :
                    createdAtStart;
            }

            if (createdAtEnd !== undefined) {
                localVarQueryParameter['createdAtEnd'] = (createdAtEnd as any instanceof Date) ?
                    (createdAtEnd as any).toISOString().substr(0,10) :
                    createdAtEnd;
            }

            if (teamType !== undefined) {
                localVarQueryParameter['teamType'] = teamType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer an agent between teams
         * @param {TransferAgentBetweenTeamsRequest} transferAgentBetweenTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferAgentBetweenTeams: async (transferAgentBetweenTeamsRequest: TransferAgentBetweenTeamsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transferAgentBetweenTeamsRequest' is not null or undefined
            assertParamExists('transferAgentBetweenTeams', 'transferAgentBetweenTeamsRequest', transferAgentBetweenTeamsRequest)
            const localVarPath = `/api/v1/teams/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transferAgentBetweenTeamsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInNormalTeam: async (teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateMemberInNormalTeam', 'teamId', teamId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateMemberInNormalTeam', 'agentId', agentId)
            // verify required parameter 'updateTeamMemberCommissionSplitRequest' is not null or undefined
            assertParamExists('updateMemberInNormalTeam', 'updateTeamMemberCommissionSplitRequest', updateTeamMemberCommissionSplitRequest)
            const localVarPath = `/api/v1/teams/normal/{teamId}/members/{agentId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMemberCommissionSplitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInPlatinumTeam: async (teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'teamId' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeam', 'teamId', teamId)
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeam', 'agentId', agentId)
            // verify required parameter 'updateTeamMemberCommissionSplitRequest' is not null or undefined
            assertParamExists('updateMemberInPlatinumTeam', 'updateTeamMemberCommissionSplitRequest', updateTeamMemberCommissionSplitRequest)
            const localVarPath = `/api/v1/teams/platinum/{teamId}/members/{agentId}`
                .replace(`{${"teamId"}}`, encodeURIComponent(String(teamId)))
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMemberCommissionSplitRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam: async (id: string, updateTeamRequest: UpdateTeamRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeam', 'id', id)
            // verify required parameter 'updateTeamRequest' is not null or undefined
            assertParamExists('updateTeam', 'updateTeamRequest', updateTeamRequest)
            const localVarPath = `/api/v1/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team\'s instant payment eligibility by id
         * @param {string} id 
         * @param {UpdateTeamInstantPaymentEligibleRequest} updateTeamInstantPaymentEligibleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateTeamInstantPaymentEligible: async (id: string, updateTeamInstantPaymentEligibleRequest: UpdateTeamInstantPaymentEligibleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamInstantPaymentEligible', 'id', id)
            // verify required parameter 'updateTeamInstantPaymentEligibleRequest' is not null or undefined
            assertParamExists('updateTeamInstantPaymentEligible', 'updateTeamInstantPaymentEligibleRequest', updateTeamInstantPaymentEligibleRequest)
            const localVarPath = `/api/v1/teams/{id}/instant-payment-eligible`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamInstantPaymentEligibleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update an invitation
         * @param {string} invitationId 
         * @param {PatchTeamInvitationRequest} patchTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamInvitation: async (invitationId: string, patchTeamInvitationRequest: PatchTeamInvitationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('updateTeamInvitation', 'invitationId', invitationId)
            // verify required parameter 'patchTeamInvitationRequest' is not null or undefined
            assertParamExists('updateTeamInvitation', 'patchTeamInvitationRequest', patchTeamInvitationRequest)
            const localVarPath = `/api/v1/teams/invitation/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTeamInvitationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team members by id
         * @param {string} id 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamMembers: async (id: string, updateTeamMembersRequest: UpdateTeamMembersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamMembers', 'id', id)
            // verify required parameter 'updateTeamMembersRequest' is not null or undefined
            assertParamExists('updateTeamMembers', 'updateTeamMembersRequest', updateTeamMembersRequest)
            const localVarPath = `/api/v1/teams/{id}/members`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMembersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update team\'s \'paid at closing\' status by id
         * @param {string} id 
         * @param {UpdateTeamPaidAtClosingRequest} updateTeamPaidAtClosingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamPaidAtClosing: async (id: string, updateTeamPaidAtClosingRequest: UpdateTeamPaidAtClosingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTeamPaidAtClosing', 'id', id)
            // verify required parameter 'updateTeamPaidAtClosingRequest' is not null or undefined
            assertParamExists('updateTeamPaidAtClosing', 'updateTeamPaidAtClosingRequest', updateTeamPaidAtClosingRequest)
            const localVarPath = `/api/v1/teams/{id}/paid-at-closing`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamPaidAtClosingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamControllerApi - functional programming interface
 * @export
 */
export const TeamControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id 
         * @param {AddDomesticTeamMemberRequest} addDomesticTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToDomesticTeam(id: string, addDomesticTeamMemberRequest: AddDomesticTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToDomesticTeam(id, addDomesticTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id 
         * @param {AddNormalTeamMemberRequest} addNormalTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToNormalTeam(id: string, addNormalTeamMemberRequest: AddNormalTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToNormalTeam(id, addNormalTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id 
         * @param {AddPlatinumTeamMemberRequest} addPlatinumTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToPlatinumTeam(id: string, addPlatinumTeamMemberRequest: AddPlatinumTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToPlatinumTeam(id, addPlatinumTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a member to an existing team
         * @param {string} id 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMemberToTeam(id: string, addTeamMemberRequest: AddTeamMemberRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addMemberToTeam(id, addTeamMemberRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} createDomesticTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDomesticTeam(createDomesticTeamRequest: CreateDomesticTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDomesticTeam(createDomesticTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} createNormalTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNormalTeam(createNormalTeamRequest: CreateNormalTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNormalTeam(createNormalTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} createPlatinumTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlatinumTeam(createPlatinumTeamRequest: CreatePlatinumTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlatinumTeam(createPlatinumTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a team
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeam(createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(createTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary invite an agent to join a team
         * @param {string} teamId 
         * @param {CreateTeamInvitationRequest} createTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTeamInvitation(teamId: string, createTeamInvitationRequest: CreateTeamInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTeamInvitation(teamId, createTeamInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary generate an generic invitation
         * @param {string} teamId 
         * @param {CreateGenericTeamInvitationRequestDto} createGenericTeamInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateGenericInvitation(teamId: string, createGenericTeamInvitationRequestDto: CreateGenericTeamInvitationRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericTeamInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateGenericInvitation(teamId, createGenericTeamInvitationRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team by id without agents
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamByIdWithoutAgents(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamWithoutAgentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamByIdWithoutAgents(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team leaders by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamLeaders(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamLeadersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamLeaders(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team leaders and admins by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamLeadersAndAdmins(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamLeadersAndAdminsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamLeadersAndAdmins(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get team permissions for approver on teamOwner\'s teams
         * @param {string} teamOwnerId 
         * @param {string} approverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamPermissionsForApprover(teamOwnerId: string, approverId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamPermissionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamPermissionsForApprover(teamOwnerId, approverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get multiple teams by ids
         * @param {BatchTeamGetRequest} batchTeamGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamsByIdsBatch(batchTeamGetRequest: BatchTeamGetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BatchTeamGetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamsByIdsBatch(batchTeamGetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Designate a team as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inactivateTeam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inactivateTeam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary redeem an generic invitation
         * @param {RedeemGenericTeamInvitationRequest} redeemGenericTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemGenericInvitation(redeemGenericTeamInvitationRequest: RedeemGenericTeamInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemGenericInvitation(redeemGenericTeamInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary redeem a team invitation for approved agent
         * @param {RedeemTeamInvitationRequest} redeemTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redeemTeamInvitation(redeemTeamInvitationRequest: RedeemTeamInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redeemTeamInvitation(redeemTeamInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} id 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeMember(id: string, agentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeMember(id, agentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search teams given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO'} [teamType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', createdAtStart?: string, createdAtEnd?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseTeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, createdAtStart, createdAtEnd, teamType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer an agent between teams
         * @param {TransferAgentBetweenTeamsRequest} transferAgentBetweenTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transferAgentBetweenTeams(transferAgentBetweenTeamsRequest: TransferAgentBetweenTeamsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transferAgentBetweenTeams(transferAgentBetweenTeamsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberInNormalTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberInNormalTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMemberInPlatinumTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemberInPlatinumTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeam(id: string, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(id, updateTeamRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update team\'s instant payment eligibility by id
         * @param {string} id 
         * @param {UpdateTeamInstantPaymentEligibleRequest} updateTeamInstantPaymentEligibleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateTeamInstantPaymentEligible(id: string, updateTeamInstantPaymentEligibleRequest: UpdateTeamInstantPaymentEligibleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamInstantPaymentEligible(id, updateTeamInstantPaymentEligibleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update an invitation
         * @param {string} invitationId 
         * @param {PatchTeamInvitationRequest} patchTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamInvitation(invitationId: string, patchTeamInvitationRequest: PatchTeamInvitationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamInvitation(invitationId, patchTeamInvitationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update team members by id
         * @param {string} id 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamMembers(id: string, updateTeamMembersRequest: UpdateTeamMembersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamMembers(id, updateTeamMembersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update team\'s \'paid at closing\' status by id
         * @param {string} id 
         * @param {UpdateTeamPaidAtClosingRequest} updateTeamPaidAtClosingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTeamPaidAtClosing(id: string, updateTeamPaidAtClosingRequest: UpdateTeamPaidAtClosingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeamPaidAtClosing(id, updateTeamPaidAtClosingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamControllerApi - factory interface
 * @export
 */
export const TeamControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a member to an existing Domestic team
         * @param {string} id 
         * @param {AddDomesticTeamMemberRequest} addDomesticTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToDomesticTeam(id: string, addDomesticTeamMemberRequest: AddDomesticTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToDomesticTeam(id, addDomesticTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a member to an existing Normal team
         * @param {string} id 
         * @param {AddNormalTeamMemberRequest} addNormalTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToNormalTeam(id: string, addNormalTeamMemberRequest: AddNormalTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToNormalTeam(id, addNormalTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a member to an existing Platinum team
         * @param {string} id 
         * @param {AddPlatinumTeamMemberRequest} addPlatinumTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToPlatinumTeam(id: string, addPlatinumTeamMemberRequest: AddPlatinumTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToPlatinumTeam(id, addPlatinumTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a member to an existing team
         * @param {string} id 
         * @param {AddTeamMemberRequest} addTeamMemberRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMemberToTeam(id: string, addTeamMemberRequest: AddTeamMemberRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.addMemberToTeam(id, addTeamMemberRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Domestic team
         * @param {CreateDomesticTeamRequest} createDomesticTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDomesticTeam(createDomesticTeamRequest: CreateDomesticTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createDomesticTeam(createDomesticTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Normal Team
         * @param {CreateNormalTeamRequest} createNormalTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNormalTeam(createNormalTeamRequest: CreateNormalTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createNormalTeam(createNormalTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Platinum team
         * @param {CreatePlatinumTeamRequest} createPlatinumTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlatinumTeam(createPlatinumTeamRequest: CreatePlatinumTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createPlatinumTeam(createPlatinumTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a team
         * @param {CreateTeamRequest} createTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeam(createTeamRequest: CreateTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.createTeam(createTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary invite an agent to join a team
         * @param {string} teamId 
         * @param {CreateTeamInvitationRequest} createTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTeamInvitation(teamId: string, createTeamInvitationRequest: CreateTeamInvitationRequest, options?: any): AxiosPromise<TeamInvitationDto> {
            return localVarFp.createTeamInvitation(teamId, createTeamInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary generate an generic invitation
         * @param {string} teamId 
         * @param {CreateGenericTeamInvitationRequestDto} createGenericTeamInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGenericInvitation(teamId: string, createGenericTeamInvitationRequestDto: CreateGenericTeamInvitationRequestDto, options?: any): AxiosPromise<GenericTeamInvitationResponse> {
            return localVarFp.generateGenericInvitation(teamId, createGenericTeamInvitationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamById(id: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.getTeamById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team by id without agents
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamByIdWithoutAgents(id: string, options?: any): AxiosPromise<TeamWithoutAgentsResponse> {
            return localVarFp.getTeamByIdWithoutAgents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team leaders by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamLeaders(id: string, options?: any): AxiosPromise<TeamLeadersResponse> {
            return localVarFp.getTeamLeaders(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team leaders and admins by team id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamLeadersAndAdmins(id: string, options?: any): AxiosPromise<TeamLeadersAndAdminsResponse> {
            return localVarFp.getTeamLeadersAndAdmins(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get team permissions for approver on teamOwner\'s teams
         * @param {string} teamOwnerId 
         * @param {string} approverId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamPermissionsForApprover(teamOwnerId: string, approverId: string, options?: any): AxiosPromise<TeamPermissionResponse> {
            return localVarFp.getTeamPermissionsForApprover(teamOwnerId, approverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get multiple teams by ids
         * @param {BatchTeamGetRequest} batchTeamGetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamsByIdsBatch(batchTeamGetRequest: BatchTeamGetRequest, options?: any): AxiosPromise<BatchTeamGetResponse> {
            return localVarFp.getTeamsByIdsBatch(batchTeamGetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Designate a team as inactive
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inactivateTeam(id: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.inactivateTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary redeem an generic invitation
         * @param {RedeemGenericTeamInvitationRequest} redeemGenericTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemGenericInvitation(redeemGenericTeamInvitationRequest: RedeemGenericTeamInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.redeemGenericInvitation(redeemGenericTeamInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary redeem a team invitation for approved agent
         * @param {RedeemTeamInvitationRequest} redeemTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redeemTeamInvitation(redeemTeamInvitationRequest: RedeemTeamInvitationRequest, options?: any): AxiosPromise<void> {
            return localVarFp.redeemTeamInvitation(redeemTeamInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a member from an existing team
         * @param {string} id 
         * @param {string} agentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMember(id: string, agentId: string, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.removeMember(id, agentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search teams given a set of criteria
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {string} [searchText] 
         * @param {'ACTIVE' | 'INACTIVE'} [status] 
         * @param {string} [createdAtStart] 
         * @param {string} [createdAtEnd] 
         * @param {'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO'} [teamType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', createdAtStart?: string, createdAtEnd?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO', options?: any): AxiosPromise<GenericSearchResponseTeamResponse> {
            return localVarFp.search(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, createdAtStart, createdAtEnd, teamType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer an agent between teams
         * @param {TransferAgentBetweenTeamsRequest} transferAgentBetweenTeamsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transferAgentBetweenTeams(transferAgentBetweenTeamsRequest: TransferAgentBetweenTeamsRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.transferAgentBetweenTeams(transferAgentBetweenTeamsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing member in a Normal team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInNormalTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateMemberInNormalTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing member in a Platinum team
         * @param {string} teamId 
         * @param {string} agentId 
         * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMemberInPlatinumTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateMemberInPlatinumTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team by id
         * @param {string} id 
         * @param {UpdateTeamRequest} updateTeamRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeam(id: string, updateTeamRequest: UpdateTeamRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeam(id, updateTeamRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team\'s instant payment eligibility by id
         * @param {string} id 
         * @param {UpdateTeamInstantPaymentEligibleRequest} updateTeamInstantPaymentEligibleRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateTeamInstantPaymentEligible(id: string, updateTeamInstantPaymentEligibleRequest: UpdateTeamInstantPaymentEligibleRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamInstantPaymentEligible(id, updateTeamInstantPaymentEligibleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update an invitation
         * @param {string} invitationId 
         * @param {PatchTeamInvitationRequest} patchTeamInvitationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamInvitation(invitationId: string, patchTeamInvitationRequest: PatchTeamInvitationRequest, options?: any): AxiosPromise<TeamInvitationDto> {
            return localVarFp.updateTeamInvitation(invitationId, patchTeamInvitationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team members by id
         * @param {string} id 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamMembers(id: string, updateTeamMembersRequest: UpdateTeamMembersRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamMembers(id, updateTeamMembersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update team\'s \'paid at closing\' status by id
         * @param {string} id 
         * @param {UpdateTeamPaidAtClosingRequest} updateTeamPaidAtClosingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTeamPaidAtClosing(id: string, updateTeamPaidAtClosingRequest: UpdateTeamPaidAtClosingRequest, options?: any): AxiosPromise<TeamResponse> {
            return localVarFp.updateTeamPaidAtClosing(id, updateTeamPaidAtClosingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamControllerApi - object-oriented interface
 * @export
 * @class TeamControllerApi
 * @extends {BaseAPI}
 */
export class TeamControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a member to an existing Domestic team
     * @param {string} id 
     * @param {AddDomesticTeamMemberRequest} addDomesticTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToDomesticTeam(id: string, addDomesticTeamMemberRequest: AddDomesticTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addMemberToDomesticTeam(id, addDomesticTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a member to an existing Normal team
     * @param {string} id 
     * @param {AddNormalTeamMemberRequest} addNormalTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToNormalTeam(id: string, addNormalTeamMemberRequest: AddNormalTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addMemberToNormalTeam(id, addNormalTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a member to an existing Platinum team
     * @param {string} id 
     * @param {AddPlatinumTeamMemberRequest} addPlatinumTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToPlatinumTeam(id: string, addPlatinumTeamMemberRequest: AddPlatinumTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addMemberToPlatinumTeam(id, addPlatinumTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a member to an existing team
     * @param {string} id 
     * @param {AddTeamMemberRequest} addTeamMemberRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public addMemberToTeam(id: string, addTeamMemberRequest: AddTeamMemberRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).addMemberToTeam(id, addTeamMemberRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Domestic team
     * @param {CreateDomesticTeamRequest} createDomesticTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createDomesticTeam(createDomesticTeamRequest: CreateDomesticTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createDomesticTeam(createDomesticTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Normal Team
     * @param {CreateNormalTeamRequest} createNormalTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createNormalTeam(createNormalTeamRequest: CreateNormalTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createNormalTeam(createNormalTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Platinum team
     * @param {CreatePlatinumTeamRequest} createPlatinumTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createPlatinumTeam(createPlatinumTeamRequest: CreatePlatinumTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createPlatinumTeam(createPlatinumTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a team
     * @param {CreateTeamRequest} createTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createTeam(createTeamRequest: CreateTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createTeam(createTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary invite an agent to join a team
     * @param {string} teamId 
     * @param {CreateTeamInvitationRequest} createTeamInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public createTeamInvitation(teamId: string, createTeamInvitationRequest: CreateTeamInvitationRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).createTeamInvitation(teamId, createTeamInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary generate an generic invitation
     * @param {string} teamId 
     * @param {CreateGenericTeamInvitationRequestDto} createGenericTeamInvitationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public generateGenericInvitation(teamId: string, createGenericTeamInvitationRequestDto: CreateGenericTeamInvitationRequestDto, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).generateGenericInvitation(teamId, createGenericTeamInvitationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamById(id: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team by id without agents
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamByIdWithoutAgents(id: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamByIdWithoutAgents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team leaders by team id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamLeaders(id: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamLeaders(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team leaders and admins by team id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamLeadersAndAdmins(id: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamLeadersAndAdmins(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get team permissions for approver on teamOwner\'s teams
     * @param {string} teamOwnerId 
     * @param {string} approverId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamPermissionsForApprover(teamOwnerId: string, approverId: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamPermissionsForApprover(teamOwnerId, approverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get multiple teams by ids
     * @param {BatchTeamGetRequest} batchTeamGetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public getTeamsByIdsBatch(batchTeamGetRequest: BatchTeamGetRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).getTeamsByIdsBatch(batchTeamGetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Designate a team as inactive
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public inactivateTeam(id: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).inactivateTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary redeem an generic invitation
     * @param {RedeemGenericTeamInvitationRequest} redeemGenericTeamInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public redeemGenericInvitation(redeemGenericTeamInvitationRequest: RedeemGenericTeamInvitationRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).redeemGenericInvitation(redeemGenericTeamInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary redeem a team invitation for approved agent
     * @param {RedeemTeamInvitationRequest} redeemTeamInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public redeemTeamInvitation(redeemTeamInvitationRequest: RedeemTeamInvitationRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).redeemTeamInvitation(redeemTeamInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a member from an existing team
     * @param {string} id 
     * @param {string} agentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public removeMember(id: string, agentId: string, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).removeMember(id, agentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search teams given a set of criteria
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>} [sortBy] 
     * @param {string} [id] 
     * @param {string} [name] 
     * @param {string} [searchText] 
     * @param {'ACTIVE' | 'INACTIVE'} [status] 
     * @param {string} [createdAtStart] 
     * @param {string} [createdAtEnd] 
     * @param {'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO'} [teamType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public search(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'NAME' | 'STATUS' | 'TEAM_TYPE' | 'LEADER_NAME' | 'CREATED_AT'>, id?: string, name?: string, searchText?: string, status?: 'ACTIVE' | 'INACTIVE', createdAtStart?: string, createdAtEnd?: string, teamType?: 'NORMAL' | 'PLATINUM' | 'GROUP' | 'DOMESTIC' | 'PRO', options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).search(pageNumber, pageSize, sortDirection, sortBy, id, name, searchText, status, createdAtStart, createdAtEnd, teamType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer an agent between teams
     * @param {TransferAgentBetweenTeamsRequest} transferAgentBetweenTeamsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public transferAgentBetweenTeams(transferAgentBetweenTeamsRequest: TransferAgentBetweenTeamsRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).transferAgentBetweenTeams(transferAgentBetweenTeamsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing member in a Normal team
     * @param {string} teamId 
     * @param {string} agentId 
     * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateMemberInNormalTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateMemberInNormalTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing member in a Platinum team
     * @param {string} teamId 
     * @param {string} agentId 
     * @param {UpdateTeamMemberCommissionSplitRequest} updateTeamMemberCommissionSplitRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateMemberInPlatinumTeam(teamId: string, agentId: string, updateTeamMemberCommissionSplitRequest: UpdateTeamMemberCommissionSplitRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateMemberInPlatinumTeam(teamId, agentId, updateTeamMemberCommissionSplitRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team by id
     * @param {string} id 
     * @param {UpdateTeamRequest} updateTeamRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeam(id: string, updateTeamRequest: UpdateTeamRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeam(id, updateTeamRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team\'s instant payment eligibility by id
     * @param {string} id 
     * @param {UpdateTeamInstantPaymentEligibleRequest} updateTeamInstantPaymentEligibleRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamInstantPaymentEligible(id: string, updateTeamInstantPaymentEligibleRequest: UpdateTeamInstantPaymentEligibleRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamInstantPaymentEligible(id, updateTeamInstantPaymentEligibleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update an invitation
     * @param {string} invitationId 
     * @param {PatchTeamInvitationRequest} patchTeamInvitationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamInvitation(invitationId: string, patchTeamInvitationRequest: PatchTeamInvitationRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamInvitation(invitationId, patchTeamInvitationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team members by id
     * @param {string} id 
     * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamMembers(id: string, updateTeamMembersRequest: UpdateTeamMembersRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamMembers(id, updateTeamMembersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update team\'s \'paid at closing\' status by id
     * @param {string} id 
     * @param {UpdateTeamPaidAtClosingRequest} updateTeamPaidAtClosingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamControllerApi
     */
    public updateTeamPaidAtClosing(id: string, updateTeamPaidAtClosingRequest: UpdateTeamPaidAtClosingRequest, options?: AxiosRequestConfig) {
        return TeamControllerApiFp(this.configuration).updateTeamPaidAtClosing(id, updateTeamPaidAtClosingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamPublicControllerApi - axios parameter creator
 * @export
 */
export const TeamPublicControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('getTeamInvitation', 'invitationId', invitationId)
            const localVarPath = `/api/v1/teams/public/invitation/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary validate an generic invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGenericInvitation: async (invitationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationId' is not null or undefined
            assertParamExists('validateGenericInvitation', 'invitationId', invitationId)
            const localVarPath = `/api/v1/teams/public/generic-link/validate/{invitationId}`
                .replace(`{${"invitationId"}}`, encodeURIComponent(String(invitationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamPublicControllerApi - functional programming interface
 * @export
 */
export const TeamPublicControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamPublicControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TeamInvitationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary validate an generic invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateGenericInvitation(invitationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateGenericTeamInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateGenericInvitation(invitationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamPublicControllerApi - factory interface
 * @export
 */
export const TeamPublicControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamPublicControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary get an invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamInvitation(invitationId: string, options?: any): AxiosPromise<TeamInvitationDto> {
            return localVarFp.getTeamInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary validate an generic invitation
         * @param {string} invitationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGenericInvitation(invitationId: string, options?: any): AxiosPromise<ValidateGenericTeamInvitationResponse> {
            return localVarFp.validateGenericInvitation(invitationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamPublicControllerApi - object-oriented interface
 * @export
 * @class TeamPublicControllerApi
 * @extends {BaseAPI}
 */
export class TeamPublicControllerApi extends BaseAPI {
    /**
     * 
     * @summary get an invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamPublicControllerApi
     */
    public getTeamInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return TeamPublicControllerApiFp(this.configuration).getTeamInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary validate an generic invitation
     * @param {string} invitationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamPublicControllerApi
     */
    public validateGenericInvitation(invitationId: string, options?: AxiosRequestConfig) {
        return TeamPublicControllerApiFp(this.configuration).validateGenericInvitation(invitationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestControllerApi - axios parameter creator
 * @export
 */
export const TestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/test/reset-clock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDate: async (localDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'localDate' is not null or undefined
            assertParamExists('setDate', 'localDate', localDate)
            const localVarPath = `/api/v1/test/set-clock/{localDate}`
                .replace(`{${"localDate"}}`, encodeURIComponent(String(localDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestControllerApi - functional programming interface
 * @export
 */
export const TestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetDate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDate(localDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDate(localDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestControllerApi - factory interface
 * @export
 */
export const TestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Search API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDate(options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resetDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clock Management API
         * @param {string} localDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDate(localDate: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.setDate(localDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestControllerApi - object-oriented interface
 * @export
 * @class TestControllerApi
 * @extends {BaseAPI}
 */
export class TestControllerApi extends BaseAPI {
    /**
     * 
     * @summary Search API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public resetDate(options?: AxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).resetDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clock Management API
     * @param {string} localDate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestControllerApi
     */
    public setDate(localDate: string, options?: AxiosRequestConfig) {
        return TestControllerApiFp(this.configuration).setDate(localDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionCoordinatorControllerApi - axios parameter creator
 * @export
 */
export const TransactionCoordinatorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Approve an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvite: async (connectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('approveInvite', 'connectionId', connectionId)
            const localVarPath = `/api/v1/transaction-coordinators/connections/{connectionId}/approve`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Transaction Coordinator Connections
         * @param {string} [transactionCoordinatorId] 
         * @param {string} [agentId] 
         * @param {string} [legacyTeamId] 
         * @param {string} [flexTeamId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>} [connectionStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections: async (transactionCoordinatorId?: string, agentId?: string, legacyTeamId?: string, flexTeamId?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, connectionStatuses?: Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/transaction-coordinators/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (transactionCoordinatorId !== undefined) {
                localVarQueryParameter['transactionCoordinatorId'] = transactionCoordinatorId;
            }

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }

            if (legacyTeamId !== undefined) {
                localVarQueryParameter['legacyTeamId'] = legacyTeamId;
            }

            if (flexTeamId !== undefined) {
                localVarQueryParameter['flexTeamId'] = flexTeamId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (connectionStatuses) {
                localVarQueryParameter['connectionStatuses'] = connectionStatuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove an Connection Request
         * @param {string} connectionId 
         * @param {RemoveTransactionCoordinatorConnectionRequest} removeTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConnection: async (connectionId: string, removeTransactionCoordinatorConnectionRequest: RemoveTransactionCoordinatorConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('removeConnection', 'connectionId', connectionId)
            // verify required parameter 'removeTransactionCoordinatorConnectionRequest' is not null or undefined
            assertParamExists('removeConnection', 'removeTransactionCoordinatorConnectionRequest', removeTransactionCoordinatorConnectionRequest)
            const localVarPath = `/api/v1/transaction-coordinators/connections/{connectionId}/removal`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeTransactionCoordinatorConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConnectionRequest: async (connectionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('removeConnectionRequest', 'connectionId', connectionId)
            const localVarPath = `/api/v1/transaction-coordinators/connections/{connectionId}/reject`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request an external connection
         * @param {CreateExternalTransactionCoordinatorConnectionRequest} createExternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestExternalTransactionCoordinator: async (createExternalTransactionCoordinatorConnectionRequest: CreateExternalTransactionCoordinatorConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExternalTransactionCoordinatorConnectionRequest' is not null or undefined
            assertParamExists('requestExternalTransactionCoordinator', 'createExternalTransactionCoordinatorConnectionRequest', createExternalTransactionCoordinatorConnectionRequest)
            const localVarPath = `/api/v1/transaction-coordinators/connections/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExternalTransactionCoordinatorConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite an internal transaction coordinator
         * @param {CreateInternalTransactionCoordinatorConnectionRequest} createInternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestInternalTransactionCoordinator: async (createInternalTransactionCoordinatorConnectionRequest: CreateInternalTransactionCoordinatorConnectionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createInternalTransactionCoordinatorConnectionRequest' is not null or undefined
            assertParamExists('requestInternalTransactionCoordinator', 'createInternalTransactionCoordinatorConnectionRequest', createInternalTransactionCoordinatorConnectionRequest)
            const localVarPath = `/api/v1/transaction-coordinators/connections/internal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createInternalTransactionCoordinatorConnectionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend request email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('resendEmail', 'id', id)
            const localVarPath = `/api/v1/transaction-coordinators/connections/{id}/resend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Transaction Coordinators
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactionCoordinators: async (searchTerm?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/transaction-coordinators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy) {
                localVarQueryParameter['sortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update access date
         * @param {string} connectionId 
         * @param {UpdateTransactionCoordinatorConnectionAccessRequest} updateTransactionCoordinatorConnectionAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccess: async (connectionId: string, updateTransactionCoordinatorConnectionAccessRequest: UpdateTransactionCoordinatorConnectionAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('updateAccess', 'connectionId', connectionId)
            // verify required parameter 'updateTransactionCoordinatorConnectionAccessRequest' is not null or undefined
            assertParamExists('updateAccess', 'updateTransactionCoordinatorConnectionAccessRequest', updateTransactionCoordinatorConnectionAccessRequest)
            const localVarPath = `/api/v1/transaction-coordinators/connections/{connectionId}/access`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransactionCoordinatorConnectionAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionCoordinatorControllerApi - functional programming interface
 * @export
 */
export const TransactionCoordinatorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionCoordinatorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Approve an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveInvite(connectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveInvite(connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Transaction Coordinator Connections
         * @param {string} [transactionCoordinatorId] 
         * @param {string} [agentId] 
         * @param {string} [legacyTeamId] 
         * @param {string} [flexTeamId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>} [connectionStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnections(transactionCoordinatorId?: string, agentId?: string, legacyTeamId?: string, flexTeamId?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, connectionStatuses?: Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseTransactionCoordinatorConnectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnections(transactionCoordinatorId, agentId, legacyTeamId, flexTeamId, pageNumber, pageSize, sortDirection, sortBy, connectionStatuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove an Connection Request
         * @param {string} connectionId 
         * @param {RemoveTransactionCoordinatorConnectionRequest} removeTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeConnection(connectionId: string, removeTransactionCoordinatorConnectionRequest: RemoveTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeConnection(connectionId, removeTransactionCoordinatorConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reject an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeConnectionRequest(connectionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeConnectionRequest(connectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request an external connection
         * @param {CreateExternalTransactionCoordinatorConnectionRequest} createExternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest: CreateExternalTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite an internal transaction coordinator
         * @param {CreateInternalTransactionCoordinatorConnectionRequest} createInternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest: CreateInternalTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend request email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendEmail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendEmail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Transaction Coordinators
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTransactionCoordinators(searchTerm?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseTransactionCoordinatorSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTransactionCoordinators(searchTerm, pageNumber, pageSize, sortDirection, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update access date
         * @param {string} connectionId 
         * @param {UpdateTransactionCoordinatorConnectionAccessRequest} updateTransactionCoordinatorConnectionAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccess(connectionId: string, updateTransactionCoordinatorConnectionAccessRequest: UpdateTransactionCoordinatorConnectionAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccess(connectionId, updateTransactionCoordinatorConnectionAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionCoordinatorControllerApi - factory interface
 * @export
 */
export const TransactionCoordinatorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionCoordinatorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Approve an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvite(connectionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.approveInvite(connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Transaction Coordinator Connections
         * @param {string} [transactionCoordinatorId] 
         * @param {string} [agentId] 
         * @param {string} [legacyTeamId] 
         * @param {string} [flexTeamId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>} [connectionStatuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections(transactionCoordinatorId?: string, agentId?: string, legacyTeamId?: string, flexTeamId?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, connectionStatuses?: Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>, options?: any): AxiosPromise<GenericSearchResponseTransactionCoordinatorConnectionResponse> {
            return localVarFp.getConnections(transactionCoordinatorId, agentId, legacyTeamId, flexTeamId, pageNumber, pageSize, sortDirection, sortBy, connectionStatuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove an Connection Request
         * @param {string} connectionId 
         * @param {RemoveTransactionCoordinatorConnectionRequest} removeTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConnection(connectionId: string, removeTransactionCoordinatorConnectionRequest: RemoveTransactionCoordinatorConnectionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.removeConnection(connectionId, removeTransactionCoordinatorConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reject an Connection Request
         * @param {string} connectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeConnectionRequest(connectionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeConnectionRequest(connectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request an external connection
         * @param {CreateExternalTransactionCoordinatorConnectionRequest} createExternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest: CreateExternalTransactionCoordinatorConnectionRequest, options?: any): AxiosPromise<IdResponse> {
            return localVarFp.requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite an internal transaction coordinator
         * @param {CreateInternalTransactionCoordinatorConnectionRequest} createInternalTransactionCoordinatorConnectionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest: CreateInternalTransactionCoordinatorConnectionRequest, options?: any): AxiosPromise<IdResponse> {
            return localVarFp.requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend request email
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmail(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendEmail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Transaction Coordinators
         * @param {string} [searchTerm] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactionCoordinators(searchTerm?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, options?: any): AxiosPromise<GenericSearchResponseTransactionCoordinatorSearchResponse> {
            return localVarFp.searchTransactionCoordinators(searchTerm, pageNumber, pageSize, sortDirection, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update access date
         * @param {string} connectionId 
         * @param {UpdateTransactionCoordinatorConnectionAccessRequest} updateTransactionCoordinatorConnectionAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccess(connectionId: string, updateTransactionCoordinatorConnectionAccessRequest: UpdateTransactionCoordinatorConnectionAccessRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateAccess(connectionId, updateTransactionCoordinatorConnectionAccessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionCoordinatorControllerApi - object-oriented interface
 * @export
 * @class TransactionCoordinatorControllerApi
 * @extends {BaseAPI}
 */
export class TransactionCoordinatorControllerApi extends BaseAPI {
    /**
     * 
     * @summary Approve an Connection Request
     * @param {string} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public approveInvite(connectionId: string, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).approveInvite(connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Transaction Coordinator Connections
     * @param {string} [transactionCoordinatorId] 
     * @param {string} [agentId] 
     * @param {string} [legacyTeamId] 
     * @param {string} [flexTeamId] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
     * @param {Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>} [connectionStatuses] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public getConnections(transactionCoordinatorId?: string, agentId?: string, legacyTeamId?: string, flexTeamId?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, connectionStatuses?: Array<'PENDING' | 'EXPIRED' | 'REJECTED' | 'ACTIVE' | 'VOIDED'>, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).getConnections(transactionCoordinatorId, agentId, legacyTeamId, flexTeamId, pageNumber, pageSize, sortDirection, sortBy, connectionStatuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove an Connection Request
     * @param {string} connectionId 
     * @param {RemoveTransactionCoordinatorConnectionRequest} removeTransactionCoordinatorConnectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public removeConnection(connectionId: string, removeTransactionCoordinatorConnectionRequest: RemoveTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).removeConnection(connectionId, removeTransactionCoordinatorConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reject an Connection Request
     * @param {string} connectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public removeConnectionRequest(connectionId: string, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).removeConnectionRequest(connectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request an external connection
     * @param {CreateExternalTransactionCoordinatorConnectionRequest} createExternalTransactionCoordinatorConnectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest: CreateExternalTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).requestExternalTransactionCoordinator(createExternalTransactionCoordinatorConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite an internal transaction coordinator
     * @param {CreateInternalTransactionCoordinatorConnectionRequest} createInternalTransactionCoordinatorConnectionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest: CreateInternalTransactionCoordinatorConnectionRequest, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).requestInternalTransactionCoordinator(createInternalTransactionCoordinatorConnectionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend request email
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public resendEmail(id: string, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).resendEmail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Transaction Coordinators
     * @param {string} [searchTerm] 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {Array<'ID' | 'CREATED_AT'>} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public searchTransactionCoordinators(searchTerm?: string, pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: Array<'ID' | 'CREATED_AT'>, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).searchTransactionCoordinators(searchTerm, pageNumber, pageSize, sortDirection, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update access date
     * @param {string} connectionId 
     * @param {UpdateTransactionCoordinatorConnectionAccessRequest} updateTransactionCoordinatorConnectionAccessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionCoordinatorControllerApi
     */
    public updateAccess(connectionId: string, updateTransactionCoordinatorConnectionAccessRequest: UpdateTransactionCoordinatorConnectionAccessRequest, options?: AxiosRequestConfig) {
        return TransactionCoordinatorControllerApiFp(this.configuration).updateAccess(connectionId, updateTransactionCoordinatorConnectionAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserAgreementControllerApi - axios parameter creator
 * @export
 */
export const UserAgreementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate Agreement By Type
         * @param {CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement} createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementByType: async (createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement: CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement' is not null or undefined
            assertParamExists('createAgreementByType', 'createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement', createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement)
            const localVarPath = `/api/v1/user-agreements/by-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgreement: async (agreementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('deleteAgreement', 'agreementId', agreementId)
            const localVarPath = `/api/v1/user-agreements/{agreementId}`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate an agent referral agreement
         * @param {CreateAgentReferralAgreementCommand} createAgentReferralAgreementCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgentReferralAgreement: async (createAgentReferralAgreementCommand: CreateAgentReferralAgreementCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAgentReferralAgreementCommand' is not null or undefined
            assertParamExists('generateAgentReferralAgreement', 'createAgentReferralAgreementCommand', createAgentReferralAgreementCommand)
            const localVarPath = `/api/v1/user-agreements/agent-referral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAgentReferralAgreementCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate an agreement
         * @param {AgreementCreationRequest} agreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgreement: async (agreementCreationRequest: AgreementCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementCreationRequest' is not null or undefined
            assertParamExists('generateAgreement', 'agreementCreationRequest', agreementCreationRequest)
            const localVarPath = `/api/v1/user-agreements/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreementCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Obtain a Willable Revshare agreement for the user to sign
         * @param {string} user 
         * @param {WillBeneficiaryOnboardingRequest} willBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgreementForWillableRevshare: async (user: string, willBeneficiaryOnboardingRequest: WillBeneficiaryOnboardingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('generateAgreementForWillableRevshare', 'user', user)
            // verify required parameter 'willBeneficiaryOnboardingRequest' is not null or undefined
            assertParamExists('generateAgreementForWillableRevshare', 'willBeneficiaryOnboardingRequest', willBeneficiaryOnboardingRequest)
            const localVarPath = `/api/v1/user-agreements/generate-willable-revshare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(willBeneficiaryOnboardingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agreement by id
         * @param {string} userId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreement: async (userId: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAgreement', 'userId', userId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgreement', 'id', id)
            const localVarPath = `/api/v1/user-agreements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agreement amendment by id
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementAmendmentById: async (id: string, amendmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAgreementAmendmentById', 'id', id)
            // verify required parameter 'amendmentId' is not null or undefined
            assertParamExists('getAgreementAmendmentById', 'amendmentId', amendmentId)
            const localVarPath = `/api/v1/user-agreements/{id}/amendments/{amendmentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"amendmentId"}}`, encodeURIComponent(String(amendmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get agreement by criteria
         * @param {string} userId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {boolean} signed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementsByUserAndAgreementTypeAndVersionDesc: async (userId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', signed: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getAgreementsByUserAndAgreementTypeAndVersionDesc', 'userId', userId)
            // verify required parameter 'agreementType' is not null or undefined
            assertParamExists('getAgreementsByUserAndAgreementTypeAndVersionDesc', 'agreementType', agreementType)
            // verify required parameter 'signed' is not null or undefined
            assertParamExists('getAgreementsByUserAndAgreementTypeAndVersionDesc', 'signed', signed)
            const localVarPath = `/api/v1/user-agreements/criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (agreementType !== undefined) {
                localVarQueryParameter['agreementType'] = agreementType;
            }

            if (signed !== undefined) {
                localVarQueryParameter['signed'] = signed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new agreements and amendments if it does not exist and return all agreement that is not signed for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLatestMandatoryAgreementsNotSigned: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-agreements/all-my-latest-not-signed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get file download URLs for an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileDownloadUrl: async (agreementId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('getFileDownloadUrl', 'agreementId', agreementId)
            const localVarPath = `/api/v1/user-agreements/{agreementId}/download-url`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get latest agreement version that is not signed
         * @param {string} agentId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestAgreementVersionNotSigned: async (agentId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('getLatestAgreementVersionNotSigned', 'agentId', agentId)
            // verify required parameter 'agreementType' is not null or undefined
            assertParamExists('getLatestAgreementVersionNotSigned', 'agreementType', agreementType)
            const localVarPath = `/api/v1/user-agreements/latest-not-signed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentId !== undefined) {
                localVarQueryParameter['agentId'] = agentId;
            }

            if (agreementType !== undefined) {
                localVarQueryParameter['agreementType'] = agreementType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new agreements and amendments if they do not exist and return all unsigned agreements for specified agreement definitions
         * @param {string} userId 
         * @param {Array<string>} agreementDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsignedAgreementsByDefinitions: async (userId: string, agreementDefinitionId: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUnsignedAgreementsByDefinitions', 'userId', userId)
            // verify required parameter 'agreementDefinitionId' is not null or undefined
            assertParamExists('getUnsignedAgreementsByDefinitions', 'agreementDefinitionId', agreementDefinitionId)
            const localVarPath = `/api/v1/user-agreements/{userId}/unsigned`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agreementDefinitionId) {
                localVarQueryParameter['agreementDefinitionId'] = Array.from(agreementDefinitionId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resend emails for agreements
         * @param {ResendAgreementEmails} resendAgreementEmails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmails: async (resendAgreementEmails: ResendAgreementEmails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendAgreementEmails' is not null or undefined
            assertParamExists('resendEmails', 'resendAgreementEmails', resendAgreementEmails)
            const localVarPath = `/api/v1/user-agreements/resend-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendAgreementEmails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search agreements (including buyer-broker agreements)
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'CREATED_AT' | 'AGREEMENT_TYPE'} [sortBy] 
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>} [status] 
         * @param {string} [name] 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgreements: async (pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: 'CREATED_AT' | 'AGREEMENT_TYPE', agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, status?: Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>, name?: string, agreementDefinitionId?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (agreementType) {
                localVarQueryParameter['agreementType'] = agreementType;
            }

            if (status) {
                localVarQueryParameter['status'] = Array.from(status);
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (agreementDefinitionId !== undefined) {
                localVarQueryParameter['agreementDefinitionId'] = agreementDefinitionId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign the agreement
         * @param {AgreementSigningRequest} agreementSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAgreement: async (agreementSigningRequest: AgreementSigningRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementSigningRequest' is not null or undefined
            assertParamExists('signAgreement', 'agreementSigningRequest', agreementSigningRequest)
            const localVarPath = `/api/v1/user-agreements/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(agreementSigningRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign an agreement amendment
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAgreementAmendment: async (id: string, amendmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('signAgreementAmendment', 'id', id)
            // verify required parameter 'amendmentId' is not null or undefined
            assertParamExists('signAgreementAmendment', 'amendmentId', amendmentId)
            const localVarPath = `/api/v1/user-agreements/{id}/amendments/{amendmentId}/sign`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"amendmentId"}}`, encodeURIComponent(String(amendmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Notify the back-end that the given user signed their Willable Revshare agreement
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willableRevshareAgreementSigned: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('willableRevshareAgreementSigned', 'user', user)
            const localVarPath = `/api/v1/user-agreements/sign-willable-revshare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserAgreementControllerApi - functional programming interface
 * @export
 */
export const UserAgreementControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserAgreementControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate Agreement By Type
         * @param {CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement} createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement: CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAgreement(agreementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAgreement(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate an agent referral agreement
         * @param {CreateAgentReferralAgreementCommand} createAgentReferralAgreementCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAgentReferralAgreement(createAgentReferralAgreementCommand: CreateAgentReferralAgreementCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAgentReferralAgreement(createAgentReferralAgreementCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate an agreement
         * @param {AgreementCreationRequest} agreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAgreement(agreementCreationRequest: AgreementCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAgreement(agreementCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Obtain a Willable Revshare agreement for the user to sign
         * @param {string} user 
         * @param {WillBeneficiaryOnboardingRequest} willBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateAgreementForWillableRevshare(user: string, willBeneficiaryOnboardingRequest: WillBeneficiaryOnboardingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateAgreementForWillableRevshare(user, willBeneficiaryOnboardingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agreement by id
         * @param {string} userId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreement(userId: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreement(userId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agreement amendment by id
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementAmendmentById(id: string, amendmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementAmendmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementAmendmentById(id, amendmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get agreement by criteria
         * @param {string} userId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {boolean} signed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAgreementsByUserAndAgreementTypeAndVersionDesc(userId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', signed: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgreementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAgreementsByUserAndAgreementTypeAndVersionDesc(userId, agreementType, signed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new agreements and amendments if it does not exist and return all agreement that is not signed for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllLatestMandatoryAgreementsNotSigned(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgreementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllLatestMandatoryAgreementsNotSigned(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get file download URLs for an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileDownloadUrl(agreementId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementFileUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileDownloadUrl(agreementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get latest agreement version that is not signed
         * @param {string} agentId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestAgreementVersionNotSigned(agentId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestAgreementVersionNotSigned(agentId, agreementType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new agreements and amendments if they do not exist and return all unsigned agreements for specified agreement definitions
         * @param {string} userId 
         * @param {Array<string>} agreementDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnsignedAgreementsByDefinitions(userId: string, agreementDefinitionId: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AgreementResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnsignedAgreementsByDefinitions(userId, agreementDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resend emails for agreements
         * @param {ResendAgreementEmails} resendAgreementEmails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendEmails(resendAgreementEmails: ResendAgreementEmails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendEmails(resendAgreementEmails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search agreements (including buyer-broker agreements)
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'CREATED_AT' | 'AGREEMENT_TYPE'} [sortBy] 
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>} [status] 
         * @param {string} [name] 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchAgreements(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: 'CREATED_AT' | 'AGREEMENT_TYPE', agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, status?: Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>, name?: string, agreementDefinitionId?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericSearchResponseAgreementPreviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchAgreements(pageNumber, pageSize, sortDirection, sortBy, agreementType, status, name, agreementDefinitionId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign the agreement
         * @param {AgreementSigningRequest} agreementSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signAgreement(agreementSigningRequest: AgreementSigningRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementSigningResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signAgreement(agreementSigningRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign an agreement amendment
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signAgreementAmendment(id: string, amendmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementAmendmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signAgreementAmendment(id, amendmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Notify the back-end that the given user signed their Willable Revshare agreement
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async willableRevshareAgreementSigned(user: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgreementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.willableRevshareAgreementSigned(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserAgreementControllerApi - factory interface
 * @export
 */
export const UserAgreementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserAgreementControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate Agreement By Type
         * @param {CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement} createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement: CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement, options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAgreement(agreementId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAgreement(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate an agent referral agreement
         * @param {CreateAgentReferralAgreementCommand} createAgentReferralAgreementCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgentReferralAgreement(createAgentReferralAgreementCommand: CreateAgentReferralAgreementCommand, options?: any): AxiosPromise<AgreementCreationResponse> {
            return localVarFp.generateAgentReferralAgreement(createAgentReferralAgreementCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate an agreement
         * @param {AgreementCreationRequest} agreementCreationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgreement(agreementCreationRequest: AgreementCreationRequest, options?: any): AxiosPromise<AgreementCreationResponse> {
            return localVarFp.generateAgreement(agreementCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Obtain a Willable Revshare agreement for the user to sign
         * @param {string} user 
         * @param {WillBeneficiaryOnboardingRequest} willBeneficiaryOnboardingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAgreementForWillableRevshare(user: string, willBeneficiaryOnboardingRequest: WillBeneficiaryOnboardingRequest, options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.generateAgreementForWillableRevshare(user, willBeneficiaryOnboardingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agreement by id
         * @param {string} userId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreement(userId: string, id: string, options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.getAgreement(userId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agreement amendment by id
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementAmendmentById(id: string, amendmentId: string, options?: any): AxiosPromise<AgreementAmendmentResponse> {
            return localVarFp.getAgreementAmendmentById(id, amendmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get agreement by criteria
         * @param {string} userId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {boolean} signed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgreementsByUserAndAgreementTypeAndVersionDesc(userId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', signed: boolean, options?: any): AxiosPromise<Array<AgreementResponse>> {
            return localVarFp.getAgreementsByUserAndAgreementTypeAndVersionDesc(userId, agreementType, signed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new agreements and amendments if it does not exist and return all agreement that is not signed for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLatestMandatoryAgreementsNotSigned(options?: any): AxiosPromise<Array<AgreementResponse>> {
            return localVarFp.getAllLatestMandatoryAgreementsNotSigned(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get file download URLs for an agreement
         * @param {string} agreementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileDownloadUrl(agreementId: string, options?: any): AxiosPromise<AgreementFileUrlResponse> {
            return localVarFp.getFileDownloadUrl(agreementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get latest agreement version that is not signed
         * @param {string} agentId 
         * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestAgreementVersionNotSigned(agentId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.getLatestAgreementVersionNotSigned(agentId, agreementType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new agreements and amendments if they do not exist and return all unsigned agreements for specified agreement definitions
         * @param {string} userId 
         * @param {Array<string>} agreementDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsignedAgreementsByDefinitions(userId: string, agreementDefinitionId: Array<string>, options?: any): AxiosPromise<Array<AgreementResponse>> {
            return localVarFp.getUnsignedAgreementsByDefinitions(userId, agreementDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resend emails for agreements
         * @param {ResendAgreementEmails} resendAgreementEmails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendEmails(resendAgreementEmails: ResendAgreementEmails, options?: any): AxiosPromise<void> {
            return localVarFp.resendEmails(resendAgreementEmails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search agreements (including buyer-broker agreements)
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {'ASC' | 'DESC'} [sortDirection] 
         * @param {'CREATED_AT' | 'AGREEMENT_TYPE'} [sortBy] 
         * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
         * @param {Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>} [status] 
         * @param {string} [name] 
         * @param {string} [agreementDefinitionId] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAgreements(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: 'CREATED_AT' | 'AGREEMENT_TYPE', agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, status?: Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>, name?: string, agreementDefinitionId?: string, userId?: string, options?: any): AxiosPromise<GenericSearchResponseAgreementPreviewResponse> {
            return localVarFp.searchAgreements(pageNumber, pageSize, sortDirection, sortBy, agreementType, status, name, agreementDefinitionId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign the agreement
         * @param {AgreementSigningRequest} agreementSigningRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAgreement(agreementSigningRequest: AgreementSigningRequest, options?: any): AxiosPromise<AgreementSigningResponse> {
            return localVarFp.signAgreement(agreementSigningRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign an agreement amendment
         * @param {string} id 
         * @param {string} amendmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signAgreementAmendment(id: string, amendmentId: string, options?: any): AxiosPromise<AgreementAmendmentResponse> {
            return localVarFp.signAgreementAmendment(id, amendmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Notify the back-end that the given user signed their Willable Revshare agreement
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        willableRevshareAgreementSigned(user: string, options?: any): AxiosPromise<AgreementResponse> {
            return localVarFp.willableRevshareAgreementSigned(user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserAgreementControllerApi - object-oriented interface
 * @export
 * @class UserAgreementControllerApi
 * @extends {BaseAPI}
 */
export class UserAgreementControllerApi extends BaseAPI {
    /**
     * 
     * @summary Generate Agreement By Type
     * @param {CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement} createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement: CreateAssistantAccessAgreement | CreateAssistantPolicyAndProceduresAgreement | CreateLicensedAssistantAddendumAgreement | CreateUnlicensedAssistantAddendumAgreement, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).createAgreementByType(createAssistantAccessAgreementCreateAssistantPolicyAndProceduresAgreementCreateLicensedAssistantAddendumAgreementCreateUnlicensedAssistantAddendumAgreement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an agreement
     * @param {string} agreementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public deleteAgreement(agreementId: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).deleteAgreement(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate an agent referral agreement
     * @param {CreateAgentReferralAgreementCommand} createAgentReferralAgreementCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public generateAgentReferralAgreement(createAgentReferralAgreementCommand: CreateAgentReferralAgreementCommand, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).generateAgentReferralAgreement(createAgentReferralAgreementCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate an agreement
     * @param {AgreementCreationRequest} agreementCreationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public generateAgreement(agreementCreationRequest: AgreementCreationRequest, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).generateAgreement(agreementCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Obtain a Willable Revshare agreement for the user to sign
     * @param {string} user 
     * @param {WillBeneficiaryOnboardingRequest} willBeneficiaryOnboardingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public generateAgreementForWillableRevshare(user: string, willBeneficiaryOnboardingRequest: WillBeneficiaryOnboardingRequest, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).generateAgreementForWillableRevshare(user, willBeneficiaryOnboardingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agreement by id
     * @param {string} userId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getAgreement(userId: string, id: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getAgreement(userId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agreement amendment by id
     * @param {string} id 
     * @param {string} amendmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getAgreementAmendmentById(id: string, amendmentId: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getAgreementAmendmentById(id, amendmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get agreement by criteria
     * @param {string} userId 
     * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
     * @param {boolean} signed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getAgreementsByUserAndAgreementTypeAndVersionDesc(userId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', signed: boolean, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getAgreementsByUserAndAgreementTypeAndVersionDesc(userId, agreementType, signed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new agreements and amendments if it does not exist and return all agreement that is not signed for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getAllLatestMandatoryAgreementsNotSigned(options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getAllLatestMandatoryAgreementsNotSigned(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get file download URLs for an agreement
     * @param {string} agreementId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getFileDownloadUrl(agreementId: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getFileDownloadUrl(agreementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get latest agreement version that is not signed
     * @param {string} agentId 
     * @param {'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'} agreementType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getLatestAgreementVersionNotSigned(agentId: string, agreementType: 'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING', options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getLatestAgreementVersionNotSigned(agentId, agreementType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new agreements and amendments if they do not exist and return all unsigned agreements for specified agreement definitions
     * @param {string} userId 
     * @param {Array<string>} agreementDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public getUnsignedAgreementsByDefinitions(userId: string, agreementDefinitionId: Array<string>, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).getUnsignedAgreementsByDefinitions(userId, agreementDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resend emails for agreements
     * @param {ResendAgreementEmails} resendAgreementEmails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public resendEmails(resendAgreementEmails: ResendAgreementEmails, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).resendEmails(resendAgreementEmails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search agreements (including buyer-broker agreements)
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {'ASC' | 'DESC'} [sortDirection] 
     * @param {'CREATED_AT' | 'AGREEMENT_TYPE'} [sortBy] 
     * @param {Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>} [agreementType] 
     * @param {Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>} [status] 
     * @param {string} [name] 
     * @param {string} [agreementDefinitionId] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public searchAgreements(pageNumber?: number, pageSize?: number, sortDirection?: 'ASC' | 'DESC', sortBy?: 'CREATED_AT' | 'AGREEMENT_TYPE', agreementType?: Array<'WILLABLE_REVSHARE' | 'LOI' | 'ICA' | 'BUYER_BROKER' | 'UNLICENSED_ASSISTANT_ADDENDUM' | 'LICENSED_ASSISTANT_ADDENDUM' | 'ASSISTANT_ACCESS_AGREEMENT' | 'ASSISTANT_POLICY_AND_PROCEDURES' | 'AGENT_REFERRAL' | 'WALLET_DEBIT' | 'WALLET_CREDIT' | 'WALLET_LENDING'>, status?: Array<'ACTIVE' | 'INCOMPLETE' | 'SIGNED' | 'VOIDED'>, name?: string, agreementDefinitionId?: string, userId?: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).searchAgreements(pageNumber, pageSize, sortDirection, sortBy, agreementType, status, name, agreementDefinitionId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign the agreement
     * @param {AgreementSigningRequest} agreementSigningRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public signAgreement(agreementSigningRequest: AgreementSigningRequest, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).signAgreement(agreementSigningRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign an agreement amendment
     * @param {string} id 
     * @param {string} amendmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public signAgreementAmendment(id: string, amendmentId: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).signAgreementAmendment(id, amendmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Notify the back-end that the given user signed their Willable Revshare agreement
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserAgreementControllerApi
     */
    public willableRevshareAgreementSigned(user: string, options?: AxiosRequestConfig) {
        return UserAgreementControllerApiFp(this.configuration).willableRevshareAgreementSigned(user, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add a new onboarding to the User
         * @param {string} userId 
         * @param {OnboardUserRequest} onboardUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOnboarding: async (userId: string, onboardUserRequest: OnboardUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addOnboarding', 'userId', userId)
            // verify required parameter 'onboardUserRequest' is not null or undefined
            assertParamExists('addOnboarding', 'onboardUserRequest', onboardUserRequest)
            const localVarPath = `/api/v1/users/{userId}/onboardings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(onboardUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve an onboarding
         * @param {string} userId 
         * @param {string} onboardingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOnboarding: async (userId: string, onboardingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('approveOnboarding', 'userId', userId)
            // verify required parameter 'onboardingId' is not null or undefined
            assertParamExists('approveOnboarding', 'onboardingId', onboardingId)
            const localVarPath = `/api/v1/users/{userId}/onboardings/{onboardingId}/approve`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"onboardingId"}}`, encodeURIComponent(String(onboardingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserById', 'id', id)
            const localVarPath = `/api/v1/users/generic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active non reportable users count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveNonReportableUsersByTypeCount: async (userType?: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/active-non-reportable-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get active users by type count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} userType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveUsersByType: async (userType: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userType' is not null or undefined
            assertParamExists('getActiveUsersByType', 'userType', userType)
            const localVarPath = `/api/v1/users/active-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get announce kit types by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnounceKitTypesUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnounceKitTypesUserById', 'id', id)
            const localVarPath = `/api/v1/users/{id}/announce-kit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} userId 
         * @param {string} name 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundExperiment: async (userId: string, name: string, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBoundExperiment', 'userId', userId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('getBoundExperiment', 'name', name)
            const localVarPath = `/api/v1/users/{userId}/experiments/{name}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName !== undefined && xRealAppName !== null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all experiment
         * @param {string} userId 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundExperiments: async (userId: string, xRealAppName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBoundExperiments', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/experiments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xRealAppName !== undefined && xRealAppName !== null) {
                localVarHeaderParameter['X-real-app-name'] = String(xRealAppName);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current generic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGenericUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/generic/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentUserPrincipal: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/myprincipal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGenericUserById', 'id', id)
            const localVarPath = `/api/v1/users/generic/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users by ids (limit 20)
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericUsersByIds: async (userIdsRequest: UserIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIdsRequest' is not null or undefined
            assertParamExists('getGenericUsersByIds', 'userIdsRequest', userIdsRequest)
            const localVarPath = `/api/v1/users/generic/ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the keymaker ids for the Users with the given Yenta ids
         * @param {Array<string>} yentaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeymakerIds: async (yentaIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yentaIds' is not null or undefined
            assertParamExists('getKeymakerIds', 'yentaIds', yentaIds)
            const localVarPath = `/api/v1/users/keymaker-ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yentaIds) {
                localVarQueryParameter['yentaIds'] = yentaIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get personal account
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalAccount: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getPersonalAccount', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/personal-account`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get public user info for a list of users, limited to no more than 20 user ids
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserInfos: async (userIdsRequest: UserIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIdsRequest' is not null or undefined
            assertParamExists('getPublicUserInfos', 'userIdsRequest', userIdsRequest)
            const localVarPath = `/api/v1/users/for-public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserById', 'id', id)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySkySlopeId: async (skySlopeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skySlopeId' is not null or undefined
            assertParamExists('getUserBySkySlopeId', 'skySlopeId', skySlopeId)
            const localVarPath = `/api/v1/users/skySlope/{skySlopeId}`
                .replace(`{${"skySlopeId"}}`, encodeURIComponent(String(skySlopeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the minimal user details needed to render a comment. Can request multiple keymaker ids, limited to no more than 20 ids
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailsForComment: async (keymakerIdsRequest: KeymakerIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keymakerIdsRequest' is not null or undefined
            assertParamExists('getUserDetailsForComment', 'keymakerIdsRequest', keymakerIdsRequest)
            const localVarPath = `/api/v1/users/for-comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(keymakerIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users count
         * @param {boolean} [terminatedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount: async (terminatedOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (terminatedOnly !== undefined) {
                localVarQueryParameter['terminatedOnly'] = terminatedOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users who joined before date count
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersJoinedBeforeDateCount: async (date: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('getUsersJoinedBeforeDateCount', 'date', date)
            const localVarPath = `/api/v1/users/joined-before-date-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Onboard a general user as Agent
         * @param {string} userId 
         * @param {ConvertUserToAgentRequest} convertUserToAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardUserAsAgent: async (userId: string, convertUserToAgentRequest: ConvertUserToAgentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('onboardUserAsAgent', 'userId', userId)
            // verify required parameter 'convertUserToAgentRequest' is not null or undefined
            assertParamExists('onboardUserAsAgent', 'convertUserToAgentRequest', convertUserToAgentRequest)
            const localVarPath = `/api/v1/users/{userId}/onboard-as-agent`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(convertUserToAgentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch user attributes
         * @param {string} id 
         * @param {UserPatchRequest} userPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser: async (id: string, userPatchRequest: UserPatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchUser', 'id', id)
            // verify required parameter 'userPatchRequest' is not null or undefined
            assertParamExists('patchUser', 'userPatchRequest', userPatchRequest)
            const localVarPath = `/api/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/v1/users/forgotpassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up as a generic user
         * @param {SignUpUser} signUpUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpUser: async (signUpUser: SignUpUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpUser' is not null or undefined
            assertParamExists('signUpUser', 'signUpUser', signUpUser)
            const localVarPath = `/api/v1/users/generic/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup: async (signUpRequest: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signup', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/v1/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update personal account
         * @param {UpdatePersonalAccountRequest} updatePersonalAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalAccount: async (updatePersonalAccountRequest: UpdatePersonalAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePersonalAccountRequest' is not null or undefined
            assertParamExists('updatePersonalAccount', 'updatePersonalAccountRequest', updatePersonalAccountRequest)
            const localVarPath = `/api/v1/users/personal-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePersonalAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user by id
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserRequest: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('updateUser', 'updateUserRequest', updateUserRequest)
            const localVarPath = `/api/v1/users/{id}/admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadAvatar: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadAvatar', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAvatar', 'file', file)
            const localVarPath = `/api/v1/users/{id}/avatar/upload`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload User Avatar exactly as provided
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarProvided: async (id: string, file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadAvatarProvided', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadAvatarProvided', 'file', file)
            const localVarPath = `/api/v1/users/{id}/avatar/upload-provided`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add a new onboarding to the User
         * @param {string} userId 
         * @param {OnboardUserRequest} onboardUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOnboarding(userId: string, onboardUserRequest: OnboardUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOnboarding(userId, onboardUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve an onboarding
         * @param {string} userId 
         * @param {string} onboardingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveOnboarding(userId: string, onboardingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveOnboarding(userId, onboardingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active non reportable users count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveNonReportableUsersByTypeCount(userType?: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveNonReportableUsersByTypeCount(userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get active users by type count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} userType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveUsersByType(userType: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveUsersByType(userType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get announce kit types by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnounceKitTypesUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnounceKitTypesUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} userId 
         * @param {string} name 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoundExperiment(userId: string, name: string, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoundExperimentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoundExperiment(userId, name, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all experiment
         * @param {string} userId 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoundExperiments(userId: string, xRealAppName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoundExperimentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoundExperiments(userId, xRealAppName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current generic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentGenericUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentGenericUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCurrentUserPrincipal(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPrincipal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserPrincipal(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenericUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users by ids (limit 20)
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGenericUsersByIds(userIdsRequest: UserIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGenericUsersByIds(userIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all the keymaker ids for the Users with the given Yenta ids
         * @param {Array<string>} yentaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeymakerIds(yentaIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKeymakerIds(yentaIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get personal account
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalAccount(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalAccount(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get public user info for a list of users, limited to no more than 20 user ids
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicUserInfos(userIdsRequest: UserIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublicInfosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicUserInfos(userIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBySkySlopeId(skySlopeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBySkySlopeId(skySlopeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the minimal user details needed to render a comment. Can request multiple keymaker ids, limited to no more than 20 ids
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetailsForComment(keymakerIdsRequest: KeymakerIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDetailsForComment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetailsForComment(keymakerIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users count
         * @param {boolean} [terminatedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersCount(terminatedOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersCount(terminatedOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users who joined before date count
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsersJoinedBeforeDateCount(date: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersJoinedBeforeDateCount(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Onboard a general user as Agent
         * @param {string} userId 
         * @param {ConvertUserToAgentRequest} convertUserToAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardUserAsAgent(userId: string, convertUserToAgentRequest: ConvertUserToAgentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.onboardUserAsAgent(userId, convertUserToAgentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch user attributes
         * @param {string} id 
         * @param {UserPatchRequest} userPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchUser(id: string, userPatchRequest: UserPatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchUser(id, userPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up as a generic user
         * @param {SignUpUser} signUpUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUpUser(signUpUser: SignUpUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUpUser(signUpUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signup(signUpRequest: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signup(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update personal account
         * @param {UpdatePersonalAccountRequest} updatePersonalAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalAccount(updatePersonalAccountRequest: UpdatePersonalAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalAccount(updatePersonalAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user by id
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async uploadAvatar(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAvatar(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload User Avatar exactly as provided
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAvatarProvided(id: string, file: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAvatarProvided(id, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Add a new onboarding to the User
         * @param {string} userId 
         * @param {OnboardUserRequest} onboardUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOnboarding(userId: string, onboardUserRequest: OnboardUserRequest, options?: any): AxiosPromise<OnboardingResponse> {
            return localVarFp.addOnboarding(userId, onboardUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve an onboarding
         * @param {string} userId 
         * @param {string} onboardingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOnboarding(userId: string, onboardingId: string, options?: any): AxiosPromise<OnboardingResponse> {
            return localVarFp.approveOnboarding(userId, onboardingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active non reportable users count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} [userType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveNonReportableUsersByTypeCount(userType?: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: any): AxiosPromise<number> {
            return localVarFp.getActiveNonReportableUsersByTypeCount(userType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get active users by type count
         * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} userType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveUsersByType(userType: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: any): AxiosPromise<number> {
            return localVarFp.getActiveUsersByType(userType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get announce kit types by User ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnounceKitTypesUserById(id: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAnnounceKitTypesUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get experiment by name
         * @param {string} userId 
         * @param {string} name 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundExperiment(userId: string, name: string, xRealAppName?: string, options?: any): AxiosPromise<BoundExperimentResponse> {
            return localVarFp.getBoundExperiment(userId, name, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all experiment
         * @param {string} userId 
         * @param {string} [xRealAppName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundExperiments(userId: string, xRealAppName?: string, options?: any): AxiosPromise<BoundExperimentsResponse> {
            return localVarFp.getBoundExperiments(userId, xRealAppName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current generic user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentGenericUser(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getCurrentGenericUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current user UserPrincipal
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCurrentUserPrincipal(options?: any): AxiosPromise<UserPrincipal> {
            return localVarFp.getCurrentUserPrincipal(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericUserById(id: string, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getGenericUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users by ids (limit 20)
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGenericUsersByIds(userIdsRequest: UserIdsRequest, options?: any): AxiosPromise<UserListResponse> {
            return localVarFp.getGenericUsersByIds(userIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the keymaker ids for the Users with the given Yenta ids
         * @param {Array<string>} yentaIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeymakerIds(yentaIds: Array<string>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getKeymakerIds(yentaIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get personal account
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalAccount(userId: string, options?: any): AxiosPromise<PersonalAccountResponse> {
            return localVarFp.getPersonalAccount(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get public user info for a list of users, limited to no more than 20 user ids
         * @param {UserIdsRequest} userIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicUserInfos(userIdsRequest: UserIdsRequest, options?: any): AxiosPromise<UserPublicInfosResponse> {
            return localVarFp.getPublicUserInfos(userIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(id: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getUserById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by SkySlope id
         * @param {string} skySlopeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySkySlopeId(skySlopeId: string, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.getUserBySkySlopeId(skySlopeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the minimal user details needed to render a comment. Can request multiple keymaker ids, limited to no more than 20 ids
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetailsForComment(keymakerIdsRequest: KeymakerIdsRequest, options?: any): AxiosPromise<Array<UserDetailsForComment>> {
            return localVarFp.getUserDetailsForComment(keymakerIdsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users count
         * @param {boolean} [terminatedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersCount(terminatedOnly?: boolean, options?: any): AxiosPromise<number> {
            return localVarFp.getUsersCount(terminatedOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users who joined before date count
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersJoinedBeforeDateCount(date: string, options?: any): AxiosPromise<number> {
            return localVarFp.getUsersJoinedBeforeDateCount(date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Onboard a general user as Agent
         * @param {string} userId 
         * @param {ConvertUserToAgentRequest} convertUserToAgentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardUserAsAgent(userId: string, convertUserToAgentRequest: ConvertUserToAgentRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.onboardUserAsAgent(userId, convertUserToAgentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch user attributes
         * @param {string} id 
         * @param {UserPatchRequest} userPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchUser(id: string, userPatchRequest: UserPatchRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.patchUser(id, userPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ForgotPassword
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up as a generic user
         * @param {SignUpUser} signUpUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUpUser(signUpUser: SignUpUser, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.signUpUser(signUpUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Apply to be Real agent
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signup(signUpRequest: SignUpRequest, options?: any): AxiosPromise<AgentResponse> {
            return localVarFp.signup(signUpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update personal account
         * @param {UpdatePersonalAccountRequest} updatePersonalAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalAccount(updatePersonalAccountRequest: UpdatePersonalAccountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonalAccount(updatePersonalAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user by id
         * @param {string} id 
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUser(id, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload User Avatar
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        uploadAvatar(id: string, file: any, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.uploadAvatar(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload User Avatar exactly as provided
         * @param {string} id 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAvatarProvided(id: string, file: any, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.uploadAvatarProvided(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add a new onboarding to the User
     * @param {string} userId 
     * @param {OnboardUserRequest} onboardUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public addOnboarding(userId: string, onboardUserRequest: OnboardUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).addOnboarding(userId, onboardUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve an onboarding
     * @param {string} userId 
     * @param {string} onboardingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public approveOnboarding(userId: string, onboardingId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).approveOnboarding(userId, onboardingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUserById(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).deleteUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active non reportable users count
     * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} [userType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getActiveNonReportableUsersByTypeCount(userType?: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getActiveNonReportableUsersByTypeCount(userType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get active users by type count
     * @param {'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC'} userType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getActiveUsersByType(userType: 'AGENT' | 'BROKER' | 'LOAN_OFFICER' | 'GENERIC', options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getActiveUsersByType(userType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get announce kit types by User ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAnnounceKitTypesUserById(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getAnnounceKitTypesUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get experiment by name
     * @param {string} userId 
     * @param {string} name 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getBoundExperiment(userId: string, name: string, xRealAppName?: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getBoundExperiment(userId, name, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all experiment
     * @param {string} userId 
     * @param {string} [xRealAppName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getBoundExperiments(userId: string, xRealAppName?: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getBoundExperiments(userId, xRealAppName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current generic user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentGenericUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentGenericUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current user UserPrincipal
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUserPrincipal(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUserPrincipal(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getGenericUserById(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getGenericUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users by ids (limit 20)
     * @param {UserIdsRequest} userIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getGenericUsersByIds(userIdsRequest: UserIdsRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getGenericUsersByIds(userIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the keymaker ids for the Users with the given Yenta ids
     * @param {Array<string>} yentaIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getKeymakerIds(yentaIds: Array<string>, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getKeymakerIds(yentaIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get personal account
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getPersonalAccount(userId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getPersonalAccount(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get public user info for a list of users, limited to no more than 20 user ids
     * @param {UserIdsRequest} userIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getPublicUserInfos(userIdsRequest: UserIdsRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getPublicUserInfos(userIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserById(id: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by SkySlope id
     * @param {string} skySlopeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserBySkySlopeId(skySlopeId: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserBySkySlopeId(skySlopeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the minimal user details needed to render a comment. Can request multiple keymaker ids, limited to no more than 20 ids
     * @param {KeymakerIdsRequest} keymakerIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserDetailsForComment(keymakerIdsRequest: KeymakerIdsRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUserDetailsForComment(keymakerIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users count
     * @param {boolean} [terminatedOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsersCount(terminatedOnly?: boolean, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUsersCount(terminatedOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users who joined before date count
     * @param {string} date 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsersJoinedBeforeDateCount(date: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getUsersJoinedBeforeDateCount(date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Onboard a general user as Agent
     * @param {string} userId 
     * @param {ConvertUserToAgentRequest} convertUserToAgentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public onboardUserAsAgent(userId: string, convertUserToAgentRequest: ConvertUserToAgentRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).onboardUserAsAgent(userId, convertUserToAgentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch user attributes
     * @param {string} id 
     * @param {UserPatchRequest} userPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public patchUser(id: string, userPatchRequest: UserPatchRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).patchUser(id, userPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ForgotPassword
     * @param {ResetPasswordRequest} resetPasswordRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).resetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up as a generic user
     * @param {SignUpUser} signUpUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public signUpUser(signUpUser: SignUpUser, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).signUpUser(signUpUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Apply to be Real agent
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public signup(signUpRequest: SignUpRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).signup(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update personal account
     * @param {UpdatePersonalAccountRequest} updatePersonalAccountRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updatePersonalAccount(updatePersonalAccountRequest: UpdatePersonalAccountRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updatePersonalAccount(updatePersonalAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user by id
     * @param {string} id 
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUser(id: string, updateUserRequest: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).updateUser(id, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload User Avatar
     * @param {string} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public uploadAvatar(id: string, file: any, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).uploadAvatar(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload User Avatar exactly as provided
     * @param {string} id 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public uploadAvatarProvided(id: string, file: any, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).uploadAvatarProvided(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserJwtControllerApi - axios parameter creator
 * @export
 */
export const UserJwtControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} keymakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJwtInfo: async (keymakerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keymakerId' is not null or undefined
            assertParamExists('getUserJwtInfo', 'keymakerId', keymakerId)
            const localVarPath = `/api/v1/users/{keymakerId}/jwt-info`
                .replace(`{${"keymakerId"}}`, encodeURIComponent(String(keymakerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJwtInfoBulk: async (keymakerIdsRequest: KeymakerIdsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keymakerIdsRequest' is not null or undefined
            assertParamExists('getUserJwtInfoBulk', 'keymakerIdsRequest', keymakerIdsRequest)
            const localVarPath = `/api/v1/users/jwt-info-bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(keymakerIdsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserJwtControllerApi - functional programming interface
 * @export
 */
export const UserJwtControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserJwtControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} keymakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJwtInfo(keymakerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJwtInfo(keymakerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserJwtInfoBulk(keymakerIdsRequest: KeymakerIdsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserJwtInfoBulkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserJwtInfoBulk(keymakerIdsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserJwtControllerApi - factory interface
 * @export
 */
export const UserJwtControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserJwtControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} keymakerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJwtInfo(keymakerId: string, options?: any): AxiosPromise<UserJwtInfoResponse> {
            return localVarFp.getUserJwtInfo(keymakerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KeymakerIdsRequest} keymakerIdsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserJwtInfoBulk(keymakerIdsRequest: KeymakerIdsRequest, options?: any): AxiosPromise<UserJwtInfoBulkResponse> {
            return localVarFp.getUserJwtInfoBulk(keymakerIdsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserJwtControllerApi - object-oriented interface
 * @export
 * @class UserJwtControllerApi
 * @extends {BaseAPI}
 */
export class UserJwtControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} keymakerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserJwtControllerApi
     */
    public getUserJwtInfo(keymakerId: string, options?: AxiosRequestConfig) {
        return UserJwtControllerApiFp(this.configuration).getUserJwtInfo(keymakerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KeymakerIdsRequest} keymakerIdsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserJwtControllerApi
     */
    public getUserJwtInfoBulk(keymakerIdsRequest: KeymakerIdsRequest, options?: AxiosRequestConfig) {
        return UserJwtControllerApiFp(this.configuration).getUserJwtInfoBulk(keymakerIdsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersCheckPermissionsControllerApi - axios parameter creator
 * @export
 */
export const UsersCheckPermissionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission: async (userId: string, action?: 'READ' | 'WRITE', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('checkPermission', 'userId', userId)
            const localVarPath = `/api/v1/users/{userId}/check-permissions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersCheckPermissionsControllerApi - functional programming interface
 * @export
 */
export const UsersCheckPermissionsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersCheckPermissionsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPermission(userId: string, action?: 'READ' | 'WRITE', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPermission(userId, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersCheckPermissionsControllerApi - factory interface
 * @export
 */
export const UsersCheckPermissionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersCheckPermissionsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {'READ' | 'WRITE'} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPermission(userId: string, action?: 'READ' | 'WRITE', options?: any): AxiosPromise<void> {
            return localVarFp.checkPermission(userId, action, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersCheckPermissionsControllerApi - object-oriented interface
 * @export
 * @class UsersCheckPermissionsControllerApi
 * @extends {BaseAPI}
 */
export class UsersCheckPermissionsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {'READ' | 'WRITE'} [action] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersCheckPermissionsControllerApi
     */
    public checkPermission(userId: string, action?: 'READ' | 'WRITE', options?: AxiosRequestConfig) {
        return UsersCheckPermissionsControllerApiFp(this.configuration).checkPermission(userId, action, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WorkvivoControllerApi - axios parameter creator
 * @export
 */
export const WorkvivoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Workvivo JWT for a given agentId
         * @param {string} agentId 
         * @param {boolean} [mobile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt: async (agentId: string, mobile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agentId' is not null or undefined
            assertParamExists('generateJwt', 'agentId', agentId)
            const localVarPath = `/api/v1/workvivo/{agentId}/jwt`
                .replace(`{${"agentId"}}`, encodeURIComponent(String(agentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkvivoControllerApi - functional programming interface
 * @export
 */
export const WorkvivoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkvivoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Workvivo JWT for a given agentId
         * @param {string} agentId 
         * @param {boolean} [mobile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateJwt(agentId: string, mobile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkvivoJwtResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateJwt(agentId, mobile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WorkvivoControllerApi - factory interface
 * @export
 */
export const WorkvivoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkvivoControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Workvivo JWT for a given agentId
         * @param {string} agentId 
         * @param {boolean} [mobile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateJwt(agentId: string, mobile?: boolean, options?: any): AxiosPromise<WorkvivoJwtResponse> {
            return localVarFp.generateJwt(agentId, mobile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkvivoControllerApi - object-oriented interface
 * @export
 * @class WorkvivoControllerApi
 * @extends {BaseAPI}
 */
export class WorkvivoControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get Workvivo JWT for a given agentId
     * @param {string} agentId 
     * @param {boolean} [mobile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkvivoControllerApi
     */
    public generateJwt(agentId: string, mobile?: boolean, options?: AxiosRequestConfig) {
        return WorkvivoControllerApiFp(this.configuration).generateJwt(agentId, mobile, options).then((request) => request(this.axios, this.basePath));
    }
}


